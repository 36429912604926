import { AxiosDrupalClient, AxiosServerInstance, AxiosClient } from './httpClient';
import { Buffer } from 'buffer';

export default class DrupalAPI {
  static login(username: string, password: string) {
    const data = {
      name: username,
      pass: password
    };

    return AxiosDrupalClient.post(`/user/login?_format=json`, data);
  }

  static getJWT(username: string, password: string) {
    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
    return AxiosDrupalClient.get(`/jwt/token`, undefined, { 'Authorization': 'Basic ' + token });
  }

  static refreshJWT() {
    return AxiosServerInstance.get(`/jwt/token`, undefined);
  }

  static foo() {}
}
