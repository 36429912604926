import React, { useEffect } from 'react';
import './App.css';
import ImportCMSPages from './components/pages/import-cms-pages/import-cms-pages';
import ImportPage from './components/import-page/import-page';
import ImportRecurringDonations from './components/import-recurring-donations/import-recurring-donations';
import ImportDetail from './components/import-detail/import-detail';
import ImportHistory from './components/history-page/history-page';
import LoginPage from './components/login/login';
import CampaignManager from './components/campaign/campaign-manager/campaign-manager';
import CouponDetailPage from './components/coupon-detail/coupon-detail';
import RegionManager from './components/region/region-manager/region-manager';  
import FAQsManager from './components/global-contents/faqs/faqs-manager';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation
} from "react-router-dom";
import LandManagerComponent from './components/land-manager/land-manager';
import CouponManagerComponent from './components/coupon-manager/coupon-manager'
import PartnerManagerComponent from './components/partner-manager/partner-manager';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import BuilderPage from './components/pages/builder-page';
import DonationManager from './components/donation-manager/donation-manager';
import PageManager from './components/pages/page-manager/page-manager';
import PageComponent from './components/common/page';
import CMSPagePreview from './components/pages/cms-page-preview/preview-page';
import BlockManager from './components/blocks/block-manager';
import CreateBlockManager from './components/blocks/components/create/create-block-manager';
import NotFoundPage from './components/404/404-component';
import ContactCardManager from "./components/global-contents/contact/contact-manager";
import HeadersManager from './components/global-contents/header-manager/headers-manager';
import FootersManager from './components/global-contents/footer-manager/footers-manager';
import PageDetails from './components/pages/page-details';
import DonationExamplesManager from './components/global-contents/donation-example/donation-examples-manager';
import { useIdleTimer } from 'react-idle-timer';
import { DrupalAPI } from './services';
import MediaManager from './components/global-contents/media-manager/media-manager';
import QuoteManager from './components/global-contents/quote-manager/quote-manager';
import PartnerTypeManager from './components/global-contents/partner-type-manager/partner-type-manager';
import GlobalBlockManager from './components/global-contents/global-block/global-block';
import GeoImportHistory from './components/geo-import/geo-import';
import ImportGeoForm from './components/geo-import/geo-import-form/geo-import-form';
import GeoImportDetail from './components/geo-import/geo-import-details/geo-import-details';
import CreateCampaignManager from './components/campaign/components/create-campaign-component';
import CreateLandManager from './components/land-manager/components/create-land-manager';
import DonationDetail from './components/donation-manager/components/donation-detail/donation-detail';
import CreatePartnerManager from './components/partner-manager/components/create-partner-manager';
import EditDonationDetail from './components/donation-manager/components/edit-donation-detail/edit-donation-detail';
import EditDonationDetailV2 from './components/donation-manager/components/edit-donation-detail/edit-donation-detail-v2';
import MailingsManager from './components/mailings/mailings-component';
import MailingDetail from './components/mailings/components/mailing-details/mailing-details';
import CreateCouponManager from './components/coupon-manager/components/create-coupon-manager';
import ReceiptManager from './components/receipts/receipt-manager';
import SubscriptionsManager from './components/subscription/subscription-component';
import CreateContactManager from './components/global-contents/contact/components/create-contact-manager';
import AddDonationComponent from './components/donation-manager/components/add-donation/add-donation';
import DonorManager from "./components/donor-manager/donor-manager";
import WildernessRunManager from './components/wilderness-run-manager/wilderness-run-manager';
import WildernessRunDetails from './components/wilderness-run-details/wilderness-run-details';
import CertificateManager from './components/certificate-manager/certificate-manager';
import TeamsDetails from './components/teams-details/teams-details';
import RunnersDetails from './components/runners-details/runners-details';
import ImportOldDonationsPage from './components/import-old-data/import-old-donations-page';
import ImportCampaignsPage from './components/import-campaigns/import-campaigns-page';
import GoalManager from './components/goal-manager/goal-manager';
import CreateRegionManager from './components/region/components/create-region-component';
import RecurringYearlyManager from './components/recurring-yearly-manager/recurring-yearly-manager';
import PowerBIReport from './components/power-bi-report/power-bi-report';

const ProtectedRoute = ({
  redirectPath = '/login',
  children,
}: any) => {
  const userData = localStorage.getItem('access_token');
  const location = useLocation();
  if (!userData) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return children;
};

const Dashboard = () => {
  return (
    <Outlet />
  )
}

function App() {
  const timeout = 1000 * 5 * 60;
  const promptTimeout = 1000 * 30;

  const onActive = () => {
    DrupalAPI.refreshJWT();
  }

  const onIdle = () => {
    // console.log('idle', getLastActiveTime());
  }

  const { getRemainingTime, getLastActiveTime, isPrompted, activate } = useIdleTimer({
    timeout,
    onActive,
    onIdle,
    // crossTab: true,
    // syncTimers: 200,
    name: 'activity-timer'
  });

  activate();

  useEffect(() => {
    console.log(getRemainingTime())
  }, [getRemainingTime])

  return (
    <Router>
      <div className="App">
        <div className="app-container">
        <Routes>
          <Route 
            element={
            <ProtectedRoute>
              <Dashboard/>
            </ProtectedRoute> 
          }
          path="/"
          key="DashboardPage"
          >
            <Route path="/" element={<PageComponent component={PowerBIReport} title="Dashboard" />} />
            <Route path="/import" element={<PageComponent component={ImportPage} title="Import" />} />
            <Route path="/import-old-donations" element={<PageComponent component={ImportOldDonationsPage} title="Import Old Data" />} />
            <Route path="/import-recurring-donations" element={<PageComponent component={ImportRecurringDonations} title="Import Recurring Donations" />} />
            <Route path="/import-cms-pages" element={<PageComponent component={ImportCMSPages} title="Import CMS Pages" />} />
            <Route path="/import-campaigns" element={<PageComponent component={ImportCampaignsPage} title="Import Campaigns" />} />
            <Route path="/geo-coordinate-imports" element={<PageComponent component={GeoImportHistory} title="Import Geo Coordinate" />} />
            <Route path="/import-detail/:id" element={<PageComponent component={ImportDetail} title="Import Detail" />} />
            <Route path="/geo-coordinate-imports/new" element={<PageComponent component={ImportGeoForm} title="Geo Coordinate Detail" />} />
            <Route path="/geo-coordinate-imports/:id" element={<PageComponent component={GeoImportDetail} title="Geo Coordinate Detail" />} />
            <Route path="/donations" element={<PageComponent component={DonationManager} title="Donation" />} />
            <Route path="/subscriptions/geo" element={<PageComponent component={RecurringYearlyManager} title="Yearly GEO Booking" />} />
            <Route path="/donations/new" element={<PageComponent component={AddDonationComponent} title="Add Donation" />} />
            <Route path="/donors" element={<PageComponent component={DonorManager} title="Donor" />} />
            <Route path="/donations/:id" element={<PageComponent component={DonationDetail} title="Donation details" />} />
            <Route path="/edit-donation/:id" element={<PageComponent component={EditDonationDetail} title="Edit donation details" />} />
            <Route path="/edit-donations/:id" element={<PageComponent component={EditDonationDetailV2} title="Edit donation details" />} />
            <Route path="/areas" element={<PageComponent component={LandManagerComponent} title="Area" />} />
            <Route path="/areas/:id" element={<PageComponent component={CreateLandManager} title="Area Detail" />} />
            <Route path="/areas/create" element={<PageComponent component={CreateLandManager} title="Create Area" />} />
            <Route path="/campaigns" element={<PageComponent component={CampaignManager} title="Campaign" />} />
            <Route path="/subscriptions" element={<PageComponent component={SubscriptionsManager} title="Subscription" />} />
            <Route path="/campaigns/create" element={<PageComponent component={CreateCampaignManager} title="Create Campaign" />} />
            <Route path="/campaigns/:id" element={<PageComponent component={CreateCampaignManager} title="Campaign Detail" />} />
            <Route path="/codes" element={<PageComponent component={CouponManagerComponent} title="Code" />} />
            <Route path="/partners" element={<PageComponent component={PartnerManagerComponent} title="Partner" />} />
            <Route path="/partners/:id" element={<PageComponent component={CreatePartnerManager} title="Update Partner" />} />
            <Route path="/partners/create" element={<PageComponent component={CreatePartnerManager} title="Create Partner" />} />
            <Route path="/codes/:id" element={<PageComponent component={CouponDetailPage} title="Code Detail" />} />
            <Route path="/codes/create" element={<PageComponent component={CreateCouponManager} title="Create Code" />} />
            <Route path="/regions" element={<PageComponent component={RegionManager} title="Region" />} />
            <Route path="/regions/create" element={<PageComponent component={CreateRegionManager} title="Create Region" />} />
            <Route path="/regions/:id" element={<PageComponent component={CreateRegionManager} title="Update Region" />} />
            <Route path="/pages" element={<PageComponent component={PageManager} title="Pages" />} />
            <Route path="/builder-page" element={<PageComponent component={PageDetails} title="CMS Builder Page" />} />
            <Route path="/builder-page/:id" element={<PageComponent component={PageDetails} title="CMS Builder Page Detail" />} />
            <Route path="/builder-page/:id/content" element={<PageComponent component={BuilderPage} title="CMS Builder Page Detail" />} />
            <Route path="/builder-page/:id/preview" element={<PageComponent component={CMSPagePreview} title="CMS Builder Page Preview" />} />
            {/* <Route path="/test" element={<PageComponent component={DynamicForm} title="Test" />} /> */}
            <Route path="/blocks" element={<PageComponent component={BlockManager} title="Block Builder Page" />} />
            <Route path="/blocks/create" element={<PageComponent component={CreateBlockManager} title="Create Block Builder Page" />} />
            <Route path="/blocks/update/:id" element={<PageComponent component={CreateBlockManager} title="Update Block Builder Page" />} />
            <Route path="/404" element={<PageComponent component={NotFoundPage} title="404" />} />
            <Route path="/global-contents/faqs" element={<PageComponent component={FAQsManager} title="FAQs" />} />
            <Route path="/global-contents/people" element={<PageComponent component={ContactCardManager} title="People Manager" />} />
            <Route path="/global-contents/people/:id" element={<PageComponent component={CreateContactManager} title="Update People" />} />
            <Route path="/global-contents/people/create" element={<PageComponent component={CreateContactManager} title="Create People" />} />
            <Route path="/global-contents/headers-manager" element={<PageComponent component={HeadersManager} title="Header Manager" />} />
            <Route path="/global-contents/footers-manager" element={<PageComponent component={FootersManager} title="Footer Manager" />} />
            <Route path="/global-contents/donation-examples" element={<PageComponent component={DonationExamplesManager} title="Donation Example" />} />
            <Route path="/global-contents/footers" element={<PageComponent component={FootersManager} title="Footers" />} />
            <Route path="/global-contents/media" element={<PageComponent component={MediaManager} title="Media Manager"/>} />
            <Route path="/global-contents/quotes" element={<PageComponent component={QuoteManager} title="Quote Manager"/>} />
            <Route path="/global-contents/partner-types" element={<PageComponent component={PartnerTypeManager} title="Partner Type Manager"/>} />
            <Route path="/global-contents/global-blocks" element={<PageComponent component={GlobalBlockManager} title="Global Blocks" />} />
            <Route path="/recurring-donations/mailings" element={<PageComponent component={MailingsManager} title="Mailings" />} />
            <Route path="/recurring-donations/mailings/:id" element={<PageComponent component={MailingDetail} title="Mailing Details" />} />
            <Route path="/utilities/receipt" element={<PageComponent component={ReceiptManager} title="Receipts Manager" />} />
            <Route path="/wilderness-runs" element={<PageComponent component={WildernessRunManager} title="Wilderness Runs Manager" />} />
            <Route path="/wilderness-runs/:id" element={<PageComponent component={WildernessRunDetails} title="Wilderness Run Details" />} />
            <Route path="/utilities/certificate" element={<PageComponent component={CertificateManager} title="Certificate Manager"/>} />
            <Route path="/wilderness-runs/:runId/teams/:id" element={<PageComponent component={TeamsDetails} title="Teams Details"/>} />
            <Route path="/wilderness-runs/:runId/teams/:id/runners/:id" element={<PageComponent component={RunnersDetails} title="Runners Details"/>} />
            <Route path="/utilities/goals" element={<PageComponent component={GoalManager} title="Goals Manager" />} />
          </Route>
          <Route path="/login" element={<PageComponent component={LoginPage} title="Login" />} />
        </Routes>
        </div>
      </div>
    </Router>
  );
}

library.add(fas);
export default App;
