import { Dialog } from "primereact/dialog";
import { useRef } from "react";
import React from "react";
import { Toast } from "primereact/toast";
import UpdateDonationFormComponent from "../update-donation-form/update-donation-form";

const DonationDialogComponent = (props: any) => {
    const { visible, onHide, donation, fetchDonationsData } = props;
    const toast = useRef(null);

    return (
        <>
          <Toast ref={toast} />
          <Dialog
            header={"Update Donation"}
            visible={visible}
            style={{ width: "700px" }}
            modal
            className="wi-dialog"
            onHide={onHide}
          >
            <UpdateDonationFormComponent
              donation={donation}
              onHide={onHide}
              fetchDonationsData={fetchDonationsData}
              toast={toast}
            />
          </Dialog>
        </>
      );
};

export default DonationDialogComponent;