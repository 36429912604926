import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import "./media-info.scss";
import DynamicForm from "../../../../dynamic-form/dynamic-form";
import _ from "lodash";
import { MediaManagerAPI, MediaV2ManagerAPI } from "../../../../../services";
import { AxiosResponse } from "axios";
import { showNotification } from "../../../../../utils/logic";
import {
  formatFileSize,
  formatImageFileURL,
  getOriginalFileName,
  IconConfig,
} from "../../../../../utils/mediaUtils";
import copy from "copy-to-clipboard";
import { env } from "../../../../../environment";

const MediaInfo = (props: any) => {
  const { data, refresh, toast, setIsLoading } = props;
  const [copyText, setCopyText] = useState("");

  const fileUrl =
    data.selectedMedia && data.selectedMedia.fullPath
      ? `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(
          data.selectedMedia.fullPath
        )}`
      : "";

  const previewFileURL =
    data.selectedMedia && data.selectedMedia.type === "Image"
      ? `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(data.selectedMedia.fullPath)}`
      : IconConfig[data.selectedMedia?.file_extension];

  const onSubmitDataForm = async (value: any) => {
    try {
      setIsLoading(true);
      const resGenerateMedia = await resquestMediaData(value);
      handleRequestMedia(resGenerateMedia);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showNotification("error", "Failed to update file", toast);
    }
  };

  const resquestMediaData = async (value: any) => {
    // @ts-ignore: Object is possibly 'null'.
    const mediaObje = data.selectedMedia;
    let request = { ...mediaObje, ...value };

    return updateMedia({
      ...request,
    });
  };

  const handleRequestMedia = (resGenerateMedia: AxiosResponse<any, any>) => {
    // @ts-ignore: Object is possibly 'null'.
    const response = resGenerateMedia;
    const method = response.config.method;

    if (response && response.status === 200 && method === "put") {
      const mesage = "Update file successfully";
      showNotification("success", mesage, toast);
      refresh();
    } else {
      showNotification("error", "Failed to update file", toast);
    }
  };

  const updateMedia = async (parameters: any) => {
    delete parameters["file_size"];
    delete parameters["file"];
    delete parameters[`${parameters["file"]}_extension`];
    
    let updateData = {
      uuid: parameters.uuid,
      alternativeText: JSON.stringify(parameters.alternativeText),
      description: JSON.stringify(parameters.description),
      folderId: parameters.folder_id,
      fullPath: parameters.fullPath,
      metadata: JSON.stringify(parameters.metadata),
      name: parameters.name,
      size: parameters.size,
      type: parameters.type,
      title: JSON.stringify(parameters.title)
    }
    
    return await MediaV2ManagerAPI.updateFile(updateData, false);
  };

  useEffect(() => {
    if (data.selectedMedia) setCopyText(fileUrl);
  }, [data.selectedMedia, fileUrl]);

  const copyToClipboard = () => {
    copy(copyText);
    showNotification("success", "URL is copied", toast);
  };

  return (
    <div className="media-details">
      <div className="file-preview">
        <img
          src={`
        ${
          data.selectedMedia?.type === "Image"
            ? `${encodeURI(previewFileURL)}?v=${moment().seconds()}`
            : `${encodeURI(previewFileURL)}`
        }`}
          alt=""
          className={`fixed-frame-image image-w-${
            data.selectedMedia?.type === "Image" ? "90" : "25"
          } image-bg-${data.selectedMedia?.file_extension}`}
          loading="lazy"
        />
      </div>

      <div className="audit-info">
        <div className="row audit-info-text">
          <div className="col-4">
            <strong>Type</strong>
          </div>
          <div className="col-8">{data.selectedMedia?.type}</div>
          <div className="col-4">
            <strong>Name</strong>
          </div>
          <div className="col-8">
            {getOriginalFileName(data.selectedMedia?.name)}
          </div>
          <div className="col-4">
            <strong>Size</strong>
          </div>
          <div className="col-8">
            {formatFileSize(data.selectedMedia?.size)}
          </div>
          <div className="col-4">
            <strong>Uploaded on</strong>
          </div>
          <div className="col-8">
            {moment(data.selectedMedia?.createdAt).format("DD MMM y hh:mm:ss")}
          </div>
          <div className="col-4">
            <strong>Uploaded by</strong>
          </div>
          <div className="col-8">{data.selectedMedia?.createdUser}</div>
          <div className="col-4">
            <strong>File URL</strong>
          </div>
          <div className="col-8">
            {fileUrl}
            <button
              className="p-sidebar-icon p-link"
              title="Copy to Clipboard"
              onClick={copyToClipboard}
            >
              <span
                className="fa-solid fa-copy"
                title="Copy to Clipboard"
                style={{ color: "#13735f", padding: "5px" }}
              />
            </button>
          </div>

          {data.selectedMedia?.metadata &&
            data.selectedMedia?.metadata?.camera_model && (
              <>
                <div className="col-4">
                  <strong>Camera model</strong>
                </div>
                <div className="col-8">
                  {data.selectedMedia.metadata.camera_model}
                </div>
              </>
            )}
          {data.selectedMedia?.metadata &&
            data.selectedMedia?.metadata.shoot_date && (
              <>
                <div className="col-4">
                  <strong>Shot date</strong>
                </div>
                <div className="col-8">
                  {data.selectedMedia.metadata.shoot_date}
                </div>
              </>
            )}
          {data.selectedMedia?.metadata &&
            data.selectedMedia?.metadata.xResolution > 0 && (
              <>
                <div className="col-4">
                  <strong>xResolution</strong>
                </div>
                <div className="col-8">
                  {data.selectedMedia.metadata.xResolution} dpi
                </div>
              </>
            )}
          {data.selectedMedia?.metadata &&
            data.selectedMedia?.metadata.yResolution > 0 && (
              <>
                <div className="col-4">
                  <strong>yResolution</strong>
                </div>
                <div className="col-8">
                  {data.selectedMedia.metadata.yResolution} dpi
                </div>
              </>
            )}
          {data.selectedMedia?.metadata &&
            data.selectedMedia?.type === "Image" &&
            data.selectedMedia?.metadata.dimensions && (
              <>
                <div className="col-4">
                  <strong>Dimensions (width x height)</strong>
                </div>
                <div className="col-8">
                  {`${data.selectedMedia.metadata.dimensions.width} x ${data.selectedMedia.metadata.dimensions.height}`}
                </div>
              </>
            )}
        </div>
      </div>
      <div className="media-">
        <DynamicForm
          formSchema={MediaBlock?.configuration || {}}
          data={
            data.selectedMedia && data.selectedMedia.metadata
              ? _.cloneDeep(data.selectedMedia)
              : MediaBlock?.parameters
          }
          submitButtonConfig={{
            title: "Save",
            icon: "pi pi-chevron-right",
            iconPos: "right",
          }}
          onSubmitData={onSubmitDataForm}
          mode="dialog"
        ></DynamicForm>
      </div>
    </div>
  );
};

export default MediaInfo;

export const MediaBlock = {
  configuration: {
    alternativeText: {
      type: "textField",
      label: "Alternative Text",
      supportMultiLanguages: ["de", "en"],
      maxLength: 500,
    },
    title: {
      type: "textField",
      label: "Title",
      supportMultiLanguages: ["de", "en"],
      maxLength: 200,
    },
    description: {
      type: "textFieldCustom",
      label: "Description",
      supportMultiLanguages: ["de", "en"],
      maxLength: 500,
    },
    metadata: {
      type: "object",
      isGlobalContentRef: false,
      children: {
        originator: {
          type: "textField",
          label: "Creator",
        },
        copyright: {
          type: "textField",
          label: "Copyright",
        },
      },
    },
    xssConfiguration: {
      description: "textFieldCustom",
    },
  },
  parameters: {
    alternativeText: {
      de: "",
      en: "",
    },
    title: {
      de: "",
      en: "",
    },
    description: {
      de: "",
      en: "",
    },
    metadata: {
      originator: "",
      copyright: "",
    },
  },
};
