import * as React from "react";
import { useState, useEffect, useRef } from "react";
import "./mailings-component.scss";
import Layout from "../layout/layout";
import { Toast } from "primereact/toast";
import { useNavigate } from 'react-router-dom';
import { RecurringDonationAPI } from "../../services";

const MailingsManager = (props: any) => {

  const navigate = useNavigate();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mailings, setMailings] = useState({
    data: [],
    totalPage: 0,
  });

  const fetchMailingsData = async () => {
    setIsLoading(true);
    try {
        const res = await RecurringDonationAPI.getMailings();
        if (res && res.status === 200) {
          setIsLoading(false);
          setMailings({
            data: res.data.records,
            totalPage: res.data.records?.length || 0,
          });
        }
      } catch (error) {
        setIsLoading(false);
        setMailings({
          data: [],
          totalPage: 0
        })
      }
  };

  const navigateToDetail = (rowData: any) => {
    navigate(`/recurring-donations/mailings/${rowData.year_donation}`)
  };


  useEffect(() => {
    fetchMailingsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderWidgetMailings = (mailing :any, index :any) => {
    return (
      <div key={index} className={`widget-mailing col-md-2 status-${mailing?.status === 'not_yet' ? 'new' : mailing?.status}`} onClick={() => navigateToDetail(mailing)}>
          <div className={`widget-mailing-container`}>
              {mailing?.year_donation}
          </div>
      </div>
    )
  }

  return (
    <Layout>
      <div className="mailing-manager">
        <div className="manager-container">
        <div className="headline">
            <h3>Mailings Manager</h3>
          </div>
          <div className="table-mailing row">
            {mailings.data && mailings.data.length > 0 ? mailings.data.map((mailing: any, index: any) => renderWidgetMailings(mailing, index)) : <div className="mailings-empty">Mailings is unavailable.</div> }
          </div>
        </div>
      </div>
      <Toast ref={toast} />
    </Layout>
  );
};

export default MailingsManager;
