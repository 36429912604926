import { Toast } from "primereact/toast";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import "./edit-donation-detail.scss";
import { Button } from "primereact/button";
import _ from "lodash";
import Layout from "../../../layout/layout";
import { DonationManagerAPI } from "../../../../services";
import { WITag } from "../../../common";
import { Formik } from "formik";
import { Dropdown } from 'primereact/dropdown';
import { DONATION_STATUS_LIST, RHYTHM_LIST, DONATION_PAYMENT_METHOD_LIST } from "../../../../utils/utils";
import { Menu } from 'primereact/menu';
import { ProgressSpinner } from 'primereact/progressspinner';
import m from 'moment';
import { showNotification } from '../../../../utils/logic';
import { updateDonationData } from "../../donation-manager.util";

const EditDonationDetail = (props: any) => {
    const donationID = window.location.pathname ? window.location.pathname.split("/")[2] : '';
    const [isLoading, setIsLoading] = useState(false);
    const [donation, setDonation] = useState<any>();
    const menu = useRef(null);
    const toast = useRef(null);
    const [isEditDonation, setIsEditDonation] = useState(false);
    const formikRef = useRef(null);
    const [isError, setIsError] = useState({
        paymentDate: false,
        paymentReceviedDate: false
    });

    const itemsDonationAction = [
        {
            disabled: isEditDonation,
            label: 'Edit',
            icon: 'pi pi-file-edit',
            command: () => setIsEditDonation(true),
            className: 'item-menu'
        },
        {
            disabled: isEditDonation || donation?.nw_geo_coordinate,
            label: 'Book Geo-coordinate',
            icon: 'pi pi-file-edit',
            command: () => bookDonationGEO(donationID),
            className: 'item-menu'
        }
    ];

    const bookDonationGEO = async (id: string) => {
        setIsLoading(true);
        try {
            const res = await DonationManagerAPI.bookDonationGeo(donationID);
            showNotification("success", "Booked Geo-coordinate successfully", toast);
            setIsLoading(false);
        } catch (e) {
            showNotification("error", "Failed to book Geo-coordinate", toast);
            setIsLoading(false);
        }
    }

    const fetchDonationDetail = async () => {
        setIsLoading(true);
        try {
            const res = await DonationManagerAPI.getDonation(donationID);
            if (res && res.status === 200) {
                const donationData = updateDonationData(res.data);

                setDonation(donationData);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const updateDonationDetail = async () => {
        try {
            // @ts-ignore: Object is possibly 'null'.
            const { values, dirty, errors } = formikRef.current;

            if (dirty && _.isEmpty(errors) && !isError.paymentDate && !isError.paymentReceviedDate) {
                setIsLoading(true);

                const donationUpdate = {
                    ...values,
                    updated_at: m.utc(m(new Date()).format("MM/DD/YYYY HH:mm:ss")),
                    land_id: values.land_id ? values.land_id : null,
                    campaign_id: values.campaign_id ? values.campaign_id : null,
                    cert_template_id: values.cert_template_id ? values.cert_template_id : null,
                    subscription_id: values.subscription_id ? values.subscription_id : null,
                    parent_donation_id: values.parent_donation_id ? values.parent_donation_id : null,
                }

                setIsError({
                    paymentDate: false,
                    paymentReceviedDate: false
                })

                const resUpdate = await DonationManagerAPI.updateDonation(donationID, donationUpdate);
                if (resUpdate && resUpdate.status === 200) {
                    await fetchDonationDetail();
                    setIsEditDonation(false);
                    setIsLoading(false);
                    showNotification("success", "Update donation details successfully", toast);
                } else {
                    setIsLoading(false);
                    showNotification("error", "Failed to update donation details", toast);
                }
            }
        } catch (error) {
            setIsLoading(false);
            showNotification("error", "Failed to update donation details", toast);
        }
    }

    const initialDonationFormValues = (): any => {
        return { ...donation, payment_method: donation?.payment_method_code };
    };

    const fields = [
        { label: 'Money', field: 'donation_money', type: 'number' },
        { label: 'Forest Size', field: 'area', type: 'number' },
        { label: 'Amount Of CO2', field: 'amount_kg_co2', type: 'number' },
        { label: 'Region Id', field: 'region_id' },
        { label: 'Land Id', field: 'land_id' },
        { label: 'Campaign Id', field: 'campaign_id' },
        { label: 'NW Geo-coordinate', field: 'nw_geo_coordinate' },
        { 
            label: 'Status', field: 'status', type: 'dropdown',
            options: DONATION_STATUS_LIST, optionLabel: 'name.de', optionValue: 'code' 
        },
        { 
            label: 'Type', field: 'donation_type', type: 'dropdown',
            options: [{code: 'land', name: 'Region'}, {code: 'campaign', name: 'Campaign'}, {code: 'code', name: 'Code'}], 
            optionLabel: 'name', optionValue: 'code' },
        { 
            label: 'Rhythm', field: 'rhythm', type: 'dropdown',
            options: RHYTHM_LIST, optionLabel: 'name', optionValue: 'code' 
        },
        { 
            label: 'Method', field: 'payment_method', type: 'dropdown',
            options: DONATION_PAYMENT_METHOD_LIST, optionLabel: 'name.de', optionValue: 'code',
        },
        { label: 'Transaction Id', field: 'payment_transaction_id' },
        { label: 'Payment Date', field: 'payment_date' },
        { label: 'Payment Received Date', field: 'payment_received_date' },
        { label: 'Company Name', field: 'payment_company_name' },
        { label: 'Certificate Name', field: 'personalize_certificate_display_name' },
        { label: 'Certificate Occasion', field: 'personalize_certificate_reason' },
        { label: 'Subscription Id', field: 'subscription_id' },
        { label: 'Parent Donation Id', field: 'parent_donation_id' },
        { label: 'Certificate Template Id', field: 'cert_template_id' },
    ]

    useEffect(() => {
        fetchDonationDetail();
    }, []);

    return (
        <Layout>
            <Toast ref={toast} />
            {isLoading ? (
                <div className="loading-component">
                    <ProgressSpinner />
                </div>
            ) : (
                <div className="donation-detail">
                    <div className="donation-detail__header">
                        <div className="header-container">
                            <h3>Donation Details</h3>
                            <div className="btn-action-container">
                                <div className="donation-serial">
                                    {donation?.donation_serial || ''}
                                </div>
                                <Menu model={itemsDonationAction} popup ref={menu} id="popup_menu" />
                                <Button
                                    icon="pi pi-ellipsis-v"
                                    // @ts-ignore: Object is possibly 'null'.
                                    onClick={(event) => menu.current.toggle(event)}
                                    aria-controls="popup_menu"
                                    aria-haspopup
                                />
                            </div>
                        </div>
                        <div className="row row-amount">
                            <div className="amount">
                                <div className="amount-container">
                                    <div className="value-amount"> {donation ? parseFloat(donation.donation_money?.toString())?.toLocaleString('de-DE') : ''} </div>
                                    <div className="currency-amount">{donation?.payment_currency_code?.toUpperCase() || ''} </div>
                                    <div className="status-amount"> <WITag value={donation?.status}></WITag></div>
                                </div>
                                {
                                    isEditDonation &&
                                    <div className="edit-action">
                                        <div className="save-action">
                                            <Button
                                                type="submit"
                                                icon="pi pi-check"
                                                className="p-button-success"
                                                onClick={() => updateDonationDetail()}
                                            />
                                        </div>
                                        <div className="cancel-action">
                                            <Button
                                                icon="pi pi-times"
                                                className="p-button-danger"
                                                onClick={() => {
                                                    // @ts-ignore: Object is possibly 'null'.
                                                    formikRef.current.resetForm();
                                                    setIsEditDonation(false)
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="donation-detail__content">
                        <Formik
                            innerRef={formikRef}
                            enableReinitialize={true}
                            initialValues={initialDonationFormValues()}
                            onSubmit={(values, actions) => {
                                // nothing to do
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="donation-detail__container">
                                        <div className="row first-row">
                                            <div className="col-12 col-md-12 donation-item">
                                                <div className="donation-detail__information">
                                                    <div className="donation-detail__information-title">
                                                        <h5>Donation Information</h5>
                                                    </div>
                                                    <div className="donation-detail__information-content">
                                                        {
                                                            fields.map((f: any) => {
                                                                return (
                                                                    <div className="row content-row">
                                                                        <div className="col-md-2">
                                                                            <label>{f.label}</label>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            {
                                                                                isEditDonation ?
                                                                                    <div className="edit-donation-field">
                                                                                        { f.type !== 'dropdown' ? <input
                                                                                            className={`${touched?.[f.field] && errors?.[f.field] ? 'has-error' : ''}`}
                                                                                            name={f.field}
                                                                                            type={f.type}
                                                                                            onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            value={values?.[f.field]}
                                                                                        />
                                                                                        : <Dropdown
                                                                                            name={f.field}
                                                                                            value={values?.[f.field]}
                                                                                            options={f?.options}
                                                                                            optionLabel={f?.optionLabel}
                                                                                            optionValue={f?.optionValue}
                                                                                            appendTo="self"
                                                                                            showClear
                                                                                            onChange={(item) => {
                                                                                                setFieldValue(f.field, item && item.value ? item.value : null, false);
                                                                                            }}
                                                                                        />}
                                                                                    </div> :
                                                                                    <p>{donation ? donation?.[f.field] : '-'}</p>
                                                                            }
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p>{f.suggestions}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default EditDonationDetail;
