import { Tree } from "primereact/tree";
import React, { Fragment, useEffect, useState } from "react";
import { MediaFolderManagerAPI } from "../../../../../services";
import { showNotification } from "../../../../../utils/logic";
import { WIConfirmDialog } from "../../../../common";
import CreateDirectoryDialogComponent from "../create-directory-dialog/create-diretory-dialog";
import "./directory.scss";

const Directory = (props: any) => {
  const { toast, fetchMediaFolder, updateSelectedFolder, data, refresh, isAddFolderVisibility, isDeleteFolderVisibility, isAllNodes } = props;
  const [expandedKeys, setExpandedKeys] = useState({});
  const allNodes = data.nodes;
  const [selectedNodeKey, setSelectedNodeKey] = useState<any>(null);
  const [selectedNode, setSelectedNode] = useState<any>();
  const [directory, setDirectory] = useState<any>(null);
  const [isShowCreateDirectory, setIsShowCreateDirectory] = useState(false)
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);

  const expandAll = () => {
    let _expandedKeys = {};
    for (let node of data.nodes) {
      expandedNode(node, _expandedKeys);
    }

    setExpandedKeys(_expandedKeys);
  };

  const expandedNode = (node: any, _expandedKeys: any) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
        expandedNode(child, _expandedKeys);
      }
    }
  };

  const collapseAll = () => {
    setExpandedKeys({});
  };

  const onNodeSelect = (nodeSelect: any) => {
    setSelectedNode(nodeSelect);
    updateSelectedFolder(nodeSelect);
    refresh();
  };

  const onNodeUnselect = (node: any) => {
    console.log(1);
    toast.current.show({
      severity: "success",
      summary: "Node Unselected",
      detail: node.label,
      life: 3000,
    });
  };

  const addFolder = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setDirectory(null);
    setIsShowCreateDirectory(true);
  };

  const deleteFolder = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedNode?.node.data === "Root" || selectedNode?.node.data === "Public Media" || !selectedNode) return;
    setIsShowDeleteDialog(true);
    setDirectory(selectedNode);
  };

  const onShowDeleteDialog = (isVisible: boolean) => {
    setDirectory(null);
    setIsShowDeleteDialog(isVisible);
  };

  const onDeleteConfirm = async () => {
    const response = await MediaFolderManagerAPI.deleteMediaFolderV2(directory.node.uuid);
    setSelectedNode(null);
    if (response && response.status === 200 && response.data.result) {
      updateSelectedFolder(null);
      onShowDeleteDialog(false);
      fetchMediaFolder();
      refresh();
      setSelectedNodeKey("root");
      showNotification("success", "Delete folder successfully.", toast);
    } else {
      showNotification("info", "Delete folder failed.", toast);
    }
  };

  const nodeTemplate = (node: any, options: any) => {
    let label = <>{node.label}</>;
    if (node.data === "Root") label = <b>{label}</b>

    return (
      <span className={`${options.className} `} style={{ fontSize: '13px' }}>
        <i className="fa-solid fa-folder mr-1"></i> {label}
      </span>
    );
  };

  useEffect(() => {
    if (!selectedNodeKey) {
      let expandedKey: any = { ...expandedKeys };

      if (expandedKey["root"]) delete expandedKey["root"];
      else expandedKey["root"] = true;

      setExpandedKeys(expandedKey);
      setSelectedNodeKey("root");
      setSelectedNode(allNodes);
    }
  }, [selectedNodeKey]);

  return (
    <>
      <div className="directory-container">
        <div className="tree-panel-header mb-1 mt-1">
          <i
            title="Expand Folder"
            className="fa-solid fa-square-plus mr-4"
            style={{ fontSize: "1.2em" }}
            onClick={expandAll}
          />
          <i
            title="Collapse Folder"
            className="fa-solid fa-square-minus mr-4"
            style={{ fontSize: "1.2em" }}
            onClick={collapseAll}
          />
          <i
            title="Add Folder"
            className="fa-solid fa-folder-plus mr-4"
            style={{ fontSize: "1.2em", visibility: isAddFolderVisibility ? "visible" : "collapse" }}
            onClick={(e: any) => addFolder(e)}
          />
          <i
            title="Delete Folder"
            className="fa-solid fa-trash"
            style={{ fontSize: "1.2em", visibility: isDeleteFolderVisibility ? "visible" : "collapse" }}
            onClick={(e: any) => deleteFolder(e)}
          />
        </div>
        <Tree
          value={allNodes}
          expandedKeys={expandedKeys}
          onToggle={(e) => setExpandedKeys(e.value)}
          selectionMode="single"
          selectionKeys={selectedNodeKey}
          onSelectionChange={(e) => {
            setSelectedNodeKey(e.value);
          }}
          onSelect={onNodeSelect}
          onUnselect={onNodeUnselect}
          nodeTemplate={nodeTemplate}
        />

        <WIConfirmDialog
          maskClassName="top-mask-dialog"
          visible={isShowDeleteDialog}
          onHide={() => onShowDeleteDialog(false)}
          onConfirm={onDeleteConfirm}
          message={
            <Fragment>
              <span style={{ fontSize: '18px', fontWeight: '700' }}>Are you sure you want to delete this folder?</span>
              <br />
              <span style={{ fontSize: '13px' }}>
                All of your folder and files will be deleted <b>permanently</b>.
                <br />
                You <b>cannot undo</b> this action.
              </span>
            </Fragment>
          }
          classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
        />

        <CreateDirectoryDialogComponent
          visible={isShowCreateDirectory}
          fetchMediaFolder={fetchMediaFolder}
          isAllNodes={isAllNodes}
          onHide={() => setIsShowCreateDirectory(false)}
          data={{
            allNodes,
            directory,
            selectedNode,
            expandedKeys,
          }}
        />
      </div>
    </>
  );
};

export default Directory;
