import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { useEffect, useRef, useState } from "react";
import "./create-block-form.scss";
import { CMsPageManagerAPI } from "../../../../services";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { formatHTMLCode } from "../../../../utils/logic";
import ReactJson from 'react-json-view'
import IFrameContent from "./iframe-content";

const CreateBlockFormComponent = (props: any) => {
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [headHtml, setHeadHtml] = useState('');
  const [components, setComponents] = useState<any[]>([]);
  const [CTATypes, setCTATypes] = useState<any>();
  const [blocks, setBlocks] = useState<any>({});
  const formikRef = useRef(null);
  const navigate = useNavigate();

  const generateBlockCategories = (records: any) => {
    const group = _.groupBy(records, "type");
    const entries: any[] = [];
    for (const key in group) {
      const item = {
        key: key,
        value: group[key].find(({ type_title }) => type_title === key).type_title,
      };
      entries.push(item);
    }
    setCTATypes(entries);
  };

  const getCMSPageData = async () => {
    setIsLoading(true);
    const { data: { records } } = await CMsPageManagerAPI.getCTABlocks();
    const blocks = [...records];
    generateBlockCategories(blocks);

    if (id) {
      const { data: { record }} = await CMsPageManagerAPI.getCTABlocksById(id);
      setBlocks(record);
      // @ts-ignore: Object is possibly 'null'.
      formikRef?.current.setValues({
        ...record,
        template: formatHTMLCode(record.template),
        parameters: record.parameters,
        configuration: record.configuration
      });
    }

    const { data: { htmlTemplate } } = await CMsPageManagerAPI.getHeadHtml();
    if (htmlTemplate) {
      setHeadHtml(htmlTemplate);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCMSPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateBlock = async () => {
    setIsLoading(true)
    // @ts-ignore: Object is possibly 'null'.
    const values = formikRef?.current.values;
    let result;
  
    delete values['thumbnail'];

    if (id && blocks) {
      result = await CMsPageManagerAPI.updateCTABlocks(values);
    } else {
      result = await CMsPageManagerAPI.createCTABlocks(values);      
    }
    setIsLoading(false);
    navigate('/blocks');
  }

  const initialBlockFormValues = () => {
    return {
      name: "",
      code: "",
      type: "",
      parameters: {},
      configuration: {},
      template: ""
    }
  };

  const blockFormSchema = Yup.object().shape({
    name: Yup.string()
      .required('This field is required.'),
    code: Yup.string()
      .required('This field is required.'),
    type: Yup.string()
      .required('This field is required.'),
    template: Yup.string()
      .required('This field is required.')
  });

  return (
    <div className="w-100 block-form-container">
        <Formik
          innerRef={formikRef}
          initialValues={initialBlockFormValues()}
          validationSchema={blockFormSchema}
          validate={(values: any) => {
            if (values.parameters && values.configuration && values.template) {
              setComponents([{
                key: uuidv4(),
                name: values.name,
                uuid: uuidv4(),
                blockId: uuidv4(),
                code: values.code,
                type: values.type,
                template: values.template,
                parameters: values.parameters,
                configuration: values.configuration
              }])
            }
          }}
          onSubmit={(values, { setSubmitting }) => {
          }}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            isValid,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} className="w-100 d-flex flex-column justify-content-start align-items-center">
              <div className="w-100 p-0 m-0">
                <div className="row">
                  <div className="col-lg-4 d-flex flex-column form-controls">
                    <label className="label">
                      Name <span className="required-label">(*)</span>
                    </label>
                    <InputText
                      className={`${
                        touched.name && errors.name ? "has-error" : ""
                      } text-input-inline text-currency-target`}
                      value={values.name}
                      name="name"
                      onChange={(item) => {
                        setFieldValue("name", item.target.value, true);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (<small className="error">{errors.name}</small>) : null}
                  </div>
                  <div className="col-lg-4 d-flex flex-column form-controls">
                    <label className="label">Code <span className="required-label">(*)</span></label>
                    <InputText
                      disabled={id ? true : false}
                      className={`${
                        touched.code && errors.code ? "has-error" : ""
                      } text-input-inline text-currency-target`}
                      value={values.code}
                      name="code"
                      onChange={(item) => {
                        setFieldValue("code", item.target.value, true);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.code && touched.code ? (<small className="error">{errors.code}</small>) : null}
                  </div>
                  <div className="col-lg-4 d-flex flex-column form-controls">
                    <label className="label">Type <span className="required-label">(*)</span></label>
                    <Dropdown
                      id="type"
                      value={values.type}
                      options={CTATypes}
                      onChange={(item: any) => {
                        setFieldValue("type", item.value, true);
                      }}
                      optionLabel="value"
                      optionValue="key"
                      appendTo="self"
                    />
                    {errors.type && touched.type ? (<small className="error">{errors.type}</small>) : null}
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col-lg-4 form-controls">
                    <div className="control-input">
                      <label className="label">Template <span className="required-label">(*)</span></label>
                      <InputTextarea
                        className="template-textarea"
                        rows={26}
                        cols={30} 
                        value={values.template}
                        onChange={(item: any) => {
                          setFieldValue("template", item.target.value, true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 form-controls">
                    <label className="label">Preview</label>
                    <div className="thumbnail-preview">
                      <IFrameContent
                        id={`cb_0`}
                        key={`cb_0`}
                        components={components}
                        headHtml={headHtml}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col-lg-6 form-controls">
                    <div className="control-input">
                      <label className="label">Parameters <span className="required-label">(*)</span></label>
                      <div className="template-json">
                        <ReactJson 
                          src={values.parameters} 
                          collapsed={1} 
                          collapseStringsAfterLength={50} 
                          style={{padding: "15px", height: "100%"}} 
                          name={null} 
                          onEdit={(value) => setFieldValue("parameters", value.updated_src, true)} 
                          onAdd={value => {}}
                          onDelete={value => {}}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-controls">
                    <div className="control-input">
                      <label className="label">Configuration <span className="required-label">(*)</span></label>
                       <div className="template-json">
                        <ReactJson 
                          src={values.configuration} 
                          collapsed={1} 
                          collapseStringsAfterLength={50} 
                          style={{padding: "15px", height: "100%"}} 
                          name={null} 
                          onEdit={(value) => setFieldValue("configuration", value.updated_src, true)} 
                          onAdd={(value) => {}}
                          onDelete={value => {}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-end align-items-center my-4 btn-container">
                  <Button
                    id="btn-submit-coupons"
                    type="submit"
                    disabled={!(isValid && dirty)}
                    loading={isLoading}
                    label={"Generate"}
                    className="p-button-rounded btn-submit"
                    icon="pi pi-chevron-right"
                    iconPos="right"
                    onClick={generateBlock}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
  );
};

export default CreateBlockFormComponent;
