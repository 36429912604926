import { Column, ColumnAlignType } from "primereact/column";
import { DataTable } from "primereact/datatable";
import * as React from "react";
import { useState } from "react";
import { MenuDot } from "../../../../common";
import "./coupon-manager.scss";
import CreateCouponDialog from "./create-coupon-dialog";
import DownloadReceiptDialog from "./download-receipt-dialog";
import { Tag } from "primereact/tag";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  formatDateByFormatBodyTemplate,
} from "../../../../common/column-template-table/column-template";
import { CampaignCouponStatus } from "../../../../../utils/utils";

interface Coupon {
  id: number;
  code: string;
  discount: number;
}

const CouponManager: React.FC = () => {
  const [coupons, setCoupons] = useState<any[]>([
    {
      uuid: uuidv4(),
      coupon: "CHRISTMAS2024",
      couponType: "off-once",
      start_date: new Date("2023-12-01"),
      end_date: new Date("2023-12-31"),
      budget: 10000,
      limit: 500,
      couponStatus: CampaignCouponStatus[0].key,
      currency: "eur",
      receiptStatus: "Not Yet Issued",
    },
    {
      uuid: uuidv4(),
      coupon: "CHRISTMAS2025",
      couponType: "off-once",
      start_date: new Date("2023-12-01"),
      end_date: new Date("2023-12-31"),
      budget: 10000,
      limit: 500,
      couponStatus: CampaignCouponStatus[0].key,
      currency: "eur",
      receiptStatus: "Not Yet Issued",
    },
    {
      uuid: uuidv4(),
      coupon: "CHRISTMAS2026",
      couponType: "off-once",
      start_date: new Date("2023-12-01"),
      end_date: new Date("2023-12-31"),
      budget: 10000,
      limit: 500,
      couponStatus: CampaignCouponStatus[1].key,
      currency: "eur",
      receiptStatus: "Not Yet Issued",
    },
  ]);
  const [showCreateCouponPopup, setShowCreateCouponPopup] = useState(false);
  const [coupon, setCoupon] = useState<any>();
  const [isShowDownloadReceiptDialog, setIsShowDownloadReceiptDialog] =
    useState(false);

  const toggleCouponStatus = (coupon: any) => {
    let newCoupons = [...coupons];
    newCoupons.forEach((item: any) => {
      if (item.coupon === coupon.coupon) {
        item.couponStatus =
          item.couponStatus === CampaignCouponStatus[0].key ? CampaignCouponStatus[1].key : CampaignCouponStatus[0].key;
      }
    });
    setCoupons(newCoupons);
  };

  const addCoupon = (newCoupon: any) => {
    setCoupons([...coupons, { ...newCoupon, uuid: uuidv4() }]);
  };

  const editCoupon = (editedCoupon: any) => {
    const updatedCoupons = coupons.map((coupon) =>
      coupon.uuid === editedCoupon.uuid ? editedCoupon : coupon
    );
    setCoupons(updatedCoupons);
    setCoupon(null);
  };

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: "Edit",
        description: "Edit coupon",
        icon: "fa-solid fa-pen-to-square",
        command: (e: any) => {
          setCoupon(rowData);
          setShowCreateCouponPopup(true);
        },
      },
      {
        label: "Download Receipt",
        description: "Download receipt of coupon",
        icon: "fa-solid fa-file-invoice",
        command: (e: any) => {
          setIsShowDownloadReceiptDialog(true);
        },
      },
      {
        label: "Activate / Deactivate",
        description: "Active or Deactive coupon",
        icon: "fa-solid fa-toggle-on",
        command: (e: any) => {
          toggleCouponStatus(rowData);
        },
      },
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const buildCampaignCouponStatusColumnTemplate = (
    rowData: any,
    column: any
  ) => {
    const status = _.isString(rowData[column.field])
      ? rowData[column.field]
      : rowData[column.field]
      ? "active"
      : "inactive";
    const value = status;
    return (
      <React.Fragment>
        <Tag
          className={`mr-2 wi-tag tag-status-${status?.toLowerCase()}`}
          value={value}
        />
      </React.Fragment>
    );
  };

  const tableColumns = [
    { field: "coupon", header: "Coupon", width: "15%", align: "left" },
    { field: "couponType", header: "Type", width: "10%", align: "left" },
    {
      field: "couponStatus",
      header: "Status",
      width: "10%",
      align: "center",
      bodyTemplate: buildCampaignCouponStatusColumnTemplate,
    },
    {
      field: "start_date",
      header: "Start Date",
      width: "15%",
      align: "left",
      bodyTemplate: formatDateByFormatBodyTemplate("DD.MM.YYYY"),
    },
    {
      field: "end_date",
      header: "End Date",
      width: "15%",
      align: "left",
      bodyTemplate: formatDateByFormatBodyTemplate("DD.MM.YYYY"),
    },
    {
      field: "budget",
      header: "Total Budget",
      width: "15%",
      align: "left",
      bodyTemplate: (rowData: any) =>
        rowData.budget.toLocaleString("de-DE")
    },
    {
      field: "limit",
      header: "Limit",
      width: "10%",
      align: "left",
      bodyTemplate: (rowData: any) =>
        rowData.limit.toLocaleString("de-DE")
    },
    { field: "currency", header: "Currency", width: "10%", align: "left" },
    {
      field: "receiptStatus",
      header: "Receipt Status",
      width: "10%",
      align: "left",
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  return (
    <div className="row row-content">
      <div className="campaign-coupon-manager">
        <CreateCouponDialog
          visible={showCreateCouponPopup}
          onHide={() => {
            setShowCreateCouponPopup(false);
            setCoupon(null);
          }}
          coupons={coupons}
          addCoupon={addCoupon}
          editCoupon={editCoupon}
          coupon={coupon}
        />

        <DownloadReceiptDialog
          visible={isShowDownloadReceiptDialog}
          onHide={() => setIsShowDownloadReceiptDialog(false)}
          coupons={coupons}
        />

        <div className="col-md-12">
          <div className="campaign-coupon-manager-create d-flex flex-column flex-md-row justify-content-start justify-content-md-between certificate-action common-horizontal-layout-header">
            <div className="btn-create">
              <button
                className="wi-main-button"
                onClick={(e) => setShowCreateCouponPopup(true)}
              >
                <div className="wi-btn-label">
                  Create <i className="fa-solid fa-circle-plus ml-2"></i>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="element-form">
            <DataTable
              //loading={isLoading}
              value={coupons}
              //filters={search}
              // onSort={(e: any) => {
              //   setSortConfig({
              //     sortField: e.sortField,
              //     sortOrder: e.sortOrder,
              //   });
              // }}
              // sortField={sortConfig.sortField}
              // sortOrder={sortConfig.sortOrder}
              scrollable
              scrollHeight="calc(100vh - 166px)"
              scrollDirection="both"
            >
              {dynamicColumns}
              <Column
                frozen
                alignFrozen="right"
                style={{ width: "50px" }}
                body={buildMenu}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponManager;
