import React, { useState, useEffect, useRef } from "react";
import {Link, useNavigate, useSearchParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column, ColumnAlignType } from "primereact/column";
import { Toast } from "primereact/toast";
import Layout from "../layout/layout";
import "./subscription-component.scss";
import {
  LanguageList,
  SUBSCRIPTION_RHYTHM_LIST,
  SUBSCRIPTION_STATUS,
} from "../../utils/utils";
import moment from "moment";
import {
  formatDateBodyTemplate,
} from "../common/column-template-table/column-template";
import { MenuDot, WIPaginator } from "../common";
import _ from "lodash";
import SubscriptionFilterComponent from "./subscription-filter/subscription-filter-component";
import {DonorManagerAPI, SubscriptionManagerAPI } from "../../services";
import { Tag } from "primereact/tag";
import MenuButton from "../common/menu-button/menu-button";
import { getObjectSearchValue, getObjectWithValues } from "./subscription-component-util";
import { env } from "../../environment";
import DownloadCertificateDialogComponent from "./download-certificate/download-certificate-dialog";
import { showNotification } from "../../utils/logic";

export type SubscriptionFilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[];
    status: string[];
    rhythm: number[];
    collection_date: string[];
    finished_date: string[];
    start_date: string[];
  };
  order: [];
};

const SubscriptionsManager = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Math.max(+(searchParams?.get("pageNumber") || 1) - 1, 0);
  const noRows = Math.min(
    Math.max(+(searchParams?.get("noRows") || 50), 50),
    1000
  );
  const sortField = searchParams?.get("sortField") || "serial";
  const sortOrder = +(searchParams?.get("sortOrder") || -1);
  const filterStatus = (searchParams?.get("filterStatus") || SUBSCRIPTION_STATUS.map((c) => c.code).join(",")).split(",");


  const filterRhythm = (searchParams?.get("filterRhythm") || SUBSCRIPTION_RHYTHM_LIST.map((c) => c.code).join(",")).split(",").map(Number);
  const filterStartDate = searchParams?.get("filterStartDate") ? searchParams?.get("filterStartDate")?.split(",") || [] : [];
  const filterCollectionDate = searchParams?.get("filterCollectionDate") ? searchParams?.get("filterCollectionDate")?.split(",") || [] : [];
  const filterFinishedDate = searchParams?.get("filterFinishedDate") ? searchParams?.get("filterFinishedDate")?.split(",") || [] : [];
  const searchField = searchParams?.get("search") || "";
  const [language] = useState(LanguageList[0]);
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder,
  });
  const toast = useRef(null);
  const [donations, setDonations] = useState({
    data: [] as any,
    totalPage: 0,
  });
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(pageNumber);
  const [first, setFirst] = useState(pageNumber * noRows);
  const [rows, setRows] = useState(noRows);
  const [isShowDownloadCertificateDialog, setIsShowDownloadCertificateDialog] = useState(false);
  const [yearsDonation, setYearsDonation] = useState([])
  const [certificateData, setCertificateData] = useState<any>({
    id : '',
    name: ''
  });
  const [filterValues, setFilterValues] =
    useState<SubscriptionFilterParameters>({
      pageNo: pageNumber,
      range: noRows,
      search: searchField,
      where: {
        or: searchField ? getObjectSearchValue(searchField) : [],
        status: filterStatus,
        rhythm: filterRhythm,
        collection_date: filterCollectionDate,
        finished_date: filterFinishedDate,
        start_date: filterStartDate,
      },
      order: [],
    });

  const getRecurringDonation = async (search: any) => {
    setLoading(true);
    delete search.where.collection_date;
    delete search.where.start_date;
    delete search.where.finished_date;

    const res = await SubscriptionManagerAPI.getSubscriptionsBySearch(search);
    if (res && res.status === 200) {
      if (res.data.total > 0) {
        setDonations({
          data: res.data.records,
          totalPage: res.data.total
        });
      } else {
        setDonations({
          data: [],
          totalPage: res.data.length || 0,
        });
      }
      setLoading(false);
    }
  };

  const preprocessSerial = (str: any) => {
    if (!str || _.isNumber(str)) {
      return str;
    } 
    const parts = str.split('-');
    return parts.length > 1 ? parts[1] : parts[0];
  }

  useEffect(() => {
    const where_conditions = getObjectWithValues(filterValues.where);
    var filter = {
      pageNo: (page).toString(),
      range: rows.toString(),
      where: {
        ...where_conditions,
        serial: preprocessSerial(filterValues.search),
        search_name: filterValues.search,
      },
      order: [sortConfig.sortField, sortConfig.sortOrder === -1 ? "DESC": "ASC"],
    }

    const searchParams = { 
      pageNumber: (page).toString(),
      noRows: rows.toString(),
      search: filterValues.search,
      sortField: sortConfig.sortField,
      sortOrder: sortConfig.sortOrder,
      filterStatus: filterValues.where.status?.join(',') || '',
      filterRhythm: filterValues.where.rhythm?.join(',') || '',
      filterStartDate: filterValues.where.start_date?.join(',') || '',
      filterCollectionDate: filterValues.where.collection_date?.join(',') || '',
      filterFinishedDate: filterValues.where.finished_date?.join(',') || '',
    }

    setSearchParams(_.omitBy(searchParams, (p) => !p));
    getRecurringDonation(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, sortConfig]);

  useEffect(() => {
    if (typeof page === "number") {
      setFilterValues({
        ...filterValues,
        pageNo: page,
        range: rows
      });

       setSearchParams({ 
        pageNumber: (page).toString(),
        noRows: rows.toString()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const buildStatusColumnTemplate = (rowData: any, column: any) => {
    const status = _.isString(rowData[column.field])
      ? rowData[column.field]
      : "pending";
    const value =
      SUBSCRIPTION_STATUS.find((c) => c.code === status.toLowerCase())?.name
        .de || status;

    try {
      return (
        <React.Fragment>
          <Tag
            className={`mr-2 wi-tag tag-status-${status.toLowerCase()}`}
            value={value}
          />
        </React.Fragment>
      );
    } catch (error) {
      return (
        <React.Fragment>
          <Tag className="mr-2" value={value} />
        </React.Fragment>
      );
    }
  };

  const formatAmountBodyTemplate = (rowData: any) => {
    return (
      <div>
        <b style={{ fontSize: "13px", color: "black", marginRight: "5px" }}>
          {rowData.status !== "finished"
            ? parseFloat(rowData.amount?.toString())?.toLocaleString('de')
            : "-"}
        </b>{" "}
        <span>{rowData.currency?.toUpperCase()}</span>
      </div>
    );
  };

  const formatCollectionDateBodyTemplate = (rowData: any) => {
    const value = moment(
      new Date(rowData.collection_date),
      "MMM DD, YYYY HH:mm:ss"
    )
      .utc()
      .add(1, "hour")
      .format("MMM DD, YYYY HH:mm:ss");
    const now = moment();
    return rowData.status === "finished" ? "-" : value;
  };

  const formatFinishedDateBodyTemplate = (rowData: any) => {
    const value = moment(
      new Date(rowData.finished_date),
      "MMM DD, YYYY HH:mm:ss"
    )
      .utc()
      .add(1, "hour")
      .format("MMM DD, YYYY HH:mm:ss");
    return rowData.status === "active" ? "-" : value;
  };

  const navigateToDetail = (rowData: any) => {
    navigate(`/donations/${rowData.id}`)
  };

  const tableColumns = [
    {
      field: "serial",
      header: "Parent ID",
      width: "130px", 
      align: "left",
      bodyTemPlate: (rowData: any) => {
        return <Link to={`/donations/${rowData.id}`} className="btn-serial" onClick={() => navigateToDetail(rowData)} >{rowData.serial}</Link>;
      }
    },
    {
      field: "payment_first_name",
      header: "Name",
      width: "200px", 
      align: "left",
      bodyTemPlate: (rowData: any) => {
        return rowData.payment_first_name + " " + rowData.payment_last_name;
      }
    },
    {
      field: "payment_email",
      header: "Email",
      width: "280px", 
      align: "left",
    },
    {
      field: "created_at",
      header: "Start Date",
      width: "170px", 
      align: "left",
      bodyTemPlate: formatDateBodyTemplate("DD.MM.YYYY hh:mm"),
      disabledSort: false,
    },
    {
      field: "collection_date",
      header: "Collection Date",
      width: "170px", 
      align: "left",
      bodyTemPlate: formatCollectionDateBodyTemplate,
      disabledSort: false,
    },
    {
      field: "donation_money",
      header: "Collection Amount",
      width: "190px", 
      align: "left",
      bodyTemPlate: formatAmountBodyTemplate,
    },
    {
      field: "status",
      header: "",
      width: "130px", 
      align: "center",
      bodyTemPlate: buildStatusColumnTemplate,
      disabledSort: true,
    },
    {
      field: "rhythm",
      header: "Rhythm",
      width: "130px",
      align: "left",
      bodyTemPlate: (rowData: any) => {
        return SUBSCRIPTION_RHYTHM_LIST.find(c => c.code === rowData.rhythm)?.name || 'Once';
      }
    },
    {
      field: "finished_date",
      header: "Finished Date",
      align: "left",
      bodyTemPlate: formatFinishedDateBodyTemplate,
      width: "170px",
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
        sortable={!col?.disabledSort}
      />
    );
  });

  const handleGenerateYearsDonation = (start_date: any, end_date: any) => {
    let current_year = moment(new Date()).format("YYYY").toString();
    let years: any = [];

    end_date = end_date ? end_date : moment(new Date()).format("YYYY-MM-DD").toString()
    for (var m = moment(start_date, 'YYYY-MM-DD'); m.isBefore(end_date); m.add(1, "year")) {
      years.push(m.format("YYYY"));
    }

    if (years.length === 0) {
      years.push(current_year)
    }

    setYearsDonation(years);
  };

  const handleDownloadCertificate = (rowData: any) =>{
    handleGenerateYearsDonation(rowData?.created_at, rowData?.finished_date);
    let data = { id: rowData?.id, name: rowData?.serial}
    setCertificateData(data);
    setIsShowDownloadCertificateDialog(true);
  }

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const copyLinkDonation = (rowData: any) => {
    navigator.clipboard.writeText(`${window.location.origin}/donations/${rowData.id}`).then(() => {
      showNotification("success", "Copied parent donation link successfully", toast);
    });
  }

  const onOpenDonorPortal = async (e: any, rowData: any) => {
    if (rowData) {
      const paymentEmail = rowData.payment_email?.toLowerCase();
      const donorData = {
        donorEmail: paymentEmail,
        language: "de",
      };

      try {
        let newTab: any = window.open();
        const response = await DonorManagerAPI.generateDonorPortalURL(donorData);
        if (response?.status === 200 && response?.data) {
          const data = response.data;
          // @ts-ignore: Object is possibly 'null'.
          newTab.location.href = data.link;
        } else {
          showNotification("error", "Get donor portal URL failed.", toast);
        }
      } catch (error) {
        showNotification("error", "Get donor portal URL failed.", toast);
      }  
    }
  };

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: "Open parent donation",
        description: "Open parent donation details",
        icon: "fa-solid fa-arrow-up-right-from-square",
        url: `/donations/${rowData.id}`,
      },
      {
        label: "Open subscription",
        description: "Open subscription details",
        icon: "fa-solid fa-arrow-up-right-from-square",
        externalUrl: `${env.BASE_STRIPE_URL}/subscriptions/${rowData.subscription_id}`,
      },
      {
        label: "Open donor portal",
        description: "Open donor portal",
        icon: "fa-solid fa-arrow-up-right-from-square",
        command: (e: any) => onOpenDonorPortal(e, rowData),
      },
      {
        label: "Download certificate",
        description: "Download certificate as PDF",
        icon: "pi pi-download",
        command: (e: any) => handleDownloadCertificate(rowData),
      },
      {
        label: 'Copy parent donation link',
        icon: 'pi pi-copy',
        command:(e: any) => copyLinkDonation(rowData),
        description: 'Copy parent donation link to clipboard'
      }
    ];
    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const buildActionMenu = (data: any) => {
    return [];
  };

  const onFilterChange = (values: any) => {
    const { field, value } = values;
    const where_conditions = getObjectWithValues(filterValues.where);
    if (field !== "start_date" && field !== "finished_date" && field !== "collection_date") {
      where_conditions[field] = value;
    } else {
      const dates = value.map((c: any) => moment(c).format("DD/MM/YYYY"));
      where_conditions[field] = dates;
      where_conditions["from_"+field] = dates[0];
      where_conditions["to_"+field] = dates[1];
    }
    setFilterValues({
      ...filterValues,
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order,
    });
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    p.where["status"] = [...SUBSCRIPTION_STATUS.map((c) => c.code)];
    p.where["rhythm"] = [...SUBSCRIPTION_RHYTHM_LIST.map((c) => c.code)];
    p.search = "";
    setFilterValues(p);
  };

  const onSearchFunc = (searchText: string) => {
    const where_conditions = getObjectWithValues(filterValues.where);
    const or_conditions = getObjectSearchValue(searchText);
    where_conditions["or"] = searchText ? or_conditions : null;
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      search: searchText,
      order: filterValues.order,
    });
    if (page !== 0) {
      setPage(0);
      setFirst(0);
    }
  };

  return (
    <Layout>
      <div className="subscription-content">
        <div className="header-containers">
          <div className="subscription-header-panel" style={{ display: "flex" }}>
            <SubscriptionFilterComponent
              lang={language.code}
              filterValues={filterValues}
              fields={[
                "rhythm",
                "status",
                "collection_date",
                "start_date",
                "finished_date",
              ]}
              onChange={onFilterChange}
              onRemove={onFilterRemove}
              onSearchFunc={onSearchFunc}
            />
            <MenuButton label="Actions" items={[...buildActionMenu(null)]}></MenuButton>
          </div>
        </div>
        <div className="content-table">
          <div className="table-subscription">
            <DataTable
              loading={loading}
              value={donations.data}
              scrollable
              dataKey="code"
              // scrollHeight="calc(100vh - 180px)"
              scrollDirection="both"
              lazy
              selection={selectedSubscriptions}
              onSelectionChange={(e) => {
                setSelectedSubscriptions(e.value);
              }}
              onSort={(e: any) => {
                setSortConfig({
                  sortField: e.sortField,
                  sortOrder: e.sortOrder,
                });
              }}
              sortField={sortConfig.sortField}
              sortOrder={sortConfig.sortOrder}
            >
              {dynamicColumns}
              <Column
                frozen
                alignFrozen="right"
                className="p-align-right"
                style={{ width: "50px" }}
                body={buildMenu}
              ></Column>
            </DataTable>
            <WIPaginator
              first={first}
              rows={rows}
              totalRecords={donations.totalPage}
              onPageChange={onBasicPageChange}
            />
          </div>
        </div>
        <Toast ref={toast} />
      </div>
      <DownloadCertificateDialogComponent
          visible={isShowDownloadCertificateDialog}
          onHide={() => setIsShowDownloadCertificateDialog(false)}
          data={certificateData}
          yearsDonation={yearsDonation}
        />
       
    </Layout>
  );
};

export default SubscriptionsManager;
