import * as React from "react";
import * as Yup from 'yup';
import { Field, Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { StatusPartner, StatusDisplay } from "../../../../utils/utils";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { showNotification } from "../../../../utils/logic";
import { PartnerManagerAPI, PartnerTypeManagerAPI, CMsPageManagerAPI, CampaignManagerAPI } from "../../../../services";
import { AxiosResponse } from "axios";
import CountryService from "../../../../services/country-service";
import "./create-partner-form.scss";
import { LanguageItem } from "../../../../utils/commonType";
import WIUploadMediaField from "../../../common/upload/wi-upload-media-field";
import SearchURLGlobal from "../../../common/searchURLGlobal/searchURLGlobal";
import { fetchCMSHeaders, fetchCMSFooters, getPageTypeByCode, fetchPageTypes,
  fetchCMSParentPages, fetchAllBlocks, callUpdatePageAPI, callCreateAndPublishPageAPI, callDeletePageAPI
} from "../../../pages/builder-page.common";
import { PAGE_CODE } from "../../../pages/utils";
import { generatePartnerPageData } from "../../partner-business";
import _ from "lodash";
import { env } from "../../../../environment";
import moment from "moment";
import { Tag } from "primereact/tag";
import { RichTextEditor } from "./custom-editor-component";  
import { Tooltip } from "primereact/tooltip";
import { buildTooltipManageTemplate } from "../../../common/column-template-table/column-template";
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";

interface PartnerType {
  name: string;
  code: string;
  status: string;
  country: string;
  partner_logo: string;
  headline_image: string;
  mobile_headline_image: string;
  description: LanguageItem;
  fact: LanguageItem;
  slogan: LanguageItem;
  profile_url: LanguageItem;
  type_id: string;
  generate_page: boolean;
}

const CreatePartnerFormComponent = (props: any) => {
  const navigate = useNavigate();
  const { 
    countries, 
    partner, 
    partners, 
    toast,
    parentPages,
    globalContent, 
    cmsPartnerPage, 
    cmsMasterPages, 
    partnerTypesParam 
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [partnerTypes, setPartnerTypes] = useState([]);
  const [headers, setHeaders] = useState<any>([]);
  const [footers, setFooters] = useState<any>([]);
  const [partnerPageType, setPartnerPageType] = useState<any>();
  const [partnerPageURL, setPartnerPageURL] = useState<any>();
  const [allBlocks, setAllBlocks] = useState<any>([]);
  const formikRef = useRef(null);

  const callCreatePartnerAPI = async (parameters: any) => {
    return await PartnerManagerAPI.generatePartners(parameters);
  };

  const callUpdatePartnerAPI = async (parameters: any) => {
    return await PartnerManagerAPI.updatePartners(parameters);
  };

  const fetchPartnerTypes = async () => {
    let partnerTypes = partnerTypesParam ? partnerTypesParam.filter((f: any) => f.status === 'active').map((f: any) => ({
      id: f.uuid,
      name: f.name.de
    })) : [];

    setPartnerTypes(partnerTypes);
  };

  const handleRequestPartners = (resGeneratePartners: AxiosResponse<any, any>) => {
    // @ts-ignore: Object is possibly 'null'.
    const response = resGeneratePartners;
    //setIsLoading(false);
    if (response && response.status === 200 && response.config.method === "post") {
      if (response.data?.result?.error?.code) {
        showNotification("error", "The partner code is already existed.", toast);
      } else {
        showNotification("success", "Generate partners successfully.", toast);
        return response.data.result;
      }
    } else if (response && response.status === 200 && response.config.method === "put") {
      showNotification("success", "Update partners successfully.", toast);
      return response.data.result;
    } else {
      showNotification("error", "Failed to generate partners.", toast);
    }

    return false;
  };

  const requestPartnersData = async () => {
    // @ts-ignore: Object is possibly 'null'.
    const formikValues = formikRef?.current.values;
    if(formikValues.generate_page !== true )
    {
      emptyPageInfo(formikValues);
    }

    if (partner) {
      // @ts-ignore: Object is possibly 'null'.
      const partnerObj = partner;
      const isChangedLogo = partnerObj.partner_logo !== formikValues.partner_logo ? true : false;
      const isChangedHeadlineImage = partnerObj.headline_image !== formikValues.headline_image ? true : false;
      const isChangedMobileHeadlineImage = partnerObj.mobile_headline_image !== formikValues.mobile_headline_image ? true : false;
      let request = { ...partnerObj, ...formikValues };
      if (!isChangedLogo) {
        delete request['partner_logo'];
        delete request['partner_logo_extension'];
      }
      if (!isChangedHeadlineImage) {
        delete request['headline_image'];
      }
      if (!isChangedMobileHeadlineImage) {
        delete request['mobile_headline_image'];
      }

      return callUpdatePartnerAPI({
        ...request,
        code: formikValues.code.toString().toUpperCase(),
        // become_partner_since: moment(formikValues.become_partner_since).format('YYYY-MM-DD')
      });
    }

    return callCreatePartnerAPI({
      ...formikValues,
      code: formikValues.code.toString().toUpperCase(),
      // become_partner_since: moment(formikValues.become_partner_since).format('YYYY-MM-DD')
    });
  };

  const handleRequestPartnerPage = async (partnerResponse: any, formikValues: any) => {
    let resultHandlePartnerPage = false;
    if(!partnerResponse) {
      return resultHandlePartnerPage;
    }

    if(formikValues.generate_page != true)
    {
      if(partner && cmsPartnerPage && cmsPartnerPage.uuid)
      {
        await callDeletePageAPI(cmsPartnerPage.uuid, 
          (result: any) => {
            resultHandlePartnerPage = true;
            showNotification("success", "Delete Partner Page successfully", toast);
          },
          () => showNotification("error", "Failed to delete Partner Page", toast));
      }
      return resultHandlePartnerPage;
    }

    let pageData = await generatePartnerPageData(formikValues, parentPages, cmsPartnerPage, partnerPageType, allBlocks, footers, headers, partnerResponse.id, true);
    if(partner && cmsPartnerPage && cmsPartnerPage.uuid) {
      delete pageData["url"];
      await callUpdatePageAPI(cmsPartnerPage.uuid, pageData,
       async () => {
          let updateContentRes = await CMsPageManagerAPI.update(cmsPartnerPage.uuid, pageData.content);
          if(updateContentRes && updateContentRes.status === 200) {
            resultHandlePartnerPage = true;
            showNotification("success", "Update Partner Page successfully", toast);
          }
        },
        () => showNotification("error", "Failed to update Partner Page", toast)
      );
    } else {
      await callCreateAndPublishPageAPI(pageData, 
        (result: any) => {
          resultHandlePartnerPage = true;
          showNotification("success", "Generate Partner Page successfully", toast);
        },
        () => showNotification("error", "Failed to generate Partner Page", toast));
    }

    await callUpdatePartnerAPI({id:  partnerResponse.id})

    return resultHandlePartnerPage;
  };

  const emptyPageInfo = (page: any) =>
  {
    page.description.de = "";
    page.slogan.de = "";
    page.fact.de = "";
    page.headline_image = "";
    page.mobile_headline_image = "";
  }

  const generatePartners = async () => {
    // @ts-ignore: Object is possibly 'null'.
    formikRef.current.validateForm();
    
    // @ts-ignore: Object is possibly 'null'.
    if (formikRef && formikRef.current.dirty && formikRef.current.isValid) {
      try {
        setIsLoading(true);
        // @ts-ignore: Object is possibly 'null'.
        const formikValues = formikRef?.current.values;
        const resGeneratePartners = await requestPartnersData();
        let partnerResponse = handleRequestPartners(resGeneratePartners);
        await handleRequestPartnerPage(partnerResponse, formikValues);
        if(partnerResponse) {
          navigate(`/partners/${resGeneratePartners?.data.result.id}`);
          window.location.reload();
        }
        else{
          setIsLoading(false);
        }
      } catch (error) {
        showNotification("error", "Failed to generate partners", toast);
        setIsLoading(false);
      }
    }
  };

  const onKeyPress = (evt: any) => {
    return (
      ["+", "-", ".", "{", "}", "[", "]"].includes(evt.key) &&
      evt.preventDefault()
    );
  };

  useEffect(() => {
    fetchDataToCreatePartnerPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPartnerTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerTypesParam]);

  useEffect(() => {
    if(cmsPartnerPage && cmsMasterPages) {
      const partnerURL = getParentURL(cmsPartnerPage?.page_id, cmsMasterPages);
      setPartnerPageURL(partnerURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner, cmsPartnerPage]);

  const fetchDataToCreatePartnerPage = async () => {
    let [
      footers, 
      headers, 
      pageTypes,
      allBlocks
    ] = await Promise.all([
      fetchCMSFooters(),
      fetchCMSHeaders(),
      fetchPageTypes(),
      fetchAllBlocks()
    ]);

    setAllBlocks(allBlocks);
    setFooters(footers);
    setHeaders(headers);
    let partnerPageType = getPageTypeByCode(pageTypes, PAGE_CODE.Partner);
    setPartnerPageType(partnerPageType);
    
    if(cmsPartnerPage)
    {
      setPartnerPageURL(getParentURL(cmsPartnerPage?.page_id, cmsMasterPages));
    }
  }

  const getParentURL = (page_id: string, pages: any[]) => {
    if (!page_id) {
      return '/';
    }
  
    let selected = pages.find((p: any) => p.uuid === page_id);
    let prefix = selected?.url;
    return prefix ? '/' + prefix + '/' : '/';
  }

  const updateValuePartner = () => {
    if (partner && !partner?.profile_url?.page_id) {
      return partner?.profile_url;
    } else if (partner && partner?.profile_url?.page_id) {
      const IDPartner = partner ? partner?.profile_url?.page_id : '';
      let source = globalContent.find((f: any) => f.id === IDPartner);
      if(source) {
        return {
          ...source.url,
            page_id: source?.id || '',
            global_content_id: source?.id || ''
        }
      }
    }

    return { de: '#', en: '#', global_content_id: '', page_id: '' };
  }

  const updateIsGeneratePageForPartner = () => {
    if ((partner && cmsPartnerPage) || !partner)
    { 
      return true;
    }

    if(partner && !partner.generate_page && partner.mobile_headline_image && partner.headline_image)
    {
      return true;
    }

    return partner.generate_page;
  }

  const initialPartnersValues = (): PartnerType => {
    return {
      name: partner ? `${partner.name}` : "",
      code: partner ? `${partner.code}` : "",
      status: partner ? `${partner.status}` : "initialized",
      partner_logo: partner ? `${partner.partner_logo ?? ""}` : "",
      headline_image: partner ? `${partner.headline_image ?? ""}` : "",
      mobile_headline_image: partner ? `${partner.mobile_headline_image ?? ""}` : "",
      country: partner ? `${partner.country}` : "DE",
      description: partner && partner.description ? { de: partner.description.de, en: partner.description.en } : { de: '', en: '' },
      fact: partner && partner.fact ? { de: partner.fact.de, en: partner.fact.en } : { de: '', en: '' },
      slogan: partner && partner.slogan ? { de: partner.slogan.de, en: partner.slogan.en } : { de: '', en: '' },
      profile_url: updateValuePartner(),
      type_id: partner ? `${partner.type_id}` : "",
      generate_page:  updateIsGeneratePageForPartner(),
      // address: partner ? `${partner.address}` : "",
      // additional_address: partner ? `${partner.additional_address}` : "",
      // postal_code: partner ? `${partner.postal_code}` : "",
      // city: partner ? `${partner.city}` : "",
      // become_partner_since: partner ? moment(`${partner.become_partner_since}`).toDate() : moment().toDate(),
    }
  };

  const partnersFormSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, 'This field must be with a maximum length of 100.')
      .required('This field is required.'),
    code: Yup.string()
      .max(3, 'This field must be with a maximum length of 3.')
      .required('This field is required.'),
    partner_logo: Yup.string()
      .required('This field is required.'),
    headline_image: Yup.string().when("generate_page", {
      is: (type: boolean) => type === true,
      then: Yup.string().required('This field is required.')
    }),
    mobile_headline_image: Yup.string().when("generate_page", {
      is: (type: boolean) => type === true,
      then: Yup.string().required('This field is required.')
    }),
    type_id: Yup.string()
      .required('This field is required.'),
    description: Yup.object().when("generate_page", {
      is: (type: boolean) => type === true,
      then: Yup.object().shape({
        de: Yup.string().required('This field is required.'),
        //en: Yup.string().required('This field is required.')
    })}),
    fact: Yup.object().when("generate_page", {
      is: (type: boolean) => type === true,
      then: Yup.object().shape({
        de: Yup.string().required('This field is required.'),
        //en: Yup.string().required('This field is required.')
    })}),
    slogan: Yup.object().when("generate_page", {
      is: (type: boolean) => type === true,
      then: Yup.object().shape({
        de: Yup.string().required('This field is required.'),
        //en: Yup.string().required('This field is required.')
    })}),
    profile_url: Yup.object().shape({
      de: Yup.string()
        .max(255, 'This field must be with a maximum length of 255.').required('This field is required.'),
      en: Yup.string()
        .max(255, 'This field must be with a maximum length of 255.').required('This field is required.')
    }),
    // address: Yup.string()
    //   .max(50, 'This field must be with a maximum length of 50.'),
    // additional_address: Yup.string()
    //   .max(50, 'This field must be with a maximum length of 50.'),
    // city: Yup.string()
    //   .max(100, 'This field must be with a maximum length of 100.'),
    // postal_code: Yup.string()
    //   .max(10, 'This field must be with a maximum length of 10.'),
    // become_partner_since: Yup.date()
    //   .transform(function (value, originalValue) {
    //     if (this.isType(value)) {
    //       return value;
    //     }
    //     return moment(originalValue).format('MM-DD-YYYY');
    //   })
    //   .typeError("This field must be a valid date."),
  });

  const carouselItemTemplate = (campaign: any) => {


    return (
      <div>
        <div>
            {/* <img src={`images/product/${campaign.image}`} alt={campaign.name.de} className="product-image" /> */}
            <img width="100%" height="300px" src={`${env.PUBLIC_IMAGE_URL}/${campaign.campaign_logo}?u=${moment().unix()}`} alt={campaign.image} className="image-cert carousel-img-campaign" style={{objectFit: "fill"}} />
        </div>
        <div>
            <div style={{textAlign:"center"}}>{campaign.name.de}</div>
            <div style={{textAlign:"center"}}>
            <React.Fragment>
              <Tag
              className={`mr-2 wi-tag tag-status-${campaign.status.toLowerCase()}`}
              // @ts-ignore
              // @ts-ignore
              severity={StatusDisplay[campaign.status]?.severity}
              value={campaign.status.toUpperCase()}
              />
            </React.Fragment>
            </div>
        </div>
      </div>
    );
  };

  const validateExistName = (value: string) => {
    if(Object.values(partners.data).some((u: any) => u.name?.toLowerCase() === value?.toLowerCase() 
      && (partner ? u.uuid !== partner.uuid : true ))){
      return "This field is existed.";
    }
    return "";
  };

  const buildHeaderTemplate = () => {
    const type = "Partner";
    const title = `${type} code`;
    const code = partner?.code || "";
    const tooltip = `Click to open partner page with code ${code}`;
    //const URL = partner ? env.CMS_URL + partnerPageURL + partner.name?.trim().replaceAll(" ", "-").toLowerCase() : "";
    const URL = partner && cmsPartnerPage && cmsPartnerPage.uuid ? env.CMS_URL + partnerPageURL + cmsPartnerPage.url : "";
    return buildTooltipManageTemplate(title, code, tooltip, URL, !cmsPartnerPage);
  };

  return (
    <div className="partner-form">
      <div className="partner-form-container">
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={initialPartnersValues()}
          validationSchema={partnersFormSchema}
          onSubmit={(values, { setSubmitting }) => {
            // console.log("values >>>", values);
          }}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            isValid,
            handleBlur,
            handleSubmit,
            setFieldValue,
            handleChange
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="sticky-header-form">
                  <div className="col-12 d-flex align-items-center justify-content-between btn-submit-container">
                    {buildHeaderTemplate()}
                    <Button
                      type="submit"
                      // disabled={!(isValid && dirty)}
                      loading={isLoading}
                      id="btn-submit-coupons"
                      label={partner ? "Update" : "Create"}
                      className="p-button-rounded btn-submit"
                      icon="pi pi-chevron-right"
                      iconPos="right"
                      onClick={generatePartners}
                    />
                  </div>
                </div>
                {isLoading ?   
                <div className="loading-component">
                  <ProgressSpinner />
                </div> : null}
                {isLoading !== true ?   
                 <div className="col-12 col-lg-6">
                 <div className="row row-content">
                   <div className="col-md-6">
                     <div className="w-100">
                       <div className="element-form">
                         <label className="label">
                           Name <span className="required-label">*</span>
                         </label>
                         <Field
                           className={`${
                             touched.name && errors.name ? "has-error" : ""
                           } text-input-inline text-currency-target`}
                           name="name"
                           validate={validateExistName}
                           onChange={(item: any) => {
                             setFieldValue("name", item.target.value, true);
                           }}
                           onBlur={handleBlur}
                           maxLength={100}
                         />
                         {errors.name && touched.name ? (
                           <small className="error">{errors.name}</small>
                         ) : null}
                       </div>
                     </div>
                     <div className="w-100">
                       <div className="element-form">
                         <label className="label">
                           Partner Type <span className="required-label">*</span>
                         </label>
                         <Dropdown
                           id="type_id"
                           value={values.type_id}
                           options={partnerTypes}
                           onChange={handleChange}
                           optionLabel="name"
                           optionValue="id"
                           appendTo="self"
                           itemTemplate={(option: any) => {
                             return (
                               <div
                                 dangerouslySetInnerHTML={{
                                   __html: _.get(option, "name"),
                                 }}
                               ></div>
                             );
                           }}
                           valueTemplate={(option: any) => {
                             return (
                               <div
                                 dangerouslySetInnerHTML={{
                                   __html: _.get(option, "name"),
                                 }}
                               ></div>
                             );
                           }}
                         />
                       </div>
                     </div>
                   </div>
                   <div className="col-md-6">
                     <div className="element-form">
                       <WIUploadMediaField
                         type="file"
                         hideLabelFileName={true}
                         classImage="image-cert"
                         className="partner_logo"
                         name="partner_logo"
                         label="Partner Logo"
                         required={true}
                       />
                     </div>
                   </div>
                   {!partner && (
                     <div className="col-md-6">
                       <div className="element-form">
                         <label className="label">
                           Code <span className="required-label">*</span>
                         </label>
                         <InputText
                           style={{ textTransform: "uppercase" }}
                           className={`${
                             touched.code && errors.code ? "has-error" : ""
                           } text-input-inline text-currency-target`}
                           value={values.code}
                           name="code"
                           maxLength={3}
                           onChange={(item) => {
                             setFieldValue("code", item.target.value, true);
                           }}
                           onBlur={handleBlur}
                           onKeyDown={(e) => onKeyPress(e)}
                         />
                         {errors.code && touched.code ? (
                           <small className="error">{errors.code}</small>
                         ) : null}
                       </div>
                     </div>
                   )}
                   <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">
                          Country <span className="required-label">*</span>
                        </label>
                        <Dropdown
                          id="country"
                          value={values.country}
                          options={countries}
                          onChange={(item: any) => {
                            setFieldValue("country", item.value, true);
                          }}
                          className="p-dropdown-country"
                          panelClassName="p-dropdown-country-panel"
                          optionLabel="name"
                          optionValue="ISO2"
                          appendTo="self"
                          filter
                          filterBy="name"
                        />
                      </div>
                   </div>
                   <div className={partner ? "col-md-3" : "col-md-6"}>
                      <div className="element-form">
                       <label className="label">
                         Status <span className="required-label">*</span>
                       </label>
                       <Dropdown
                         id="status"
                         value={values.status}
                         options={StatusPartner}
                         onChange={(item: any) => {
                           setFieldValue("status", item.value, true);
                         }}
                         optionLabel="name"
                         optionValue="key"
                         appendTo="self"
                       />
                     </div>
                   </div>
                   <div className="col-md-3">
                     <div className="element-form">
                     <label className="label">
                       Generate page?
                     </label>
                     <InputSwitch
                       className="wi-form-toggle-button-partner"
                       checked={values.generate_page}
                       onChange={(e: any) => setFieldValue("generate_page", e.value)}
                       onBlur={handleBlur}
                     />
                     </div>
                   </div>
                   <div className="col-md-12">
                     <SearchURLGlobal
                       label={"Partner website"}
                       search_content={globalContent}
                       values={values.profile_url}
                       searchFields={["name"]}
                       isRequired={true}
                       maxLength={255}
                       onChangeValue={(valueURL: any) => {
                         setFieldValue("profile_url", valueURL, true);
                       }}
                     />
                   </div>
                 </div>
                 <div className="row row-content mt-1" style={{visibility: values.generate_page ? "visible" : "collapse"}}>
                   <div className="col-md-12">
                     <RichTextEditor
                       name="slogan.de"
                       header="Slogan"
                       isMultipleLine={false}
                       isRequired={true}
                     />
                   </div>
                   <div className="col-md-6">
                     <RichTextEditor
                       name="description.de"
                       header="Description"
                       isMultipleLine={true}
                       isRequired={true}
                     />
                   </div>
                   <div className="col-md-6">
                     <RichTextEditor
                       name="fact.de"
                       header="Fact"
                       isMultipleLine={true}
                       isRequired={true}
                     />
                   </div>
                 </div>
               </div> : null}

               {isLoading !== true ?   
                 <div className="col-12 col-lg-6" style={{visibility: values.generate_page ? "visible" : "collapse"}}>
                 <div className="row upload-logo-row">
                   <div className="col-md-12">
                     <div className="element-form">
                       <WIUploadMediaField
                         type="file"
                         hideLabelFileName={true}
                         classImage="image-cert"
                         className="img-frame-16-9"
                         name="headline_image"
                         label="Header Picture"
                         required={true}
                       />
                     </div>
                   </div>
                   <div className="col-md-12">
                     <div className="element-form">
                       <WIUploadMediaField
                         type="file"
                         hideLabelFileName={true}
                         classImage="image-cert"
                         name="mobile_headline_image"
                         className="img-frame-9-16 partner-mobile-picture"
                         label="Mobile Header Picture"
                         required={true}
                       />
                     </div>
                   </div>
                 </div>
               </div> : null}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreatePartnerFormComponent;
