export const PartnerTypeBlock =
{
    uuid: "c85c6d86-7207-42da-b5d6-626fc61249a3",
    name: "partner",
    code: "partner-type",
    type: "Text",
    type_title: "Text",
    configuration: {
        name: {
            type: 'textFieldCustom',
            label: 'Name',
            supportMultiLanguages: ["de", "en"],
            isRequired: true,
            validationType: "string",
            validations: [
                {
                    type: "required",
                    params: ["Partner Type is required"],
                },
                {
                  "type": "nullable",
                  "params": []
                }
            ],
        },
        status: {
          type: "toggleButtons",
          label: "Display on Partner List",
          options: [
            {
              label: "Active",
              value: "active"
            },
            {
              label: "InActive",
              value: "inactive"
            }
          ]
        },
        description: {
            type: 'richTextEditor',
            label: 'Description',
            isRequired: false,
            supportMultiLanguages: ["de", "en"],
        },
        alternative_text: {
          type: '',
          supportMultiLanguages: ["de", "en"],
        },
        image_title: {
          type: '',
          supportMultiLanguages: ["de", "en"],
        },
        xssConfiguration: {
            name: 'textFieldCustom',
            description: 'richTextEditor',
        }
    },
    parameters: {
      status: 'active'
    },
}

