import React, { Fragment, useEffect, useRef, useState } from "react";
import Layout from "../../layout/layout";
import { env } from "../../../environment";
import { FIELDS_SEARCH, LanguageList, removeEmpty } from "../../../utils/utils";
import { showNotification } from "../../../utils/logic";
import {
  MenuDot,
  WIButton,
  WIConfirmDialog,
  WIPaginator,
  WISearchField,
} from "../../common";
import { Column, ColumnAlignType } from "primereact/column";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { ContactManagerAPI } from "../../../services";
import "./contact-manager.scss";
import CreateContactDialogComponent from "./components/create-contact-dialog/create-contact-dialog";
import { getInternalURLPages, fetchCMSAllPages } from "../../pages/builder-page.common";
import { PAGE_CODE } from "../../pages/utils";
import _ from "lodash";
import { buildActionBtnTemplate, formatInnerHtmlDataByLanguageBodyTemplate, formatLogoBodyTemplate } from "../../common/column-template-table/column-template";
import { Link, useNavigate } from "react-router-dom";

const ContactManagerComponent = () => {
  const navigate = useNavigate();
  const [language] = useState(LanguageList[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowCreate, setIsShowCreate] = useState(false);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [contactCards, setcontactCards] = useState({
    data: [],
    totalPage: 0,
  });
  const [contact, setContact] = useState<any>(null);
  const toast = useRef(null);
  const [search, setSearch] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [globalContent, setGlobalContent] = useState<any>({
    search_content: []
  });

  const fetchContactData = async (valueSearch?: string) => {
    setIsLoading(true);

    const data = removeEmpty({
      pageNo: page,
      range: rows,
      search_text: valueSearch || undefined,
      search_fields: valueSearch ? FIELDS_SEARCH.CONTACT : undefined,
    });

    try {
      const res = await ContactManagerAPI.getContacts(data);
      if (res && res.status === 200) {
        const contactData = res.data.records || [];
        setIsLoading(false);
        setcontactCards({
          data: contactData,
          totalPage: res.data.total || 0,
        });

        return contactData;
      }
      return [];
    } catch (error) {
      setIsLoading(false);
      setcontactCards({
        data: [],
        totalPage: 0,
      });
    }
  };

  const fetchGlobalContent = async () => {
    let allPages = await fetchCMSAllPages();
    let personPages = [];
    let masterPages = [];

    if ( allPages && allPages.length > 0) {
      personPages = allPages.filter((item: any) => item.type === PAGE_CODE.Person);
      masterPages = allPages.filter((item: any) => item.type === PAGE_CODE.Master);
    }
    
    let internal_urls = getInternalURLPages(personPages.concat(masterPages));
    const personPageIds = personPages?.map((m: any) => m.uuid);
    const personPageUrls = internal_urls.filter((f) => personPageIds.includes(f.id));
    let search_content = { internal_urls: personPageUrls };
    let newGlobalContent = {...globalContent, search_content};
    setGlobalContent(newGlobalContent);
  }

  const fetchData = async() => {
    setIsLoading(true);
    await Promise.all([
      fetchContactData(),
      fetchGlobalContent()
    ])
    setIsLoading(false);
  }

  const onSearch = () => {
    if (page !== 0) {
      setPage(0);
      setFirst(0);
      return;
    }
    fetchContactData(search);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === "number") {
      fetchContactData(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const onCreateContact = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setContact(null);
    setIsShowCreate(true);
  };

  const onUpdateContact = async (e: any, rowData: any) => {
    navigate(`/global-contents/people/${rowData.uuid}`);
  };

  const onDeleteContact = async (e: any, rowData: any) => {
    setIsShowDeleteDialog(true);
    setContact(rowData);
  };

  const onShowDeleteDialog = (isVisible: boolean) => {
    setContact(null);
    setIsShowDeleteDialog(isVisible);
  };

  const onDeleteConfirm = async () => {
    const response = await ContactManagerAPI.deleteContact(contact.uuid);
    if (response && response.status === 200 && response.data.result.isValid) {
      onShowDeleteDialog(false);
      fetchContactData();
      showNotification("success", "Delete contact successfully.", toast);
    } else {
      showNotification("info", "Delete contact failed.", toast);
    }
  };

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const tableColumns = [
    {
      field: "profile_picture",
      header: "Photo",
      width: "calc(15% - 50px)",
      align: "left",
      bodyTemPlate: formatLogoBodyTemplate(env.PUBLIC_UPLOAD_URL),
    },
    { field: "name", header: "Name", width: "20%", align: "left" },
    {
      field: "position",
      header: "Position",
      width: "20%",
      align: "left",
      bodyTemPlate: formatInnerHtmlDataByLanguageBodyTemplate(language),
    },
    { field: "email", header: "Email", width: "25%", align: "left" },
    { field: "phone", header: "Phone", width: "20%", align: "left" },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        className={col.field}
        style={{ width: col.width }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
          label: "Edit",
          description: "Edit information",
          icon: "fa-solid fa-pen-to-square",
          command: (e: any) => {
            onUpdateContact(e, rowData)
          },
      },
      {
        label: "Delete",
        description: "Delete information",
        icon: "fa-solid fa-trash-can delete-icon",
        dangerous: true,
        command: (e: any) => {
          onDeleteContact(e, rowData);
        },
      }
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="contact-card-manager">
        <div className="manager-container">
          <div className="headline">
            <h3>People</h3>
          </div>
          <div className="header-content common-horizontal-layout-header">
            <WISearchField
              icon={"pi pi-search"}
              placeholder="Search for anything"
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={onSearch}
            />
            <div className="btn-create">
              <Link className="wi-main-button" to="/global-contents/people/create" >
                <div className="wi-btn-label">Create<i className="fa-solid fa-circle-plus ml-2"></i></div>
              </Link>
            </div>
          </div>
          <div className="table-contact">
            <DataTable
              loading={isLoading}
              value={contactCards.data}
              filters={search}
              globalFilterFields={["name", "headline"]}
              emptyMessage="No areas found."
              scrollable
              scrollHeight="calc(100vh - 215px)"
              scrollDirection="both"
            >
              {dynamicColumns}
              <Column
                frozen
                alignFrozen="right"
                style={{ width: "50px" }}
                body={buildMenu}
              ></Column>
            </DataTable>
            <WIPaginator
              first={first}
              rows={rows}
              totalRecords={contactCards.totalPage}
              onPageChange={onBasicPageChange}
            />
          </div>
          <WIConfirmDialog
            visible={isShowDeleteDialog}
            onHide={() => onShowDeleteDialog(false)}
            onConfirm={onDeleteConfirm}
            message={
              <Fragment>
                <span style={{fontSize: '18px', fontWeight: '700'}}>Are you sure you want to delete this person?</span>
                <br/>
                <span style={{fontSize: '13px'}}>
                  All of your data will be deleted <b>permanently</b>.
                  <br/>
                  You <b>cannot undo</b> this action.
                  <br/>
                  <br/>
                  <b>Note</b>: <br/> If this <b>person</b> is using by our website, it can cause <b>unexpected issues</b>.
                  </span>
              </Fragment>
            }
            classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
          />
          <CreateContactDialogComponent
            visible={isShowCreate}
            fetchContactData={fetchContactData}
            onHide={() => setIsShowCreate(false)}
            contact={contact}
            globalContent={globalContent}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ContactManagerComponent;
