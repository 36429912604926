export const ContactBlock =
{
    uuid: "ff886bfa-69c7-4f4f-85db-34a7a36bba15",
    code: "contact-card",
    name: "Contact Card",
    type: "General Blocks",
    type_title: "General Blocks",
    configuration: {
        "cover_picture": {
            "type": "imageField",
            "label": "Cover Picture",
            "isRequired": true,
            "frame": "16:9", 
            "validationType": "string",
            "validations": [
            {
                    "type": "required",
                    "params": [
                        "Picture is required"
                    ]
                }
            ]
        },
        "mobile_cover_picture": {
            "type": "imageField",
            "label": "Mobile Cover Picture",
            "isRequired": true,
            "frame": "9:16",
            "validationType": "string",
            "validations": [
            {
                    "type": "required",
                    "params": [
                        "Picture is required"
                    ]
                }
            ]
        },
        "profile_picture": {
            "type": "imageField",
            "label": "Profile Picture",
            "isRequired": true,
            "frame": "1:1",
            "validationType": "string",
            "validations": [
            {
                    "type": "required",
                    "params": [
                        "Picture is required"
                    ]
                }
            ]
        },
        "alternative_text": {
            "type": "",
            "supportMultiLanguages": [
                "de",
                "en"
            ]
        },
        "image_title": {
            "type": "",
            "supportMultiLanguages": [
                "de",
                "en"
            ]
        },
        "link": {
            "type": "",
            "supportMultiLanguages": [
                "de",
                "en"
            ]
        },
        "headline": {
            "type": "",
            "label": "Headline",
            "isRequired": true,
            "maxLength": 20,
            "supportMultiLanguages": [
                "de",
                "en"
            ],
            "validationType": "string",
            "validations": [
                {
                    "type": "required",
                    "params": [
                        "Headline is required"
                    ]
                },
                {
                    "type": "nullable",
                    "params": []
                },
                {
                    "type": "max",
                    "params": [20, "Max Length is 20 letters"]
                }
            ]
        },
        "generate_page": {
            "type": "toggleButtons",
            "label": "Generate page?",
            "options": [
                { "label": "Yes", "value": true },
                { "label": "No", "value": false },
              ],
          },
        "name": {
            "type": "textField",
            "label": "Name",
            "isRequired": true,
            "maxLength": 50,
            "validationType": "string",
            "validations": [
                {
                    "type": "required",
                    "params": [
                        "Name is required"
                    ]
                }
            ]
        },
        "position": {
            "type": "textFieldCustom",
            "label": "Position",
            "maxLength": 50,
            "isRequired": false,
            "supportMultiLanguages": [
                "de",
                "en"
            ],
            // "validationType": "string",
            // "validations": [
            //     {
            //         "type": "nullable",
            //         "params": []
            //     }, 
            //     {
            //         "type": "max",
            //         "params": [50, "Max Length is 50 letters"]
            //     }
            // ]
        },
        "email": {
            "type": "textField",
            "label": "Email",
            "maxLength": 100,
            "isRequired": false,
            "validationType": "string",
            "validations": [
                {
                    "type": "email",
                    "params": [
                        "Invalid email format"
                    ]
                }
            ]
        },
        "phone": {
            "type": "textField",
            "label": "Phone",
            "maxLength": 20,
            "isRequired": false
        },
        "description": {
            "type": "richTextEditor",
            "label": "Description",
            "isRequired": false,
            "maxLength": 200,
            "supportMultiLanguages": [
                "de",
                "en"
            ],
            // "validationType": "string",
            // "validations": [              
            //     {
            //         "type": "nullable",
            //         "params": []
            //     },
            //     {
            //         "type": "max",
            //         "params": [200, "Max Length is 200 letters"]
            //     }
            // ]
        },
        "xssConfiguration": {
            "name": "textField",
            "position": "textFieldCustom",
            "email": "textField",
            "phone": "textField",
            "description": "richTextEditor"
        }
    },
    parameters: {
        headline: { de: "Kontakt", en: "Contact" },
        link: { de: "", en: "" },
        generate_page :  true,
    }
}