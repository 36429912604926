import * as React from 'react';
import { useState, useEffect } from 'react';
import { WISearchField } from "../../../common";
import CalendarField from "../../../donation-manager/components/donation-filter/calendar-field";
import { Button } from 'primereact/button';
import "./donor-filter.scss"

const DonorFilterComponent = (props: any) => {
    const { fields, filterValues, onRemove, onChange, onSearchFunc } = props;
    const [search, setSearch] = useState<any>(filterValues.search);
    const [values, setValues] = useState<Map<string, string[]>>();
    const [isDirty, setDirty] = useState<boolean>(false);

    const onRemoveFilters = async () => {
        fields.forEach(async (item: string) => {
          values?.set(item, []);
        });
        setSearch("");
        await onRemove();
    };

    const onFilterChange = async (event: any) => {
        setDirty(true);
        values?.set(event.field, event.value);
        await onChange({ field: event.field, value: event.value });
    };

    const getFields = () => {
        const resetValues = new Map<string, string[]>();
        fields.forEach((item: string) => {
          resetValues.set(item, []);
        });
        setValues(resetValues);
      };

    useEffect(() => {
        const filter = new Map<string, string[]>();
        fields.forEach((k: string) => {
        if (k === "joined_date" && filterValues.where.from_joined_date && filterValues.where.to_joined_date) {
            filter.set(k, [filterValues.where.from_joined_date, filterValues.where.to_joined_date]);
        }
        else {
            filter.set(k, filterValues.where[k]);
        }
        });

        setValues(filter);
        setSearch(filterValues.search);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues]);

    useEffect(()=> {
        getFields();
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <div className="donor-filter-component">
            <div className="flex filter-panel">
                <div className="filter-container">
                    <WISearchField
                    icon={"pi pi-search"}
                    placeholder="Search for anything"
                    setSearch={(value: any) => setSearch(value.global.value)}
                    enterSearch={() => onSearchFunc(search)}
                    globalValue={search}/>
                    <CalendarField
                    name="joined_date"
                    label="Joined Date"
                    className="wi-filter-option-donor"
                    pannelClassName="overlaypanel-filter-donor"
                    selectedItems={values?.get("joined_date") || []}
                    visible={values?.has("joined_date") || true}
                    isDirty={isDirty}
                    setDirty={setDirty}
                    onChange={onFilterChange}/>
                </div>
                <div className="remove-filter-button">
                    <Button 
                    type="button"
                    icon="pi pi-times"
                    label="Clear filter(s)"
                    onClick={onRemoveFilters}/>
                </div>
            </div>
        </div>
    );
};

export default DonorFilterComponent;