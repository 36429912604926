import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Column, ColumnAlignType } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "./mailing-details.scss";
import { Toast } from "primereact/toast";
import Layout from "../../../layout/layout";
import ConfirmSendMaiDialog from "../confirm-sendMail-dialog/confirm-sendMail-dialog";
import { formatDateBodyTemplate, formatMailStatusBodyTemplate } from "../../../common/column-template-table/column-template";
import { RHYTHM_LIST, PASSWORD_SENDMAIL, StatusDisplay, SEND_MAIL_STATUS, MAILINGS_STATUS } from "../../../../utils/utils";
import { WIPaginator, WIButton } from "../../../common";
import { DonationManagerAPI, RecurringDonationAPI } from "../../../../services";
import { MenuDot } from '../../../common';
import GenerateCertificateDonationDialogComponent from "../../../donation-manager/components/generate-certificate-donation-dialog/generate-certificate-donation-dialog";
import { onDownloadReceiptByYear } from "../../../../utils/donationManagerLogic";
import { showNotification } from "../../../../utils/logic";
import moment from "moment-timezone";
import _ from "lodash";

const MailingDetail = (props: any) => {

  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [donations, setDonations] = useState({
    data: [],
    totalPage: 0,
  });
  const [paginator, setPaginator] = useState({
      page: 0,
      first: 0,
      rows: 50
  })
  const [isShowSendMailDialog, setIsShowSendMailDialog] = useState(false);
  const [iShowProgressLoadingLoading, setIShowProgressLoadingLoading] = useState(false);
  const [password, setPassword] = useState<string>('');
  const yearDonation = window.location.pathname ? window.location.pathname.split("/")[3] : '';
  const [expandedRows, setExpandedRows] = useState({});
  const [isShowCreateCertificateDialog, setIsShowCreateCertificateDialog] = useState(false);
  const [donation, setDonation] = useState<any>(null);
  const [mailing, setMailing] = useState({
    data: null,
    sentRecords: [],
    sentFailedRecords: []
  });
  const [isDisabledBTNSend, setIsDisabledBTNSend] = useState(false);
  const [isRetrySend, setIsRetrySend] = useState(false);


  const filterObject = {
    pageNo: 0,
    range: 50,
    where: {
      is_first_time: true,
      to: `31/12/${yearDonation}`,
      is_recurring: true,
      rhythm: [1,3,6,12],
      status: 'completed'
    },
    order: [["serial", "DESC"]]
  }
  
  const onBasicPageChange = (event: any) => {
    setPaginator({
        page: event.page,
        first: event.first,
        rows: event.rows
    })
  };
  
  const fetchDonationsData = async (search: any, mailingData?: any) => {
    setIsLoading(true);
    
    try {
        const res = await DonationManagerAPI.getDonationsBySearch(search);
        if (res && res.status === 200) {
          if(mailingData.data && res.data.records.length > 0) {     
            const validRecurringDonations = _.sortBy(updateRecurringDonations(res.data.records), 'payment_date').reverse() || []; 
            const recurringDonationsData :any = validRecurringDonations && validRecurringDonations.length > 0 ? validRecurringDonations.map((item: any) => ({
              ...item,
              recurring_donations: [],
              full_name: item.payment_company_name || item.payment_first_name + " " + item.payment_last_name,
              status_mailing: renderMailingStatus(mailingData, item)
            })) : [];

            setDonations({
              data: recurringDonationsData,
              totalPage: res.data.total || 0,
            });
          } else {
            setDonations({
              data: [],
              totalPage: res.data.total || 0,
            });
          }
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setDonations({
          data: [],
          totalPage: 0
        })
      }
  };

  const disabledBTNSendAction = (data: any) => {
    switch(data.status) {
      case MAILINGS_STATUS.INPROGRESS:
      case MAILINGS_STATUS.COMPLETED:
      case MAILINGS_STATUS.OBSOLETED: {
        setIsDisabledBTNSend(true);
        setIsRetrySend(false);
        break;
      }
      case MAILINGS_STATUS.INCOMPLETE: {
        setIsDisabledBTNSend(false);
        setIsRetrySend(true);
        break;
      }
      case MAILINGS_STATUS.NEW: {
        setIsDisabledBTNSend(false);
        setIsRetrySend(false);
        break;
      }
      default: {
        setIsDisabledBTNSend(false);
        setIsRetrySend(false);
        break;
      }
    }
  }

  const fetchMailingDetail = async () => {
    try {
        const res = await RecurringDonationAPI.getMailingByYear(yearDonation);
        if (res && res.status === 200) {
          const mailingData = {
            data: res.data,
            sentRecords: JSON.parse(res.data.sent_records ||'[]') || [],
            sentFailedRecords: JSON.parse(res.data.sent_failed_records ||'[]') || []
          };

          disabledBTNSendAction(mailingData.data);

          setMailing(mailingData);
          fetchDonationsData(filterObject, mailingData);
        } else {
          setMailing({
            data: null,
            sentRecords: [],
            sentFailedRecords: []
          });
        }
      } catch (error) {
        setMailing({
          data: null,
          sentRecords: [],
          sentFailedRecords: []
        });
      }
  };

  const updateRecurringDonations = (recurringDonations: any) => {
    return recurringDonations.filter((item :any) => {
      const yearRecurringDonation = moment(moment.tz(new Date(item.payment_date), "Europe/Berlin").format("MMM DD, YYYY hh:mm:ss")).get('year');
      if(yearRecurringDonation < parseInt(yearDonation) || yearRecurringDonation === parseInt(yearDonation)) {
        return item;
      }
    }) 
  }

  const fetchRecurringDonationsData = async (uuid: any) => {
    const indexUUID = donations.data.findIndex((d: any) => d.uuid == uuid);
    const donationItem: any = donations.data[indexUUID];
    if(donationItem && donationItem.recurring_donations && donationItem.recurring_donations.length === 0 && !donationItem.stop_loading) {
        try {
            setIsLoading(true);
            const filterRecurringDonation = {
                pageNo: 0,
                range: 50,
                where: {
                  from: `01/01/${yearDonation}`,
                  to: `31/12/${yearDonation}`,
                  parent_donation_id: uuid,
                  status: 'completed'
                },
                order: [["serial", "DESC"]]
              }
            const res = await DonationManagerAPI.getDonationsBySearch(filterRecurringDonation);
            if (res && res.status === 200) {
              const recurringDonations :any = donations.data.map((item:any, index: number) => {
                    return {
                        ...item,
                        recurring_donations: index === indexUUID ?  _.sortBy(updateRecurringDonations(res.data.records), 'payment_date') : item.recurring_donations,
                        stop_loading: index === indexUUID ? true : false
                    }
              })
              setDonations({
                  ...donations,
                data: recurringDonations
              });
              
              setIsLoading(false);
            }
          } catch (error) {
            setIsLoading(false);
            setDonations({
              data: [],
              totalPage: 0
            })
          }
    }
  };

  const sendMailingsAPI = async () => {
    setIShowProgressLoadingLoading(true);
    try {
        const res = await RecurringDonationAPI.sendMailings(yearDonation);
        if (res && res.status === 200) {
          initialData();
          onHide();
          showNotification("success", "Send mailings successfully", toast);
        } else {
          onHide();
        }
      } catch (error) {
        onHide();
        showNotification("error", "Failed to send mailings", toast);
      }
  };

  const onSendMail = () => {
    setIsShowSendMailDialog(true);
  }

  const onHideConfirmDialog = () => {
    if(!iShowProgressLoadingLoading) {
      setPassword("");
      setIsShowSendMailDialog(false);
      setIShowProgressLoadingLoading(false);
    }
  }

  const onHide = () => {
    setIShowProgressLoadingLoading(false);
    setPassword("");
    setIsShowSendMailDialog(false);
    setIShowProgressLoadingLoading(false);
  }

  const onSetPassword = (value: string) => {
    setPassword(value);
  }

    const sendMailRecurringDonation = async () => {
      if(password === PASSWORD_SENDMAIL) {
        sendMailingsAPI();
      } else {
        showNotification("error", "Incorrect password", toast);
      }
    };

  const initialData = async () => {
    fetchMailingDetail();
  }

  const renderMailingStatus = (mailingData: any, item: any) => {
    
    const { uuid } = item;

    const pass_status = mailingData.sentRecords.some((item: any) => item === uuid);
    if(pass_status) {
      return SEND_MAIL_STATUS.COMPLETED;
    }
    
    const failed_status = mailingData.sentFailedRecords.some((item: any) => item === uuid);
    if(failed_status) {
      return SEND_MAIL_STATUS.FAILED;
    }

    if(mailingData.data.status !==  MAILINGS_STATUS.NEW && mailingData.data.status !== MAILINGS_STATUS.INCOMPLETE) { 
      return SEND_MAIL_STATUS.NOT_APPLICABLE;
    }

    return SEND_MAIL_STATUS.NOT_YET;
  }

  useEffect(() => {
    initialData();
  }, []);

  useEffect(() => {
    if (typeof paginator.page === "number") {
      fetchDonationsData({
        ...filterObject,
        pageNo: paginator.page,
        range: paginator.rows
      }, mailing);
    }
  }, [paginator]);

  const formatSerialBodyTemplate = (rowData: any) => {
    return <a href={`/donations/${rowData.uuid}`} className="btn-serial" >{rowData.donation_serial}</a>
  };

  const formatAmountBodyTemplate = (rowData: any) => {
    return <div><b style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>{rowData ? parseFloat(rowData.donation_money?.toString())?.toLocaleString("de-DE") : '-'}</b> <span>{rowData.payment_currency_code?.toUpperCase()}</span></div>;
  };
  
  const parentColumns = [
    {
      field: "donation_serial",
      header: "ID",
      width: "8%",
      align: "left",
      bodyTemPlate: formatSerialBodyTemplate,
    },
    {
      field: "donation_money",
      header: "AMOUNT",
      width: "8%",
      align: "right",
      bodyTemPlate: formatAmountBodyTemplate,
    },
    {
      field: "status_mailing",
      header: "",
      width: "12%",
      align: "center",
      bodyTemPlate: formatMailStatusBodyTemplate(StatusDisplay),
    },
    {
      field: "rhythm",
      header: "RHYTHM",
      width: "10%",
      align: "left",
      bodyTemPlate: (rowData: any) => {
        const rhythm = RHYTHM_LIST.find(c => c.code === rowData.rhythm)?.name;
        if (rhythm && rowData.is_recurring) {
          return <span>{rhythm} <i title="First time donation" className="fa-solid fa-clock-rotate-left rhythm-icon"></i></span>
        }

        if (rhythm && !rowData.is_first_time) {
          return <span>{rhythm} <i title="Subsequent donation" className="fa-solid fa-arrows-rotate rhythm-icon"></i></span>
        }

        return RHYTHM_LIST.find(c => c.code === rowData.rhythm)?.name || 'Once';
      }
    },
    {
      field: "full_name",
      header: "NAME",
      width: "13%",
      align: "left",
      minWidth: "150px",
    },
    {
      field: "payment_email",
      header: "EMAIL",
      width: "15%",
      align: "left",
      wordBreak: "break-word",
      maxWidth: "270px",
    },
    {
      field: "payment_date",
      header: "DATE",
      width: "13%",
      align: "left",
      bodyTemPlate: formatDateBodyTemplate("MMM DD, YYYY HH:mm:ss"),
    },
    {
      field: "payment_method",
      header: "METHOD",
      width: "13%",
      align: "left",
    }
  ];

  const childrenColumn = [
    {
      field: "donation_serial",
      header: "ID",
      width: "10%",
      align: "left",
      bodyTemPlate: formatSerialBodyTemplate,
    },
    {
      field: "donation_money",
      header: "AMOUNT",
      width: "15%",
      align: "right",
      bodyTemPlate: formatAmountBodyTemplate,
    },
    {
      field: "payment_date",
      header: "DATE",
      width: "20%",
      align: "left",
      bodyTemPlate: formatDateBodyTemplate("MMM DD, YYYY HH:mm:ss"),
    },
    {
      field: "payment_method",
      header: "METHOD",
      width: "45%",
      align: "left",
    }
  ];

  const renderDynamicColumns = (columns: any) => {
      return columns.map((col: any, i: any) => {
        return (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            style={{ width: col.width }}
            body={col.bodyTemPlate}
            align={col.align as ColumnAlignType}
          />
        );
      });
  }
 
  const rowExpansionTemplate = (e: any) => {
    return (
        <div className="orders-subtable">
            <DataTable value={e.recurring_donations} responsiveLayout="scroll">
                <Column style={{ width: '10%' }} />
                {renderDynamicColumns(childrenColumn)}
            </DataTable>
        </div>
    );
}

const onGenerateCertificateDialog = (e: any, rowData: any) => {
    setDonation(rowData);
    setIsShowCreateCertificateDialog(true);
  };

  return (
    <Layout>
      <div className="mailing-manager">
        <div className="manager-container">
            <div className="header-container">
                <div className="headline">
                    <h3>{yearDonation} - Mailing</h3>
                </div>
                <div className="btn-action">
                    <WIButton
                        primary={true}
                        type={"button"}
                        size={"small"}
                        label={`${isRetrySend ? 'Resend' : 'Send'}`}
                        icon={"fa-sharp fa-solid fa-paper-plane"}
                        onClick={() => onSendMail()}
                        disabled={isDisabledBTNSend}
                    />
                </div>
            </div>
            <div className="table-mailing-detail">
                <DataTable 
                    loading={isLoading}
                    value={donations.data}
                    scrollable 
                    scrollHeight="calc(100vh - 166px)"
                    scrollDirection="both"
                    expandedRows={expandedRows} 
                    onRowToggle={(e: any) => {
                      const uuid = Object.keys(e.data)[Object.keys(e.data).length - 1];
                      fetchRecurringDonationsData(uuid);
                      setExpandedRows(uuid ? { [uuid]: true } : {});
                    }}
                    responsiveLayout="scroll"
                    rowExpansionTemplate={(e) => rowExpansionTemplate(e)} 
                    dataKey="uuid"
                >
                    <Column expander style={{ width: '5%' }} />
                    {renderDynamicColumns(parentColumns)}
                    <Column
                        frozen
                        alignFrozen="right"
                        style={{ width: "3%" }}
                        body={(rowData) => {
                      return <MenuDot items={[
                        {
                          label: 'Download Certificate',
                          icon: 'pi pi-download',
                          command: (e: any) => onGenerateCertificateDialog(e, rowData)
                        },
                        {
                          label: 'Download Receipt',
                          icon: 'pi pi-download',
                          command: (e: any) => onDownloadReceiptByYear(toast, rowData, yearDonation)
                        },

                      ]}></MenuDot>;
                    }}
                  ></Column>
                </DataTable>
                <WIPaginator
                    first={paginator.first}
                    rows={paginator.rows}
                    totalRecords={donations.totalPage}
                    onPageChange={onBasicPageChange}
                />
            </div>
            <ConfirmSendMaiDialog 
                visible={isShowSendMailDialog}
                onHide={() => onHideConfirmDialog()}
                onConfirm={sendMailRecurringDonation}
                password={password}
                onSetPassword={onSetPassword}
                iShowProgressLoadingLoading={iShowProgressLoadingLoading}
            />
            <GenerateCertificateDonationDialogComponent
                visible={isShowCreateCertificateDialog}
                onHide={() => setIsShowCreateCertificateDialog(false)}
                donation={donation}
                yearDonation={yearDonation}
                isMailing={true}
            />
        </div>
      </div>
      <Toast ref={toast} />
    </Layout>
  );
};

export default MailingDetail;
