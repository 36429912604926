import { Dialog } from "primereact/dialog";
import { useRef, useState } from "react";
import React from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { LanguageCode, LanguageDisplayList } from "../../../../utils/utils";
import "./download-certificate-dialog.scss";
import { SelectButton } from 'primereact/selectbutton';
import { CertificateManagerAPI } from "../../../../services";
import { generateLinkDownLoad, showNotification } from "../../../../utils/logic";
import moment from 'moment';

const DownloadCertificateDialogComponent = (props: any) => {
  const { visible, onHide, certificateData } = props;
  const [isLoading, setIsLoading] = useState(false);
  
  const toast = useRef(null);
  const [languageCode, setLanguageCode] = useState(LanguageCode.DE);

  const generateFileDownload = (filename:string, data:any) =>{
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const url = URL.createObjectURL(new Blob([byteArray], { type: "application/pdf; charset=utf-8" }));
    generateLinkDownLoad(filename, url);
}

  const downloadPDF = async (certificateID: string, fileName: string, languageCode: LanguageCode) => {
    try {
        if (certificateID) {
          let response = await CertificateManagerAPI.downloadCertificate(certificateID, languageCode);
            if (response && response.status === 200) {
                generateFileDownload(fileName, response.data.content);
                showNotification("success", "Generate successfully", toast);
            } 
            else {
                showNotification("error", "Generate failed", toast);
            }
        }
        setIsLoading(false);
        onHide();
    } catch (error) {
        onHide();
        setIsLoading(false);
        showNotification("error", "Generate failed", toast);
    }
}

  const onDownloadCertificate = async () => {
    const name = certificateData.certificate_name.trim();
    const default_file_name = name.toLowerCase().replace(/(^|\s)\S/g, (l: any) => l.toUpperCase());
    const format_date = moment(new Date(certificateData.certificate_date)).format("YYYY-MM-DD");
    const default_de_file_name = `Urkunde_${default_file_name.replace(' ', '-')}_${format_date}.pdf`;
    const default_en_file_name = `Certificate_${default_file_name.replace(' ', '-')}_${format_date}.pdf`;
    const fileName = languageCode === 'de' ? default_de_file_name : default_en_file_name;

    await downloadPDF(certificateData.id, fileName, languageCode);
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={`Download certificate`}
        visible={visible}
        style={{ width: "350px" }}
        modal
        className="wi-dialog"
        onHide={onHide}
      >
        <div className="dialog-certificate-container">
          <div className="row">
            <div className="col-md-12 language-container">
              <label className="label">Choose language</label>
              <SelectButton
                className="wi-selectbutton"
                name='language_option'
                value={languageCode}
                options={LanguageDisplayList}
                onChange={(item: any) => {
                  setLanguageCode(item.value);
                }}
                optionLabel="name"
                optionValue="code"
            />
            </div>
          </div>
         
          <div className="btn-submit-container">
            <Button
              type="submit"
              label={"Download"}
              icon="pi pi-download"
              iconPos="right"
              loading={isLoading}
              className="p-button-rounded btn-submit wi-button"
              onClick={(e) =>{
                setIsLoading(true);
                onDownloadCertificate();
              }} 
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DownloadCertificateDialogComponent;
