import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { ContactBlock } from "../../utils";
import DynamicForm from "../../../../dynamic-form/dynamic-form";
import { AxiosResponse } from "axios";
import { onNavigateToByURL, showNotification } from "../../../../../utils/logic";
import { CMsPageManagerAPI, ContactManagerAPI } from "../../../../../services";
import { findCmsPage } from "../../../../../utils/commonGeneratePageData";
import "./create-contact-dialog.scss";
import { generatePersonPageData } from "../../contact-business";
import { callCreateAndPublishPageAPI, callDeletePageAPI, callUpdatePageAPI, fetchAllBlocks, fetchCMSAllPages, fetchCMSFooters, fetchCMSHeaders, fetchCMSParentPages, fetchPageTypes, getPageTypeByCode } from "../../../../pages/builder-page.common";
import { PAGE_CODE } from "../../../../pages/utils";
import { buildTooltipManageTemplate } from "../../../../common/column-template-table/column-template";
import { env } from "../../../../../environment";
import { Tooltip } from "primereact/tooltip";

const CreateContactDialogComponent = (props: any) => {
  const { visible, contact, fetchContactData, onHide, globalContent} = props;
  const toast = useRef(null);
  const block = { ...ContactBlock };
  const [allBlocks, setAllBlocks] = useState<any>([]);
  const [headers, setHeaders] = useState<any>([]);
  const [footers, setFooters] = useState<any>([]);
  const [personPageType, setPersonPageType] = useState<any>();
  const [parentPages, setParentPages] = useState<any>([]);
  const [cmsPersonPages, setCmsPersonPages] = useState<any>([]);
  const [cmsPersonPage, setCmsPersonPage] = useState<any>();
  const [cmsMasterPages, setCmsMasterPages] = useState<any>([]);
  const [personPageURL, setPersonPageURL] = useState<any>();

  useEffect(() => {
    fetchDataToCreatePersonPage();
  }, []);

  useEffect(() => {
    const cmsPersonPage= findCmsPage(cmsPersonPages, contact);
    if(cmsPersonPage && cmsMasterPages) {
      const partnerURL = getParentURL(cmsPersonPage?.page_id, cmsMasterPages);
      setPersonPageURL(partnerURL);
      setCmsPersonPage(cmsPersonPage);
    }
  }, [contact]);


  const onSubmitDataForm = async (value: any) => {
    try {
      const resGenerateContact = await requestContactData(value);
      let personResponse = handleRequestContact(resGenerateContact);
      await handleRequestPersonPage(personResponse, value);
      if(personResponse){
        await fetchDataToCreatePersonPage();
      }
    } catch (error) {
      showNotification("error", "Failed to generate contact", toast);
    }
  };

  const fetchDataToCreatePersonPage = async () => {
    let [
      footers, 
      headers, 
      pageTypes,
      //allPages,
      allBlocks
    ] = await Promise.all([
      fetchCMSFooters(),
      fetchCMSHeaders(),
      fetchPageTypes(),
      //fetchCMSAllPages(),
      fetchAllBlocks()
    ]);

    setAllBlocks(allBlocks);
    setFooters(footers);
    setHeaders(headers);
    let personPageType = getPageTypeByCode(pageTypes, PAGE_CODE.Person);
    let masterPageType = getPageTypeByCode(pageTypes, PAGE_CODE.Master);

    let [
      personRes,
      masterRes
    ] = await Promise.all([
        CMsPageManagerAPI.getPages(personPageType?.uuid, { pageNo: 0, range: 10000 }),
        CMsPageManagerAPI.getPages(masterPageType?.uuid, { pageNo: 0, range: 10000 })
    ]);

    setCmsPersonPages(personRes?.data?.records);
    setPersonPageType(personPageType);

    setCmsMasterPages(masterRes?.data?.records);
    let parentPages = fetchCMSParentPages(masterRes?.data?.records);
    setParentPages(parentPages);

  }

  const requestContactData = async (value: any) => {
    // @ts-ignore: Object is possibly 'null'.
    if (contact) {
      // @ts-ignore: Object is possibly 'null'.
      const contactObj = contact;

      let request = { ...contactObj, ...value };
      return updateContact({
        ...request,
      });
    }

    return generateContact({
      ...value,
    });
  };

  const handleRequestContact = (
    resGenerateContact: AxiosResponse<any, any>
  ) => {
    // @ts-ignore: Object is possibly 'null'.
    const response = resGenerateContact;
    let method = response.config.method;

    if (
      response &&
      response.status === 200 &&
      (method === "post" || method === "put")
    ) {
      let mesage =
        method === "post"
          ? "Generate contact successfully"
          : "Update contact successfully";
      showNotification("success", mesage, toast);
      onHide();
      fetchContactData();
      return response.data.result;
    } else {
      showNotification("error", "Failed to generate contact", toast);
    }
    return false;
  };

  const generateContact = async (parameters: any) => {
    return await ContactManagerAPI.createContact(parameters);
  };

  const updateContact = async (parameters: any) => {
    contact.name = parameters.name;
    contact.position = parameters.position;
    contact.email = parameters.email ? parameters.email: null;
    contact.phone = parameters.phone;
    contact.description = parameters.description;
    contact.link = parameters.link;
    contact.generate_page = parameters.generate_page;

    if (contact.profile_picture === parameters.profile_picture) {
      delete contact["profile_picture"];
      delete contact[`${contact["profile_picture"]}_extension`];
    } else {
      contact.profile_picture = parameters.profile_picture;
    }
    if (contact.cover_picture === parameters.cover_picture) {
      delete contact["cover_picture"];
      delete contact[`${contact["cover_picture"]}_extension`];
    } else {
      contact.cover_picture = parameters.cover_picture;
    }
    if (contact.mobile_cover_picture === parameters.mobile_cover_picture) {
      delete contact["mobile_cover_picture"];
      delete contact[`${contact["mobile_cover_picture"]}_extension`];
    } else {
      contact.mobile_cover_picture = parameters.mobile_cover_picture;
    }

    return await ContactManagerAPI.updateContact(contact);
  };

  const handleRequestPersonPage = async (personResponse: any, formikValues: any) => {
    let resultHandlePersonPage = false;
    if(!personResponse) {
      return resultHandlePersonPage;
    }

    const cmsPage= findCmsPage(cmsPersonPages, contact);
    if(formikValues.generate_page != true)
    {
      if(contact && cmsPage && cmsPage.uuid)
      {
        await callDeletePageAPI(cmsPage.uuid, 
          (result: any) => {
            resultHandlePersonPage = true;
            showNotification("success", "Delete Person Page successfully", toast);
          },
          () => showNotification("error", "Failed to delete Person Page", toast));
      }
      return resultHandlePersonPage;
    }

    let pageData = await generatePersonPageData(formikValues, parentPages, cmsPage, personPageType, allBlocks, footers, headers, personResponse.id, true);
    if(contact && cmsPage && cmsPage.uuid) {
      delete pageData["url"];

      await callUpdatePageAPI(cmsPage.uuid, pageData,
       async () => {
          let updateContentRes = await CMsPageManagerAPI.update(cmsPage.uuid, pageData.content);
          if(updateContentRes && updateContentRes.status === 200) {
            resultHandlePersonPage = true;
            showNotification("success", "Update Person Page successfully", toast);
          }
        },
        () => showNotification("error", "Failed to update Person Page", toast)
      );
    } else {
      
      correctUrlPersonPage(pageData);
      await callCreateAndPublishPageAPI(pageData, 
        (result: any) => {
          resultHandlePersonPage = true;
          showNotification("success", "Generate Person Page successfully", toast);
        },
        () => showNotification("error", "Failed to generate Person Page", toast));
    }

    return resultHandlePersonPage;
  };

  const correctUrlPersonPage = (pageData: any) =>{
    const duplicateUrls = cmsPersonPages.filter((f: any) => f.url.includes(pageData.url));
    if(duplicateUrls.length > 0){
      let personUrl = pageData.url + "-";
      let expectedUrl = "";
      for (let index = 1; index < 1000; index++) {
         let urlExisted = duplicateUrls.find((f: any) => f.url.includes(personUrl + index));
        if(!urlExisted)
        {
          expectedUrl = personUrl + index;
          break;
        }
      }

      if(expectedUrl != ""){
        pageData.url = expectedUrl;
      }
    }
  }

  const buildHeaderTemplate = () => {
    const label = contact?.uuid ? "Update Person" : "New Person";
    const tooltip = `Click to open person page`;
    const URL = contact && cmsPersonPage && cmsPersonPage.uuid ? env.CMS_URL + personPageURL + cmsPersonPage.url : "";
    return buildTooltipManageTemplate(label, " ", tooltip, URL, !cmsPersonPage || !contact?.generate_page);
  };

  const buildTooltipManageTemplate = (title: string, label: string, tooltipLabel: string, url: string, isDisabled = false) => {
    return (
      <div className="title-content">
        {label ? ( <>
          <Tooltip target=".fa-arrow-up-right-from-square" mouseTrack mouseTrackTop={15} position="bottom">{tooltipLabel}</Tooltip>
          <label className="title-header">{title}</label>
          <span className="me-3">{label}</span>
          <i className={`${ isDisabled ? "has-disable-navigate" : "" } fa-solid fa-arrow-up-right-from-square person-navigate-arrow`}  onClick={() => onNavigateToByURL(url)}></i>
        </>) : (
          <label className="title-header">New</label>
        )}
      </div>
    )
  }

  const getParentURL = (page_id: string, pages: any[]) => {
    if (!page_id) {
      return '/';
    }
  
    let selected = pages.find((p: any) => p.uuid === page_id);
    let prefix = selected?.url;
    return prefix ? '/' + prefix + '/' : '/';
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        className="create-contact-dialog wi-dialog"
        header={buildHeaderTemplate}
        visible={visible}
        style={{ width: "700px" }}
        modal
        onHide={onHide}
      >
        <DynamicForm
          formSchema={block?.configuration || {}}
          enableValidation
          globalContent={globalContent}
          data={
            contact
              ? {
                  headline: contact.headline,
                  name: contact.name,
                  profile_picture: contact.profile_picture,
                  cover_picture: contact.cover_picture,
                  mobile_cover_picture: contact.mobile_cover_picture,
                  position: contact.position,
                  email: contact.email,
                  phone: contact.phone,
                  description: contact.description,
                  link_name: contact.link_name,
                  link: contact.link,
                  generate_page: contact.generate_page
                }
              : block?.parameters
          }
          submitButtonConfig={{
            title: contact?.uuid ? "Update" : "Create",
            icon: "pi pi-chevron-right",
            iconPos: "right",
          }}
          onSubmitData={onSubmitDataForm}
          mode="dialog"
        ></DynamicForm>
      </Dialog>
    </>
  );
};

export default CreateContactDialogComponent;