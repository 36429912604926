import * as React from "react";
import { useRef } from "react";
import { Menu } from "primereact/menu";
import "./menu-button.scss";
import { Button } from "primereact/button";
import { customMenuItemsDot } from "../column-template-table/column-template";

const MenuButton = (props: any) => {
  const { items, label = 'Action', icon = 'fa-chevron-down' } = props;
  const refMenu = useRef<any>(null);

  const handleClickEvent = (event: any) => {
    refMenu?.current?.toggle(event);
  };

  return (
    <div className="wi-menu-button-action" onClick={handleClickEvent}>
      <Menu
        className="wi-button-action-menu"
        model={items.map((m: any) => ({ template: customMenuItemsDot, ...m})) || []}
        popup
        ref={refMenu}
      />
      <Button
        type="button"
        className="menu-button"
        {
          ...(icon ? {
            icon: `fa-solid ${icon}`,
            iconPos: "right"
          } : {})
        }
        label={label}
      />
    </div>
  );
};

export default MenuButton;
