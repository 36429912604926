import * as React from "react";
import { useState, useRef } from "react";
import "./import-cms-pages.scss";
import Layout from "../../layout/layout";
import { Formik } from "formik";
import {
  CMsPageManagerAPI
} from "../../../services";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { showNotification } from "../../../utils/logic";
import WICSVUploadField from "../../common/upload/wi-upload-csv-field";

const ImportCMSPages = () => {
  
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const formikRef = useRef(null);

  const fetchData = async (values: any) => {
    try {
      let data = {
        _batch: {
          data: values.dataImport
        }
      };
      
      let response = await CMsPageManagerAPI.importCMSPages(data);
      if (response && response.status === 200) {
        if(response.data?.result?.failedURLs?.length <= 0) {
          showNotification("success", "Import successfully.", toast);
        } else {
          console.log('Failed URLs', response.data?.result?.failedURLs);
          showNotification("error", "Failed to import", toast);
        }
      }
    } catch (error) {
      console.log('error', error);
      showNotification("error", "Failed to import", toast);
    }

    setIsLoading(false);
  };

  const initialValues = () => {
    return {
      dataImport: ""
    }
  };

  return (
    <Layout>
      <div className="import-page">
        <Toast ref={toast} />
        <div className="import-header">
        </div>
        <div className="import-container">
          <div className="import-btn">
            <Formik
              innerRef={formikRef}
              initialValues={initialValues()}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                fetchData(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                dirty,
                isValid,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-start upload-file">
                      <WICSVUploadField
                        name="dataImport"
                        className="input-upload"
                        labelClassName="label-upload-csv"
                        nameFileClassName="name-file" />
                    </div>
                    <div className="col-12 d-flex flex-column align-items-start justify-content-start note-message">
                      <p>Please make sure that all data are correct.</p>
                      <p>This cannot be undone</p>
                    </div>
                    <div className="btn-import">
                      <Button
                        loading={isLoading}
                        type="submit"
                        label="Submit"
                        icon="pi pi-chevron-right"
                        iconPos="right"
                        className="btn-submit p-button-rounded">
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ImportCMSPages;
