import * as React from "react";
import { useEffect, useRef, useState } from "react";
import "./goal-manager.scss";
import Layout from "../layout/layout";
import { DataTable } from "primereact/datatable";
import { Column, ColumnAlignType } from "primereact/column";
import { CampaignManagerAPI, GoalManagerAPI, PartnerManagerAPI, RegionManagerAPI } from "../../services";
import { Toast } from "primereact/toast";
import { useSearchParams, Link } from "react-router-dom";
import {
  MenuDot,
  WIPaginator,
} from "../common";
import {
  buildProgressTemplate,
  formatDateBodyTemplate,
} from "../common/column-template-table/column-template";
import _ from "lodash";
import {
  getGoalObjectSearchValue,
  getGoalObjectWithValues,
  getGoalPayloadConditions,
  getObjectWithValues
} from "./goal-manager-util";
import { Sidebar } from 'primereact/sidebar';
import AddGoalFormComponent from "./components/add-goal-form/add-goal-form";
import { GOAL_STATUS_LIST, GOAL_TYPES } from "../../utils/utils";
import GoalFilterComponent from "./components/goal-runs-filter/goal-runs-filter";
import moment from "moment";
import WITagWIR from "../wilderness-run-details/components/tag-wir/tag-wir";
import { env } from "../../environment";

export type FilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[],
    region_id: string[],
    partner_id: string[],
    status: string[],
    campaign_id: string[]
  };
  order: [];
};

const GoalManager = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Math.max(+(searchParams?.get("pageNumber") || 1) - 1, 0);
  const noRows = Math.min(
    Math.max(+(searchParams?.get("noRows") || 50), 50),
    1000
  );
  const sortField = searchParams?.get("sortField") || "created_at";
  const sortOrder = +(searchParams?.get("sortOrder") || -1);
  const searchField = searchParams?.get("search") || "";
  const filterCampaign = searchParams?.get('campaign_id') ? searchParams?.get('campaign_id')?.split(',') || [] : [];
  const filterPartner = searchParams?.get('partner_id') ? searchParams?.get('partner_id')?.split(',') || [] : [];
  const filterRegions = searchParams?.get('region_id') ? searchParams?.get('region_id')?.split(',') || [] : [];
  const filterStatus = (searchParams?.get('filter_status') || GOAL_STATUS_LIST.map((c) => c.code).join(',')).split(',');
  
  const statusTypeAll = 'all';
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [goals, setGoals] = useState({
    data: [],
    totalPage: 0,
  });
  const [paginator, setPaginator] = useState({
    page: 0,
    first: 0,
    rows: 20,
  });
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder,
  });
  const [filterValues, setFilterValues] = useState<FilterParameters>({
    pageNo: pageNumber,
    range: noRows,
    search: searchField,
    where: {
      or: searchField ? getGoalObjectSearchValue(searchField) : [],
      status: filterStatus,
      region_id: filterRegions,
      partner_id: filterPartner,
      campaign_id: filterCampaign 
    },
    order: [],
  });
  const [goal, setGoal] = useState<any>(null);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [campaigns, setCampaigns] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [partners, setPartners] = useState<any[]>([]);
  const [statusGoal, setStatusGoal] = useState<any[]>([]);
  const [selectedStatusGoal, setSelectedStatusGoal] = useState<any>(filterStatus.length !== 1 ? statusTypeAll : filterStatus[0]);
  const onBasicPageChange = (event: any) => {
    setPaginator({
      page: event.page,
      first: event.first,
      rows: event.rows,
    });
  };

  const setupGoalsStatus = async () => {
    const statusList = _.cloneDeep(GOAL_STATUS_LIST);
    statusList.unshift({ code: 'all', name: { de: 'All', en: 'All' } });
    setStatusGoal(statusList);
  };

  const fetchGoalData = async () => {
    setIsLoading(true);
    const payload = getGoalPayloadConditions(filterValues, sortConfig);
    
    try {
      const resGoals = await GoalManagerAPI.getGoals({
        pageNo: paginator.page,
        range: paginator.rows,
        ...payload,
      });

      if (resGoals && resGoals.status === 200) {
        setGoals({
          data: resGoals.data.records,
          totalPage: resGoals.data.total || 0,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setGoals({
        data: [],
        totalPage: 0,
      });
      setIsLoading(false);
    }
  };


  const fetchAreasData = async () => {
    setIsLoading(true);
    const [regionsRes, campaignsRes, partnersRes] = await Promise.all([
      RegionManagerAPI.getAllRegions(),
      CampaignManagerAPI.getAllCampaigns(),
      PartnerManagerAPI.getAllPartners()
    ]);

    // @ts-ignore: Object is possibly 'null'.
    if (regionsRes && regionsRes.status === 200 && regionsRes.data.records?.length > 0) {
      setRegions(_.sortBy(regionsRes.data.records, (item) => item.name?.de?.toLowerCase()));
    }
    // @ts-ignore: Object is possibly 'null'.
    if (campaignsRes && campaignsRes.status === 200 && campaignsRes.data.records?.length > 0) {
      setCampaigns(_.sortBy(campaignsRes.data.records, (item) => item.name?.de?.toLowerCase()));
    }

    if (partnersRes.status === 200 && partnersRes.data.records.length > 0) {
      setPartners(_.sortBy(partnersRes.data.records, (item) => item.name?.de?.toLowerCase()));
    }

    setIsLoading(false);
  };


  useEffect(() => {
    fetchAreasData();
    setupGoalsStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof paginator.page === "number") {
      setFilterValues({
        ...filterValues,
        pageNo: paginator.page,
        range: paginator.rows,
      });
      setSearchParams({
        pageNumber: (paginator.page + 1).toString(),
        noRows: paginator.rows.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginator]);

  useEffect(() => {
    const searchParams = {
      pageNumber: (paginator.page + 1).toString(),
      noRows: paginator.rows.toString(),
      search: filterValues.search,
      sortField: sortConfig.sortField,
      sortOrder: sortConfig.sortOrder,
      filterStatus: filterValues.where.status.join(',') || '',
      filterRegions: filterValues.where.region_id?.join(',') || '',
      filterPartner: filterValues.where.partner_id?.join(',') || '',
      filterCampaign: filterValues.where.campaign_id?.join(',') || ''
    };

    setSearchParams(_.omitBy(searchParams, (p) => !p));
    fetchGoalData(); // temporary
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, sortConfig]);

  const onSearchFunc = (searchText: string) => {
    const where_conditions = getGoalObjectWithValues(filterValues.where);
    const or_conditions = getGoalObjectSearchValue(searchText);
    where_conditions["or"] = searchText ? or_conditions : null;
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      search: searchText,
      order: filterValues.order,
    });
    if (paginator.page !== 0) {
      setPaginator({
        ...paginator,
        page: 0,
        first: 0,
      });
    }
  };

  const onFilterChange = (values: any) => {
    const { field, value } = values;
    const where_conditions = getObjectWithValues(filterValues.where);
    if (field !== "dates") {
      where_conditions[field] = value;
    } else {
      const dates = value.map((c: any) => moment(c).format("DD/MM/YYYY"));
      where_conditions[field] = dates;
      where_conditions.from_run_date = dates[0];
      where_conditions.to_run_date = dates[1];
    }
    setFilterValues({
      ...filterValues,
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order
    });
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    if (selectedStatusGoal === statusTypeAll) {
      p.where["status"] = [...GOAL_STATUS_LIST.map((c) => c.code)];
    } else {
      p.where["status"] = [selectedStatusGoal];
    }
    p.search = '';
    setFilterValues(p);
  };

  const handleChangeStatus = (status: any) => {
    setPaginator({
      ...paginator,
      page: 0,
      first: 0,
    });
    setSelectedStatusGoal(status.code);

    if (status.code === statusTypeAll) {
      let p = _.cloneDeep({ ...filterValues });
      p.where["status"] = [...GOAL_STATUS_LIST.map((c) => c.code)];
      setFilterValues(p);
    }
  };

  const formatAmountBodyTemplate = (rowData: any, column: string) => {
    return (
      <div>
        <b style={{ fontSize: "13px", color: "black", marginRight: "5px" }}>
          {rowData
            ? parseFloat(rowData[column]?.toString())?.toLocaleString("de-DE")
            : "-"}
        </b>{" "}
        <span>{rowData.currency_code?.toUpperCase()}</span>
      </div>
    );
  };

  const onHideSideBar = () => {
    setIsShowSidebar(false);
    if(goal) {
      setGoal(null);
    }
  }

  const tableColumns = [
    {
      field: "name",
      header: "Name",
      width: "200px",
      align: "left",
      bodyTemPlate: (rowData: any) => {
        // eslint-disable-next-line react/no-danger-with-children
        return <span dangerouslySetInnerHTML={{ __html: rowData.name?.replace('\n', '<br/>') ||'-' }}></span>
      },
      disabledSort: false,
    },
    {
      field: "region_id",
      header: "Region",
      width: "180px",
      align: "left",
      bodyTemPlate: (rowData: any, column: any) =>  {
        return rowData.region_id ? <Link onClick={(e) => e.stopPropagation()} className="table-ref-link-cell" to={`/regions/${rowData.region_id}`}>{rowData.region_name}</Link> : <span>{rowData?.region_name || '-'}</span>;
      },
      disabledSort: false,
    },
    {
      field: "campaign_id",
      header: "Campaign",
      width: "160px",
      align: "left",
      bodyTemPlate: (rowData: any, column: any) =>  {
        return rowData.campaign_id ? <Link onClick={(e) => e.stopPropagation()} className="table-ref-link-cell" to={`/campaigns/${rowData.campaign_id}`}>{rowData.campaign_name}</Link> : <span>{rowData?.campaign_name || '-'}</span>;
      },
      disabledSort: false,
    },
    {
      field: "code",
      header: "Code",
      width: "120px",
      bodyTemPlate: (rowData: any, column: any) =>  {
        if (rowData?.code) {
          let urlCodeParam = null;
          if (rowData?.goal_type === GOAL_TYPES.REGION && rowData?.region_identifier) {
            urlCodeParam = `?region=${rowData.region_identifier}`;
          } else if (rowData?.goal_type === GOAL_TYPES.CAMPAIGN && rowData?.campaign_code) {
            urlCodeParam = `?campaign=${rowData.campaign_code}`
          }
          if (urlCodeParam) {
            return <a target="_blank" href={`${env.DONATION_URL}${urlCodeParam}&goal=${rowData?.code}`} className="table-ref-link-cell" rel="noreferrer">{rowData?.code}</a>;
          }
        }
        return <span>{rowData?.code || ''}</span>;
      },
      align: "left",
      disabledSort: false,
    },
    {
      field: "partner_id",
      header: "Partner",
      width: "calc(92% - 1560px)",
      minWidth: "230px",
      bodyTemPlate: (rowData: any, column: any) =>  {
        return rowData.partner_id ? <Link onClick={(e) => e.stopPropagation()} className="table-ref-link-cell" to={`/partners/${rowData.partner_id}`}>{rowData.partner_name}</Link> : <span>{rowData?.partner_name || '-'}</span>;
      },
      align: "left",
      disabledSort: false,
    },
    {
      field: "status",
      header: "Status",
      width: "130px",
      bodyTemPlate: (rowData: any) => {
        return <WITagWIR value={rowData?.status || 'Active'}></WITagWIR>
      },
      align: "left",
      disabledSort: true,
    },
    {
      field: "donation_goal",
      header: "Goal (m²)",
      width: "135px",
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, 'donation_goal'),
      align: "left",
      disabledSort: false,
    },
    {
      field: "",
      header: "Progress",
      width: "280px",
      bodyTemPlate: buildProgressTemplate("", "donation_goal", "total_area"),
      align: "left",
      disabledSort: true,
    },
    {
      field: "total_area",
      header: "Protected (m²)",
      width: "140px",
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, 'total_area'),
      align: "left",
      disabledSort: false,
    },
    {
      field: "created_at",
      header: "Date",
      width: "160px",
      bodyTemPlate: formatDateBodyTemplate("MMM DD, YYYY HH:mm:ss"),
      align: "left",
      disabledSort: false,
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={`${col.field}_${i}`}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
          minWidth: col.minWidth,
        }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
        sortable={!col?.disabledSort}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: "Edit",
        description: "Edit goal information",
        icon: "fa-solid fa-pen-to-square",
        command: (e: any) => {
          setGoal(rowData);
          setIsShowSidebar(true);
        },
        disabled: rowData.status !== "active"
      },
    ];
    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="goal-manager">
        <div className="manager-container">
          <div className="header-containers">
            <div className="goal-header-panel">
            <GoalFilterComponent
                lang={"de"}
                items={statusGoal}
                selectedItem={selectedStatusGoal}
                onStatusChange={handleChangeStatus}
                isStatusAll={selectedStatusGoal === statusTypeAll}
                filterValues={filterValues}
                fields={["partner_id", "region_id", "status", "campaign_id"]}
                onChange={onFilterChange}
                onRemove={onFilterRemove}
                onSearchFunc={onSearchFunc}
                onAddGoal={() => setIsShowSidebar(true)}
                regions={regions} 
                partners={partners}
                campaigns={campaigns}
              />
            </div>
          </div>
          <div className="table-goal">
            <DataTable
              loading={isLoading}
              value={goals.data}
              scrollable
              dataKey="id"
              scrollHeight="calc(100vh - 208px)"
              scrollDirection="both"
              lazy
              onSort={(e: any) => {
                setSortConfig({
                  sortField: e.sortField,
                  sortOrder: e.sortOrder,
                });
              }}
              sortField={sortConfig.sortField}
              sortOrder={sortConfig.sortOrder}
            >
              {dynamicColumns}
              <Column
                frozen
                alignFrozen="right"
                style={{ width: "50px" }}
                body={buildMenu}
              ></Column>
            </DataTable>
            <WIPaginator
              first={paginator.first}
              rows={paginator.rows}
              totalRecords={goals.totalPage}
              onPageChange={onBasicPageChange}
            />
          </div>
        </div>
        <Sidebar 
          visible={isShowSidebar} 
          position="right" 
          className="p-sidebar-md add-donor-sibar-right" 
          style={{width:'30em'}}
          onHide={() => onHideSideBar()}>
            <div className="add-donor-sidebar-content">
              <div className="headline">
                <h6>{goal ? 'Update goal' : 'Add a new goal'}</h6>
              </div>
              <AddGoalFormComponent
                regions={regions} 
                campaigns={campaigns}
                fetchCallBack={(() => fetchGoalData())}
                toast={toast}
                onHide={() => onHideSideBar()}
                goalData={goal}
                notify={toast}
                partners={partners}
              />
            </div>
          </Sidebar>
      </div>
    </Layout>
  );
};

export default GoalManager;
