import * as React from "react"
import { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Column, ColumnAlignType } from 'primereact/column';
import { Toast } from "primereact/toast";
import { Tag } from 'primereact/tag';
import { DataTable } from 'primereact/datatable';
import moment from 'moment';
import { MailStatus, removeEmpty, FIELDS_SEARCH } from '../../utils/utils';
import { WIButton, WIPaginator, WISearchField } from "../common";
import Layout from '../layout/layout';
import "./geo-import.scss";
import { HistoryImportAPI } from "../../services";

const GeoImportHistory = (props: any) => {

  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [donationHistory, setDonationHistory] = useState({
    data: [],
    total: 0
  });
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<any>(null);
  const toast = useRef(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);

  const fetchData = async (valueSearch?: string) => {
    const data = removeEmpty({
      pageNo: page,
      range: rows,
      search_text: valueSearch || undefined,
      search_fields: valueSearch ? FIELDS_SEARCH.DASHBOARD : undefined
    });

    try {
      const res = await HistoryImportAPI.getGeoImports(data);

      if (res && res.status === 200) {
        setIsLoading(false);
        const donationData = res.data.records  || [];
        const totalPage = res.data.total  || 0;
        setDonationHistory({ data: donationData, total: totalPage });
      }
    } catch (error) {
      setIsLoading(false);
      setDonationHistory({ data: [], total: 0 });
    }
  }

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [page, rows]);

  const onSearchHistory = () => {
      setIsLoading(true);
      fetchData(search);
  }

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  }

  const formatDateTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        { moment(rowData.created_at).format('DD.MM.YYYY hh:mm') }
      </React.Fragment>
    );
  };

  const formatImportTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        { +rowData.no_passed_records }/{(+rowData.no_passed_records) + (+rowData.no_failed_records)}
      </React.Fragment>
    );
  };

  const formatStatusTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        {
          rowData.status === MailStatus.NotYet ?
            <Tag className="mr-2 wi-tag" icon="pi pi-times" severity="error" value={rowData.status}></Tag>
            : rowData.status === MailStatus.Pending ?
              <Tag className="mr-2 wi-tag" icon="pi pi-exclamation-triangle" severity="warn" value={rowData.status}></Tag> :
              <Tag className="mr-2 wi-tag" icon="pi pi-check" severity="success" value={rowData.status}></Tag>
        }
      </React.Fragment>
    );
  };

  const formatMailStatusTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        {
          rowData.mail_status === MailStatus.NotYet ?
            <Tag className="mr-2 wi-tag" icon="pi pi-times" severity="error" value={rowData.mail_status}></Tag>
            : rowData.mail_status === MailStatus.Pending ?
              <Tag className="mr-2 wi-tag" icon="pi pi-exclamation-triangle" severity="warn" value={rowData.mail_status}></Tag> :
              <Tag className="mr-2 wi-tag" icon="pi pi-check" severity="success" value={rowData.mail_status}></Tag>
        }
      </React.Fragment>
    );
  };

  const tableColumns = [
    { field: 'serial', header: 'Serial', width: "5%", align: 'center' },
    { field: 'import', header: 'Import', width: "15%", align: 'center', bodyTemPlate: formatImportTemplate },
    { field: 'status', className: 'upper-case', header: 'Import status', width: "10%", align: 'center', bodyTemPlate: formatStatusTemplate },
    { field: 'mail_status', className: 'upper-case', header: 'Mail status', width: "10%", align: 'center', bodyTemPlate: formatMailStatusTemplate },
    { field: 'created_at', header: 'Date', width: "15%", align: 'center', bodyTemPlate: formatDateTemplate },
    { field: 'created_user', header: 'Import By', width: "15%", align: 'center' },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return <Column
      key={col.field}
      field={col.field}
      header={col.header}
      className={col.className ? col.className : ''}
      style={{width: col.width}}
      body={col.bodyTemPlate }
      align={col.align as ColumnAlignType}
    />;
  });

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="geo-detail">
        <div className="detail-container">
          <div className="certificate">
            <div className="d-flex flex-column flex-md-row justify-content-end certificate-action">
              {/* <WISearchField
                icon={'pi pi-search'}
                placeholder="Search for anything"
                setSearch={(value: any) => setSearch(value.global.value)}
                enterSearch={onSearchHistory}
              /> */}
              <div className="btn-create">
                <WIButton
                  primary={true}
                  type={'button'}
                  size={'small'}
                  label="Create An Import"
                  icon={'pi-angle-right'}
                  onClick={() => {
                    navigate(`/geo-coordinate-imports/new`)
                  }}/>
              </div>
            </div>
          </div>
          <div className="table-history">
            <DataTable
              loading={isLoading}
              value={donationHistory.data}
            //   globalFilterFields={['land_code', 'campaign_code']}
              emptyMessage="No items found."
              onRowClick={(row: any) => {
                navigate(`/geo-coordinate-imports/${row.data.uuid}`);
              }}
              scrollable 
              scrollHeight="calc(100vh - 166px)"
            >
              {dynamicColumns}
            </DataTable>
            <WIPaginator
              first={first}
              rows={rows}
              totalRecords={donationHistory.total}
              onPageChange={onBasicPageChange}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default GeoImportHistory;
