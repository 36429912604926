import { AxiosClient } from '../httpClient'

export default class PartnerTypeManagerAPI {
  static deletePartnerType(id: string) {
    return AxiosClient.delete(`/partner-types/${id}`);
  }

  static createPartnerType(data: any) {
    return AxiosClient.post(`/partner-types`, data);
  }

  static updatePartnerType(data: any) {
    return AxiosClient.put(`/partner-types/${data.uuid}`, data);
  }

  static getAll() {
    return AxiosClient.get(`/partner-types`);
  }

  static getPartnerType(uuid: string) {
    return AxiosClient.get(`/partner-types/${uuid}`);
  }
}