import * as React from "react";
import "./snack-bar.scss";
import { Button } from 'primereact/button';

const SnackBarComponent = (props: any) => {
  const { selectedDonations, visible, clearDonations, downloadCertificate, downloadReceipt, exportDonation } = props;
  const canDownload = selectedDonations && selectedDonations.length <= 20;
  const canExport = selectedDonations && selectedDonations.length <= 1000;

  return (
    <div className={`snack-bar-component ${!visible && 'snack-bar-none'} `}>
        <div className="snack-bar-container">
            <div className="items-selected">
                <Button icon="pi pi-plus" onClick={clearDonations}/>
                <span className="number-selected"> {selectedDonations ? selectedDonations.length : 0} </span> items selected
            </div>
            <div className="btns-container">
                <Button disabled={!canDownload} label="Download Certificates" onClick={downloadCertificate} />
                <Button disabled={!canDownload} label='Download Receipts' onClick={downloadReceipt} />
                <Button disabled={!canExport} label='Export Donations' onClick={exportDonation} />
            </div>
        </div>
    </div>
  );
};

export default SnackBarComponent;
