import { Dialog } from "primereact/dialog";
import { useRef, useState } from "react";
import React from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { LanguageCode, LanguageDisplayList } from "../../../../utils/utils";
import "./generate-certificate-donation-dialog.scss";
import { onDownloadCertificate, onDownloadMultipleCertificate } from "../../../../utils/donationManagerLogic";
import { SelectButton } from 'primereact/selectbutton';

const GenerateCertificateDonationDialogComponent = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { visible, onHide, donation, yearDonation, selectedDonations, isMultipleDownload, isMailing } = props;
  
  const toast = useRef(null);
  const [languageCode, setLanguageCode] = useState(LanguageCode.DE);

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={`Download ${isMultipleDownload && selectedDonations ? selectedDonations.length : '' }  Certificate${isMultipleDownload && selectedDonations && selectedDonations.length > 1 ? 's' : '' }`}
        visible={visible}
        style={{ width: "350px" }}
        modal
        className="wi-dialog"
        onHide={onHide}
      >
        <div className="dialog-container">
          <div className="row">
            <div className="col-md-12 language-container">
              <label className="label">Choose language</label>
              <SelectButton
                className="wi-selectbutton"
                name='language_option'
                value={languageCode}
                options={LanguageDisplayList}
                onChange={(item: any) => {
                  setLanguageCode(item.value);
                }}
                optionLabel="name"
                optionValue="code"
            />
            </div>
          </div>
         
          <div className="btn-submit-container">
            <Button
              type="submit"
              label={"Download"}
              icon="pi pi-download"
              iconPos="right"
              loading={isLoading}
              className="p-button-rounded btn-submit wi-button"
              onClick={(e) =>{
                setIsLoading(true);
                if(isMultipleDownload) {
                  const ids = selectedDonations.map((donation: any) => donation.uuid);
                  onDownloadMultipleCertificate(ids, languageCode, toast).then(() => {
                    setIsLoading(false);
                  })
                } else {
                  const pdfType = isMailing ? 'recurring-certificate' : 'certificate';
                  onDownloadCertificate(toast, e, donation, languageCode, pdfType, yearDonation).then(()=>{
                    setIsLoading(false);
                  })}} 
                }
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default GenerateCertificateDonationDialogComponent;
