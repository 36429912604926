import _ from 'lodash';
import { parseJSON } from '../../utils/logic';

const SEARCH_FIELDS = [
  "serial", "donor.payment_email", "donor.payment_first_name", 
  "donor.payment_last_name", 'payment_company_name',
  'personalize_certificate_display_name', "old_donation_id", "personalize_certificate_reason"
];
const NO_ACTION_LIST = ['draft', 'cancelled','refunded', 'failed', 'reserved'];

const getObjectWithValues = (where_obj: any) => {
  const tempt_obj: any = {};
  for (const [key, value] of Object.entries(where_obj)) {
    if (key && key !== "dates") {
      if ((Array.isArray(value) || typeof value === "string" ) && value.length > 0) {
        tempt_obj[key] = value;
      }
    } else {
      if (Array.isArray(value) && value.length === 2) {
        tempt_obj["from"] = value[0];
        tempt_obj["to"] = value[1];
      }
    }
  }
  return tempt_obj;
};

const preprocessSerial = (str: any) => {
  if (!str || _.isNumber(str)) {
    return str;
  } 
  const parts = str.split('-');
  return parts.length > 1 ? parts[1] : parts[0];
}

const getObjectSearchValue = (value: string) => {
  const search_result: any[] = [];
  SEARCH_FIELDS.forEach((key: string) => {
    const obj = {
      [key]: key === 'serial' ? preprocessSerial(value) : value,
    };
    search_result.push(obj);
  });

  return search_result;
};

const getPayloadConditions = (where_obj: any, sort_config: any) => {
  const { sortField, sortOrder } = sort_config;
  const where_conditions = getObjectWithValues(where_obj);
  // Specific logic for donation manager only
  where_conditions['is_first_time'] = where_conditions['subsequent'] || [0, 1];
  delete where_conditions['subsequent'];

  const mapper: any = {
    donation_serial: [["serial"]],
    donation_money: [["donation_money"]],
    rhythm: [["rhythm"]],
    payment_date: [["payment_date"]],
    payment_method: [["payment_method"]],
    payment_email: [["donor", "payment_email"]],
    full_name: [
      ["donor", "payment_first_name"],
      ["donor", "payment_last_name"],
    ]
  };
  const orders = mapper[sortField];
  orders.forEach((r: any) => r.push(sortOrder === -1 ? "DESC" : "ASC"));
  const order = [...orders];
  return {
    where: where_conditions,
    order
  };
};

const fetchFromObject = (obj: any, prop: any): any => {
  if (typeof obj === "undefined") return false;
  const index = prop.indexOf(".");
  if (index > -1) {
    return fetchFromObject(
      obj[prop.substring(0, index)],
      prop.substr(index + 1)
    );
  }
  return obj[prop];
};

const updateDonationData = (donation: any) => {
  return {
    ...donation,
    personalize_certificate_reason: donation?.donation_type === 'code' ? parseJSON(donation.personalize_certificate_reason) : donation.personalize_certificate_reason
  }
}

const disabledEditAction = (donation: any) => {
  return donation ? donation.donation_type === 'code' && donation.status === 'reserved' : false;
}

const isValidDonorInfo = (donation: any) => {
  return !!donation &&
    isNotNullOrEmpty(donation.payment_first_name.trim()) &&
    isNotNullOrEmpty(donation.payment_last_name.trim()) &&
    isNotNullOrEmpty(donation.payment_email.trim()) &&
    isNotNullOrEmpty(donation.payment_address.trim()) &&
    isNotNullOrEmpty(donation.payment_post_code.trim()) &&
    isNotNullOrEmpty(donation.payment_city.trim()) &&
    isNotNullOrEmpty(donation.payment_country.trim())
};

const isNotNullOrEmpty = (value: any) => {
  return !!value && value !== '';
};

export { 
  getObjectWithValues, 
  getObjectSearchValue, 
  getPayloadConditions, 
  NO_ACTION_LIST, 
  updateDonationData,
  disabledEditAction,
  fetchFromObject,
  isValidDonorInfo
};
