import * as React from "react";
import "./add-donation-form.scss";
import { Formik } from "formik";
import { useRef, useState, useLayoutEffect, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import _ from "lodash";
import { parseStringToFloat, showNotification } from "../../../../../../utils/logic";
import { InputNumber } from "primereact/inputnumber";
import {
  COMMON_STATUS,
  CurrencyCoupons,
  DONATION_PAYMENT_METHOD_LIST,
  DONATION_STATUS,
  ImportNewTypeList,
  ImportTypeList,
  LanguageDisplayList,
  NameCurrency,
  TypeLandCode,
} from "../../../../../../utils/utils";
import { SelectButton } from "primereact/selectbutton";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { WIButton } from "../../../../../common";
import CheckboxSvg from "../../../../../common/CheckboxSvg";
import SearchDonorComponent from "../search-donor/search-donor";
import { DonationManagerAPI, DonorManagerAPI } from "../../../../../../services";
import { useNavigate } from "react-router-dom";
import { Sidebar } from 'primereact/sidebar';
import AddDonorFormComponent from "../../../../../donor-manager/components/add-donor-form/add-donor-form";
import { formatSourceOptionTemplate } from "../../../../../common/column-template-table/column-template";

const AddDonationFormComponent = (props: any) => {
  const {
    toast,
    setIsLoading,
    lands,
    campaigns,
    countries,
    isSuperAdmin,
    isReserve
  } = props;

  const formikRef = useRef(null);
  const navigate = useNavigate();
  const [isShowSidebarDonor, setIsShowSidebarDonor] = useState(false);
  const [selectedDonor, setSelectedDonor] = useState<any>({
    name: "",
    item: null,
  });
  const [donors, setDonors] = useState([]);
  let timeoutInput: any = null;

  const getDonors = async (text: string = '', item?: any) => {
    try {
      if(item) {
        setSelectedDonor({
          name: `${item.payment_first_name} ${item.payment_last_name} - ${item.payment_email}`,
          item: item
        });
      }
      const res = await DonorManagerAPI.searchDonors(item ? `${item.payment_first_name} ${item.payment_last_name}` : text);
      if (res && res.status === 200) {
          const sortDonors: any = _.sortBy(res.data, 'joined_date').reverse();
          setDonors(res.data?.length > 0 ? sortDonors : []);
      }
    } catch (error) {
      console.log("error >>>", error);
    }
  }

  useEffect(() => {
    getDonors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (selectedDonor && selectedDonor.name) {
      setSelectedDonor(selectedDonor);
    }
  }, [selectedDonor]);

  const initialDonationValues = () => {
    return {
      donation_money: 0,
      payment_method: "",
      payment_language_code: "de",
      payment_currency_code: "eur",
      donation_type: TypeLandCode.region,
      land_code: "",
      payment_date: moment().toDate(),
      personalize_certificate_display_name: "",
      personalize_certificate_reason: "",
      is_add_donation: false,
      disabled_receipt: false,
      area_value: 0,
      donor_id: "",
      land_id: "",
      campaign_id: "",
      area_selected: null,
      custom_area: 0,
      payment_company_name: ''
    };
  };

  const donationSchema = Yup.object().shape({
    donation_money: Yup.number()
      .when("payment_currency_code", {
        is: (value:any) => value === NameCurrency.CAD,
        then: Yup.number().nullable().min(2, "This field must be greater than 2."),
        otherwise: Yup.number().nullable().min(1, "This field must be greater than 1."),
      })
      .required("This field is required."),
  });

  const donationFormSchema = Yup.object().shape({
    donation_money: donationSchema.fields.donation_money,
    payment_method: Yup.string().nullable().required("This field is required."),
    land_code: Yup.string().nullable().required("This field is required."),
    payment_date: Yup.string().nullable().required("This field is required."),
    donation_type: Yup.string().nullable().required("This field is required."),
    payment_currency_code: Yup.string().nullable().required("This field is required."),
    payment_language_code: Yup.string().nullable().required("This field is required."),
  });

  const formatOptionTemplate = formatSourceOptionTemplate;

  const selectedOptionTemplate = (
    option: any,
    values: any,
    available_size: any,
    props: any
  ) => {
    if (available_size < values.custom_area) {
      values.donation_type !== TypeLandCode.campaign
        ? (values.land_id = "")
        : (values.campaign_id = "");
      option = null;
    }

    if (!option) {
      return <span>{props.placeholder}</span>;
    }

    if (values.donation_type === TypeLandCode.region) {
      return (<div>{option?.region?.name?.de || option?.region?.name} - Available size [{parseInt(available_size || '0')?.toLocaleString("de-DE")} m<sup>2</sup>]</div>);
    }

    return option ? (
      formatOptionTemplate(option, available_size, values.donation_type)
    ) : (
      <span>{props.placeholder}</span>
    );
  };

  const onAddDonation = async (isSendMail: boolean) => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      const { values, resetForm } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef?.current?.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && formikRef?.current?.dirty && formikRef?.current?.isValid && selectedDonor.item) {
        setIsLoading(true);

        const valuesData = {
          ...values,
          send_mail: isSendMail,
          payment_date: moment(values?.payment_date).format("DD.MM.YYYY"),
          donor_id: selectedDonor.name ? selectedDonor.item.id : '',
          payment_company_name: values.payment_company_name?.trim(),
          personalize_certificate_display_name: values.personalize_certificate_display_name?.trim(),
          personalize_certificate_reason: values.personalize_certificate_reason?.trim(),
          payment_received_date: moment(values?.payment_date).format("DD.MM.YYYY"),
          status: isReserve ? DONATION_STATUS.RESERVED : DONATION_STATUS.SUCCEEDED
        };

        if (values.donation_type !== TypeLandCode.campaign) {
          valuesData.land_id = values.land_code;
          delete valuesData.campaign_id;
          valuesData.donation_type = TypeLandCode.land;
        } else {
          valuesData.campaign_id = values.land_code;
          valuesData.land_id = values.area_selected?.uuid;
        }

        delete valuesData.land_code;
        delete valuesData.area_value;
        delete valuesData.is_add_donation;
        delete valuesData.area_selected;
        delete valuesData.custom_area;

        const resAdd = await DonationManagerAPI.addDonationManual(valuesData);
        if (resAdd && resAdd.status === 200) {
          if (values.is_add_donation) {
            resetForm();
            setSelectedDonor({
              name: '',
              item: null
            })
          } else {
            navigate(`/donations/${resAdd.data.data.id}`);
          }
          showNotification(
            "success",
            "Donation has been added successfully.",
            toast
          );
        } else {
          showNotification("error", "There was an error occurred.", toast);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      showNotification("error", "There was an error occurred.", toast);
    }
  };

  const onBlurAmountInput = (values: any, setFieldValue: any) => {
    const { custom_area, land_code, area_value } = values;
    if (custom_area && land_code && custom_area > area_value) {
      setFieldValue("land_code", "", false);
    }
  };

  const onSelectedItemSearch = (item: any, setFieldValue: any) => {
    if(item) {
      setFieldValue("donor_id", item.id , false);
      setSelectedDonor({
        name: `${item?.payment_first_name} ${item?.payment_last_name} - ${item?.payment_email}`,
        item: item
      });
    } else {
      setFieldValue("donor_id", "", false);
      setSelectedDonor({
        name: '',
        item: null
      });
    }
  }

  return (
    <div className="add-form-donation">
      <div className="add-form-container">
        <Formik
          enableReinitialize={false}
          innerRef={formikRef}
          initialValues={initialDonationValues()}
          validationSchema={donationFormSchema}
          onSubmit={(values, { setSubmitting }) => {}}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="element-form">
                      <label className="label">
                        Donor
                        <span className="required-label"> *</span>
                      </label>
                      <SearchDonorComponent
                        name="donor_id"
                        id="donor_id"
                        setIsShowSidebar={setIsShowSidebarDonor}
                        donors={donors}
                        selectedDonor={selectedDonor}
                        setSelectedDonor={setSelectedDonor}
                        onBlur={(e:any) => {
                          handleBlur(e);
                          if(!e.target.value) {
                            onSelectedItemSearch(null, setFieldValue)
                          }
                        }}
                        getDonors={getDonors}
                        onSelected={(item: any) => onSelectedItemSearch(item, setFieldValue)}
                      />
                      <div
                        className={`${
                          touched.donor_id && !selectedDonor.name ? "error" : ""
                        }`}
                      >
                        {touched.donor_id && !selectedDonor.name ? 'This field is required.' : ''}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="element-form">
                      <label className="label">
                        Amount
                        <span className="required-label"> *</span>
                      </label>
                      <InputNumber
                        className={`${
                          touched.donation_money && errors.donation_money
                            ? "has-error"
                            : ""
                        }`}
                        name="donation_money"
                        onBlur={(e) => {
                          const value = e.target.value ? parseStringToFloat(e.target.value) : 0;
                          setFieldValue('donation_money', value, true);
                          if (values.land_code) {
                            // @ts-ignore: Object is possibly 'null'.
                            const amount_conversion = values?.area_selected["amount_area_conversion"][values.payment_currency_code];
                            // @ts-ignore: Object is possibly 'null'.
                            setFieldValue("custom_area",Math.floor(value * amount_conversion), true);
                          } else {
                            setFieldValue(
                              "custom_area",
                              value ?? 0,
                              true
                            );
                          }
                          onBlurAmountInput(values, setFieldValue);
                          clearTimeout(timeoutInput);
                          timeoutInput = setTimeout(() => {
                              handleBlur(e);
                          }, 200);
                        }}
                        value={values.donation_money}
                        mode="decimal"
                        locale="de-DE"
                        placeholder="0.00"
                        minFractionDigits={0}
                        maxFractionDigits={2}
                      />
                      <div
                        className={`${
                          touched.donation_money && errors.donation_money
                            ? "error"
                            : ""
                        }`}
                      >
                        {
                          touched.donation_money && errors.donation_money
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="element-form">
                      <label className="label">
                        Payment method
                        <span className="required-label"> *</span>
                      </label>
                      <Dropdown
                        id="payment_method"
                        name="payment_method"
                        className="p-dropdown-custom-search"
                        value={values.payment_method}
                        onBlur={handleBlur}
                        options={DONATION_PAYMENT_METHOD_LIST}
                        onChange={(item: any) => {
                          setFieldValue("payment_method", item.value, true);
                        }}
                        optionLabel={"name.de"}
                        optionValue={"code"}
                        appendTo="self"
                        placeholder="Choose a payment method"
                        filter
                        filterBy="name.de,code"
                      />
                      <div
                        className={`${
                          touched.payment_method && errors.payment_method
                            ? "error"
                            : ""
                        }`}
                      >
                        {touched.payment_method && errors.payment_method}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="element-form">
                      <label className="label">
                        Type
                        <span className="required-label"> *</span>
                      </label>
                      <SelectButton
                        id="donation_type"
                        name="donation_type"
                        value={values.donation_type}
                        className={`wi-selectbutton ${isSuperAdmin ? 'wi-selectbutton-tri' : ''}`}
                        options={ImportNewTypeList.filter((l: any) => isSuperAdmin || l.code !== TypeLandCode.land)}
                        onChange={(item: any) => {
                          if (item.value) {
                            setFieldValue("donation_type", item.value, true);
                          }
                        }}
                        optionLabel="name"
                        optionValue="code"
                      />
                      <div
                        className={`${
                          touched.donation_type && errors.donation_type
                            ? "error"
                            : ""
                        }`}
                      >
                        {
                          touched.donation_type && errors.donation_type
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="element-form">
                      <label className="label">
                        Donation date
                        <span className="required-label"> *</span>
                      </label>
                      <div className="calendar-item">
                        <Calendar
                          id="basic"
                          name="payment_date"
                          panelClassName="expire-calendar"
                          value={values.payment_date}
                          onBlur={handleBlur}
                          onChange={(item) => {
                            setFieldValue("payment_date", item.value, false);
                          }}
                          dateFormat="dd.mm.yy"
                          iconPos="right"
                          icon="fa-solid fa-calendar-days"
                          showIcon={true}
                          disabled={isReserve}
                        />
                      </div>
                      <div
                        className={`${
                          touched.payment_date && errors.payment_date
                            ? "error"
                            : ""
                        }`}
                      >
                        {`${
                          touched.payment_date && errors.payment_date
                            ? errors.payment_date
                            : ""
                        }`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="element-form">
                      <label className="label">
                        { values.donation_type === TypeLandCode.region ? "Region" : (values.donation_type === TypeLandCode.campaign ? "Campaign" : "Area" ) }
                        <span className="required-label"> *</span>
                      </label>
                      <Dropdown
                        name="land_code"
                        id="land_code"
                        className="p-dropdown-custom-search"
                        disabled={!values.donation_type}
                        value={values.land_code}
                        onBlur={handleBlur}
                        options={values.donation_type === TypeLandCode.region ? lands.filter((l: any) => !!l.region) : (values.donation_type === TypeLandCode.land ? lands.filter((l: any) => l.status === COMMON_STATUS.ACTIVE) : campaigns) || []}
                        onChange={(item: any) => {
                          const area_item_selected =
                            values.donation_type !== TypeLandCode.campaign
                              ? lands.find(
                                  (land: any) => land.uuid === item.value
                                )
                              : campaigns.find(
                                  (campaign: any) =>
                                    campaign.uuid === item.value
                                );
                          setFieldValue("land_code", item.value, false);
                          setFieldValue(
                            "region_id",
                            values.donation_type !== TypeLandCode.campaign
                              ? area_item_selected?.region_id
                              : area_item_selected?.land?.region_id,
                            false
                          );
                          setFieldValue(
                            "area_value",
                            values.donation_type !== TypeLandCode.campaign
                              ? area_item_selected?.available_size
                              : area_item_selected?.land?.available_size,
                            false
                          );
                          setFieldValue(
                            "area_selected",
                            values.donation_type !== TypeLandCode.campaign
                              ? area_item_selected
                              : area_item_selected.land,
                            false
                          );
                        }}
                        optionLabel={`name.de`}
                        optionDisabled={(option) =>
                          values.donation_type !== TypeLandCode.campaign
                            ? option.available_size < values.custom_area
                            : option?.land?.available_size < values.custom_area
                        }
                        itemTemplate={(option) =>
                          formatOptionTemplate(
                            option,
                            values.donation_type !== TypeLandCode.campaign
                              ? option.available_size
                              : option?.land?.available_size,
                            values.donation_type
                          )
                        }
                        valueTemplate={(option, props) =>
                          selectedOptionTemplate(
                            option,
                            values,
                            values.donation_type !== TypeLandCode.campaign
                              ? option?.available_size
                              : option?.land?.available_size,
                            props
                          )
                        }
                        optionValue={"uuid"}
                        appendTo="self"
                        placeholder={`Choose ${
                          values.donation_type !== TypeLandCode.campaign
                            ? (values.donation_type !== TypeLandCode.region ? "an area" : "a region")
                            : "a campaign"
                        }`}
                        filter
                        filterBy="region.name.de,name,name.de,code"
                      />
                      <div
                        className={`${
                          touched.land_code && errors.land_code ? "error" : ""
                        }`}
                      >
                        {`${
                          touched.land_code && errors.land_code
                            ? errors.land_code
                            : ""
                        }`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="element-form">
                      <label className="label">
                        Language
                        <span className="required-label"> *</span>
                      </label>
                      <SelectButton
                        className="donation-checkbox wi-selectbutton"
                        id="payment_language_code"
                        name="payment_language_code"
                        options={LanguageDisplayList}
                        onChange={(item: any) => {
                          if (item.value) {
                            setFieldValue(
                              "payment_language_code",
                              item.value,
                              true
                            );
                          }
                        }}
                        value={values.payment_language_code}
                        optionLabel="name"
                        optionValue="code"
                      />
                      <div
                        className={`${
                          touched.payment_language_code && errors.payment_language_code
                            ? "error"
                            : ""
                        }`}
                      >
                        {
                          touched.payment_language_code && errors.payment_language_code
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="element-form">
                      <label className="label">
                        Currency
                        <span className="required-label"> *</span>
                      </label>
                      <SelectButton
                        id="payment_currency_code"
                        name="payment_currency_code"
                        value={values.payment_currency_code}
                        className="wi-selectbutton currency-selectbutton"
                        options={CurrencyCoupons}
                        onChange={(item: any) => {
                          if (item.value) {
                            setFieldValue(
                              "payment_currency_code",
                              item.value,
                              true
                            );
                            if (values.land_code) {
                              // @ts-ignore: Object is possibly 'null'.
                              const amount_conversion = values?.area_selected["amount_area_conversion"][item.value];
                              setFieldValue(
                                "custom_area",
                                Math.floor(
                                  values.donation_money * amount_conversion
                                ),
                                false
                              );
                            } else {
                              setFieldValue(
                                "custom_area",
                                values.donation_money,
                                false
                              );
                            }
                          }
                        }}
                        optionLabel="name"
                        optionValue="code"
                      />
                      <div
                        className={`${
                          touched.payment_currency_code && errors.payment_currency_code
                            ? "error"
                            : ""
                        }`}
                      >
                        {
                          touched.payment_currency_code && errors.payment_currency_code
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="element-form">
                      <label className="label">Organization</label>
                      <InputText
                        value={values.payment_company_name}
                        name="payment_company_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={80}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="element-form">
                      <label className="label">Certificate name</label>
                      <InputText
                        value={values.personalize_certificate_display_name}
                        name="personalize_certificate_display_name"
                        onChange={(item) => {
                          setFieldValue(
                            "personalize_certificate_display_name",
                            item.target.value,
                            false
                          );
                        }}
                        onBlur={handleBlur}
                        maxLength={80}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="element-form">
                      <label className="label">Certificate occasion</label>
                      <InputText
                        value={values.personalize_certificate_reason}
                        name="personalize_certificate_reason"
                        onChange={(item) => {
                          setFieldValue(
                            "personalize_certificate_reason",
                            item.target.value,
                            false
                          );
                        }}
                        onBlur={handleBlur}
                        maxLength={80}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="check-box-container col-md-12">
                    <CheckboxSvg
                      className={`donation-checkbox`}
                      id={`disabled_receipt`}
                      name={`disabled_receipt`}
                      label={"No receipt"}
                    />
                  </div>
                </div>
                {
                  !isReserve ? <div className="row">
                    <div className="check-box-container col-md-12">
                      <CheckboxSvg
                        className={`donation-checkbox`}
                        id={`is_add_donation`}
                        name={`is_add_donation`}
                        label={`${isReserve ? "Reserve" : "Add"} another donation`}
                      />
                    </div>
                  </div> : <></>
                }
                <div className="btn-submit">
                  <WIButton
                    className="add-donation"
                    primary={true}
                    type="submit"
                    label={isReserve ? "Reserve" : "Add"}
                    icon={"pi-angle-right"}
                    onClick={() => onAddDonation(false)}
                  />
                  {
                    !isReserve ? <WIButton
                      type="submit"
                      className="add-mail-donation"
                      primary={true}
                      label="Add and send mail"
                      icon={"pi-angle-right"}
                      onClick={() => onAddDonation(true)}
                    /> : <></>
                  }
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <Sidebar 
        visible={isShowSidebarDonor} 
        position="right" 
        className="p-sidebar-md add-donor-sibar-right" 
        style={{width:'35em'}}
        onHide={() => setIsShowSidebarDonor(false)}>
          <div className="add-donor-sidebar-content">
            <div className="headline">
              <h6>Add a new donor</h6>
            </div>
            <AddDonorFormComponent
              countries={countries}
              fetchCallBack={((donorItem:any) => getDonors("", donorItem))}
              toast={toast}
              disableAdd={true}
              onHide={() => setIsShowSidebarDonor(false)}
            />
          </div>
      </Sidebar>
    </div>
  );
};

export default AddDonationFormComponent;
