import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import "./download-receipt-dialog.scss";
import { Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import * as Yup from "yup";
import CountryService from "../../../services/country-service";
import { generateLinkDownLoad, showNotification } from "../../../utils/logic";
import CouponDetailAPI from "../../../services/couponDetail";

const DownloadReceiptDialogComponent = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { visible, onHide, quantity, donation_money, currency_unit, id } = props;

  const toast = useRef(null);
  const formikRef = useRef(null);
  const [countries, setCountries] = useState([]);

  const BATCH_DEFAULT_VALUE = {
    name: "",
    address: "",
    postal_code: "",
    city: "",
    country: "DE",
    donated_date: moment().toDate(),
    receipt_date: moment().toDate(),
    currency_code: "eur",
    donation_money: donation_money * quantity,
    currency_unit: currency_unit,
  };

  const LanguageOptions = {
    eur: "de",
    chf: "de",
    cad: "en",
  };

  type CurrencyType = "eur" | "chf" | "cad";

  const initialReceiptFormValues = () => {
    let initialReceipt = BATCH_DEFAULT_VALUE;
    initialReceipt.name = "";
    initialReceipt.address = "";
    initialReceipt.postal_code = "";
    initialReceipt.city = "";
    initialReceipt.country = "DE";
    initialReceipt.donated_date = moment().toDate();
    initialReceipt.receipt_date = moment().toDate();
    initialReceipt.currency_code = "eur";

    return initialReceipt;
  };

  const fetchCountryData = async () => {
    try {
      const res = await CountryService.getCountries();
      if (res.status === 200 && res.data.data) {
        setCountries(res.data.data);
      } else {
        setCountries([]);
      }
    } catch (error) {
      setCountries([]);
    }
  };

  useEffect(() => {
    fetchCountryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required."),
    address: Yup.string().required("This field is required."),
    postal_code: Yup.string().required("This field is required."),
    city: Yup.string().required("This field is required."),
    country: Yup.string().required("This field is required."),
    donated_date: Yup.string().required("This field is required."),
    receipt_date: Yup.string().required("This field is required.")
  });

  const generateReceipt = async () => {
    // @ts-ignore: Object is possibly 'null'.
    formikRef.current.validateForm();
    // @ts-ignore: Object is possibly 'null'.
    if (formikRef && formikRef.current.dirty) {
      const dataReceipt = {
        // @ts-ignore: Object is possibly 'null'.
        ...formikRef?.current.values,
        // @ts-ignore: Object is possibly 'null'.
        donated_date: moment.utc(moment(new Date(formikRef?.current.values.donated_date)).format("MM/DD/YYYY HH:mm:ss")).toISOString(),
        // @ts-ignore: Object is possibly 'null'.
        receipt_date: moment.utc(moment(new Date(formikRef?.current.values.receipt_date)).format("MM/DD/YYYY HH:mm:ss")).toISOString()
      };
      const currency_value = dataReceipt.currency_code as CurrencyType;
      dataReceipt.language_code = LanguageOptions[currency_value];
      try {
        setIsLoading(true);
        let response: any;
        if (id) {
          response = await CouponDetailAPI.generateReceipt(id, dataReceipt);
          if (response && response.status === 200) {
            generateFileDownload("Spendenquittung.pdf", response.data.content);
            showNotification("success", "Generate successfully", toast);
          } else {
            showNotification("error", "Generate failed", toast);
          }
          setIsLoading(false);
          onHide();
        }
      } catch (error) {
        showNotification("error", "Generate failed", toast);
        setIsLoading(false);
      }
    }
  };

  const generateFileDownload = (filename: string, data: any) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const url = URL.createObjectURL(
      new Blob([byteArray], { type: "application/pdf; charset=utf-8" })
    );
    generateLinkDownLoad(filename, url);
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={`Download Receipt`}
        visible={visible}
        style={{ width: "800px" }}
        modal
        className="wi-dialog"
        onHide={onHide}
      >
        <div className="codes-receipt-form">
          <div className="receipt-form-container">
            <Formik
              enableReinitialize={true}
              innerRef={formikRef}
              initialValues={initialReceiptFormValues()}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                //console.log("values >>>", values);
              }}
            >
              {({
                values,
                errors,
                touched,
                dirty,
                isValid,
                handleBlur,
                handleSubmit,
                setFieldValue,
                handleChange,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Name <span className="asterik">*</span>
                          </label>
                          <InputText
                            value={values.name}
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <div
                            className={`${
                              touched.name && errors.name ? "error" : ""
                            }`}
                          >
                            {touched.name && errors.name}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Country <span className="asterik">*</span>
                          </label>
                          <Dropdown
                            id="dropdown_countries"
                            name="country"
                            className="wi-country-dropdown"
                            value={values.country}
                            scrollHeight={'150px'}
                            options={countries || []}
                            onChange={(item: any) => {
                              setFieldValue("country", item.value);
                            }}
                            optionLabel="name"
                            optionValue="ISO2"
                            appendTo="self"
                            filter
                            filterBy="name"
                            placeholder="Choose a country"
                          />
                          <div
                            className={`${
                              touched.country && errors.country ? "error" : ""
                            }`}
                          >
                            {touched.country && errors.country}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            City <span className="asterik">*</span>
                          </label>
                          <InputText
                            value={values.city}
                            name="city"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div
                            className={`${
                              touched.city && errors.city ? "error" : ""
                            }`}
                          >
                            {touched.city && errors.city}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Address <span className="asterik">*</span>
                          </label>
                          <InputText
                            value={values.address}
                            name="address"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <div
                            className={`${
                              touched.address && errors.address ? "error" : ""
                            }`}
                          >
                            {touched.address && errors.address}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Post Code <span className="asterik">*</span>
                          </label>
                          <InputText
                            value={values.postal_code}
                            name="postal_code"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div
                            className={`${
                              touched.postal_code && errors.postal_code
                                ? "error"
                                : ""
                            }`}
                          >
                            {touched.postal_code && errors.postal_code}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Donated date <span className="asterik">*</span>
                          </label>
                          <Calendar
                            id="basic"
                            name="donated_date"
                            panelClassName="expire-calendar"
                            value={values.donated_date}
                            onBlur={handleBlur}
                            onChange={(item) => {
                              setFieldValue("donated_date", item.value, false);
                            }}
                            dateFormat="dd.mm.yy"
                          />
                          <div
                            className={`${
                              touched.donated_date && errors.donated_date
                                ? "error"
                                : ""
                            }`}
                          >
                            {touched.donated_date && errors.donated_date
                              ? "This field is invalid"
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Receipt date <span className="asterik">*</span>
                          </label>
                          <Calendar
                            id="basic"
                            name="receipt_date"
                            panelClassName="expire-calendar"
                            value={values.receipt_date}
                            onBlur={handleBlur}
                            onChange={(item) => {
                              setFieldValue("receipt_date", item.value, false);
                            }}
                            dateFormat="dd.mm.yy"
                          />
                          <div
                            className={`${
                              touched.receipt_date && errors.receipt_date
                                ? "error"
                                : ""
                            }`}
                          >
                            {touched.receipt_date && errors.receipt_date
                              ? "This field is invalid"
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="btn-submit-code">
                      <Button
                        type="submit"
                        label={"Download"}
                        icon="pi pi-download"
                        disabled={!(isValid && dirty)}
                        iconPos="right"
                        loading={isLoading}
                        className="p-button-rounded btn-submit wi-button"
                        onClick={generateReceipt}
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DownloadReceiptDialogComponent;
