import * as React from "react";
import { Dialog } from "primereact/dialog";
import { Button as PButton } from 'primereact/button';
import "./confirm-sendMail-dialog.scss";
import { ProgressBar } from 'primereact/progressbar';
import { Password } from 'primereact/password';

const ConfirmSendMaiDialog = (props: any) => {
  const { visible, onConfirm, onHide, password, onSetPassword, iShowProgressLoadingLoading } = props;

  const buildFooterTemplate = () => (
    <React.Fragment>
      <PButton
        label="No"
        icon="pi pi-times"
        className="p-button-text button-hide"
        onClick={onHide}
        disabled={iShowProgressLoadingLoading}
      />
      <PButton
        label="Confirm"
        icon="pi pi-check"
        className="p-button-text button-confirm"
        onClick={onConfirm}
        disabled={iShowProgressLoadingLoading}
      />
    </React.Fragment>
  );

  return (
    <>
      <Dialog
        modal
        header="Confirmation Password"
        className="confirm-password-dialog"
        visible={visible}
        style={{ width: "550px" }}
        footer={buildFooterTemplate}
        onHide={onHide}
      >
        <div className="confirmation-sendMail">
          <div className="pass_text">
            {
              iShowProgressLoadingLoading ? 
                <div className="progess_bar_container">
                  <ProgressBar mode="indeterminate"></ProgressBar>
                </div>
              :
              <div className="password_filed">
                  <label htmlFor="pass_word" className="block">Password</label>
                  <Password 
                      id="pass_word" 
                      className="block"
                      toggleMask
                      value={password}
                      onChange={(e) => onSetPassword(e.target.value)}
                  />
              </div>
            }
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmSendMaiDialog;
