//react-hooks/exhaustive-deps
import * as React from "react"
import { useState, useEffect, useRef } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useParams } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { TreeTable } from 'primereact/treetable';
import { Button } from 'primereact/button';
import _ from "lodash";
import Layout from '../../layout/layout';
import { HistoryImportAPI } from '../../../services';
import { MailStatus } from '../../../utils/utils';
import { showNotification } from "../../../utils/logic";
import "./geo-import-details.scss";

const GeoImportDetail = () => {
    let { id } = useParams();
    const toast = useRef(null);
    const [donationImport, setDonationImport] = useState({
        passed_records: '',
        dataPassed: [],
        dataFailed: [],
        sendMailCompleted: [],
        sendMailFailed: []
    });
    const mailStatusObject = useRef({
        status: ''
    })

    const [activeIndex, setActiveIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        const res = await HistoryImportAPI.getDetailGeoImport(id || '');
        if (res && res.status === 200) {
            setIsLoading(false);
            const details = res.data.record;
            mailStatusObject.current.status = details.mail_status;
            const recordsSuccess = details.passed_records;
            const errorRecords = details.failed_records;
            const errorRecordsFormat = errorRecords.map((item: any, index: number) => {
                return {
                    key: `${index}`,
                    data: {
                        name: item.no,
                        message: '',
                    },
                    children: (_.isArray(item.errors) ? item.errors : [])?.map((errorItem: any, indexError: number) => {
                        return {
                            key: `${index}-${indexError}`,
                            data: {
                                name: errorItem.instancePath,
                                message: errorItem.message
                            }
                        }
                    })
                }
            })
            setDonationImport({
                passed_records: details.passed_records,
                dataPassed: recordsSuccess,
                dataFailed: errorRecordsFormat,
                sendMailCompleted: recordsSuccess.filter((item: any) => item?.mailStatus?.sendMail),
                sendMailFailed: recordsSuccess.filter((item: any) => item?.mailStatus?.sentMail === false)
            })
        }
    }

    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, [])

    const sendMail = async () => {
        const result = await HistoryImportAPI.sendGeoMails(id, donationImport.passed_records || '');
        const mailsCompleted = donationImport.dataPassed.filter((r: any) => r.mailStatus && r.mailStatus.sendMail);
        mailStatusObject.current.status = MailStatus.Pending;
        setDonationImport({
            ...donationImport,
            sendMailCompleted: mailsCompleted,
        })
        if (result.data.result) {
            showNotification("success", "Send mail successfully", toast);
        } else {
            showNotification("error", "Failed to send mail", toast);
        }
    }

    const submitSendMail = () => {
        sendMail();
        const interval = setInterval(() => {
            if (mailStatusObject.current.status !== MailStatus.Completed) {
                fetchData();
            } else {
                clearInterval(interval);
            }
        }, 60000);
    }

    const retrySendMail = async () => {
        sendMail();
        const intervalRetry = setInterval(() => {
            if (mailStatusObject.current.status === MailStatus.Completed && donationImport.sendMailCompleted.length !== donationImport.dataPassed.length) {
                fetchData();
            } else {
                clearInterval(intervalRetry);
            }
        }, 60000);
    }

    const formatMailStatusTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                {
                    rowData.mailStatus?.sendMail ?
                        <div className="completed-container">
                            <i className="pi pi-check mr-2 completed-icon"></i>
                        </div>
                        : (rowData.mailStatus ? <div className="remove-container">
                            <i className="pi pi-times remove-icon"></i>
                        </div> : <div></div>)
                }
            </React.Fragment>
        );
    }

    return (
        <Layout>
            <div className="geo-import-detail">
                <Toast ref={toast} />
                <div className="geo-detail-container">
                    <div className="d-flex flex-column flex-lg-row flex-nowrap align-content-center justify-content-start btn-send">
                        <div className="mail-errors">
                            {
                                donationImport.sendMailFailed.length > 0 ?
                                    <div>
                                        Send mails failed: {donationImport.sendMailFailed.length}
                                    </div> : <div />
                            }
                        </div>
                        <div className="status-sendmail">
                            {
                                mailStatusObject.current.status === MailStatus.Pending ?
                                    <div className="sendmail-lable">
                                        Pending: {donationImport.dataPassed.length - donationImport.sendMailCompleted.length} / {donationImport.dataPassed.length} mails
                                    </div>
                                    : <div />
                            }

                            <div className="sendmail-lable">
                                Sent: {donationImport.sendMailCompleted.length} / {donationImport.dataPassed.length} mails
                            </div>
                            {
                                mailStatusObject.current.status === MailStatus.Completed && donationImport.sendMailCompleted.length !== donationImport.dataPassed.length ?
                                    <Button onClick={retrySendMail}>
                                        Retry
                                    </Button> : <div />
                            }

                            {
                                mailStatusObject.current.status === MailStatus.NotYet ?
                                    <Button onClick={submitSendMail}>
                                        Send Emails
                                    </Button>
                                    : <div />
                            }
                            </div>
                    </div>
                    <div className="table-import">
                        <TabView activeIndex={activeIndex} onTabChange={(e) => {
                            setActiveIndex(e.index)
                        }}>
                            <TabPanel header="Success">
                                <DataTable
                                    value={donationImport.dataPassed}
                                    paginator
                                    responsiveLayout="scroll"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    rows={50}
                                    rowsPerPageOptions={[5, 10, 20, 50]}
                                    loading={isLoading}
                                    scrollable 
                                    scrollHeight="calc(100vh - 165px)"
                                >
                                    <Column field="data.donation_serial" header="Serial" align='center' style={{ width: '10%' }}></Column>
                                    <Column field="data.nw_geo_coordinate" header="Geo Coordinate" align='center' style={{ width: '10%' }}></Column>
                                    <Column field="mailStatus.sendMail" header="Mail Status" align='center' body={formatMailStatusTemplate} style={{ width: '10%' }} sortable></Column>
                                </DataTable>
                            </TabPanel>
                            <TabPanel header="Failed">
                                <TreeTable
                                    value={donationImport.dataFailed}
                                    paginator
                                    rows={50}
                                    rowsPerPageOptions={[5, 10, 20, 50]}
                                    loading={isLoading}
                                >
                                    <Column field="name" header="Row" style={{ width: '25%' }} expander></Column>
                                    <Column field="message" header="Message" style={{ width: '75%' }}></Column>
                                </TreeTable>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        </Layout>

    )
}

export default GeoImportDetail;
