import * as React from "react";
import "./add-donation.scss";
import Layout from "../../../layout/layout";
import AddDonationFormComponent from "./components/add-donation-form/add-donation-form";
import { CampaignManagerAPI, LandManagerAPI, RegionManagerAPI } from "../../../../services";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import CountryService from "../../../../services/country-service";
import { ProgressSpinner } from "primereact/progressspinner";

const AddDonationComponent = (props: any) => {

  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [lands, setLands] = useState([]);
  const toast = useRef<any>(null);
  const [countries, setCountries] = useState([]);

  const fetchAreasData = async () => {
    setIsLoading(true);
    const [regionRes, landsRes, campaignsRes, countriesRes] = await Promise.all([
      RegionManagerAPI.getAllRegions(),
      LandManagerAPI.getAllLands(),
      CampaignManagerAPI.getAllCampaigns(),
      CountryService.getCountries()
    ]);

    // @ts-ignore: Object is possibly 'null'.
    if (landsRes && landsRes.status === 200 && landsRes.data.records?.length > 0) {
      const lands = landsRes.data.records;
      lands.forEach((item: any) => {
        item.region = regionRes?.data?.records?.find((r: any) => r.default_land_id === item.uuid);
      });
      setLands(lands);
    }
    // @ts-ignore: Object is possibly 'null'.
    if (campaignsRes && campaignsRes.status === 200 && campaignsRes.data.records?.length > 0) {
      const campaigns = campaignsRes.data.records.filter(
        (item: any) =>
          item.status === "active" && item.land?.status === "active"
      );
      setCampaigns(campaigns);
    }

    if (countriesRes && countriesRes.status === 200) {
      if (countriesRes.data.data && countriesRes.data.data.length > 0) {
        setCountries(countriesRes.data.data);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchAreasData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="add-donation-page">
      {isLoading && (
        <div className="loading-component">
          <ProgressSpinner />
        </div>
      )}
        <div className="add-donation-container">
          <div className="headline">
            <h3>Add a donation</h3>
          </div>
          <div className="add-donation-form">
            <AddDonationFormComponent 
              lands={lands} 
              campaigns={campaigns}
              toast={toast}
              setIsLoading={setIsLoading}
              countries={countries}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddDonationComponent;
