import * as React from "react";
import { memo } from 'react';
import { SelectButton } from "primereact/selectbutton";
import { VideoOptionTypes, VideoOptions, defaultDataSource, defaultVideoUrl, docData, replaceVideoUrl } from "../../../common/useVideoCustom";
import { WIVideo } from "../../../dynamic-form/commons/wi-video/wi-video";
import { WiTextEditor, WiTextEditor1Line } from "../../../dynamic-form/commons";

const ThankyouFormComponent = (props: any) => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } = props;

  const onDataDocTemplate = (valuesForm: any) => {
    var expectedDataDoc = docData;
    if (valuesForm.is_default_video === false) {
      var url = valuesForm.video_url?.de
        ? valuesForm.video_url?.de
        : valuesForm.video_url?.en;
      expectedDataDoc = replaceVideoUrl(
        url,
        valuesForm.video_type,
        valuesForm.is_default_video
      );
    }
    else{
      expectedDataDoc = replaceVideoUrl(
        defaultVideoUrl.de,
        defaultDataSource,
        valuesForm.is_default_video
      );
    }

    return expectedDataDoc;
  };
  
    return (
        <>
          <div className="row row-content">
            <div className="col-md-6">
              <div className="element-form">
                <label className="label">Video Mode</label>
                <SelectButton
                  className="donation-checkbox wi-selectbutton"
                  id="is_default_video"
                  name="is_default_video"
                  options={VideoOptions}
                  onChange={(item: any) => {
                    if (item.value) {
                      setFieldValue(
                        "is_default_video",
                        item.value === "Default",
                        false
                      );
                      setFieldValue( "video_type", "yt", false );
                      setFieldValue( "video_url.de", "", false );
                      setFieldValue( "video_url.en", "", false );
                      setFieldValue( "video_headline.en", "", false );
                      setFieldValue( "video_headline.de", "", false );
                    }
                  }}
                  onBlur={handleBlur}
                  value={
                    values.is_default_video ? "Default" : "Custom"
                  }
                />
              </div>
            </div>
            <div className="col-md-12 video-container">
              <div className="element-form">
                <div
                  className="aw_video_wrapper"
                  style={{ height: "32rem"}}
                >
                  <iframe
                    title="video"
                    srcDoc={onDataDocTemplate(values)}
                    style={{ width: "100%", height: "100%" }}
                    scrolling="no"
                  ></iframe>
                </div>
              </div>
            </div>
            {!values.is_default_video ? (
              <div
                className="col-md-12"
                style={{
                  visibility: values.is_default_video
                    ? "collapse"
                    : "visible",
                }}
              >
                <div className="element-form">
                  <label className="label">Video Source</label>
                  <SelectButton
                    className="donation-checkbox wi-selectbutton"
                    id="video_type"
                    name="video_type"
                    options={VideoOptionTypes}
                    onChange={(item: any) => {
                      if (item.value) {
                        setFieldValue(
                          "video_type",
                          item.value,
                          false
                        );
                        setFieldValue(
                          "video_url.de",
                          "",
                          false
                        );
                        setFieldValue(
                          "video_url.en",
                          "",
                          false
                        );
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.video_type}
                    optionValue="value"
                    optionLabel="label"
                  />
                </div>
                <div className="element-form">
                  {values.video_type !== "ml" ? (
                    <div
                      className="col-md-12"
                      style={{
                        visibility: values.is_default_video
                          ? "collapse"
                          : "visible",
                      }}
                    >
                      <div className="col-md-12">
                        <div className="element-form">
                          <label className="label">
                            Video URL DE
                            <span className="required-label">
                              {" "}
                              *
                            </span>
                          </label>
                          <input
                            className={`${
                              touched.video_url?.de &&
                              errors.video_url?.de
                                ? "has-error"
                                : ""
                            }`}
                            type="text"
                            name="video_url.de"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.video_url?.de}
                          />
                          <div
                            className={`${
                              touched.video_url?.de &&
                              errors.video_url?.de
                                ? "error"
                                : ""
                            }`}
                          >
                            {touched.video_url?.de &&
                              errors.video_url?.de}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="element-form">
                          <label className="label">
                            Video URL EN
                            <span className="required-label">
                              {" "}
                              *
                            </span>
                          </label>
                          <input
                            className={`${
                              touched.video_url?.en &&
                              errors.video_url?.en
                                ? "has-error"
                                : ""
                            }`}
                            type="text"
                            name="video_url.en"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.video_url?.en}
                          />
                          <div
                            className={`${
                              touched.video_url?.en &&
                              errors.video_url?.en
                                ? "error"
                                : ""
                            }`}
                          >
                            {touched.video_url?.en &&
                              errors.video_url?.en}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {values.video_type === "ml" ? (
                    <div
                      className="col-md-12"
                      style={{
                        visibility: values.is_default_video
                          ? "collapse"
                          : "visible",
                      }}
                    >
                      <div className="col-md-12">
                        <div className="element-form">
                          <label className="label">
                            Video DE
                            <span className="required-label">
                              {" "}
                              *
                            </span>
                          </label>
                          <WIVideo
                            name="video_url.de"
                            schema={{}}
                          />
                          <div
                            className={`${
                              touched.video_url?.de &&
                              errors.video_url?.de &&
                              !values.video_url?.de
                                ? "error"
                                : ""
                            }`}
                          >
                            {touched.video_url?.de &&
                              errors.video_url?.de &&
                              !values.video_url?.de ? errors.video_url?.de : ""}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="element-form">
                          <label className="label">
                            Video EN
                            <span className="required-label">
                              {" "}
                              *
                            </span>
                          </label>
                          <WIVideo
                            name="video_url.en"
                            schema={{}}
                          />
                          <div
                            className={`${
                              touched.video_url?.en &&
                              errors.video_url?.en &&
                              !values.video_url?.en
                                ? "error"
                                : ""
                            }`}
                          >
                            {touched.video_url?.en &&
                              errors.video_url?.en &&
                              !values.video_url?.en ? errors.video_url?.en : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="col-md-12">
              <div className="element-form">
                <label className="label">
                  Thank You Message DE
                </label>
                <WiTextEditor name="message.de" values={values} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="element-form">
                <label className="label">
                  Thank You Message EN
                </label>
                <WiTextEditor name="message.en" values={values} />
              </div>
            </div>
          </div>
        </>
    );
};

export default memo(ThankyouFormComponent);
