import * as React from "react";
import { useEffect, useRef, useState, Fragment } from "react";
import "./certificate-manager.scss";
import Layout from "../layout/layout";
import { DataTable } from "primereact/datatable";
import { Column, ColumnAlignType } from "primereact/column";
import { CampaignManagerAPI, CertificateManagerAPI, RegionManagerAPI } from "../../services";
import { Toast } from "primereact/toast";
import { useSearchParams, Link } from "react-router-dom";
import { showNotification } from "../../utils/logic";
import {
  MenuDot,
  WIPaginator,
  WISearchField,
  WIConfirmDialog,
} from "../common";
import {
  buildDefaultBodyTemplate,
  formatDateBodyTemplate,
} from "../common/column-template-table/column-template";
import _ from "lodash";
import { Button } from "primereact/button";
import {
  getCertificateObjectSearchValue,
  getCertificateObjectWithValues,
  getCertificatePayloadConditions,
} from "./certificate-manager-util";
import { Sidebar } from 'primereact/sidebar';
import AddCertificateFormComponent from "./components/add-certificate-form/add-certificate-form";
import DownloadCertificateDialogComponent from "./components/download-certificate-dialog/download-certificate-dialog";

export type FilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[];
  };
  order: [];
};

const CertificateManager = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Math.max(+(searchParams?.get("pageNumber") || 1) - 1, 0);
  const noRows = Math.min(
    Math.max(+(searchParams?.get("noRows") || 50), 50),
    1000
  );
  const sortField = searchParams?.get("sortField") || "serial";
  const sortOrder = +(searchParams?.get("sortOrder") || -1);
  const searchField = searchParams?.get("search") || "";
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [certificates, setCertificates] = useState({
    data: [],
    totalPage: 0,
  });
  const [paginator, setPaginator] = useState({
    page: 0,
    first: 0,
    rows: 20,
  });
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder,
  });
  const [filterValues, setFilterValues] = useState<FilterParameters>({
    pageNo: pageNumber,
    range: noRows,
    search: searchField,
    where: {
      or: searchField ? getCertificateObjectSearchValue(searchField) : [],
    },
    order: [],
  });
  const [search, setSearch] = useState<any>(filterValues.search);
  const [certificate, setCertificate] = useState<any>(null);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [campaigns, setCampaigns] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [isShowDownloadCertificateDialog, setIsShowDownloadCertificateDialog] = useState(false);

  const onBasicPageChange = (event: any) => {
    setPaginator({
      page: event.page,
      first: event.first,
      rows: event.rows,
    });
    setSelectedCertificates([]);
  };

  const fetchCertificateData = async () => {
    setIsLoading(true);
    const payload = getCertificatePayloadConditions(filterValues, sortConfig);

    try {
      const resCertificates = await CertificateManagerAPI.getCertificates({
        pageNo: paginator.page,
        range: paginator.rows,
        ...payload,
      });

      if (resCertificates && resCertificates.status === 200) {
        setCertificates({
          data: resCertificates.data.records,
          totalPage: resCertificates.data.total || 0,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setCertificates({
        data: [],
        totalPage: 0,
      });
      setIsLoading(false);
    }
  };

  const onDeleteConfirm = async () => {
    const ids =
      selectedCertificates.length > 0
        ? selectedCertificates.map((item: any) => item.id)
        : [];
    if(ids && ids.length > 0) {
      const response = await CertificateManagerAPI.deleteCertificates(ids);
      if (response && response.status === 200) {
        fetchCertificateData();
        showNotification("success", "Delete certificates successfully.", toast);
      } else {
        showNotification("error", "Delete certificates failed.", toast);
      }
  
      onShowDeleteDialog(false);
      setSelectedCertificates([]);
    }
  };

  const fetchAreasData = async () => {
    setIsLoading(true);
    const [regionsRes, campaignsRes] = await Promise.all([
      RegionManagerAPI.getAllRegions(),
      CampaignManagerAPI.getAllCampaigns()
    ]);

    // @ts-ignore: Object is possibly 'null'.
    if (regionsRes && regionsRes.status === 200 && regionsRes.data.records?.length > 0) {
      setRegions(_.sortBy(regionsRes.data.records, (item) => item.name?.de?.toLowerCase()));
    }
    // @ts-ignore: Object is possibly 'null'.
    if (campaignsRes && campaignsRes.status === 200 && campaignsRes.data.records?.length > 0) {
      setCampaigns(_.sortBy(campaignsRes.data.records, (item) => item.name?.de?.toLowerCase()));
    }

    setIsLoading(false);
  };


  useEffect(() => {
    fetchAreasData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof paginator.page === "number") {
      setFilterValues({
        ...filterValues,
        pageNo: paginator.page,
        range: paginator.rows,
      });
      setSearchParams({
        pageNumber: (paginator.page + 1).toString(),
        noRows: paginator.rows.toString(),
      });
      setSelectedCertificates([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginator]);

  useEffect(() => {
    const searchParams = {
      pageNumber: (paginator.page + 1).toString(),
      noRows: paginator.rows.toString(),
      search: filterValues.search,
      sortField: sortConfig.sortField,
      sortOrder: sortConfig.sortOrder,
    };

    setSearchParams(_.omitBy(searchParams, (p) => !p));
    fetchCertificateData(); // temporary
    setSelectedCertificates([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, sortConfig]);

  const onShowDeleteDialog = (isVisible: boolean) => {
    setIsShowDeleteDialog(isVisible);
  };

  const onDeleteCertificate = async (rowData: any) => {
    setIsShowDeleteDialog(true);
    setSelectedCertificates(rowData);
  };

  const onSearchFunc = (searchText: string) => {
    const where_conditions = getCertificateObjectWithValues(filterValues.where);
    const or_conditions = getCertificateObjectSearchValue(searchText);
    where_conditions["or"] = searchText ? or_conditions : null;
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      search: searchText,
      order: filterValues.order,
    });
    if (paginator.page !== 0) {
      setPaginator({
        ...paginator,
        page: 0,
        first: 0,
      });
    }
  };

  const formatAmountBodyTemplate = (rowData: any, column: string) => {
    return (
      <div>
        <b style={{ fontSize: "13px", color: "black", marginRight: "5px" }}>
          {rowData
            ? parseFloat(rowData[column]?.toString())?.toLocaleString("de-DE")
            : "-"}
        </b>{" "}
        <span>{rowData.currency_code?.toUpperCase()}</span>
      </div>
    );
  };

  const onDownloadPDFCertificate = (rowData: any, e: any) => {
    setCertificate(rowData);
    setIsShowDownloadCertificateDialog(true);
  }

  const onHideSideBar = () => {
    setIsShowSidebar(false);
    if(certificate) {
      setCertificate(null);
    }
  }

  const tableColumns = [
    {
      field: "serial",
      header: "ID",
      width: "100px",
      align: "left",
      disabledSort: false,
    },
    {
      field: "protected_amount",
      header: "Protected Amount",
      width: "160px",
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, 'protected_amount'),
      align: "left",
      disabledSort: true,
    },
    {
      field: "co2_amount",
      header: "Compensated CO2",
      width: "160px",
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, 'co2_amount'),
      align: "left",
      disabledSort: true,
    },
    {
      field: "certificate_name",
      header: "Name",
      width: "220px",
      bodyTemPlate: buildDefaultBodyTemplate,
      align: "left",
      disabledSort: false,
    },
    {
      field: "occasion",
      header: "Occasion",
      width: "calc(92% - 1160px)",
      minWidth: "230px",
      bodyTemPlate: buildDefaultBodyTemplate,
      align: "left",
      disabledSort: false,
    },
    {
      field: "certificate_date",
      header: "Certificate Date",
      width: "160px",
      bodyTemPlate: formatDateBodyTemplate("MMM DD, YYYY HH:mm:ss"),
      align: "left",
      disabledSort: true,
    },
    {
      field: "region_name",
      header: "Template",
      width: "225px",
      bodyTemPlate: (rowData: any, column: any) =>  {
        return rowData.campaign_id ? <Link onClick={(e) => e.stopPropagation()} className="table-ref-link-cell" to={`/campaigns/${rowData.campaign_id}`}>{rowData.campaign_name}</Link> : <Link onClick={(e) => e.stopPropagation()} className="table-ref-link-cell" to={`/regions/${rowData.region_id}`}>{rowData.region_name}</Link>;
      },
      align: "left",
      disabledSort: true,
    },
    {
      field: "created_at",
      header: "Created Date",
      width: "160px",
      bodyTemPlate: formatDateBodyTemplate("MMM DD, YYYY HH:mm:ss"),
      align: "left",
      disabledSort: false,
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={`${col.field}_${i}`}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
          minWidth: col.minWidth,
        }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
        sortable={!col?.disabledSort}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: "Edit",
        description: "Edit certificate information",
        icon: "fa-solid fa-pen-to-square",
        command: (e: any) => {
          setCertificate(rowData);
          setIsShowSidebar(true);
        },
      },
      {
        label: "Download",
        description: "Download certificate as PDF",
        icon: "fa-solid fa-download",
        command: (e: any) => onDownloadPDFCertificate(rowData, e),
      },
      {
        label: "Delete",
        description: "Delete certificate information",
        icon: "fa-solid fa-trash-can delete-icon",
        dangerous: true,
        command: (e: any) => onDeleteCertificate([rowData]),
      },
    ];
    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="certificate-manager">
        <div className="manager-container">
          <div className="headline">
            <h3>Certificate Generator</h3>
          </div>
          <div className="header-containers">
            <div className="certificate-header-panel">
              <WISearchField
                icon={"pi pi-search"}
                placeholder="Search for anything"
                setSearch={(value: any) => setSearch(value.global.value)}
                enterSearch={() => onSearchFunc(search)}
                globalValue={search}
              />
            </div>
            <div className="btn-certificate">
              {selectedCertificates && selectedCertificates.length > 0 && (
                <Button
                  type="button"
                  icon="fa-solid fa-trash-can"
                  label={"Delete"}
                  iconPos="right"
                  className="delete-button"
                  onClick={() => {
                    onDeleteCertificate(selectedCertificates);
                  }}
                />
              )}
              <Button
                type="button"
                icon="fa-solid fa-circle-plus"
                label={"Generate"}
                iconPos="right"
                className="generate-button"
                onClick={() => {
                  setIsShowSidebar(true);
                }}
              />
            </div>
          </div>
          <div className="table-certificate">
            <DataTable
              loading={isLoading}
              value={certificates.data}
              scrollable
              dataKey="id"
              scrollHeight="calc(100vh - 295px)"
              scrollDirection="both"
              lazy
              onSort={(e: any) => {
                setSortConfig({
                  sortField: e.sortField,
                  sortOrder: e.sortOrder,
                });
                setSelectedCertificates([]);
              }}
              selectionMode="checkbox"
              selection={selectedCertificates}
              onSelectionChange={(e) => {
                setSelectedCertificates(e.value);
              }}
              sortField={sortConfig.sortField}
              sortOrder={sortConfig.sortOrder}
            >
              <Column
                selectionMode="multiple"
                style={{ width: "50px" }}
              ></Column>
              {dynamicColumns}
              <Column
                frozen
                alignFrozen="right"
                style={{ width: "50px" }}
                body={buildMenu}
              ></Column>
            </DataTable>
            <WIPaginator
              first={paginator.first}
              rows={paginator.rows}
              totalRecords={certificates.totalPage}
              onPageChange={onBasicPageChange}
            />
          </div>
        </div>
        <WIConfirmDialog
          visible={isShowDeleteDialog}
          onHide={() => {
            setSelectedCertificates([]);
            onShowDeleteDialog(false);
          }}
          onConfirm={() => onDeleteConfirm()}
          message={
            <Fragment>
              <span style={{ fontSize: "18px", fontWeight: "700" }}>
                Are you sure you want to delete this certificate?
              </span>
              <br />
              <span style={{ fontSize: "13px" }}>
                All of your data will be deleted <b>permanently</b>.
                <br />
                You <b>cannot undo</b> this action.
              </span>
            </Fragment>
          }
          classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
        />
        <DownloadCertificateDialogComponent
            visible={isShowDownloadCertificateDialog}
            onHide={() => {
              setCertificate(null);
              setIsShowDownloadCertificateDialog(false)
            }}
            certificateData={certificate}
          />
        <Sidebar 
          visible={isShowSidebar} 
          position="right" 
          className="p-sidebar-md add-donor-sibar-right" 
          style={{width:'30em'}}
          onHide={() => onHideSideBar()}>
            <div className="add-donor-sidebar-content">
              <div className="headline">
                <h6>{certificate ? 'Update' : 'Generate'}  a certificate</h6>
              </div>
              <AddCertificateFormComponent
                regions={regions} 
                campaigns={campaigns}
                fetchCallBack={(() => fetchCertificateData())}
                toast={toast}
                onHide={() => onHideSideBar()}
                certificateData={certificate}
                notify={toast}
              />
            </div>
          </Sidebar>
      </div>
    </Layout>
  );
};

export default CertificateManager;
