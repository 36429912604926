import * as React from "react";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import "./create-contact-form.scss";
import WIUploadMediaField from "../../../../common/upload/wi-upload-media-field";
import { env } from "../../../../../environment";
import { RichTextEditor } from "./custom-editor-component";
import { buildTooltipManageTemplate } from "../../../../common/column-template-table/column-template";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import { LanguageItem } from "../../../../../utils/commonType";
import { showNotification } from "../../../../../utils/logic";
import { CMsPageManagerAPI, ContactManagerAPI } from "../../../../../services";
import { AxiosResponse } from "axios";
import { generatePersonPageData } from "../../contact-business";
import {
  callCreateAndPublishPageAPI,
  callDeletePageAPI,
  callUpdatePageAPI,
  fetchAllBlocks,
  fetchCMSFooters,
  fetchCMSHeaders,
  getMasterURL,
} from "../../../../pages/builder-page.common";
import { useNavigate } from "react-router-dom";

interface ContactType {
  name: string;
  phone: string;
  email: string;
  cover_picture: string;
  profile_picture: string;
  description: LanguageItem;
  position: LanguageItem;
  headline: LanguageItem;
  generate_page: boolean;
  mobile_cover_picture: string;
  link: LanguageItem;
}

const CreateContactFormComponent = (props: any) => {
  const {
    personPageType,
    contact,
    toast,
    parentPages,
    cmsPersonPage,
    cmsPersonPages,
    cmsMasterPages
  } = props;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [personPageURL, setPersonPageURL] = useState<any>();
  const [allBlocks, setAllBlocks] = useState<any>([]);
  const formikRef = useRef(null);
  const [headers, setHeaders] = useState<any>([]);
  const [footers, setFooters] = useState<any>([]);

  useEffect(() => {
    fetchDataToCreatePersonPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPersonPageURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, cmsPersonPage]);

  const updateContact = async (parameters: any) => {
    if (contact.profile_picture === parameters.profile_picture) {
      delete parameters["profile_picture"];
    }

    if (contact.cover_picture === parameters.cover_picture) {
      delete parameters["cover_picture"];
      delete parameters["mobile_cover_picture"];
    }

    return await ContactManagerAPI.updateContact(parameters);
  };

  const generateContact = async (parameters: any) => {
    parameters.mobile_cover_picture = parameters.cover_picture;
    return await ContactManagerAPI.createContact(parameters);
  };

  const requestContactData = async () => {
    // @ts-ignore: Object is possibly 'null'.
    const formikValues = formikRef?.current.values;

    // @ts-ignore: Object is possibly 'null'.
    if (contact) {
      // @ts-ignore: Object is possibly 'null'.
      const contactObj = contact;

      let request = { ...contactObj, ...formikValues };
      request.mobile_cover_picture = formikValues.cover_picture;
      return updateContact({
        ...request,
      });
    }

    return generateContact({
      ...formikValues,
    });
  };

  const handleRequestContact = (
    resGenerateContact: AxiosResponse<any, any>
  ) => {
    // @ts-ignore: Object is possibly 'null'.
    const response = resGenerateContact;
    let method = response.config.method;

    if (
      response &&
      response.status === 200 &&
      (method === "post" || method === "put")
    ) {
      let mesage =
        method === "post"
          ? "Generate contact successfully"
          : "Update contact successfully";
      showNotification("success", mesage, toast);
      return response.data.result;
    } else {
      showNotification("error", "Failed to generate contact", toast);
    }
    return false;
  };

  const onSubmitDataForm = async () => {
    try {
      setIsLoading(true);
      const resGenerateContact = await requestContactData();
      let personResponse = handleRequestContact(resGenerateContact);
      await handleRequestPersonPage(personResponse);
      if(personResponse) {
        navigate(`/global-contents/people/${resGenerateContact?.data.result.id}`);
        window.location.reload();
      }
      else{
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      showNotification("error", "Failed to generate contact", toast);
    }
  };

  const handleRequestPersonPage = async (personResponse: any) => {
    // @ts-ignore: Object is possibly 'null'.
    const formikValues = formikRef?.current.values;
    let resultHandlePersonPage = false;
    if (!personResponse) {
      return resultHandlePersonPage;
    }

    //const cmsPage = findCmsPage(cmsPersonPages, contact);
    if (formikValues.generate_page !== true) {
      if (contact && cmsPersonPage && cmsPersonPage.uuid) {
        await callDeletePageAPI(
          cmsPersonPage.uuid,
          (result: any) => {
            resultHandlePersonPage = true;
            showNotification(
              "success",
              "Delete Person Page successfully",
              toast
            );
          },
          () => showNotification("error", "Failed to delete Person Page", toast)
        );
      }
      return resultHandlePersonPage;
    }
    
    let pageData = await generatePersonPageData(
      formikValues,
      parentPages,
      cmsPersonPage,
      personPageType,
      allBlocks,
      footers,
      headers,
      personResponse.id,
      true
    );

    if (contact && cmsPersonPage && cmsPersonPage.uuid) {
      delete pageData["url"];
      await callUpdatePageAPI(
        cmsPersonPage.uuid,
        pageData,
        async () => {
          let updateContentRes = await CMsPageManagerAPI.update(
            cmsPersonPage.uuid,
            pageData.content
          );
          if (updateContentRes && updateContentRes.status === 200) {
            resultHandlePersonPage = true;
            showNotification(
              "success",
              "Update Person Page successfully",
              toast
            );
          }
        },
        () => showNotification("error", "Failed to update Person Page", toast)
      );
    } else {
      correctUrlPersonPage(pageData);
      await callCreateAndPublishPageAPI(
        pageData,
        (result: any) => {
          resultHandlePersonPage = true;
          showNotification(
            "success",
            "Generate Person Page successfully",
            toast
          );
        },
        () => showNotification("error", "Failed to generate Person Page", toast)
      );
    }

    return resultHandlePersonPage;
  };

  const correctUrlPersonPage = (pageData: any) => {
    const duplicateUrls = cmsPersonPages.filter((f: any) =>
      f.url.includes(pageData.url)
    );
    if (duplicateUrls.length > 0) {
      let personUrl = pageData.url + "-";
      let expectedUrl = "";
      for (let index = 1; index < 1000; index++) {
        let urlExisted = duplicateUrls.find((f: any) =>
          f.url.includes(personUrl + index)
        );
        if (!urlExisted) {
          expectedUrl = personUrl + index;
          break;
        }
      }

      if (expectedUrl !== "") {
        pageData.url = expectedUrl;
      }
    }
  };

  const fetchDataToCreatePersonPage = async () => {
    let [
      footers,
      headers,
      allBlocks,
    ] = await Promise.all([
      fetchCMSFooters(),
      fetchCMSHeaders(),
      fetchAllBlocks(),
    ]);

    setAllBlocks(allBlocks);
    setFooters(footers);
    setHeaders(headers);
    fetchPersonPageURL();
  };

  const fetchPersonPageURL = () => {
    if(cmsPersonPage && cmsMasterPages) {
      const parentPersonURL = getMasterURL(cmsPersonPage?.page_id, cmsMasterPages);
      setPersonPageURL(parentPersonURL);
    }
  }

  const defaultCoverPicture = () => {
    return  "header/hero-header-landingpages/Blick-auf-den-Regenwald-von-oben-Peru-hero.jpg"
  }; 

  const initialPartnersValues = (): ContactType => {
    return {
      name: contact ? `${contact.name}` : "",
      phone: contact ? `${contact.phone}` : "",
      email: contact ? `${contact.email}` : "",
      profile_picture: contact ? `${contact.profile_picture ?? ""}` : "",
      cover_picture: contact ? `${contact.cover_picture ? contact.cover_picture : defaultCoverPicture()}` : defaultCoverPicture(),
      description:
        contact && contact.description
          ? { de: contact.description.de, en: contact.description.en }
          : { de: "", en: "" },
      position:
        contact && contact.position
          ? { de: contact.position.de, en: contact.position.en }
          : { de: "", en: "" },
      generate_page: contact ? contact.generate_page : true,
      mobile_cover_picture: contact ? `${contact.cover_picture ? contact.cover_picture : defaultCoverPicture()}` : defaultCoverPicture(),
      link:
        contact && contact.link
          ? { de: contact.link.de, en: contact.link.en }
          : { de: "", en: "" },
      headline:
        contact && contact.headline
          ? { de: contact.headline.de, en: contact.headline.en }
          : { de: "", en: "" },
    };
  };

  const partnersFormSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, "This field must be with a maximum length of 100.")
      .required("This field is required."),

    profile_url: Yup.object().shape({
      de: Yup.string()
        .max(255, "This field must be with a maximum length of 255.")
        .required("This field is required."),
      en: Yup.string()
        .max(255, "This field must be with a maximum length of 255.")
        .required("This field is required."),
    }),
  });

  const buildHeaderTemplate = () => {
    const type = "Person";
    const title = `${type}`;
    const code = contact?.name || "";
    const tooltip = `Click to open person page with name ${code}`;
    const URL =
      contact && cmsPersonPage && cmsPersonPage.uuid
        ? env.CMS_URL + personPageURL + cmsPersonPage.url
        : "";
    return buildTooltipManageTemplate(
      title,
      code,
      tooltip,
      URL,
      !cmsPersonPage
    );
  };

  return (
    <div className="person-create-form">
      <div className="person-form-container">
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={initialPartnersValues()}
          validationSchema={partnersFormSchema}
          onSubmit={(values, { setSubmitting }) => {
            // console.log("values >>>", values);
          }}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            isValid,
            handleBlur,
            handleSubmit,
            setFieldValue,
            handleChange,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="sticky-header-form">
                  <div className="col-12 d-flex align-items-center justify-content-between btn-submit-container">
                    {buildHeaderTemplate()}
                    <Button
                      type="submit"
                      // disabled={!(isValid && dirty)}
                      loading={isLoading}
                      id="btn-submit-coupons"
                      label={contact ? "Update" : "Create"}
                      className="p-button-rounded btn-submit"
                      icon="pi pi-chevron-right"
                      iconPos="right"
                      onClick={onSubmitDataForm}
                    />
                  </div>
                </div>
                {isLoading ? (
                  <div className="loading-component">
                    <ProgressSpinner />
                  </div>
                ) : null}
                {isLoading !== true ? (
                  <div className="col-12 col-lg-6">
                    <div className="row row-content">
                      <div className="col-md-8">
                        <div className="col-md-12">
                          <div className="w-100">
                            <div className="element-form">
                              <label className="label">
                                Name <span className="required-label">*</span>
                              </label>
                              <Field
                                className={`${
                                  touched.name && errors.name ? "has-error" : ""
                                } text-input-inline text-currency-target`}
                                name="name"
                                onChange={(item: any) => {
                                  setFieldValue(
                                    "name",
                                    item.target.value,
                                    true
                                  );
                                }}
                                onBlur={handleBlur}
                                maxLength={100}
                              />
                              {errors.name && touched.name ? (
                                <small className="error">{errors.name}</small>
                              ) : null}
                            </div>
                          </div>

                          <div className="w-100">
                            <div className="element-form">
                              <label className="label">Email</label>
                              <Field
                                className={` text-input-inline text-currency-target`}
                                name="email"
                                onChange={(item: any) => {
                                  setFieldValue(
                                    "email",
                                    item.target.value,
                                    true
                                  );
                                }}
                                onBlur={handleBlur}
                                maxLength={100}
                              />
                            </div>
                          </div>

                          <div className="w-100">
                            <div className="element-form">
                              <label className="label">Phone</label>
                              <Field
                                className={`  text-input-inline text-currency-target`}
                                name="phone"
                                onChange={(item: any) => {
                                  setFieldValue(
                                    "phone",
                                    item.target.value,
                                    true
                                  );
                                }}
                                onBlur={handleBlur}
                                maxLength={100}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3" >
                        <div className="element-form" style={{marginLeft: "10px"}}>
                          <WIUploadMediaField
                            type="file"
                            hideLabelFileName={true}
                            classImage="image-cert"
                            className="profile-picture-people"
                            name="profile_picture"
                            label="Profile Picture"
                            required={true}
                          />
                        </div>
                      </div>
                     
                      <div className="col-md-12">
                        <div className="element-form">
                          <RichTextEditor
                            name="position.de"
                            header="position"
                            isMultipleLine={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="element-form" style={{marginTop: "-10px"}}>
                          <label className="label">Generate page?</label>
                          <InputSwitch
                            className="wi-form-toggle-button-partner"
                            checked={values.generate_page}
                            onChange={(e: any) =>
                              setFieldValue("generate_page", e.value)
                            }
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="element-form">
                          <RichTextEditor
                            name="description.de"
                            header="description"
                            isMultipleLine={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {isLoading !== true ? (
                  <div className="col-12 col-lg-6">
                    <div className="row upload-logo-row">
                      <div className="col-md-12">
                        <div className="element-form">
                          <WIUploadMediaField
                            type="file"
                            hideLabelFileName={true}
                            classImage="image-cert"
                            className="img-frame-16-9"
                            name="cover_picture"
                            label="Header Picture"
                            required={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateContactFormComponent;
