import { Toast } from "primereact/toast";
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { Fragment, useEffect, useRef, useState } from "react";
import Layout from "../../layout/layout";
import CardItem from "./components/card-item/card-item";
import MediaInfo from "./components/media-details/media-info";
import Directory from "./components/directory/directory";
import "./media-manager.scss";
import {
  WIButton,
  WIConfirmDialog,
  WISearchField,
} from "../../common";
import { generateLinkDownLoad, showNotification } from "../../../utils/logic";
import { FIELDS_SEARCH, removeEmpty } from "../../../utils/utils";
import { MediaFolderManagerAPI, MediaManagerAPI, MediaV2ManagerAPI } from "../../../services";
import { DataViewLayoutOptions } from "primereact/dataview";
import { DataScroller } from "primereact/datascroller";
import { UploadMediaDialogComponent } from "./components/upload-media-dialog/upload-media-dialog";
import { Sidebar } from "primereact/sidebar";
import _ from "lodash";
import { env } from "../../../environment";
import axios from "axios";
import {
  getBase64,
  getFileType,
  getImageDimensions,
  getFolderByFolderParentId,
  getOriginalFileName,
  IMAGE_EXTENSIONS,
  isFileOverSize,
  isFileTypeNotAllowed,
  parseImageMetadata,
  getFileExtension,
  requestBase64StringFromImageURL,
  formatImageFileURL,
  uploadPhysicalFileToS3,
  getCompressedImage,
  getCompressedThumbnail,
  getThumbnailFullpath,
  PNG_MAZ_SIZE,
} from "../../../utils/mediaUtils";
import { Splitter, SplitterPanel } from "primereact/splitter";
import ListItem from "./components/list-item/list-item";
import moment from "moment";
import ImageEditorDialogComponent from "./components/image-editor/editor-dialog";
import { Button } from "primereact/button";

const MediaManager = () => {
  const toast = useRef(null);
  const [isShowCreate, setIsShowCreate] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarLoading, setIsSidebarLoading] = useState(false);
  const [mediaFiles, setMediaFiles] = useState<any>({
    data: [],
    totalPage: 0,
  });
  const [search, setSearch] = useState<any>(null);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const inputFileRef = useRef(null);
  const [nodes, setNodes] = useState<any>(null);
  const selectedFolder = useRef(null);
  const [uploadActive, setUploadActive] = useState(true);
  const [layout, setLayout] = useState("grid");
  const [isShowEditor, setIsShowEditor] = useState(false);
  const [editingPhoto, setEditingPhoto] = useState<any>(null);
  const [fileBase64, setFileBase64] = useState<any>(null);
  const ds = useRef<any>(null);
  const rootFolderGuid = '00000000-0000-0000-0000-000000000000';
  const [folders, setFolders] = useState<any>(null);

  const onCreateMedia = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsShowCreate(true);
    setSelectedMedia(null);
  };

  const onEditMedia = async (isCreating: boolean, e: any, rowData: any) => {
    e.preventDefault();
    setIsShowCreate(isCreating);
    setSelectedMedia(rowData);
  };

  const onSearch = () => {
    setIsLoading(true);
    refresh();
  };

  const itemTemplate = (file: any) => {
    if (layout === "list")
      return <ListItem mediaFile={file} onEditMedia={onEditMedia} />;
    else if (layout === "grid")
      return <CardItem mediaFile={file} onEditMedia={onEditMedia} />;
  };

  const fetchMediaByFolderId = async (folder_id: any, valueSearch?: string) => {
    setIsLoading(true);

    const data = removeEmpty({
      search_text: valueSearch || undefined,
      search_fields: valueSearch ? FIELDS_SEARCH.MEDIA : undefined,
    });

    try {
      const response = await MediaV2ManagerAPI.getFilesInsideFolder(folder_id, data);
      if (response && response.status === 200) {
        const mediaData: any = response.data.files || [];
        setIsLoading(false);

        setMediaFiles({
          data: [...mediaData, ..._.fill(Array(6), {})],
          totalPage: response.data.total || 0,
        });

        return mediaData;
      }

      return [];
    } catch (error) {
      setMediaFiles({
        data: [],
        totalPage: 0,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    //refresh();
    //fetchMediaFolder();
    fetchMediaFolder().then(() => refresh());
  }, // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (selectedMedia && isShowCreate) {
      const foundMedia = mediaFiles.data.find(
        (f: any) => f.uuid === selectedMedia.uuid
      );
      if (foundMedia) {
        const refreshData = _.cloneDeep(foundMedia);
        setSelectedMedia(refreshData);
      }
    }
  },
    // eslint-disable-next-line
    [mediaFiles]);

  const refresh = () => {
    // @ts-ignore: Object is possibly 'null'.
    if (selectedFolder.current && selectedFolder.current.data !== "Root") {
      const node: any = selectedFolder.current;
      fetchMediaByFolderId(node.uuid, search);
    } else {
      // fetchMediaData(search);
      fetchMediaByFolderId(rootFolderGuid, search);
    }
  };

  const deleteMedia = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsShowDeleteDialog(true);
  };

  const onShowDeleteDialog = (isVisible: boolean) => {
    setSelectedMedia(null);
    setIsShowDeleteDialog(isVisible);
  };

  const onDeleteConfirm = async () => {
    try {
      if (selectedMedia) {
        await MediaV2ManagerAPI.deleteFile(selectedMedia.uuid);
        onShowDeleteDialog(false);
        refresh();
        setSelectedMedia(null);
        setIsShowCreate(false);
        showNotification("success", "Deleted successfully", toast);
      }
    } catch (error) {
      showNotification("error", "Failed to delete", toast);
    }
  };

  const downLoadMedia = async () => {
    if (selectedMedia) {
      const fileUrl = `${env.PUBLIC_UPLOAD_URL}/${selectedMedia.fullPath}`;
      axios
        .get(fileUrl, {
          responseType: "blob",
        })
        .then((response) => {
          if (response && response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const fileName =
              getOriginalFileName(selectedMedia.name) || "name_undefined";
            generateLinkDownLoad(fileName, url);
          } else {
            showNotification("error", "Download failed", toast);
          }
        });
    }
  };

  const replaceImage = () => {
    // @ts-ignore: Object is possibly 'null'.
    inputFileRef.current.click();
  };

  /**
   * Upload image to S3
   * @param s3ObjectKey: S3 Object Key (File Full Path, ex: media/animals/bird.png)
   * @param s3PresignedUrl: S3 Uploading Presigned URL
   * @param base64: Image Base64
   * @param fileType: Image Type
   * @param fileSize: Image Size
   * @returns Size of image (whether compressed or not), otherwise NULL if error(s)
   */
  const uploadImageToS3 = async (s3ObjectKey: string, s3PresignedUrl: string, base64: any, fileType: string, fileSize: number = 0) => {
    try {
      const newImageSize = await uploadPhysicalFileToS3(s3PresignedUrl, base64, fileType, fileSize > PNG_MAZ_SIZE);
      return { s3ObjectKey, s3PresignedUrl, newImageSize };
    } catch(ex) {
      console.log('Cannot upload image to S3: ', ex);
      return null;
    }
  }

  // Operator Replace Image 
  const handleFileChange = async (event: any) => {
    try {
      setIsSidebarLoading(true);
      
      let file = event.target.files && event.target.files[0];
      if (!file) return;

      const fileExtension = getFileExtension(file.name).toLowerCase();

      if (isFileOverSize(file)) {
        event.target.value = null;
        showNotification(
          "error",
          "File size has exceeded it max limit of 25MB",
          toast
        );
        setIsSidebarLoading(false);
        return;
      }

      if (isFileTypeNotAllowed(file)) {
        event.target.value = null;
        showNotification(
          "error",
          "The system accepts jpg, jpeg, png, svg, doc, docx, xls, xlsx, pdf, txt, 7z, rar, zip, avi, mov, and mp4 file types",
          toast
        );
        setIsSidebarLoading(false);
        return;
      }

      if (fileExtension !== selectedMedia.file_extension.toLowerCase()) {
        event.target.value = null;
        showNotification(
          "error",
          "The system requires you to upload a file of the same type",
          toast
        );
        setIsSidebarLoading(false);
        return;
      }

      let imageBase64: any = null;
      let imageFullPath: string = `media/${selectedMedia.fullPath}`;
      let thumbnailBase64: any = null;
      let thumbnailFullPath: string;
      const objectKeys: string[] = [];

      let fileMetaInfo;
      if (IMAGE_EXTENSIONS.some((item) => item === fileExtension)) {
        fileMetaInfo = await parseImageMetadata(file);
      }

      const isJpgImage = IMAGE_EXTENSIONS.some((item) => item === fileExtension) && !/^svg$/ig.test(fileExtension) && !/^png$/ig.test(fileExtension);
      if (isJpgImage) {
        file = await getCompressedImage(file);
        const thumbnailFile = await getCompressedThumbnail(file);
        thumbnailBase64 = await getBase64(thumbnailFile);
        thumbnailFullPath = imageFullPath.replace(/.jpg$/ig, '_thumbnail.webp').replace(/.jpeg$/ig, '_thumbnail.webp');
        objectKeys.push(thumbnailFullPath);
      }

      imageBase64 = await getBase64(file);
      objectKeys.push(imageFullPath);

      
      if (IMAGE_EXTENSIONS.some((item) => item === fileExtension)) {
        const dimensions = await getImageDimensions(imageBase64);
        fileMetaInfo = { ...fileMetaInfo, dimensions };
      }

      // Get Uploading Presigned URL(s) for image and thumbnail (if any)
      const presignedUrlsResponse = await MediaV2ManagerAPI.getMultipleFileUploadingPresignedUrls({object_keys: objectKeys});
      if (!presignedUrlsResponse || presignedUrlsResponse.status !== 200 || presignedUrlsResponse.data.length < 1) {
        throw 'Cannot get S3 Presigned URL!';
      }
      /* Define a S3 Uploading Task List */
      const s3UploadingTasks: any[] = [];
      /* Add uploading tasks to the list */
      presignedUrlsResponse.data.forEach((presignedUrlObject: any) => {
        if (/_thumbnail.webp$/ig.test(presignedUrlObject.object_key)) {
          s3UploadingTasks.push(uploadImageToS3(presignedUrlObject.object_key, presignedUrlObject.presigned_url, thumbnailBase64, 'image/webp'));
        } else {
          s3UploadingTasks.push(uploadImageToS3(presignedUrlObject.object_key, presignedUrlObject.presigned_url, imageBase64, file.type, file.size));
        }
      });
      /* Execute this S3 Uploading Task List */
      if (s3UploadingTasks.length > 0) {
        const s3UploadingResponse = await Promise.all(s3UploadingTasks);
        const results = s3UploadingResponse.filter((response: any) => !!response);
        const imageUploadingResult = results?.find((result) => result.s3ObjectKey === imageFullPath);
        if (imageUploadingResult) {
          /* Update Image Metadata */
          let fileInfoData = {
            uuid: selectedMedia.uuid,
            alternativeText: JSON.stringify(fileMetaInfo.alternative_text),
            description: JSON.stringify(fileMetaInfo.description),
            // @ts-ignore: Object is possibly 'null'.
            folderId: selectedFolder?.current?.uuid,
            fullPath: selectedMedia.fullPath,
            metadata: JSON.stringify(fileMetaInfo),
            name: selectedMedia.name,
            size: imageUploadingResult.newImageSize || file.size,
            type: getFileType(fileExtension),
            title: JSON.stringify(fileMetaInfo.title),
          }
          const res = await MediaV2ManagerAPI.updateFile(fileInfoData);
          if (res && res.status === 200) {
            showNotification("success", "Upload file successfully", toast);
            refresh();
            setIsSidebarLoading(false);
          } else {
            showNotification("error", `Failed to upload file ${file.name}`, toast);
            setIsSidebarLoading(false);
          }
        } else {
          throw `Cannot upload ${file.name} to S3!`;
        }
      }
    } catch(ex) {
      console.log(ex);
      setIsSidebarLoading(false);
    }
  };

  const fetchMediaFolder = async () => {
    try {
      const root: any = {
        key: "root",
        label: "/",
        data: "Root",
        icon: "pi pi-fw pi-folder",
        children: [],
      };

      const res = await MediaFolderManagerAPI.getAllV2();
      if (res && res.status === 200) {
        const folderData = res.data.records || [];
        setFolders(folderData);

        const folderTree = getFolderByFolderParentId(null, folderData);
        root.children = folderTree;
        setNodes([root]);
        return;
      }
      setNodes([]);
    } catch (error) { }
  };

  useEffect(() => {
    if (isShowEditor && selectedMedia) {
      setEditingPhoto(selectedMedia);
    }
  }, [isShowEditor, selectedMedia]);

  const editPhoto = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSidebarLoading(true);

    const fileURL = encodeURI(
      `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(
        selectedMedia.fullPath
      )}?v=${moment().seconds()}`
    );
    const imageBase64 = await requestBase64StringFromImageURL(fileURL);
    if (imageBase64) {
      setFileBase64(imageBase64);
    }

    setIsShowEditor(true);
    // setIsShowCreate(false);
    setIsSidebarLoading(false);
  };

  const customIcons = (
    <React.Fragment>
      {selectedMedia &&
        selectedMedia.type === "Image" &&
        getFileExtension(selectedMedia.name).toLowerCase() !== "svg" && (
          <button
            className="p-sidebar-icon p-link mr-1"
            title="Edit Media"
            onClick={editPhoto}
          >
            <span className="fa-solid fa-pencil" title="Edit Media" />
          </button>
        )}
      <button
        className="p-sidebar-icon p-link mr-1"
        title="Replace Media"
        onClick={replaceImage}
      >
        <span className="fa-solid fa-retweet" title="Replace Media" />
      </button>
      <button
        className="p-sidebar-icon p-link mr-1"
        title="Download Media"
        onClick={downLoadMedia}
      >
        <span className="fa-solid fa-download" title="Download Media" />
      </button>
      <button
        className="p-sidebar-icon p-link mr-1"
        title="Delete Media"
        onClick={(e: any) => deleteMedia(e)}
      >
        <span className="fa-solid fa-trash" title="Delete Media" />
      </button>
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputFileRef}
        onChange={handleFileChange}
        accept=".jpg, .jpeg, .png, .svg, .doc, .docx, .xls, .xlsx, .pdf, .txt, .zip, .rar, .7z, .avi, .mov, .mp4"
      />
    </React.Fragment>
  );

  const updateSelectedFolder = (folder: any) => {
    selectedFolder.current = folder?.node || null;
    // @ts-ignore: Object is possibly 'null'.
    if (!selectedFolder.current || selectedFolder.current?.data === "Root") {
      setUploadActive(true);
    } else setUploadActive(false);

    setIsShowCreate(false);
    setSelectedMedia(null);
  };

  // Operator Edit Image = Image Editor 
  const saveChangesPhoto = async (editedPhoto: any) => {
    setIsSidebarLoading(true);
    try {
      const fileExtension = getFileExtension(editedPhoto.name).toLowerCase();
      const isJpgImage = IMAGE_EXTENSIONS.some((item) => item === fileExtension) && !/^svg$/ig.test(fileExtension) && !/^png$/ig.test(fileExtension);
      let imageBase64: any = null;
      let imageFullPath: string = `media/${selectedMedia.fullPath}`;
      let thumbnailBase64: any = null;
      let thumbnailFullPath: string;
      let file: any = editedPhoto.file;
      const objectKeys: string[] = [];
      if (isJpgImage) {
        file = await getCompressedImage(editedPhoto.file);
        const thumbnailFile = await getCompressedThumbnail(file);
        thumbnailBase64 = await getBase64(thumbnailFile);
        thumbnailFullPath = imageFullPath.replace(/.jpg$/ig, '_thumbnail.webp').replace(/.jpeg$/ig, '_thumbnail.webp');
        objectKeys.push(thumbnailFullPath);
      }
      imageBase64 = await getBase64(file);
      objectKeys.push(imageFullPath);
      // Get Uploading Presigned URL(s) for image and thumbnail (if any)
      const presignedUrlsResponse = await MediaV2ManagerAPI.getMultipleFileUploadingPresignedUrls({object_keys: objectKeys});
      if (!presignedUrlsResponse || presignedUrlsResponse.status !== 200 || presignedUrlsResponse.data.length < 1) {
        throw 'Cannot get S3 Presigned URL!';
      }
      /* Define a S3 Uploading Task List */
      const s3UploadingTasks: any[] = [];
      /* Add uploading tasks to the list */
      presignedUrlsResponse.data.forEach((presignedUrlObject: any) => {
        if (/_thumbnail.webp$/ig.test(presignedUrlObject.object_key)) {
          s3UploadingTasks.push(uploadImageToS3(presignedUrlObject.object_key, presignedUrlObject.presigned_url, thumbnailBase64, 'image/webp'));
        } else {
          s3UploadingTasks.push(uploadImageToS3(presignedUrlObject.object_key, presignedUrlObject.presigned_url, imageBase64, file.type, file.size));
        }
      });
      /* Execute this S3 Uploading Task List */
      if (s3UploadingTasks.length > 0) {
        const s3UploadingResponse = await Promise.all(s3UploadingTasks);
        const results = s3UploadingResponse.filter((response: any) => !!response);
        if (results && results.length > 0) {
          const imageUploadingResult = results.find((result) => result.s3ObjectKey === imageFullPath);
          if (imageUploadingResult) {
            /* Update Image Metadata */
            let updateInfoData = {
              uuid: selectedMedia.uuid,
              size: imageUploadingResult.newImageSize || file.size,
              // @ts-ignore: Object is possibly 'null'.
              folderId: selectedFolder?.current?.uuid,
              fullPath: selectedMedia.fullPath,
              name: selectedMedia.name,
              type: getFileType(fileExtension),
            }
            const res = await MediaV2ManagerAPI.updateFile(updateInfoData);
            if (res && res.status === 200) {
              showNotification("success", "File has been changed successfully", toast);
              // setSelectedMedia(null);
              setFileBase64(null);
              setIsShowEditor(false);
              refresh();
              setIsSidebarLoading(false);
            } else {
              showNotification("error", `Failed to save file`, toast);
              setIsSidebarLoading(false);
            }
          }
        } else {
          throw `Cannot upload ${selectedMedia.name} to S3!`;
        }
      }
    } catch (error) {
      console.log("Error: Save changes Photo ==>> ", error);
      showNotification("error", `Failed to save file`, toast);
    }
    setIsSidebarLoading(false);
  };

  const getFullPath = (selected_folder: any) => {
    if (!selected_folder?.uuid) {
      return null;
    }

    let folderId = selected_folder.uuid;
    let nameArray = [];
    let i = 0;
    do {
      i++;
      const folder = folders.find((f: any) => f.uuid === folderId);
      if (!folder) {
        break;
      }

      nameArray.unshift(folder.name);
      if (!folder.parentId) {
        break;
      }

      folderId = folder.parentId;
    } while (i < 1000)

    return nameArray.join('/');
  }

  const footer = <Button className="wi-main-button" label="Load More" onClick={() => ds?.current?.load()} />;

  const renderHeader = () => {
    return (
      <>
        <div className="row">
            {layout === "list" && (
              <>
                <div className="col-1 text-start">
                </div>
                <div className="col-4 text-start">
                  <span style={{ fontSize: "0.75rem" }}>Name</span>
                </div>
                <div className="col-2 text-start">
                  <span style={{ fontSize: "0.75rem" }}>Type</span>
                </div>
                <div className="col-2 text-start">
                  <span style={{ fontSize: "0.75rem" }}>Size</span>
                </div>
                <div className="col-1 text-start">
                  <span style={{ fontSize: "0.75rem" }}>Date</span>
                </div>
              </>
            )}
          <div className={`${layout === "list" ? 'col-2' : 'col-12'} text-end`}>
            <DataViewLayoutOptions
              layout={layout}
              onChange={(e) => setLayout(e.value)}
            />
          </div>
        </div>
      </>
    );
  };

  const header = renderHeader();

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="media-manager-container">
        <div className="headline">
          <h3>Media</h3>
        </div>
        <div className="row">
          <div className="header-content common-horizontal-layout-header">
            <WISearchField
              icon={"pi pi-search"}
              placeholder="Search for anything"
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={onSearch}
            />
            <div className="btn-create">
              <WIButton
                className='wi-main-button'
                type={"button"}
                label="Add Media"
                icon={"pi-angle-right"}
                onClick={(e: any) => onCreateMedia(e)}
                disabled={uploadActive}
              />
            </div>
          </div>
        </div>
        <Splitter>
          <SplitterPanel size={15} minSize={15} className="tree-panel">
            <Directory
              toast={toast}
              isAddFolderVisibility={true}
              isDeleteFolderVisibility={true}
              fetchMediaByFolderId={fetchMediaByFolderId}
              fetchMediaFolder={fetchMediaFolder}
              refresh={refresh}
              updateSelectedFolder={updateSelectedFolder}
              data={{
                nodes,
              }}
            />
          </SplitterPanel>
          <SplitterPanel size={85} className="file-panel-items max-height-none"> 
            {isLoading ? (
              <div className="media-loading-component">
                <ProgressSpinner/>
              </div>
            ) : 
            (<DataScroller
              ref={ds}
              value={mediaFiles.data}
              itemTemplate={itemTemplate}
              rows={40}
              inline
              header={header}
              // loader
              // footer={footer}
              className={`${layout === "grid" ? "custom-style-grid" : "custom-style-list"}`}
              emptyMessage={"No records found"}
            />)}
          </SplitterPanel>
        </Splitter>

        <div className="row">
          <Sidebar
            visible={selectedMedia && isShowCreate}
            position="right"
            onHide={() => setIsShowCreate(false)}
            modal={false}
            icons={customIcons}
          >
            {
              isSidebarLoading ? (
                <div className="loading-component sidebar-loading-component">
                  <ProgressSpinner/>
                </div>) : <></>
            }
            <MediaInfo
              data={{
                selectedMedia,
                isShowCreate,
              }}
              setIsLoading={setIsSidebarLoading}
              isLoading={isSidebarLoading}
              refresh={refresh}
              toast={toast}
            ></MediaInfo>
          </Sidebar>
          <UploadMediaDialogComponent
            visible={!selectedMedia && isShowCreate}
            refresh={refresh}
            onHide={() => setIsShowCreate(false)}
            selectedFolder={selectedFolder}
            fullPath={getFullPath(selectedFolder?.current)}
            toast={toast}
          />
          <WIConfirmDialog
            visible={isShowDeleteDialog}
            onHide={() => {
              onShowDeleteDialog(false);
              setIsShowCreate(false);
            }}
            onConfirm={onDeleteConfirm}
            maskClassName="top-mask-dialog"
            message={
              <Fragment>
                <span style={{ fontSize: '18px', fontWeight: '700' }}>Are you sure you want to delete this media?</span>
                <br />
                <span style={{ fontSize: '13px' }}>
                  All of your data will be deleted <b>permanently</b>.
                  <br />
                  You <b>cannot undo</b> this action.
                  <br />
                  <br />
                  <b>Note</b>: <br /> If this <b>media</b> is using by our website, it can cause <b>unexpected issues</b>.
                </span>
              </Fragment>
            }
            classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
          />
          <ImageEditorDialogComponent
            visible={editingPhoto && isShowEditor}
            onHide={() => setIsShowEditor(false)}
            toast={toast}
            data={editingPhoto}
            fileBase64={fileBase64}
            saveChangesPhoto={saveChangesPhoto}
          />
        </div>
      </div>
    </Layout>
  );
};

export default MediaManager;
