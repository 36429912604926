import * as React from "react";
import { ErrorMessage, useFormikContext } from "formik"; 
import { Editor } from "@tinymce/tinymce-react";
import "./create-partner-form.scss"; 
import _ from "lodash"; 
import { env } from "../../../../environment";
import {ToolbarsSingleLine, ToolbarsMultiLine, Plugins, ColorsEditor, ContentStyleForMultiLine, ContentStyleForSingleLine} from "../../../common/header-editor/header-editor"

export function RichTextEditor(props: any) {
  const { name, header, isMultipleLine, isRequired = false } = props;
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const onRichTextKeyDown = (e: any, isMultipleLine: boolean) => {
    if(e.key === 'Enter' && isMultipleLine === false)
    {
      e.preventDefault();
    }
  };

  return(
    <div>
      <div className="element-form mb-3">
          <label className="label">
            {header} {isRequired ? <span className="required-label">*</span> : ""}
          </label>
           <Editor
            scriptLoading={{ async: true }}
            apiKey={env.EDITOR_API_KEY}
            init={{
              min_height: isMultipleLine ? 300 : 120,
              max_height: isMultipleLine ? 300 : 120, 
              min_width: 500,
              menubar: false, 
              plugins: Plugins,
              toolbar: isMultipleLine ? ToolbarsMultiLine : ToolbarsSingleLine,
              color_map: ColorsEditor,
              custom_colors: false,
              toolbar_mode: "sliding",
              branding: false,
              paste_as_text: true,
              content_style: isMultipleLine ? ContentStyleForMultiLine : ContentStyleForSingleLine,
            }}
            
            onKeyDown={(e) => { onRichTextKeyDown(e, isMultipleLine)}}
            id={name}
            textareaName={name}
            onEditorChange={(newValue, editor) => {
              setFieldValue(name, newValue, true);
            }}
        onBlur={() => setFieldTouched(name)}
        value={_.get(values, name)}
        />
          <ErrorMessage
            name={name}
            render={(msg: any) => <div className="error">{msg}</div>}
          />
        </div>
    </div>)
};