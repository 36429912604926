import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import Directory from "../../global-contents/media-manager/components/directory/directory";
import "./wi-upload-media-dialog.scss";
import { WIButton, WISearchField } from "../../common";
import { FIELDS_SEARCH, removeEmpty } from "../../../utils/utils";
import { MediaFolderManagerAPI, MediaV2ManagerAPI } from "../../../services";
import _ from "lodash";
import CardItem from "../../global-contents/media-manager/components/card-item/card-item";
import { showNotification } from "../../../utils/logic";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { getFolderByFolderParentId,
  getBase64,
  getFileExtension,
  getImageDimensions,
  IconConfig,
  IMAGE_EXTENSIONS,
  isFileOverSize,
  isFileTypeNotAllowed,
  parseImageMetadata,
  getFileType,
  uploadPhysicalFileToS3, 
  getCompressedImage,
  getCompressedThumbnail,
  canBeThumbnail,
  getThumbnailFullpath,
  PNG_MAZ_SIZE} from "../../../utils/mediaUtils";
import { createEmptyMediaV2, } from "../../global-contents/media-manager/components/upload-media-dialog/mediaType";
import { UploadMediaDialogComponent } from "../../global-contents/media-manager/components/upload-media-dialog/upload-media-dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataScroller } from "primereact/datascroller";
import { DataViewLayoutOptions } from "primereact/dataview";
import { Button } from "primereact/button";
import ListItem from "../../global-contents/media-manager/components/list-item/list-item";

const InsertMediaDialogComponent = (props: any) => {
  const { handleFileValue, visible, onHide, fileType} = props;
  const toast = useRef(null);
  const [selectedMedia, setSelectedMedia] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowCreate, setIsShowCreate] = useState(false);
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [uploadActive, setUploadActive] = useState(true);
  const selectedFolder = useRef(null);
  const [mediaFiles, setMediaFiles] = useState({
    data: [],
    totalPage: 0,
  });
  const [search, setSearch] = useState<any>(null);
  const [nodes, setNodes] = useState<any>(null);
  const rootFolderGuid = '00000000-0000-0000-0000-000000000000';
  const [folders, setFolders] = useState<any>(null);
  const [layout, setLayout] = useState("grid");
  const ds = useRef<any>(null);

  const onCreateMedia = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsShowCreate(true);
    setSelectedMedia(null);
  };

  const onSaveFile = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    handleFileValue(selectedMedia);
  };

  const areValidatedFiles = (chosenFiles: any) => {
    const foundFileOverSizeLimit = chosenFiles.some(isFileOverSize);
    if (foundFileOverSizeLimit) {
      window.alert("File size has exceeded it max limit of 25MB");
      return false;
    }

    const hasFileIsNotAllowed = chosenFiles.some(isFileTypeNotAllowed);

    if (hasFileIsNotAllowed) {
      window.alert(
        "The system accepts jpg, jpeg, png, svg, doc, docx, xls, xlsx, pdf, txt, 7z, rar, zip, avi, mov, and mp4 file types"
      );
      return false;
    }

    return true;
  };

  const getFullPath = (selected_folder: any) => {
    if(!selected_folder?.uuid) {
      return null;
    }
    
    let folderId = selected_folder.uuid;
    let nameArray = [];
    let i = 0;
    do {
      i++;
      const folder = folders.find((f: any) => f.uuid === folderId);
      if(!folder) {
        break;
      }

      nameArray.unshift(folder.name);
      if(!folder.parentId) {
        break;
      }

      folderId = folder.parentId;
    } while (i < 1000)
    
    return nameArray.join('/');
  }
  
  // Operator Drag Drop Image into Popup
  const onSubmitFileData = async (files: any) => {
    try {
      setIsLoading(true);
      const fullPath = getFullPath(selectedFolder?.current);
      /* Create a list of file metadata objects */
      let newMedia = createEmptyMediaV2();
      const fileInfoDataList = files?.map((mediaFile: any) => {
        const metaInfo = _.cloneDeep(newMedia.metadata);
        const finalMetadata = {...metaInfo, ...mediaFile.metadata};
        newMedia.name = mediaFile.name;
        newMedia.fullPath = fullPath ? `${fullPath}/${mediaFile.name}` : mediaFile.name;
        newMedia.type = getFileType(mediaFile.extension);
        newMedia.size = mediaFile.size;
        newMedia.extension = mediaFile.extension;
         //@ts-ignore
        newMedia.folderId = selectedFolder?.current?.uuid;
        newMedia.metadata = finalMetadata;
        
        let fileInfo: any = {
          name: newMedia.name,
          originalName: newMedia.name,
          description: JSON.stringify(newMedia.description),
          folderId: newMedia.folderId,
          fullPath: newMedia.fullPath,
          alternativeText: JSON.stringify(newMedia.alternativeText),
          metadata: JSON.stringify(newMedia.metadata),
          size: newMedia.size,
          type: newMedia.type,
          title: JSON.stringify(newMedia.title)
        }
        // Create a link between this "fileInfo" and "media file"
        const relationalKey = Math.random();
        mediaFile.relationalKey = relationalKey;
        fileInfo.relationalKey = relationalKey;
        return fileInfo;
      });
      /* Stop here if there is no file */
      if (!fileInfoDataList || fileInfoDataList.length < 1) {
        setIsLoading(false);
        return;
      }
      /* Save the list of file metadata objects to the app in order to get the adjusted file names (if duplicate) */
      for (let i = 0; i < fileInfoDataList.length; i++) {
        const fileCreatingResponse = await MediaV2ManagerAPI.createFile(fileInfoDataList[i]);
        if (fileCreatingResponse?.status === 200 && fileCreatingResponse.data?.file) {
          fileInfoDataList[i].uuid = fileCreatingResponse.data.file.uuid;
          fileInfoDataList[i].name = fileCreatingResponse.data.file.name;
          fileInfoDataList[i].fullPath = fullPath ? `media/${fullPath}/${fileInfoDataList[i].name}` : `media/${fileInfoDataList[i].name}`;
          fileInfoDataList[i].hasError = false;
          if (canBeThumbnail(fileInfoDataList[i].fullPath)) {
            fileInfoDataList[i].thumbnailFullPath = getThumbnailFullpath(fileInfoDataList[i].fullPath);
          }
        } else {
          fileInfoDataList[i].hasError = true;
        }
      }
      /* If there is no any valid file for being uploaded to S3 storage -> stop here */
      const hasValidFile: boolean = fileInfoDataList.some((f: any) => f.hasError === false);
      if (!hasValidFile) {
        setIsLoading(false);
        return;
      }
      /* Get S3 Uploading Presigned URLs for files */
      const imageObjectKeys: string[] = fileInfoDataList.filter((item: any) => item.fullPath !== null).map((item: any) => item.fullPath);
      const thumbnailObjectKeys: string[] = fileInfoDataList.filter((item: any) => item.thumbnailFullPath !== null).map((item: any) => item.thumbnailFullPath);
      const presignedUrlsResponse = await MediaV2ManagerAPI.getMultipleFileUploadingPresignedUrls({object_keys: [...imageObjectKeys, ...thumbnailObjectKeys]});
      if (!presignedUrlsResponse || presignedUrlsResponse.status !== 200 || presignedUrlsResponse.data.length < 1) {
        setIsLoading(false);
        return;
      }
      /* Define a Task (Promise) of uploading the image to S3 storage */
      const uploadImageToS3 = async (s3PresignedUrl: string, fileInfo: any, mediaFile: any) => {
        try {
          const newSize = await uploadPhysicalFileToS3(s3PresignedUrl, mediaFile.url, mediaFile.type, mediaFile.size > PNG_MAZ_SIZE);
          if (newSize != fileInfo.size) {
            // Just update new Size, so don't wait for invalidating image's CDN cache
            MediaV2ManagerAPI.updateFile({ ...fileInfo, size: newSize, fullPath: fullPath ? `${fullPath}/${fileInfo.name}` : fileInfo.name }, false);
          }
          return { fileInfo, isSuccessful: true };
        } catch(ex) {
          console.log(`Cannot upload media file of '${fileInfo.fullPath}'`);
          return { fileInfo, isSuccessful: false };
        }
      }
      /* Define another Task (Promise) of uploading that image's thumbnail (if any) to S3 storage */
      const uploadThumbnailToS3 = async (s3PresignedUrl: string, fileInfo: any, mediaFile: any) => {
        try {
          await uploadPhysicalFileToS3(s3PresignedUrl, mediaFile.thumbnailBase64, 'image/webp');
        } catch(ex) {
          console.log(`Cannot upload thumbnail of '${fileInfo.thumbnailFullPath}'`);
        }
        return null;
      }
      /* Define a S3 Uploading Task List */
      const s3UploadingTasks: any[] = [];
      /* Add uploading tasks to the list */
      presignedUrlsResponse.data.forEach((presignedUrlObject: any) => {
        // Add a task of uploading an image to the list
        const fileInfo = fileInfoDataList.find((f: any) => f.fullPath === presignedUrlObject.object_key);
        if (!fileInfo) return null;
        const mediaFile = files?.find((mediaFile: any) => mediaFile.relationalKey === fileInfo.relationalKey);
        if (!mediaFile) return null;
        s3UploadingTasks.push(uploadImageToS3(presignedUrlObject.presigned_url, fileInfo, mediaFile));
        // Add a task of uploading its thumbnail to the list (if any)
        if (fileInfo.thumbnailFullPath) {
          const thumbnailPresignedUrlResponse = presignedUrlsResponse.data.find((presignedUrlObject: any) => presignedUrlObject.object_key === fileInfo.thumbnailFullPath);
          if (thumbnailPresignedUrlResponse) {
            s3UploadingTasks.push(uploadThumbnailToS3(thumbnailPresignedUrlResponse.presigned_url, fileInfo, mediaFile));
          }
        }
      });
      /* Execute the Uploading Task List */
      if (s3UploadingTasks.length > 0) {
        const s3UploadingResponse = await Promise.all(s3UploadingTasks);
        const results = s3UploadingResponse.filter((response: any) => !!response);
        if (results && results.length > 0) {
          results.forEach((result: any) => {
            if (result.isSuccessful) {
              showNotification("success", `${result.fileInfo.name} file uploaded successfully`, toast);          
            } else {
              showNotification("error", `Failed to upload ${result.fileInfo.name} file`, toast);
            }
          });
          refresh();
        }
      }
      setIsLoading(false);
    } catch (error) {
      showNotification("error", "Failed to upload file", toast);
    }
  };

  const handleUploadFiles = async (files: any) => {
    const imagesArray = Array.from(files).map(async (uFile: any) => {
      let f = uFile;
      const fileExtension = getFileExtension(f.name).toLowerCase();
      const isImage = IMAGE_EXTENSIONS.some((item) => item === fileExtension) && (fileExtension !== 'svg' && fileExtension !== 'png');
      let thumbnailBase64 = null;
      let fileMetaInfo;
      if (IMAGE_EXTENSIONS.some((item) => item === fileExtension)) {
        fileMetaInfo = await parseImageMetadata(f);
      }
      if (isImage) {
        f = await getCompressedImage(uFile);
        const thumbnailFile = await getCompressedThumbnail(f);
        thumbnailBase64 = await getBase64(thumbnailFile);
      }
      const fileBase64: any = await getBase64(f);
      if (IMAGE_EXTENSIONS.some((item) => item === fileExtension)) {
        const description = { de: "", en: "" };
        const dimensions = await getImageDimensions(fileBase64);
        fileMetaInfo = { description, ...fileMetaInfo, dimensions };
      }
      const fileIcon = IconConfig[fileExtension] || "";

      return {
        url: fileBase64,
        icon: fileIcon,
        name: f.name,
        size: f.size,
        type: f.type,
        extension: fileExtension,
        metadata: fileMetaInfo || {},
        thumbnailBase64: thumbnailBase64
      };
    });

    let updatedList;
    await Promise.all(imagesArray).then((results: any) => {
      updatedList = [...results];
    });
    await onSubmitFileData(updatedList);
  };

  const handleDrop = (e: any) => {
    if (!selectedFolder.current) {
      return;
    }

    e.preventDefault(); 
    const chosenFiles = Array.prototype.slice.call(e.dataTransfer.files);
    setDropzoneActive(false);
    if (areValidatedFiles(chosenFiles)) {
      handleUploadFiles(chosenFiles);
    } else {
      e.dataTransfer.value = null;
    }
  }

  const onSearch = () => {
    setIsLoading(true);
    refresh();
  };
 
  const onSetSelectedItem = (selectedItem: any) => {
    setSelectedMedia(selectedItem);
  }

  const handleDoubleClick = (e: any) => {
    onSaveFile(e);
  }

  const itemTemplate = (file: any) => {
    if (layout === "list")
      return <ListItem mediaFile={file} 
              isInsertMediaPopup={true}
              selectedMedia={selectedMedia}
              onSetSelectedItem={onSetSelectedItem}
              handleDoubleClick={handleDoubleClick}/>;
    else if (layout === "grid")
      return <CardItem mediaFile={file} 
              isInsertMediaPopup={true} 
              selectedMedia={selectedMedia}
              onSetSelectedItem={onSetSelectedItem}
              handleDoubleClick={handleDoubleClick}/>;
  };

  const fetchMediaByFolderId = async (folder_id: any, valueSearch?: string) => {
    setIsLoading(true);
    
    const data = removeEmpty({
      file_type: ['image', 'video'].includes(fileType?.toLowerCase()) ? fileType : undefined,
      search_text: valueSearch || undefined,
      search_fields: valueSearch  ? FIELDS_SEARCH.MEDIA : undefined,
    });

    try {
      const res = await MediaV2ManagerAPI.getFilesInsideFolder(folder_id, data);
      if (res && res.status === 200) {
        setMediaFiles({
          // @ts-ignore
          data: [...(res.data.files || []), ..._.fill(Array(6), {})], //mediaFiles.slice(firstItem,lastItem),
          totalPage: res.data.total || 0, //mediaFiles.length || 0,
        });
        setIsLoading(false);
      }
      return [];
    } catch (error) {
      setMediaFiles({
        data: [],
        totalPage: 0,
      });
    }

    setIsLoading(false);
  };

  const refresh = () => {
    // @ts-ignore: Object is possibly 'null'.
    if (selectedFolder.current && selectedFolder.current.data !== "Root") {
      //fetchMediaByFolderId(selectedFolder.current);
      const node: any = selectedFolder.current;
      fetchMediaByFolderId(node.uuid, search);
    } else {
      //fetchMediaData();
      fetchMediaByFolderId(rootFolderGuid, search);
    }
  }
  
  const fetchMediaFolder = async () => {
    try {
      const res = await MediaFolderManagerAPI.getAllV2();
      if (res && res.status === 200) {
        const folderData = res.data.records || [];
        setFolders(folderData);
        const folderTree = getFolderByFolderParentId(null, folderData);
        setNodes(folderTree);
        return;
      }
      setNodes([]);
    } catch (error) {}
  };

  useEffect(() => {
    if (visible) {
      //fetchMediaFolder();
      //fetchMediaData();
      fetchMediaFolder().then(() => refresh());
    }
    else{
      selectedFolder.current = null;
      setSearch(null);
      setUploadActive(true);
      setDropzoneActive(false);
    }
  }, [visible]);

  const updateSelectedFolder = (folder: any) => {
    selectedFolder.current = folder?.node || null;
    // @ts-ignore: Object is possibly 'null'.
    if (!selectedFolder.current || selectedFolder.current?.data === "Root") {
      setUploadActive(true);
      setDropzoneActive(false);
    }
    else setUploadActive(false);

    setIsShowCreate(false);
    setSelectedMedia(null);
  }

  const footer = <Button className="wi-main-button" label="Load More" onClick={() => ds?.current?.load()} />;

  const renderHeader = () => {
    return (
      <>
        <div className="grid grid-nogutter">
          <div className="col-10">
            {layout === "list" && (
              <div className="row">
                <div className="col-6 text-start">
                  <span style={{ fontSize: "0.75rem" }}>Name</span>
                </div>
                <div className="col-2 text-start">
                  <span style={{ fontSize: "0.75rem" }}>Type</span>
                </div>
                <div className="col-2 text-center">
                  <span style={{ fontSize: "0.75rem" }}>Size</span>
                </div>
                <div className="col-2 text-center">
                  <span style={{ fontSize: "0.75rem" }}>Date</span>
                </div>
              </div>
            )}
          </div>
          <div className="col-2" style={{ textAlign: "right" }}>
            <DataViewLayoutOptions
              layout={layout}
              onChange={(e) => setLayout(e.value)}
            />
          </div>
        </div>
      </>
    );
  };

  const header = renderHeader();

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Insert media"
        maskClassName="top-mask-dialog"
        visible={visible}
        style={{ width: "1630px", height: "92vh", maxHeight: "92vh" }}
        modal
        onHide={onHide}
      >
        <div className="media-container-dialog">
          <div>
            <div className="header-content common-horizontal-layout-header">
              <WISearchField
                icon={"pi pi-search"}
                placeholder="Search for anything"
                setSearch={(value: any) => setSearch(value.global.value)}
                enterSearch={onSearch}
              />
              <div className="certificate">
                <div className="btn-upload">
                  <WIButton 
                  className='wi-main-button'
                  primary={true}
                  type={"button"}
                  size={"small"}
                  label="Upload"
                  icon={"pi-angle-right"}
                  onClick={(e: any) => onCreateMedia(e)}
                  disabled={uploadActive}
                  />
                </div>
                <div className="btn-create">
                  <WIButton  
                    className='wi-main-button'
                    primary={true}
                    type={"button"}
                    size={"small"}
                    label="Insert"
                    icon={"pi-angle-right"}
                    disabled={!selectedMedia?.uuid}
                    onClick={(e: any) => onSaveFile(e)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
          <Splitter>
            <SplitterPanel size={15} minSize={15} className="tree-panel">
              <Directory
                isAllNodes = {!selectedFolder.current}
                isAddFolderVisibility={true}
                isDeleteFolderVisibility={true}
                toast={toast}
                fetchMediaByFolderId={fetchMediaByFolderId}
                refresh={refresh}
                fetchMediaFolder={fetchMediaFolder}
                updateSelectedFolder={updateSelectedFolder}
                data={{
                  nodes,
                }}
              />
            </SplitterPanel>
            <SplitterPanel  size={85} className="file-panel-items max-height-none"> 
              {isLoading ? (
                <div className="media-loading-component">
                  <ProgressSpinner/>
                </div>
              ) : 
              (<DataScroller
                  ref={ds}
                  value={mediaFiles.data}
                  itemTemplate={itemTemplate}
                  rows={40}
                  inline
                  header={header}
                  className={`${layout === "grid" ? `custom-style-grid` : "custom-style-list"} ${dropzoneActive && selectedFolder.current ? "active" : ""}`}
                  emptyMessage={"No records found"}
                  //@ts-ignore
                  onDragOver={(e: any) => {
                    setDropzoneActive(true);
                    e.preventDefault();
                  }}
                  onDragLeave={(e: any) => {
                    setDropzoneActive(false);
                    e.preventDefault();
                  }}
                  onDrop={(e: any) => handleDrop(e)}
                  />
                )}
          </SplitterPanel>
          </Splitter>
          <UploadMediaDialogComponent 
            visible={!selectedMedia && isShowCreate}
            fullPath={getFullPath(selectedFolder?.current)}
            refresh={refresh}
            onHide={() => setIsShowCreate(false)}
            selectedFolder={selectedFolder}
            toast={toast}/>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default InsertMediaDialogComponent;