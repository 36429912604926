import React from "react";
import { useState, useRef, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { TabView, TabPanel } from 'primereact/tabview';
import { Formik, Field, useFormikContext } from "formik";
import "./cms-page-info-form.scss";
import { createEmptyPage, PageType } from "./pageType";
import * as Yup from "yup";
import { PAGE_LANGUAGE, PAGE_STATUS } from "../../utils";
import { showNotification, validateCheckSpace } from "../../../../utils/logic";
import CMsPageManagerAPI from "../../../../services/cmsPageManager";
import CheckboxSvg from "../../../common/CheckboxSvg";
import { useNavigate } from "react-router-dom";
import { SelectButton } from "primereact/selectbutton";
import { MultiSelect } from "primereact/multiselect";
import flag from '../../../../images/flag/flag_placeholder.png';
import { Accordion, AccordionTab } from "primereact/accordion";
import _ from "lodash";
import { env } from "../../../../environment";
import { findType, getParentURL } from "../../builder-page.common";
import WIUploadMediaField from "../../../common/upload/wi-upload-media-field";
import { generateUrl } from "../../../common/useUrlConversion";
import { Yes_NO } from "../../../../utils/utils";

const CMSPageInfoFormComponent = (props: any) => {
  const { isShowCreate, toast, onCreatedPage, masterData, onChangeForm, isDisabled } = props;

  const formikRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCustomHeader, setIsCustomHeader] = useState(false);
  const navigate = useNavigate();
  const [pageTypes, setPageTypes] = useState<any>([]);
  const [headers, setHeaders] = useState<any>([]);
  const [footers, setFooters] = useState<any>([]);
  const [parentPages, setParentPages] = useState<any>([]);
  const [listURLs, setlistURLs] = useState<any>([]);
  const [prefixURL, setPrefixURL] = useState<any>('');
  const [isURLDisabled, setIsURLDisabled] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    dirty,
    isValid,
    setFieldValue,
    setFieldTouched,
    validateField
  } = useFormikContext<PageType>();

  const setupMasterData = async () => {
    if (masterData.pageTypes) {
      const items = masterData.pageTypes
        // .filter((t: any) => t.code !== 'master')
        ?.sort((a: any, b: any) => (a.order > b.order) ? 1 : -1)
        .map((f: any) => ({
          id: f.uuid,
          name: f.name,
          special_page_type: f.special_page_type
        }));
      setPageTypes(items);
    }

    if (masterData.headers) {
      const items = masterData.headers.sort((a: any, b: any) => (a.is_primary < b.is_primary) ? 1 : -1).map((f: any) => ({ id: f.uuid, name: f.name.de.replace('Header', '') }));
      setHeaders(items);
    }

    if (masterData.footers) {
      const items = masterData.footers.sort((a: any, b: any) => (a.is_primary < b.is_primary) ? 1 : -1).map((f: any) => ({ id: f.uuid, name: f.name.de.replace('Footer', '') }));
      setFooters(items);
    }

    if (masterData.parentPages) {
      parseURL(values.page_id, masterData.parentPages);
      const options = _.sortBy(masterData.parentPages, 'name');

      const defaultRootIndex = options.findIndex((o: any) => !o.page_id && !o.url);
      if (defaultRootIndex > 0) {
        const defaultRoot = options[defaultRootIndex];
        options.splice(defaultRootIndex, 1);
        options.unshift(defaultRoot);
      } else if (defaultRootIndex !== 0) {
        options.unshift({ id: '', name: "None" });
      }

      setParentPages(options);
    }

    if (masterData.listURLs) {
      setlistURLs(masterData.listURLs);
    }
  };

  const buildPageTemplate = (option: any) => {
    return <div>
      <div>{option?.name}</div>
      <div className="page-item">/{option?.nodes?.map((n: any) => n.url).filter((u: string) => !!u).join('/')}</div>
    </div>
  }

  const parseURL = (page_id: string, pages: any[]) => {
    setPrefixURL(getParentURL(page_id, pages));
  }

  useEffect(() => {
    setIsURLDisabled(!isShowCreate || isDisabled);
    setupMasterData();
  }, [masterData]);

  useEffect(() => {
    validateField('url');
  }, [prefixURL]);

  useEffect(() => {
    if (values && dirty) {
      onChangeForm();
    }
  }, [values]);

  const languageTemplate = (option: any) => {
    return (
      <div className="country-item">
        <img alt={option?.name} src={flag} className={`flag flag-${option?.code.toLowerCase()}`} />
        <div className="language">{option?.name}</div>
      </div>
    );
  }

  const selectedLanguageTemplate = (option: any) => {
    if (typeof option === 'string') {
      option = PAGE_LANGUAGE.find(l => l.code?.toLowerCase() === option.toLowerCase())
    }

    if (option) {
      return (
        <div className="country-item country-item-value">
          <img alt={option?.name} src={flag} className={`flag flag-${option?.code?.toLowerCase()}`} />
        </div>
      );
    }

    return [];
  }

  const isDisabledParentPage = () => {
    return (values as any).special_type || pageTypes.find((p: any) => p.id === values?.type_id)?.special_page_type || isDisabled || !isShowCreate;
  }

  return (
    <div className="page-form">
      <div className="page-form-container">
        <form onSubmit={handleSubmit}>
          <Accordion activeIndex={0}>
            <AccordionTab header="Settings">
              <div className="row row-content">
                <div className="col-12 col-sm-6 col-md-12">
                  <div className="element-form">
                    <label className="label">
                      Type
                      <span className="required-label"> *</span>
                    </label>
                    <Dropdown
                      disabled={!isShowCreate || isDisabled}
                      name="type_id"
                      id="dropdown_type"
                      value={values.type_id}
                      options={pageTypes}
                      optionLabel="name.de"
                      optionValue="id"
                      appendTo="self"
                      className={`${touched.type_id && errors.type_id ? "has-error" : ""
                        }`}
                      onChange={(e: any) => {
                        const type = pageTypes.find((p: any) => p.id === e.value);
                        const findParentPage = (str: string) => parentPages.find((p: any) => p.special_type === str);
                        if (type.special_page_type) {
                          setFieldValue('page_id', findParentPage(type.special_page_type)?.id);
                        }

                        handleChange(e);
                      }}
                    />
                    <div
                      className={`${touched.type_id && errors.type_id ? "error" : ""
                        }`}
                    >
                      {touched.type_id && errors.type_id}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-12">
                  <div className="element-form">
                    <label className="label">
                      Parent Page
                      <span className="required-label"> *</span>
                    </label>
                    <Dropdown
                      name="page_id"
                      id="dropdown_type"
                      disabled={isDisabledParentPage()}
                      value={values.page_id}
                      options={parentPages}
                      optionLabel="name"
                      itemTemplate={buildPageTemplate}
                      optionValue="id"
                      appendTo="self"
                      className={`${touched.page_id && errors.page_id ? "has-error" : ""
                        }`}
                      onChange={handleChange}
                      filter
                      filterBy={'name'}
                    />
                    <div
                      className={`${touched.page_id && errors.page_id ? "error" : ""
                        }`}
                    >
                      {touched.page_id && errors.page_id}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12">
                  <div className="element-form">
                    <label className="label">
                      Page Name <span className="required-label"> *</span>
                    </label>
                    <input
                      disabled={isDisabled}
                      type="text"
                      name="name"
                      value={values.name}
                      className={`${touched.name && errors.name ? "has-error" : ""
                        }`}
                      onChange={(e) => {
                        if (!isURLDisabled) {
                          setFieldValue('url', generateUrl(e.target.value))
                          setFieldTouched('url');
                        }

                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    <div className="helper-text">
                      <i className="fa-solid fa-circle-info"></i> The name in our system
                    </div>
                    {touched.name && errors.name && <div
                      className={`${touched.name && errors.name ? "error-text" : ""
                        }`}
                    >
                      <i className="fa-solid fa-circle-info"></i> {touched.name && errors.name}
                    </div>}
                  </div>
                </div>
                <div className="col-12 col-md-12">
                  <div className="element-form">
                    <label className="label">
                      Permanent URL <span className="required-label"> *</span>
                    </label>
                    <div className={`p-inputgroup url-group url-group-${values.status}`}>
                      <span className="p-inputgroup-addon">{prefixURL}</span>
                      <Field
                        disabled={isURLDisabled}
                        className={`${touched.url && errors.url ? "has-error" : `field-disabled-url-${isURLDisabled}`
                          }`}
                        name="url"
                        validate={((value: any) => validateCheckSpace(value, getParentURL(values.page_id, parentPages), listURLs))}
                        onChange={(e: any) => {
                          setFieldValue('url', generateUrl(e.target.value));
                        }}
                        onBlur={handleBlur}
                      />
                      <a className="p-inputgroup-addon"
                        // href={values.status === 'published' ? [env.CMS_URL, prefixURL, values.url].join('/') : '#'} 
                        // Multilang
                        // href={env.CMS_URL + '/'+ ['de', prefixURL || '', values.url || ''].filter(c => !!c).join('/').replaceAll('//', '/')}
                        href={env.CMS_URL + [prefixURL || '', values.url || ''].filter(c => !!c).join('/').replaceAll('//', '/')}
                        target="_blank">
                        <i className={`fa-solid ${values.status === 'published' ? 'fa-eye' : 'fa-eye-slash'}`} />
                      </a>
                    </div>

                    <div className="url-note">
                      {!isURLDisabled && "Please make sure the URL is as expected, it cannot be changed later"}
                    </div>
                    
                    {touched.url && errors.url && 
                    <div className={`${touched.url && errors.url ? "error-text " : ""}`}>
                      <i className="fa-solid fa-circle-info"></i>  {touched.url && errors.url}
                    </div>}
                  </div>
                </div>
              </div>
              <div className="row row-content">
                <div className="col-12 col-md-12">
                  <div className="element-form">
                    <label className="label">
                      Page Title <span className="required-label"> *</span>
                    </label>
                    <input
                      disabled={isDisabled}
                      type="text"
                      name="title.de"
                      value={values.title?.de}
                      className={`${touched.title?.de && errors.title?.de ? "has-error" : ""
                        }`}
                      onChange={(e) => {
                        setFieldValue('og_seo_config.seo_title.de', e.target.value);
                        setFieldTouched('og_seo_config.seo_title.de');
                        setFieldValue('title.en', e.target.value);
                        setFieldTouched('title.en');
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    <div className="helper-text">
                      <i className="fa-solid fa-circle-info"></i> This title people will see on the tab of the browser
                    </div>
                    {touched.name && errors.name && <div
                      className={`${touched.title?.de && errors.title?.de ? "error-text " : ""
                        }`}
                    >
                      <i className="fa-solid fa-circle-info"></i> {touched.title?.de && errors.title?.de}
                    </div>}
                  </div>
                </div>
                {/* <div className="col-12 col-md-12">
                  <div className="element-form">
                    <label className="label">
                      Page Title EN <span className="required-label">*</span>
                    </label>
                    <input
                      type="text"
                      name="title.en"
                      value={values.title?.en}
                      className={`${touched.title?.en && errors.title?.en ? "has-error" : ""
                        }`}
                      onChange={(e)=> {
                        setFieldValue('og_seo_config.seo_title.en', e.target.value);
                        setFieldTouched('og_seo_config.seo_title.en');
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    <div
                      className={`${touched.title?.en && errors.title?.en ? "error" : ""
                        }`}
                    >
                      {touched.title?.en && errors.title?.en}
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row row-content">

                {/* <div className="col-12 col-sm-6 col-md-12">
                  <div className="element-form">
                    <label className="label">
                      Language <span className="required-label">*</span>
                    </label>
                    <MultiSelect
                      value={values.language}
                      options={PAGE_LANGUAGE}
                      className={`${touched.language && errors.language ? "has-error" : ""
                        } multiselect-custom`}
                      onChange={(item: any) => {
                        if (item.value) {
                          setFieldValue('language', item.value, true);
                        }
                      }}
                      optionLabel="name"
                      optionValue="code"
                      itemTemplate={languageTemplate}
                      selectedItemTemplate={selectedLanguageTemplate}
                      selectAll={values.language[0] === 'both'}
                    />
                    <div
                      className={`${touched.language && errors.language ? "error" : ""
                        }`}
                    >
                      {touched.language && errors.language}
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-12 col-sm-6 col-md-12">
                  <div className="element-form">
                    <label className="label">
                      Status
                      <span className="required-label">*</span>
                    </label>
                    <Dropdown
                      name="status"
                      id="dropdown_status"
                      value={values.status}
                      options={PAGE_STATUS}
                      optionLabel="name"
                      optionValue="code"
                      appendTo="self"
                      className={`${touched.status && errors.status ? "has-error" : ""
                        }`}
                      onChange={handleChange}
                    />
                    <div
                      className={`${touched.status && errors.status ? "error" : ""
                        }`}
                    >
                      {touched.status && errors.status}
                    </div>
                  </div>
                </div> */}
                <div className="col-12 col-sm-6 col-md-6">
                  <div className="element-form">
                    <label className="label">
                      Header
                      <span className="required-label"> *</span>
                    </label>
                    <SelectButton
                      className="donation-checkbox wi-selectbutton"
                      id="is_prepaid"
                      name="is_prepaid"
                      options={headers}
                      optionLabel="name"
                      optionValue="id"
                      onChange={(item: any) => {
                        if (item.value) {
                          setFieldValue('header_id', item.value, true);
                        }
                      }}
                      value={values.header_id}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6">
                  <div className="element-form">
                    <label className="label">
                      Footer
                      <span className="required-label"> *</span>
                    </label>
                    <SelectButton
                      className="donation-checkbox wi-selectbutton"
                      id="is_prepaid"
                      name="is_prepaid"
                      options={footers}
                      optionLabel="name"
                      optionValue="id"
                      onChange={(item: any) => {
                        if (item.value) {
                          setFieldValue('footer_id', item.value, true);
                        }
                      }}
                      value={values.footer_id}
                    />
                  </div>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="SEO">
              <div className="row row-content">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="element-form">
                    <label className="label">Title</label>
                    <input
                      disabled={isDisabled}
                      type="text"
                      name="og_seo_config.seo_title.de"
                      value={values.og_seo_config.seo_title.de}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="helper-text">
                      <i className="fa-solid fa-circle-info"></i> This title should be between 30-60 characters, with a maximum of 90
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 col-sm-12 col-md-12">
                  <div className="element-form">
                    <label className="label">Title EN</label>
                    <input
                      type="text"
                      name="og_seo_config.seo_title.en"
                      value={values.og_seo_config.seo_title.en}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div> */}
              </div>
              <div className="row row-content">
                <div className="col-md-12">
                  <div className="element-form">
                    <label className="label">SEO Description</label>
                    <textarea
                      disabled={isDisabled}
                      name="og_seo_config.seo_description.de"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.og_seo_config.seo_description.de}
                      rows={3}
                    />
                    <div className="helper-text">
                      <i className="fa-solid fa-circle-info"></i> Recommend to make sure the important part of description is within the first 110 characters, so it doesn't get cut off on mobile
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row row-content">
                <div className="col-md-12">
                  <div className="element-form">
                    <label className="label">SEO Description EN</label>
                    <textarea
                      name="og_seo_config.seo_description.en"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.og_seo_config.seo_description.en}
                      rows={3}
                    />
                  </div>
                </div>
              </div> */}
              <div className="row row-content">
                <div className="col-md-12">
                  <div className="element-form">
                    <label className="label">Seo Image</label>
                    <WIUploadMediaField
                      disabled={isDisabled}
                      type="file"
                      name="og_seo_config.seo_image"
                      label="SEO image"
                      required={false}
                      hideLabel={true}
                    />
                    <div className="helper-text">
                      <i className="fa-solid fa-circle-info"></i> The recommended image ratio is 1.91:1. The optimal size would be 1200 x 630
                    </div>

                  </div>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Advanced Settings">
              <div className="row row-content">
                <div className="col-md-12">
                  <div className="element-form">
                    <label className="label">
                      Allow search engines to show this Page in search results?
                    </label>
                    <SelectButton
                      className="search-checkbox wi-selectbutton"
                      id="allow_search"
                      name="allow_search"
                      options={Yes_NO}
                      optionLabel="name"
                      optionValue="code"
                      onChange={(item: any) => {
                        if (item.value === 1 || item.value === 0) {
                          setFieldValue('allow_search', item.value, true);
                        }
                      }}
                      value={values.allow_search}
                    />
                  </div>
                </div>
              </div>
              <div className="row row-content">
                <div className="col-md-12">
                  <div className="element-form">
                    <label className="label">Addtional Javascript Libraries</label>
                    <textarea
                      name="addtional_js_libs"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.addtional_js_libs}
                      rows={3}
                    />
                  </div>
                </div>
              </div>
              <div className="row row-content">
                <div className="col-md-12">
                  <div className="element-form">
                    <label className="label">Addtional CSS Libraries</label>
                    <textarea
                      name="addtional_css_libs"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.addtional_css_libs}
                      rows={3}
                    />
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </form>
      </div>
    </div >
  );
};

export default CMSPageInfoFormComponent;
