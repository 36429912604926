import * as React from "react"
import { useEffect, useRef, useState, Fragment } from "react";
import './receipt-manager.scss';
import Layout from '../layout/layout';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import { ReceiptsAPI } from '../../services';
import { Toast } from 'primereact/toast';
import { useSearchParams } from 'react-router-dom';
import { showNotification } from "../../utils/logic";
import { MenuDot, WIPaginator, WISearchField, WIConfirmDialog } from "../common";
import {
    buildDefaultBodyTemplate,
    buildCountryBodyTemplate,
    formatDateByFormatBodyTemplate
} from "../common/column-template-table/column-template";
import _ from "lodash";
import { Button } from "primereact/button";
import { getReceiptObjectSearchValue, getReceiptObjectWithValues, getReceiptPayloadConditions } from "./receipt-manager-util";
import CreateReceiptDialogComponent from "./components/create-receipt-dialog/create-receipt-dialog";
import CountryService from "../../services/country-service";
import { onDownloadPDFReceipt } from "./components/receipt.service";

export type CouponFilterParameters = {
    pageNo: number;
    range: number;
    search?: string;
    where: {
        or: any[];
    };
    order: [];
};

const ReceiptManager = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
    const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);
    const sortField = searchParams?.get('sortField') || 'serial';
    const sortOrder = +(searchParams?.get('sortOrder') || -1);
    const searchField = searchParams?.get('search') || '';
    const [countries, setCountries] = useState([]);
    const toast = useRef(null);
    const [isShowCreate, setIsShowCreate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [receipts, setReceipts] = useState({
        data: [],
        totalPage: 0
    });
    const [paginator, setPaginator] = useState({
        page: 0,
        first: 0,
        rows: 20
    })
    const [sortConfig, setSortConfig] = useState<any>({
        sortField: sortField,
        sortOrder: sortOrder
    });
    const [filterValues, setFilterValues] = useState<CouponFilterParameters>({
        pageNo: pageNumber,
        range: noRows,
        search: searchField,
        where: {
            or: searchField ? getReceiptObjectSearchValue(searchField) : [],
        },
        order: []
    });
    const [search, setSearch] = useState<any>(filterValues.search);
    const [receipt, setReceipt] = useState<any>(null);
    const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
    const [selectedReceipts, setSelectedReceipts] = useState([]);
    const [iShowBTNDeleteReceipt, setIshowBTNDeleteReceipt] = useState(false);

    const onBasicPageChange = (event: any) => {
        setPaginator({
            page: event.page,
            first: event.first,
            rows: event.rows
        });
        setSelectedReceipts([]);
    }

    const fetchReceiptsData = async () => {
        setIsLoading(true);
        const payload = getReceiptPayloadConditions(filterValues.where, sortConfig);

        try {
            const resReceipts = await ReceiptsAPI.getReceipts({
                pageNo: paginator.page,
                range: paginator.rows, 
                ...payload
            });
          if (resReceipts && resReceipts.status === 200) {
            setIsLoading(false);
            setReceipts({
                data: resReceipts.data.records ? resReceipts.data.records: [],
                totalPage: resReceipts.data.total || 0,
            });
          }
        } catch (error) {
            setIsLoading(false);
            setReceipts({
                data: [],
                totalPage: 0
            });
        }
    }

    const fetchCountryData = async () => {
        try {
            const res = await CountryService.getCountries();
            if (res.status === 200 && res.data.data) {
              setCountries(res.data.data);
            } else {
                setCountries([]);
            }
        } catch (error) {
            setCountries([]);
        }
    };

    const onDeleteConfirm = async () => {
        const ids = selectedReceipts.length > 0 ? selectedReceipts.map((item: any) => item.uuid) : [];
        const response = await ReceiptsAPI.deleteReceipts(ids);
        if (response && response.status === 200 && response.data.result.isvalid) {
          onShowDeleteDialog(false);
          fetchReceiptsData();
          setSelectedReceipts([]);
          showNotification("success", "Delete receipts successfully.", toast);
        } else {
          setSelectedReceipts([]);
          onShowDeleteDialog(false);
          showNotification("info", "Delete receipts failed.", toast);
        }
    };

    useEffect(() => {
        fetchReceiptsData();
        fetchCountryData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (typeof paginator.page === "number") {
            setFilterValues({
                ...filterValues,
                pageNo: paginator.page,
                range: paginator.rows,
            });
            setSearchParams({
                pageNumber: (paginator.page + 1).toString(),
                noRows: paginator.rows.toString(),
            });
            setSelectedReceipts([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator]);

    useEffect(() => {
        const searchParams = { 
            pageNumber: (paginator.page + 1).toString(),
            noRows: paginator.rows.toString(),
            search: filterValues.search,
            sortField: sortConfig.sortField,
            sortOrder: sortConfig.sortOrder,
        }

        setSearchParams(_.omitBy(searchParams, (p) => !p));
        fetchReceiptsData();
        setSelectedReceipts([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, sortConfig]);


    const onShowCreateDialog = (isVisible: boolean) => {
        setIsShowCreate(isVisible);
    };

    const onShowDeleteDialog = (isVisible: boolean) => {
        setReceipt(null);
        setIsShowDeleteDialog(isVisible);
      };

    const onDeleteReceipt = async (rowData: any) => {
        setIsShowDeleteDialog(true);
        setSelectedReceipts(rowData);
    };

    const onSearchFunc = (searchText: string) => {
        const where_conditions = getReceiptObjectWithValues(filterValues.where);
        const or_conditions = getReceiptObjectSearchValue(searchText);
        where_conditions["or"] = searchText ? or_conditions : null;
        setFilterValues({
            pageNo: filterValues.pageNo,
            range: filterValues.range,
            where: where_conditions,
            search: searchText,
            order: filterValues.order,
        });
        if (paginator.page !== 0) {
            setPaginator({
                ...paginator,
                page: 0,
                first: 0
            });
        }
    };

    const formatAmountBodyTemplate = (rowData: any) => {
        return <div><b style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>{rowData ? parseFloat(rowData.amount?.toString())?.toLocaleString("de-DE") : '-'}</b> <span>{rowData.currency_code?.toUpperCase()}</span></div>;
    };

    const tableColumns = [
        { field:'serial', header: 'No.', width: "100px", align: 'left', disabledSort: false },
        { field: 'amount', header: 'Amount', width: "130px", bodyTemPlate: formatAmountBodyTemplate, align: 'right', disabledSort: false },
        { field: 'name', header: 'Name', width: "220px", bodyTemPlate: buildDefaultBodyTemplate, align: 'left', disabledSort: true },
        { field: 'company_name', header: 'Organization', width: "160px", bodyTemPlate: buildDefaultBodyTemplate, align: 'left', disabledSort: true },
        { field: 'payment_address', header: 'Address', width: "calc(92% - 1260px)", minWidth: "240px", bodyTemPlate: buildDefaultBodyTemplate, align: 'left', disabledSort: true },
        { field: 'postal_code', header: 'Postal Code', width: "120px", bodyTemPlate: buildDefaultBodyTemplate, align: 'left', disabledSort: true },
        { field: 'city', header: 'City', width: "8%", type:"numeric", bodyTemPlate: buildDefaultBodyTemplate, align: 'left', disabledSort: true },
        { field: 'country', header: 'Country', width: "120px", bodyTemPlate: buildCountryBodyTemplate(countries), align: 'left', disabledSort: true },
        { field: 'donated_date', header: 'Donated Date', width: "150px", bodyTemPlate: formatDateByFormatBodyTemplate("MMM DD, YYYY"), align: 'left', disabledSort: false },
        { field: 'receipt_date', header: 'Receipt Date', width: "150px", bodyTemPlate: formatDateByFormatBodyTemplate("MMM DD, YYYY"), align: 'left', disabledSort: false },
        { field: 'created_at', header: 'Created Date', width: "160px", bodyTemPlate: formatDateByFormatBodyTemplate("MMM DD, YYYY HH:mm:ss"), align: 'left', disabledSort: false },
    ];

    const dynamicColumns = tableColumns.map((col, i) => {
        return <Column
            key={`${col.field}_${i}`}
            field={col.field}
            header={col.header}
            style={{ 
                width: col.width,
                minWidth: col.minWidth 
            }}
            dataType={col.type}
            body={col.bodyTemPlate}
            align={col.align as ColumnAlignType}
            sortable={!col?.disabledSort}
        />;
    });

    const buildMenu = (rowData: any) => {
        const defaultActions = [
        {
            label: "Edit",
            description: "Edit receipt information",
            icon: "fa-solid fa-pen-to-square",
            command: (e: any) => {
                onShowCreateDialog(true)
                setReceipt(rowData);
            },
        },
        {
            label: "Download",
            description: "Download receipt as PDF",
            icon: "fa-solid fa-download",
            command: (e: any) => onDownloadPDFReceipt(toast, rowData, e),
        },
        {
            label: "Delete",
            description: "Delete receipt information",
            icon: "fa-solid fa-trash-can delete-icon",
            dangerous: true,
            command: (e: any) => onDeleteReceipt([rowData]),
        },];
        return <MenuDot items={[...defaultActions]}></MenuDot>;
    }

    return (
        <Layout>
            <Toast ref={toast} />
            <div className="receipt-manager">
                <div className="manager-container">
                    <div className="headline">
                        <h3>Receipt Generator</h3>
                    </div>
                    <div className="header-containers">
                        <div className="receipt-header-panel">
                        <WISearchField
                            icon={"pi pi-search"}
                            placeholder="Search for anything"
                            setSearch={(value: any) => setSearch(value.global.value)}
                            enterSearch={() => onSearchFunc(search)}
                            globalValue={search}
                        />
                        </div>
                        <div className="btn-receipt">
                            { selectedReceipts && selectedReceipts.length > 0 &&
                                <Button
                                    type="button"
                                    icon="fa-solid fa-trash-can"
                                    label={'Delete'}
                                    iconPos="right"
                                    className="delete-button"
                                    onClick={() => {
                                        onDeleteReceipt(selectedReceipts)
                                    }}
                                />
                            }
                            <Button
                                type="button"
                                icon="fa-solid fa-circle-plus"
                                label={'Generate'}
                                iconPos="right"
                                className="generate-button"
                                onClick={() => {
                                    setReceipt(null);
                                    onShowCreateDialog(true)
                                }}
                            />
                        </div>
                    </div>
                    <div className="table-receipt">
                        <DataTable
                            loading={isLoading}
                            value={receipts.data}
                            scrollable
                            dataKey="uuid"
                            scrollHeight="calc(100vh - 270px)"
                            scrollDirection="both"
                            lazy
                            onSort={(e: any) => {
                                setSortConfig({
                                    sortField: e.sortField,
                                    sortOrder: e.sortOrder,
                                });
                                setSelectedReceipts([]);
                            }}
                            selectionMode="checkbox"
                            selection={selectedReceipts} 
                            onSelectionChange={e => {
                                setSelectedReceipts(e.value)
                            }} 
                            sortField={sortConfig.sortField}
                            sortOrder={sortConfig.sortOrder}
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: '50px' }}></Column>
                            {dynamicColumns}
                            <Column
                                frozen
                                alignFrozen="right"
                                style={{ width: "50px" }}
                                body={buildMenu}
                            ></Column>
                        </DataTable>
                        <WIPaginator
                            first={paginator.first}
                            rows={paginator.rows}
                            totalRecords={receipts.totalPage}
                            onPageChange={onBasicPageChange}
                        />
                    </div>
                </div>
                <WIConfirmDialog
                    visible={isShowDeleteDialog}
                    onHide={() => onShowDeleteDialog(false)}
                    onConfirm={onDeleteConfirm}
                    message={
                        <Fragment>
                            <span style={{ fontSize: '18px', fontWeight: '700' }}>Are you sure you want to delete this receipt?</span>
                            <br />
                            <span style={{ fontSize: '13px' }}>
                                All of your data will be deleted <b>permanently</b>.
                                <br/>
                                You <b>cannot undo</b> this action.
                            </span>
                        </Fragment>
                    }
                    classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
                />
                <CreateReceiptDialogComponent
                    title={`${receipt ? 'Update' : 'Generate'} receipt`}
                    visible={isShowCreate}
                    countries={countries}
                    fetchReceiptsData={fetchReceiptsData}
                    receipt={receipt}
                    onHide={() => {
                        onShowCreateDialog(false);
                        setReceipt(null);
                    }}
                />
            </div>
        </Layout>
    );
}

export default ReceiptManager;
