import * as React from "react";
import { useRef } from "react";
import { Menu } from 'primereact/menu';
import './menu-dot.scss';
import { customMenuItemsDot } from "../column-template-table/column-template";

const MenuDot = (props: any) => {
    const { items } = props;
    const refMenu = useRef<any>(null);

    return (
      <div className="dot-action" data-testid="menudot" onClick={(event: any) => refMenu?.current?.toggle(event)}>
          <Menu className="dot-action-menu" model={items.map((m: any) => ({ template: customMenuItemsDot, ...m})) || []} popup ref={refMenu} />
          <div className="fa-solid fa-ellipsis" />
      </div>
    );
  };

  export default MenuDot;