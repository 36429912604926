import _ from "lodash";
import { Dialog } from "primereact/dialog";
import React, {useState } from "react";
import { MediaV2ManagerAPI } from "../../../../../services";
import { showNotification } from "../../../../../utils/logic";
import { PNG_MAZ_SIZE, canBeThumbnail, getFileType, getThumbnailFullpath, uploadPhysicalFileToS3, } from "../../../../../utils/mediaUtils";
import DynamicForm from "../../../../dynamic-form/dynamic-form";
import { createEmptyMediaV2} from "./mediaType";
import "./upload-media-dialog.scss";
import { ProgressSpinner } from "primereact/progressspinner";

export const MediaUploadBlock = {
  configuration: {
    mediaFiles: {
      type: "wiUploadMedia",
      label: "Files Upload",
      isRequired: true,
      validationType: "array",
      validations: [
        {
          type: "required",
          params: ["Files Upload is required"],
        },
        {
          type: "min",
          params: [1, "Min 1 file for uploading"],
        },
        {
          type: "max",
          params: [10, "Max 10 files for uploading"],
        },
      ],
    },
  },
  parameters: {},
};

export const UploadMediaDialogComponent = (props: any) => {
  const { fullPath, selectedFolder, visible, refresh, onHide, toast } = props;
  const block = { ...MediaUploadBlock };
  const [isLoading, setIsLoading] = useState(false);

  // Operator Upload Images
  const onSubmitDataForm = async (value: any) => {
    try {
      setIsLoading(true);
      /* Create a list of file metadata objects */
      let newMedia = createEmptyMediaV2();
      const fileInfoDataList = value?.mediaFiles?.map((mediaFile: any) => {
        const metaInfo = _.cloneDeep(newMedia.metadata);
        const finalMetadata = {...metaInfo, ...mediaFile.metadata};
        newMedia.name = mediaFile.name;
        newMedia.fullPath = fullPath ? `${fullPath}/${mediaFile.name}` : mediaFile.name;
        newMedia.alternativeText = mediaFile.metadata.alternative_text;
        newMedia.title = mediaFile.metadata.title;
        newMedia.description = mediaFile.metadata.description;
        newMedia.type = getFileType(mediaFile.extension);
        newMedia.size = mediaFile.size;
        newMedia.folderId = selectedFolder?.current?.uuid;
        newMedia.metadata =  finalMetadata;
        let fileInfo: any = {
          name: newMedia.name,
          originalName: newMedia.name,
          description: JSON.stringify(newMedia.description),
          folderId: newMedia.folderId,
          fullPath: newMedia.fullPath,
          alternativeText: JSON.stringify(newMedia.alternativeText),
          metadata: JSON.stringify(newMedia.metadata),
          size: newMedia.size,
          type: newMedia.type,
          title: JSON.stringify(newMedia.title)
        };
        // Create a link between this "fileInfo" and "media file"
        const relationalKey = Math.random();
        mediaFile.relationalKey = relationalKey;
        fileInfo.relationalKey = relationalKey;
        return fileInfo;
      });
      /* Stop here if there is no file */
      if (!fileInfoDataList || fileInfoDataList.length < 1) {
        setIsLoading(false);
        return;
      }
      /* Save the list of file metadata objects to the app in order to get the adjusted file names (if duplicate) */
      for (let i = 0; i < fileInfoDataList.length; i++) {
        const fileCreatingResponse = await MediaV2ManagerAPI.createFile(fileInfoDataList[i]);
        if (fileCreatingResponse?.status === 200 && fileCreatingResponse.data?.file) {
          fileInfoDataList[i].uuid = fileCreatingResponse.data.file.uuid;
          fileInfoDataList[i].name = fileCreatingResponse.data.file.name;
          fileInfoDataList[i].fullPath = fullPath ? `media/${fullPath}/${fileInfoDataList[i].name}` : `media/${fileInfoDataList[i].name}`;
          fileInfoDataList[i].hasError = false;
          if (canBeThumbnail(fileInfoDataList[i].fullPath)) {
            fileInfoDataList[i].thumbnailFullPath = getThumbnailFullpath(fileInfoDataList[i].fullPath);
          }
        } else {
          fileInfoDataList[i].hasError = true;
        }
      }
      /* If there is no any valid file for being uploaded to S3 storage -> stop here */
      const hasValidFile: boolean = fileInfoDataList.some((f: any) => f.hasError === false);
      if (!hasValidFile) {
        setIsLoading(false);
        return;
      }
      /* Get S3 Uploading Presigned URLs for files */
      const imageObjectKeys: string[] = fileInfoDataList.filter((item: any) => item.fullPath !== null).map((item: any) => item.fullPath);
      const thumbnailObjectKeys: string[] = fileInfoDataList.filter((item: any) => item.thumbnailFullPath !== null).map((item: any) => item.thumbnailFullPath);
      const presignedUrlsResponse = await MediaV2ManagerAPI.getMultipleFileUploadingPresignedUrls({object_keys: [...imageObjectKeys, ...thumbnailObjectKeys]});
      if (!presignedUrlsResponse || presignedUrlsResponse.status !== 200 || presignedUrlsResponse.data.length < 1) {
        setIsLoading(false);
        return;
      }
      /* Define a Task (Promise) of uploading the image to S3 storage */
      const uploadImageToS3 = async (s3PresignedUrl: string, fileInfo: any, mediaFile: any) => {
        try {
          const newSize = await uploadPhysicalFileToS3(s3PresignedUrl, mediaFile.url, mediaFile.type, mediaFile.size > PNG_MAZ_SIZE);
          if (newSize != fileInfo.size) {
            // Just update new Size, so don't wait for invalidating image's CDN cache
            MediaV2ManagerAPI.updateFile({ ...fileInfo, size: newSize, fullPath: fullPath ? `${fullPath}/${fileInfo.name}` : fileInfo.name }, false);
          }
          return { fileInfo, isSuccessful: true };
        } catch(ex) {
          console.log(`Cannot upload media file of '${fileInfo.fullPath}'`);
          return { fileInfo, isSuccessful: false };
        }
      }
      /* Define another Task (Promise) of uploading that image's thumbnail (if any) to S3 storage */
      const uploadThumbnailToS3 = async (s3PresignedUrl: string, fileInfo: any, mediaFile: any) => {
        try {
          await uploadPhysicalFileToS3(s3PresignedUrl, mediaFile.thumbnailBase64, 'image/webp');
        } catch(ex) {
          console.log(`Cannot upload thumbnail of '${fileInfo.thumbnailFullPath}'`);
        }
        return null;
      }
      /* Define a S3 Uploading Task List */
      const s3UploadingTasks: any[] = [];
      /* Add uploading tasks to the list */
      presignedUrlsResponse.data.forEach((presignedUrlObject: any) => {
        // Add a task of uploading an image to the list
        const fileInfo = fileInfoDataList.find((f: any) => f.fullPath === presignedUrlObject.object_key);
        if (!fileInfo) return null;
        const mediaFile = value?.mediaFiles?.find((mediaFile: any) => mediaFile.relationalKey === fileInfo.relationalKey);
        if (!mediaFile) return null;
        s3UploadingTasks.push(uploadImageToS3(presignedUrlObject.presigned_url, fileInfo, mediaFile));
        // Add a task of uploading its thumbnail to the list (if any)
        if (fileInfo.thumbnailFullPath) {
          const thumbnailPresignedUrlResponse = presignedUrlsResponse.data.find((presignedUrlObject: any) => presignedUrlObject.object_key === fileInfo.thumbnailFullPath);
          if (thumbnailPresignedUrlResponse) {
            s3UploadingTasks.push(uploadThumbnailToS3(thumbnailPresignedUrlResponse.presigned_url, fileInfo, mediaFile));
          }
        }
      });
      /* Execute the Uploading Task List */
      if (s3UploadingTasks.length > 0) {
        const s3UploadingResponse = await Promise.all(s3UploadingTasks);
        const results = s3UploadingResponse.filter((response: any) => !!response);
        if (results && results.length > 0) {
          results.forEach((result: any) => {
            if (result.isSuccessful) {
              showNotification("success", `${result.fileInfo.name} file uploaded successfully`, toast);          
            } else {
              showNotification("error", `Failed to upload ${result.fileInfo.name} file`, toast);
            }
          });
          refresh();
        }
      }
      setIsLoading(false);
      onHide();
    } catch (error) {
      showNotification("error", "Failed to upload file", toast);
    }
  };

  return (
    <>
      <Dialog
        className="upload-media-dialog wi-dialog upload-custom-media-dialog"
        maskClassName="top-mask-dialog"
        header="Upload new files"
        visible={visible}
        style={{ width: "700px", height: "80vh" }}
        modal
        onHide={onHide}
      >
        <div className="row">
          <div className="col-12 pb-0">
            <div className="element-form message-box">
              <label>
                <span className="focus-label">
                  <strong>Notes:</strong>
                </span>
              </label>
              <ul className="square">
                <li>
                  File size has max limit of <strong>25MB</strong>
                </li>
                <li>
                  File types are supported{" "}
                  <strong>
                    jpg, jpeg, png, svg, doc, docx, xls, xlsx, pdf, txt, 7z,
                    rar, zip, avi, mov, and mp4
                  </strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {isLoading ? (
        <div className="upload-media-loading-component">
          <ProgressSpinner/>
        </div>) :  (
          <DynamicForm
            loading={isLoading}
            formSchema={block.configuration}
            data={block.parameters}
            submitButtonConfig={{
              title: "Upload",
              icon: "fa-solid fa-upload",
              iconPos: "right",
            }}
            onSubmitData={onSubmitDataForm}
            mode="dialog"
          />
        )}
      </Dialog>
    </>
  );
};