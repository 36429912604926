import * as React from "react";
import { useState, useRef } from "react";
import "./import-campaigns-page.scss";
import Layout from "../layout/layout";
import { Formik } from "formik";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { showNotification } from "../../utils/logic";
import WICSVUploadField from "../common/upload/wi-upload-csv-field";
import ImportCampaignsAPI from "../../services/impportCampaignsService";

const ImportCampaignsPage = () => {
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const formikRef = useRef(null);

  const handleSubmit = async (values: any) => {
    try {
      const res = await ImportCampaignsAPI.importCampaigns({
        data: values.dataImport,
      });
      if (res && res?.status === 200) {
        console.log('response >>> ', res);
        
        setIsLoading(false);
        showNotification("success", "Import successfully", toast);
      } else {
        setIsLoading(false);
        showNotification("error", "Failed to import", toast);
      }
    } catch (error) {
      setIsLoading(false);
      showNotification("error", "Failed to import", toast);
    }
  };

  const initialValues = () => {
    return {
      language: "de",
    };
  };

  return (
    <Layout>
      <div className="import-campaigns-page">
        <Toast ref={toast} />
        <div className="import-header"></div>
        <div className="import-container import-container-extra">
          <div className="impoty-btn">
            <Formik
              innerRef={formikRef}
              initialValues={initialValues()}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                handleSubmit(values);
              }}
            >
              {({ dirty, isValid, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-6 col-md-6"></div>
                    <div className="col-12 col-md-12">
                      <div className="input-item">
                        <label className="label">
                          Import For <span className="required-label">*</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-12 d-flex align-items-center justify-content-start upload-file">
                      <WICSVUploadField
                        name="dataImport"
                        className="input-upload"
                        labelClassName="label-upload-csv"
                        nameFileClassName="name-file"
                      />
                    </div>
                    <div className="col-12 d-flex flex-column align-items-start justify-content-start note-message">
                      <p>
                        Please make sure that all data are correct. This cannot
                        be undone.
                      </p>
                    </div>
                    <div className="btn-import">
                      <Button
                        loading={isLoading}
                        type="submit"
                        label="Submit"
                        icon="pi pi-chevron-right"
                        iconPos="right"
                        className="btn-submit p-button-rounded"
                      ></Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ImportCampaignsPage;
