import { AxiosClient } from './httpClient'

export default class CertificateManagerAPI {
  static getCertificates(data: any) {
    return AxiosClient.post(`/certificates/search`, data);
  }

  static deleteCertificates(data: any) {
    return AxiosClient.post('/certificates/batch-delete', { ids: data });
  }

  static generateCertificate(data: any) {
    return AxiosClient.post(`/certificates`,  data);
  }

  static updateCertificate(id: string, data: any) {
    return AxiosClient.put(`/certificates/${id}`,  data);
  }

  static downloadCertificate(id: string, lang: string) {
    return AxiosClient.get(`/certificates/${id}/export?lang=${lang}`);
  }

}
