import * as React from "react";
import {  memo } from 'react';

const CertificateFormComponent = (props: any) => {
  const { values, errors, touched, handleChange, handleBlur } = props;

    return (
        <>
        <div className="row row-content">
          <div className="col-md-12">
            <div className="element-form">
              <label className="label">
                Certificate Description DE
                <span className="required-label"> *</span>
              </label>
              <textarea
                className={`${
                  touched.certificate_description?.de && errors.certificate_description?.de
                    ? "has-error"
                    : ""
                }`}
                name="certificate_description.de"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.certificate_description?.de}
              />
              <div
                className={`${touched.certificate_description?.de && errors.certificate_description?.de
                    ? "error"
                    : ""
                  }`}
              >
                {touched.certificate_description?.de && errors.certificate_description?.de}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="element-form">
              <label className="label">
                Certificate Description EN
                <span className="required-label"> *</span>
              </label>
              <textarea
                className={`${
                  touched.certificate_description?.en && errors.certificate_description?.en
                    ? "has-error"
                    : ""
                }`}
                name="certificate_description.en"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.certificate_description?.en}
              />
              <div
                className={`${touched.certificate_description?.en && errors.certificate_description?.en
                    ? "error"
                    : ""
                  }`}
              >
                {touched.certificate_description?.en && errors.certificate_description?.en}
              </div>
            </div>
          </div>
        </div>
        </>
    );
};

export default memo(CertificateFormComponent);
