import * as React from "react";
import { useState, useEffect, useRef, Fragment } from "react";
import { Column, ColumnAlignType } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "./campaign-manager.scss";
import Layout from "../../layout/layout";
import { CampaignManagerAPI, RegionManagerAPI } from "../../../services";
import { StatusDisplay, LanguageList, FIELDS_SEARCH, removeEmpty } from "../../../utils/utils";
import { showNotification } from "../../../utils/logic";
import { Toast } from "primereact/toast";
import { MenuDot, WIConfirmDialog, WIPaginator, WISearchField } from "../../common";
import { formatInnerHtmlDataByLanguageBodyTemplate, formatDateBodyTemplate, formatStatusBodyTemplate } from "../../common/column-template-table/column-template";
import { Link, useSearchParams } from "react-router-dom";
import { env } from "../../../environment";
import { getObjectSearchValue, getPayloadConditions, getObjectWithValues } from './campaign-manager-util';
import CampaignFilterComponent from "../components/campaign-filter/campaign-filter-component";
import _ from "lodash";

export type CampaignFilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
      or: any[];
      region_id: string[];
  };
  order: [];
};

const CampaignManager = (props: any) => {

  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState({
    data: [],
    totalPage: 0,
  });
  const [campaign, setCampaign] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [language] = useState(LanguageList[0]);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false)
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Math.max(+(searchParams?.get("pageNumber") || 1) - 1, 0);
  const filterRegions = searchParams?.get('filterRegions') ? searchParams?.get('filterRegions')?.split(',') || [] : [];
  const sortField = searchParams?.get("sortField") || "serial";
  const sortOrder = +(searchParams?.get("sortOrder") || -1);
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder,
  });
  const noRows = Math.min(
      Math.max(+(searchParams?.get("noRows") || 20), 50),
      1000
  );
  const searchField = searchParams?.get("search") || "";
  const [filterValues, setFilterValues] = useState<CampaignFilterParameters>({
    pageNo: pageNumber,
    range: noRows,
    search: searchField,
    where: {
        or: searchField ? getObjectSearchValue(searchField) : [],
        region_id: filterRegions
    },
    order: []
});

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  
  const fetchCampaignsData = async (valueSearch?: string) => {
    setIsLoading(true);

    const where_conditions = getPayloadConditions(filterValues.where, sortConfig);

    try {
      const [regionRes, res] = await Promise.all([
        RegionManagerAPI.getAllRegions(), 
        CampaignManagerAPI.searchCampaigns({
          pageNo: page,
          range: rows, 
          ...where_conditions
        })
      ]);
      if (res && res.status === 200) {
        setIsLoading(false);
        const campaignList = res.data.records || [];
        campaignList.forEach((c: any) => {
          c.region = regionRes?.data?.records?.find((r: any) => r.uuid === c.land?.region_id);
          c.region_name = c.region ? c.region.name?.de : '';
        });

        setCampaigns({
          data: campaignList,
          totalPage: res.data.total || 0,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setCampaigns({
        data: [],
        totalPage: 0,
      });
    }
  }; 

  useEffect(() => {
    if (typeof page === "number") {
      setFilterValues({
          ...filterValues,
          pageNo: page,
          range: rows,
      });
      setSearchParams({
          pageNumber: (page + 1).toString(),
          noRows: rows.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  useEffect(() => {
    const searchParams = { 
        pageNumber: (page + 1).toString(),
        noRows: rows.toString(),
        search: filterValues.search,
        sortField: sortConfig.sortField,
        sortOrder: sortConfig.sortOrder,
        filterRegions: filterValues.where.region_id?.join(',') || ''
    }
    setSearchParams(_.omitBy(searchParams, (p) => !p));
    fetchCampaignsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, sortConfig]);

  const callDeleteCampaignAPI = async () => {
    try {
      let res = await CampaignManagerAPI.DeleteCampaign(campaign?.uuid);
      if (res && res.status === 200 && res.data.result.isValid) {
        setCampaign(null);
        setIsShowDeleteDialog(false);
        fetchCampaignsData();
        showNotification("success", "Delete Campaign successfully", toast);
        return;
      }
      else{
        console.log(res);
      }
    } catch (error) {
      console.log('catch error', error);
    }

    showNotification("error", "Delete Campaign failed.", toast);
  };

  const tableColumns = [
    { field: "serial", header: "Serial", width: "5%", align: "left" },
    {
      field: "name",
      header: "Name",
      width: "15%",
      align: "left",
      bodyTemPlate: formatInnerHtmlDataByLanguageBodyTemplate(language),
    },
    {
      field: "code",
      header: "Identifier",
      width: "10%",
      align: "left",
      bodyTemPlate: (rowData: any) => {
        return <a className="table-ref-link-cell" href={`${env.DONATION_URL}?campaign=${rowData.code}`} target="_blank" rel="noreferrer">{rowData.code}</a>;
      }
    },
    {
      field: "region_name",
      header: "Region",
      width: "15%",
      align: "left",
      bodyTemPlate: (rowData: any, column: any) => {
        const child = formatInnerHtmlDataByLanguageBodyTemplate(language)(rowData, column);
        return rowData.region && rowData.region.uuid ? <Link className="table-ref-link-cell" to={`/regions/${rowData.region.uuid}`}>{child}</Link> : child;
      }
    },
    {
      field: "land_name",
      header: "Area",
      width: "calc(14% - 50px)",
      align: "left",
      bodyTemPlate: (rowData: any, column: any) => {
        const child = formatInnerHtmlDataByLanguageBodyTemplate(language)(rowData, column);
        return rowData.land && rowData.land.uuid ? <Link className="table-ref-link-cell" to={`/areas/${rowData.land.uuid}`}>{child}</Link> : child;
      }
    },
    {
      field: "partner_name",
      header: "Partner",
      width: "15%",
      align: "left",
      bodyTemPlate: (rowData: any) => {
        const child = rowData.partner_name;
        return rowData.partner && rowData.partner.uuid ? <Link className="table-ref-link-cell" to={`/partners/${rowData.partner.uuid}`}>{child}</Link> : child;
      }
    },
    {
      field: "status",
      header: "",
      width: "8%",
      align: "center",
      bodyTemPlate: formatStatusBodyTemplate(StatusDisplay),
    },
    {
      field: "protected_size",
      header: "Protected (m²)",
      width: "8%",
      align: "left",
      bodyTemPlate: (rowData: any) => (parseInt(rowData.protected_size) || 0)?.toLocaleString("de-DE")
    },
    {
      field: "updated_at",
      header: "Updated At",
      width: "10%",
      align: "left",
      bodyTemPlate: formatDateBodyTemplate("DD.MM.YYYY"),
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: "Open Donation Link",
        description: "Open campaign's donation link",
        icon: "fa-solid fa-arrow-up-right-from-square",
        externalUrl: `${env.DONATION_URL}?campaign=${rowData.code}`,
      },
      {
          label: "Edit",
          description: "Edit campaign information",
          icon: "fa-solid fa-pen-to-square",
          url: `/campaigns/${rowData?.uuid}`,
      },
      {
        label: "Delete",
        description: "Delete campaign information that isn't used anywhere yet",
        icon: "fa-solid fa-trash-can delete-icon",
        dangerous: true,
        disabled: +rowData.protected_size > 0,
        command: (e: any) => {
          setCampaign(rowData);
          setIsShowDeleteDialog(true);
        },
      }
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const onFilterChange = (values: any) => {
    const { field, value } = values;
    const where_conditions = getObjectWithValues(filterValues.where);
    where_conditions[field] = value;
    setFilterValues({
        pageNo: filterValues.pageNo,
        range: filterValues.range,
        where: where_conditions,
        order: filterValues.order,
    });
  };

  const onFilterRemove = async () => {
      let p = _.cloneDeep({ ...filterValues });
      p.where = Object.create({});
      p.search = '';
      setFilterValues(p);
  };

  const onSearchFunc = (searchText: string) => {
      const where_conditions = getObjectWithValues(filterValues.where);
      const or_conditions = getObjectSearchValue(searchText);
      where_conditions["or"] = searchText ? or_conditions : null;
      setFilterValues({
          pageNo: filterValues.pageNo,
          range: filterValues.range,
          where: where_conditions,
          search: searchText,
          order: filterValues.order,
      });
      if (page !== 0) {
          setPage(0);
          setFirst(0);
      }
  };

  return (
    <Layout>
      <div className="campaign-manager">
        <div className="manager-container">
          <div className="certificate">
          <div className="d-flex flex-column flex-md-row justify-content-start justify-content-md-between certificate-action common-horizontal-layout-header">
            {/* <WISearchField
              icon={'pi pi-search'}
              placeholder="Search for anything"
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={onSearch}
            /> */}
              <CampaignFilterComponent filterValues={filterValues}
                fields={["region_id"]}
                onChange={onFilterChange}
                onRemove={onFilterRemove}
                onSearchFunc={onSearchFunc}
              />

              <div className="btn-create">
                <Link className="wi-main-button" to="/campaigns/create" >
                  <div className="wi-btn-label">Create <i className="fa-solid fa-circle-plus ml-2"></i></div>
                </Link>
              </div>
            </div>
          </div>
          <div className="table-campaign">
            <DataTable 
              loading={isLoading}
              value={campaigns.data}
              //filters={search}
              onSort={(e: any) => {
                setSortConfig({
                    sortField: e.sortField,
                    sortOrder: e.sortOrder,
                });
              }}
              sortField={sortConfig.sortField}
              sortOrder={sortConfig.sortOrder}
              scrollable 
              scrollHeight="calc(100vh - 166px)"
              scrollDirection="both"
              >
              {dynamicColumns}
              <Column 
                frozen
                alignFrozen="right"
                style={{ width: '50px' }} 
                body={buildMenu}>
                </Column>
            </DataTable>
            <WIPaginator
              first={first}
              rows={rows}
              totalRecords={campaigns.totalPage}
              onPageChange={onBasicPageChange}
            />
          </div>
          <WIConfirmDialog 
            visible={isShowDeleteDialog}
            onHide={() => setIsShowDeleteDialog(false)}
            onConfirm={callDeleteCampaignAPI}
            message={
              <Fragment>
                <span style={{fontSize: '18px', fontWeight: '700'}}>Are you sure you want to delete this campaign?</span>
                <br/>
                <span style={{fontSize: '13px'}}>
                  All of your below data will be deleted <b>permanently</b>:
                  <ul style={{marginBottom: '5px'}}>
                    <li>This campaign with identified code <b>{campaign?.code}</b></li>
                    <li>All <b>donations</b> belongs to this campaign</li>
                  </ul>
                  You <b>cannot undo</b> this action.
                  </span>
              </Fragment>
            }
            classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
          />
        </div>
      </div>
      <Toast ref={toast} />
    </Layout>
  );
};

export default CampaignManager;
