import { AxiosClient } from "../httpClient";

export default class RecurringDonationAPI {
  static getMailings() {
    return AxiosClient.get(`/mailings`);
  }

  static getMailingByYear(year: any) {
    return AxiosClient.get(`/mailing/${year}`);
  }

  static sendMailings(year: any) {
    return AxiosClient.get(`/donations/${year}/send-mailing`);
  }
}
