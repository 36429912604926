import { AxiosClient, cacheAge } from './httpClient'

export default class PartnerManagerAPI {
  static getAllPartners(enableCache: boolean = true) {
    return enableCache
      ? AxiosClient.get(`/partners`, cacheAge)
      : AxiosClient.get(`/partners`);
  }

  static getPartners(data: any, enableCache: boolean = true) {
    return enableCache
      ? AxiosClient.get(`/partners/pagination`, {...data, ...cacheAge})
      : AxiosClient.get(`/partners/pagination`, {...data})
  }

  static getPartner(uuid: string) {
    return AxiosClient.get(`/partners/${uuid}`);
  }

  static generatePartners(data: any) {
    return AxiosClient.post(`/partners`,  data);
  }

  static updatePartners(data: any) {
    return AxiosClient.put(`/partners/${data.uuid}`,  data);
  }

  static deletePartner(uuid: string) {
    return AxiosClient.delete(`/partners/${uuid}`);
  }

}
