import * as React from "react";
import { useEffect, useState } from "react";
import "./goal-runs-filter.scss";
import { Button } from "primereact/button";
import { GOAL_STATUS_LIST } from "../../../../utils/utils";
import FilterField from "./filter-field";
import { WISearchField } from "../../../common";
import MenuButton from "../../../common/menu-button/menu-button";

const GoalFilterComponent = (props: any) => {
  const { fields, isStatusAll, onRemove, onChange, onSearchFunc, filterValues, onAddGoal, regions, partners, campaigns } = props;
  const [values, setValues] = useState<Map<string, string[]>>();
  const [isDirty, setDirty] = useState<boolean>(false);
  const [search, setSearch] = useState<any>(filterValues.search);


  const getFields = () => {
    const resetValues = new Map<string, string[]>();
    fields.forEach((item: string) => {
      resetValues.set(item, []);
    });
    setValues(resetValues);
  };

  useEffect(()=> {
    getFields();
  }, []);

  useEffect(() => {
    const filter = new Map<string, string[]>();
    fields.forEach((k: string) => {
      filter.set(k, filterValues.where[k]);
    });
    setValues(filter);
    setSearch(filterValues.search);
  }, [filterValues]);


  const onRemoveFilters = async () => {
    fields.forEach(async (item: string) => {
      values?.set(item, []);
    });
    setSearch('');
    await onRemove();
  };

  const onFilterChange = async (event: any) => {
    setDirty(true);
    values?.set(event.field, event.value);
    await onChange({field: event.field, value: event.value});  
  };

  const Action_List = () => {
    return [
      {
        label: "Add",
        description: "Add a new goal",
        icon: "fa-solid fa-circle-plus",
        command: (e: any) => {
          onAddGoal();
        }
    }
    ]
  };

  return (
    <div className="filter-goal-component">
      <div className="flex align-items-center flex-wrap justify-content-between filter-panel">
        <div className="filter-container">
          <WISearchField
              icon={"pi pi-search"}
              placeholder="Search for anything"
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={() => onSearchFunc(search)}
              globalValue={search}
          />
           <FilterField
            name="campaign_id"
            label="Campaign"
            className="wi-filter-option-goal"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get("campaign_id") || []}
            options={campaigns}
            optionLabel={"name.de"}
            optionValue={"uuid"}
            visible={values?.has("campaign_id") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <FilterField
            name="region_id"
            label="Region"
            className="wi-filter-option-goal"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get("region_id") || []}
            options={regions}
            optionLabel={"name.de"}
            optionValue={"uuid"}
            visible={values?.has("region_id") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <FilterField
            name="status"
            label="Status"
            className="wi-filter-option-goal"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get("status")  || []}
            options={GOAL_STATUS_LIST}
            optionLabel={"name.de"}
            optionValue={"code"}
            visible={isStatusAll}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <FilterField
            name="partner_id"
            label="Partner"
            className="wi-filter-option-goal"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get("partner_id") || []}
            options={partners}
            optionLabel={"name"}
            optionValue={"uuid"}
            visible={values?.has("partner_id") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
        </div>
        <div className="actions-right">
          <div className="remove-filter-button">
            <Button
              type="button"
              icon="pi pi-times"
              label="Clear filter(s)"
              onClick={onRemoveFilters}
            />
          </div>
          <div className="actions-goal">
              <MenuButton label="Actions" items={Action_List()}></MenuButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalFilterComponent;
