import * as React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button } from "primereact/button";
import { useRef, useState } from "react";
import { showNotification, parseJSON } from "../../../../utils/logic";
import { DonationManagerAPI } from "../../../../services";
import CheckboxSvg from "../../../common/CheckboxSvg";
import AnimateHeight from "react-animate-height";
import './update-donation-form.scss';

const UpdateDonationFormComponent = (props: any) => {
  const { donation, onHide, fetchDonationsData, toast } = props;
  const [isLoading, setIsLoading] = useState(false);
  const formikRef = useRef(null);
  const lang = donation.payment_language_code;
  const defaultReason = donation.campaign && donation.campaign.certificate_reason_description ? donation.campaign.certificate_reason_description[lang] : null;
  const initialRegionsValues = () => {
    return {
      payment_first_name: donation && donation.payment_first_name !== null ? donation.payment_first_name : '',
      payment_last_name: donation && donation.payment_last_name !== null ? donation.payment_last_name : '',
      payment_company_name: donation && donation.payment_company_name !== null ? donation.payment_company_name : '',
      payment_email: donation && donation.payment_email !== null ? donation.payment_email : '',
      personalize_certificate_reason: donation && donation.personalize_certificate_reason !== null ? parseJSON(donation.personalize_certificate_reason) : defaultReason,
      personalize_certificate_display_name: donation && donation.personalize_certificate_display_name !== null ?  donation.personalize_certificate_display_name : '',
      personalize_certificate: donation.personalize_certificate || false,
      as_company: donation.as_company || false,
    };
  };

  const donationFormSchema = Yup.object().shape({
    as_company: Yup.boolean().notRequired(),
    personalize_certificate: Yup.boolean().notRequired(),
    payment_first_name: Yup.string().required("First name is required"),
    payment_last_name: Yup.string().required("Last name is required"),
    payment_email: Yup.string().email("Email is invalid format").required("Email is required"),
    payment_company_name: Yup.string().when(["as_company"], { is: (as_company: any) => as_company, then: Yup.string().required("Company name is required")}),
    personalize_certificate_display_name: Yup.string().when(["personalize_certificate"], { is: (personalize_certificate: any) => personalize_certificate, then: Yup.string().notRequired()}),
    personalize_certificate_reason: Yup.string().nullable().max(80, 'Max 80 letters')
  });

  const onUpdateDonation = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (!formikRef || !formikRef?.current?.isValid) {
        return;
      }

      setIsLoading(true);
      // @ts-ignore: Object is possibly 'null'.
      const data = formikRef?.current.values;
      data.payment_company_name = data.as_company ? data.payment_company_name : "";
      data.personalize_certificate_reason = data.personalize_certificate ? data.personalize_certificate_reason : "";
      data.personalize_certificate_display_name = data.personalize_certificate ? data.personalize_certificate_display_name : "";

      const response = await DonationManagerAPI.updateDonation(
        donation.uuid,
        data
      );

      if (response && response.status === 200) {
        setIsLoading(false);
        showNotification("success", "Update donation successfully", toast);
        onHide();
        fetchDonationsData();
      } else {
        setIsLoading(false);
        showNotification("error", "Failed to update donation", toast);
      }
    } catch (error) {
      setIsLoading(false);
      showNotification("error", "Failed to update donation", toast);
    }
  };

  return (
    <div className="region-form wi-static-form">
      <div className="region-form-container">
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={initialRegionsValues()}
          validationSchema={donationFormSchema}
          onSubmit={(values, { setSubmitting }) => {
            // console.log("values >>>", values);
          }}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row-content">
                <div className="row">
                  <div className="col-md-6">
                    <div className="element-form">
                      <label className="label">
                        First Name <span className="required-label">*</span>
                      </label>
                      <input
                        className={`${
                          touched.payment_first_name &&
                          errors.payment_first_name
                            ? "has-error"
                            : ""
                        }`}
                        type="text"
                        name="payment_first_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.payment_first_name}
                        maxLength={30}
                      />
                      <div className="helper-text">
                        <i className="fa-solid fa-circle-info"></i> Max 30 letters
                      </div>
                      <div
                        className={`${
                          touched.payment_first_name &&
                          errors.payment_first_name
                            ? "error-text"
                            : ""
                        }`}
                      >
                        {
                          touched.payment_first_name && errors.payment_first_name
                            ? <span><i className="fa-solid fa-circle-info"></i> {errors.payment_first_name.toString()}</span>
                            : ""
                        } 
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="element-form">
                      <label className="label">
                        Last Name <span className="required-label">*</span>
                      </label>
                      <input
                        className={`${
                          touched.payment_last_name && errors.payment_last_name
                            ? "has-error"
                            : ""
                        }`}
                        type="text"
                        name="payment_last_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.payment_last_name}
                        maxLength={30}
                      />
                      <div className="helper-text">
                        <i className="fa-solid fa-circle-info"></i> Max 30 letters
                      </div>
                      <div
                        className={`${
                          touched.payment_last_name && errors.payment_last_name
                            ? "error-text"
                            : ""
                        }`}
                      >
                        {
                          touched.payment_last_name && errors.payment_last_name
                            ? <span><i className="fa-solid fa-circle-info"></i> {errors.payment_last_name.toString()}</span>
                            : ""
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="element-form">
                      <label className="label">
                        Payment Email<span className="required-label">*</span>
                      </label>
                      <input
                        className={`${
                          touched.payment_email && errors.payment_email
                            ? "has-error"
                            : ""
                        }`}
                        type="text"
                        name="payment_email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.payment_email}
                        maxLength={320}
                      />
                      <div
                        className={`${
                          touched.payment_email && errors.payment_email
                            ? "error-text"
                            : ""
                        }`}
                      >
                        {
                          touched.payment_email && errors.payment_email
                            ? <span><i className="fa-solid fa-circle-info"></i> {errors.payment_email.toString()}</span>
                            : ""
                        }
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div>
                    <CheckboxSvg
                      className={`mr-6 my-3 donation-checkbox`}
                      id={`as_company`}
                      name={`as_company`}
                      label={"Donate as a Company"}
                    />
                  </div>
                  <div>
                    <AnimateHeight
                      duration={300}
                      height={values?.as_company ? `auto` : 0}
                    >
                      <div className="element-form">
                        <label className="label">
                          Company Name{" "}
                          <span className="required-label">*</span>
                        </label>
                        <input
                          className={`${
                            touched.payment_company_name &&
                            errors.payment_company_name
                              ? "has-error"
                              : ""
                          }`}
                          type="text"
                          name="payment_company_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.payment_company_name}
                          maxLength={50}
                        />
                        <div
                          className={`${
                            touched.payment_company_name &&
                            errors.payment_company_name
                              ? "error"
                              : ""
                          }`}
                        >
                           {`${
                            touched.payment_company_name && errors.payment_company_name
                              ? errors.payment_company_name
                              : ""
                          }`}
                        </div>
                      </div>
                    </AnimateHeight>
                  </div>
                </div> */}

                <div className="row">
                  {/* <div>
                    <CheckboxSvg
                      className={`mr-6 my-3 donation-checkbox`}
                      id={`personalize_certificate`}
                      name={`personalize_certificate`}
                      label={"Personalize the certificate"}
                      disabled={donation.donation_type === 'code'}
                    />
                  </div> */}
                  <AnimateHeight
                    duration={300}
                    height={values?.personalize_certificate ? `auto` : 0}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="element-form">
                          <label className="label">
                            Certificate name
                          </label>
                          <input
                            className={`${
                              touched.personalize_certificate_display_name &&
                              errors.personalize_certificate_display_name
                                ? "has-error"
                                : ""
                            }`}
                            type="text"
                            name="personalize_certificate_display_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.personalize_certificate_display_name}
                            maxLength={40}
                          />
                          <div className="helper-text">
                            <i className="fa-solid fa-circle-info"></i> Name on the <b className="helper-bold">certificate</b>, max 40 letters
                          </div>
                          <div
                            className={`${
                              touched.personalize_certificate_display_name &&
                              errors.personalize_certificate_display_name
                                ? "error-text"
                                : ""
                            }`}
                          >
                            {
                              touched.personalize_certificate_display_name && errors.personalize_certificate_display_name
                                ? <span><i className="fa-solid fa-circle-info"></i> {errors.personalize_certificate_display_name.toString()}</span>
                                : ""
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                        <div className="element-form">
                          <label className="label">
                            Certificate Occasion
                          </label>
                          <input
                            disabled={donation.donation_type === 'code' || donation.is_imported}
                            className={`${
                              touched.personalize_certificate_reason &&
                              errors.personalize_certificate_reason
                                ? "has-error"
                                : ""
                            }`}
                            type="text"
                            name="personalize_certificate_reason"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.personalize_certificate_reason}
                            maxLength={80}
                          />
                          <div className="helper-text">
                            <i className="fa-solid fa-circle-info"></i> Occasion on the <b className="helper-bold">certificate</b>, under certificate name, max 80 letters
                          </div>
                        </div>
                      </div>
                    </div>
                  </AnimateHeight>
                </div>

                <div className="btn-submit-container">
                  <Button
                    type="submit"
                    disabled={!dirty}
                    loading={isLoading}
                    label={"Update"}
                    className="p-button-rounded wi-main-button"
                    onClick={onUpdateDonation}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateDonationFormComponent;
