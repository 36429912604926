import _ from "lodash";
import moment from "moment";
import formatHTML from 'html-format';
import { CouponsManagerAPI } from "../services";
import { DonationItemUIType, DonationsList } from "./commonType";
import { DonationKeyEnum } from "./utils";
import { env } from "../environment";
import { Buffer } from 'buffer';
import { INVALID_URLS } from "../components/pages/utils";

const REGEX_GEO = /^[SWEN]([-+]?)([\d]{1,3})(°)([\d]{1,2})(\')([\d]{1,2})(((\.)(\d+))?)(\s*)[SWEN]([-+]?)([\d]{1,3})(°)([\d]{1,2})(\')([\d]{1,2})(((\.)(\d+))?)$/;
const REGEX_LANDDING_PAGE = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
const REGEX_URL = /^(?:https?|ftp):\/\/(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u{00a1}-\u{ffff}]+-)*[a-z0-9\u{00a1}-\u{ffff}]+)(?:\.(?:[a-z0-9\u{00a1}-\u{ffff}]+-)*[a-z0-9\u{00a1}-\u{ffff}]+)*(?:\.(?:[a-z\u{00a1}-\u{ffff}]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/iu;
const REGEX_SPECIAL_CHARECTERS = /[`!@#$%^&*()_+\=\[\]{};'"\\|,<>\?~\/]/;
const REGEX_SPACE = /\s/;
const REGEX_CHARECTERS = /^[a-zA-Z0-9-]*$/;

export const showNotification = (type: string, message: string, notify: any) => {
  // @ts-ignore: Object is possibly 'null'.
  notify!.current?.show({ severity: `${type}`, summary: `Notification`, detail: `${message}`, life: 3000 });
}
export const generateFileDownload = (filename:string, data:any) =>{

  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const url = URL.createObjectURL(new Blob([byteArray], { type: "application/csv; charset=utf-8" }));
  generateLinkDownLoad(filename, url);
}

export const onExportCoupon = async (e: any, rowData: any, notify: any, couponData?: any ) => {
  try {
    let resGenerateCoupons;
    if (rowData.uuid) {
      resGenerateCoupons = await CouponsManagerAPI.exportSCVCoupon(rowData?.uuid || '');
    }
    console.log(rowData)

    if (resGenerateCoupons && resGenerateCoupons.status === 200) {
      generateFileDownload(`Code-${rowData.partner_code || 'WID'}-${rowData.name}-${moment().format('YYYYMMDDHHmm')}.csv`, resGenerateCoupons?.data.export_file);
      showNotification("success", "Exported successfully", notify);
    } else {
      showNotification("error", "Failed to export code", notify);
    }
  } catch (error) {
    showNotification("error", "Failed to export code", notify);
  }
}

const exportToCsv = (filename: string, rows: any) => {
  let csvFile = '';
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  generateLinkDownLoad(filename, url);
}

export const generateLinkDownLoad = (filename: string, url: any) => {
  const link = document.createElement("a");
  if (link.download !== undefined) { // feature detection
    // Browsers that support HTML5 download attribute
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

const processRow = function (row: any) {
  let finalVal = '';
  for (let j = 0; j < row.length; j++) {
    let innerValue = row[j] === null ? '' : row[j].toString();
    if (row[j] instanceof Date) {
      innerValue = row[j].toLocaleString();
    };
    let result = innerValue.replace(/"/g, '""');
    if (result.search(/("|,|\n)/g) >= 0)
      result = '"' + result + '"';
    if (j > 0)
      finalVal += ',';
    finalVal += result;
  }
  return finalVal + '\n';
};

export const createNewForDonations = (campaignData: any, key: DonationKeyEnum) => {
  if(!campaignData || !campaignData.donation_example_list){
    return [];
  }

  return convertDonationExampleToUIItems(
    campaignData.donation_example_list[key],
    key
  );
};

export const transformDonationList = (campaignData: any, donationsList: DonationsList) => {
  const donationList = campaignData.donation_example_list;
  
  donationList.eur.en = [];
  donationList.eur.de = [];
  donationList.cad.en = [];
  donationList.cad.de = [];
  donationList.chf.en = [];
  donationList.chf.de = [];

  handleTransformDonationList(
    donationsList.donations,
    donationList.eur.en,
    donationList.eur.de
  );
  handleTransformDonationList(
    donationsList.donationsCAD,
    donationList.cad.en,
    donationList.cad.de
  );
  handleTransformDonationList(
    donationsList.donationsCHF,
    donationList.chf.en,
    donationList.chf.de
  );
};

const handleTransformDonationList = (
  donations: DonationItemUIType[],
  donationListEN: any,
  donationListDE: any
) => {
  donations.forEach((d) => {
    if (!hasErrorDonationItem(d.money, d.item)) {
      let donationEn = { money: d.money, item: d.item.en };
      let donationDe = { money: d.money, item: d.item.de };
      donationListEN.push(donationEn);
      donationListDE.push(donationDe);
    }
  });
};

const hasErrorDonationItem = (
  money: any,
  item: any
): boolean => {
  return Number(money) <= 0 || item.de.length <= 0 || item.en.length <= 0;
};

export const validateDonationList = (donationexampleList: any) => {
  if (
    donationexampleList.eur.en.length <= 0 ||
    donationexampleList.eur.de.length <= 0 ||
    donationexampleList.cad.en.length <= 0 ||
    donationexampleList.cad.de.length <= 0 ||
    donationexampleList.chf.en.length <= 0 ||
    donationexampleList.chf.de.length <= 0
  ) {
    return false;
  }

  return true;
};

export const convertDonationExampleToUIItems = (
  donationExample: any,
  key: DonationKeyEnum
) => {
  if(donationExample && donationExample.de && donationExample.en){
    return donationExample.de.map((item: any, index: number) => {
      let donationDE = parseDonation(item, index);
      let donationEN = donationExample.en.find(
        (f: any) => f.money === donationDE.money
      );
      return {
        ...donationDE,
        key: key,
        item: {
          de: donationDE.item.de,
          en: donationEN.item,
        }
      }
    });
  }
  return [];
};

export const formatHTMLCode = (content: string) => {
  return formatHTML(content, " ".repeat(4), 100);
}

const parseDonation = (obj: any, index: number): DonationItemUIType => {
  return {
    key: "",
    index: index,
    money: Number(obj.money),
    item: {
      de: obj.item,
      en: obj.item
    } 
  };
}

export const isValidForm = (isUpdated: boolean, dirty: boolean, isValid: boolean, isChangingDonations: boolean): boolean => {
  if(isUpdated){
    return (dirty || isChangingDonations) && isValid;
  }

  return dirty && isValid;
}

export const validateGeoCoordinate = (value: any) => {
  let error;
  if (value && !REGEX_GEO.test(value)) {
    error = "Invalid Geo Coordinate";
  }
  return error;
}

export const validateLandingPage = (value: any) => {
  let error;
  if (value && !REGEX_LANDDING_PAGE.test(value)) {
    error = "Invalid url landing page";
  }
  return error;
}

export const validateURLFormat = (value: any) => {
  let error;
  if (value && !REGEX_URL.test(value)) {
    error = "Invalid url";
  }
  return error;
}

export const validateCheckSpace = (value: string, prefix: string, urls: any[]) => {
  let error;
  if (value && REGEX_SPACE.test(value)) {
    error = "There must be no space";
  } else if (value && REGEX_SPECIAL_CHARECTERS.test(value)) {
    error = "There must be no special charecters";
  } else if (prefix && prefix !== '/' && !value) {
    error = "URL is existed";
  }else if (INVALID_URLS.includes(value) && prefix === '/'){
    error = "URL is invalid";
  }
  
  if (error) {
    return error;
  }

  const formattedUrls = urls.map((c: any) => '/' + c.nodes?.filter((n: any) => !!n.url).map((n: any) => n.url).join('/'));
  const urlExist = formattedUrls.some((u: any) => u.toLowerCase() === (prefix + value).toLowerCase());
  if (urlExist) {
    error = "URL is existed";
  }
  return error;
}

export const formatCurrency = (value: any) => {
  return (parseInt(value)).toLocaleString();
}

export const onNavigateToDonate = (pageType: string, code: string) => {
  window.open(`${env.DONATION_URL}?${pageType.toLowerCase()}=${code}`, '_blank');
}

export const onNavigateToByURL = (url: string): void => {
  window.open(url, '_blank');
}

export const generateMapUrl = (donation : any) => {
  const mapData = {
      title: donation.campaign?.name.de || donation.region?.name?.de,
      subtitle: donation.campaign?.name.de ? 'im ' + donation.region?.name?.de : '',
      area: (Math.floor(+(donation.area))).toLocaleString('de'),
      name: donation.pdf_cerfiticate_display_name || (donation.payment_first_name + ' ' + donation.payment_last_name),
      lat: fromDMMToDD(donation.latitude).toString(),
      lng: fromDMMToDD(donation.longitude).toString(),
      date: moment(donation.payment_date).format('DD.MM.YYYY')
  };

  return Buffer.from(JSON.stringify(mapData), 'utf-8').toString('base64');
}

export const generateMapUrlForBatchCode = (code : any) => {
  const mapData = {
      title: code?.title,
      subtitle: code?.subtitle,
      area: code?.area.toLocaleString('de'),
      name: code?.name,
      lat: fromDMMToDD(code?.lat).toString(),
      lng: fromDMMToDD(code?.lng).toString(),
      date: moment(code?.date).format('DD.MM.YYYY')
  };

  return Buffer.from(JSON.stringify(mapData), 'utf-8').toString('base64');
}

export const fromDMMToDD = (dmm: any) => {
  if (_.isNumber(dmm)) {
    return dmm;
  }

  const parts = dmm ? dmm.split(' ') : [];
  if (parts.length >= 2) {
    return +(parts[0]) + (+parts[1] / 60);
  } else if (parts.length === 1) {
    return +(parts[0]);
  }
  return +dmm;
}

export const compareDate = (toDate: any, fromDate: any) => {        
  const todateValue = moment(new Date(moment(new Date(toDate)).format('MM/DD/YYYY')));
  const fromDateValue = moment(new Date(moment(new Date(fromDate)).format('MM/DD/YYYY')));

  if (todateValue.diff(fromDateValue, 'days', false) > 0) return true;
  if (todateValue.diff(fromDateValue, 'days', false) === 0) return false;
  if (fromDateValue.diff(todateValue, 'days', false) < 0) return true;
  
  return false;        
}

const validateParseJson = (parseData: any) => {
  try {
    if(parseData) {
      const objectParse = JSON.parse(parseData);
      if(typeof objectParse === 'object') {
        return objectParse.de;
      }
        return parseData
    } else {
        return parseData;
    }
} catch (error) {
    return parseData;
}
}

export const parseJSON = (parseData: any) => {
  const value = parseData ? validateParseJson(parseData) : '';
  return value;
}


export const parseStringToInt = (value: string) => {
  if(value) {
    const stringWithoutComma = value.replace(/\./g, "").replace(",", "");
    const result = parseFloat(stringWithoutComma);
  
    return result;
  }

  return 0;
}

export const parseStringToFloat = (value: string) => {
  if(value) {
    const stringWithoutComma = value.replace(/\./g, "").replace(",", ".");
    const result = parseFloat(stringWithoutComma);
  
    return result;
  }
  return 0;
}

export const formatStringToNumber = (numberString: any) => {
  if(numberString) {
    if(typeof numberString === 'string') {
      return parseFloat(numberString);
    } else {
      return numberString;
    }
  } 
  return 0;
}

export const isValidateSpecialCharacter = (value: any) => {
  if (REGEX_CHARECTERS.test(value) || value === '') {
    return true;
  }
  return false;
}

export const formatDate = (value: any) => {
  return  moment.utc(moment(new Date(value)).format("MM/DD/YYYY HH:mm:ss")).toISOString();
}