import * as React from "react";
import { useState, useEffect, useRef, Fragment } from "react";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Column, ColumnAlignType } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "./page-manager.scss";
import "./flag.scss";
import Layout from "../../layout/layout";
import { showNotification } from "../../../utils/logic";
import { Toast } from "primereact/toast";
import { MenuDot, WIButton, WIConfirmDialog, WIPaginator, WISearchField } from "../../common";
import { CMsPageManagerAPI } from "../../../services";
import { PagesLanguageDisplayList, removeEmpty, FIELDS_SEARCH, StatusDisplay, LanguageList } from "../../../utils/utils";
import flag from '../../../images/flag/flag_placeholder.png';
import { fetchPageTypes } from "../builder-page.common";
import { PAGE_CODE } from "../utils";
import { SearchType, ConditionItemType } from "../../../utils/commonType";
import { formatDateBodyTemplate, formatNamePageTemplate, formatStatusBodyTemplate, formatTypeBodyTemplate } from "../../common/column-template-table/column-template";
import ChipSlider from "../../common/chip-slider/chip-slider";
import { TabMenu } from "primereact/tabmenu";
import _ from "lodash";
import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";

const PageManager = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const uuidTypeAll = '00000000-0000-0000-0000-000000000000';
    const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
    const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);
    const filterTypes = searchParams?.get('type') || uuidTypeAll;
    const ITEM_VISIBLE = 12;
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [cmsPages, setCmsPages] = useState({
        data: [],
        totalPage: 0,
    });
    const [cmsPage, setCmsPage] = useState<any>(null);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(pageNumber);
    const [rows, setRows] = useState(noRows);
    const [pageTypes, setPageTypes] = useState<any[]>([]);
    const [selectedPageType, setSelectedPageType] = useState<any>(filterTypes);
    const [search, setSearch] = useState<any>(null);
    const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false)
    const [language] = useState(LanguageList[0]);

    const setupCMSPageTypes = async (pageTypes: any[]) => {
        const types = pageTypes.sort((a: any, b: any) => (a.order > b.order) ? 1 : -1);
        types.unshift({ uuid: uuidTypeAll, code: 'all', name: {de: 'All', en: 'All'}});
        // setSelectedPageType(types && types.length > 0 ? types[0].uuid : '');
        setPageTypes(types);
}

    const fetchData = async () => {
        let types = await fetchPageTypes();
        setupCMSPageTypes(types);
        // await fetchCmsPagesData(types && types.length > 0 ? types[0].uuid : '');
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log(selectedPageType)
        if (typeof page === "number" && selectedPageType && pageTypes.length > 0) {
            fetchCmsPagesData(selectedPageType, search);
        }

        setSearchParams({
            pageNumber: page.toString(),
            noRows: rows.toString(),
            type: selectedPageType || filterTypes || uuidTypeAll
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rows, selectedPageType, pageTypes]);

    const fetchCmsPagesData = async (page_type_id: string, valueSearch?: string) => {
        try {
            const data = removeEmpty({
                pageNo: page,
                range: rows,
                search_text: valueSearch || undefined,
                search_fields: valueSearch ? FIELDS_SEARCH.PAGES : undefined
            });

            if(page_type_id !== uuidTypeAll) {
                let res = await CMsPageManagerAPI.getPages(page_type_id, data);
                if (res && res.status === 200) {
                    setIsLoading(false);
                    setCmsPages({
                        data: res.data.records,
                        totalPage: res.data.total
                    });
                }
                return;
            }

            let excludedCodes = [PAGE_CODE.News, PAGE_CODE.Press];
            let pageTypeIds = pageTypes.filter(f => excludedCodes.includes(f.code)).map(m => `'${m.uuid}'`);
            let querySearch: SearchType = {
                conditions: []
            }
            let typeCondition: ConditionItemType  = {
                field: "type_id",
                operator: "NOT IN",
                value: `(${pageTypeIds.join(',')})`
            };
            querySearch.conditions.push(typeCondition);

            if(!!valueSearch) {
                let likeCondition: ConditionItemType = {
                    field: "name,url",
                    operator: "LIKE",
                    value: `'%${valueSearch}%'`
                };
                querySearch.conditions.push(likeCondition);
            }
            
            let res = await CMsPageManagerAPI.searchAllPagesWithPagination(querySearch, { pageNo: page, range: rows });
            if (res && res.status === 200) {
                setIsLoading(false);
                setCmsPages({
                    data: res.data.records,
                    totalPage: res.data.totalRecords
                });
            }
        } catch (error) {
            setIsLoading(false);
            setCmsPages({
                data: [],
                totalPage: 0,
            });
        }
    };

    const callDeleteAPI = async () => {
        try {
            setIsLoading(true);
            setIsShowDeleteDialog(false);
            let res = await CMsPageManagerAPI.deletePage(cmsPage?.uuid);
            if (res && res.status === 200 && res.data.result.isValid) {
                setCmsPage(null);
                setIsLoading(false);
                showNotification("success", "Delete CMS Page successfully", toast);
                fetchCmsPagesData(selectedPageType);
                return;
            }
            else {
                setIsLoading(false);
                showNotification("success", "Failed to delete CMS Page", toast);
                console.log(res);
            }
        } catch (error) {
            setIsLoading(false);
            showNotification("success", "Failed to delete CMS Page", toast);
            console.log('catch error', error);
        }

        showNotification("error", "Delete CMS Page failed.", toast);
    }

    const onBasicPageChange = (event: any) => {
        setPage(event.page);
        setFirst(event.first);
        setRows(event.rows);
    }

    const onDelete = async (e: any, rowData: any) => {
        setIsShowDeleteDialog(true);
        setCmsPage(rowData);
    }

    const onSearch = () => {
        if (page !== 0) {
            setPage(0);
            setFirst(0);
            return;
        }
        fetchCmsPagesData(selectedPageType, search);
    }

    const formatTypeBodyTemplate = (rowData: any, column: any) => {
        let type = rowData[column.field];
        let pageType = pageTypes.find((f: any) => f.code.toLocaleLowerCase() === type?.toLocaleLowerCase());
        return pageType?.name?.de;
    };

    const tableColumns = [
        {
            field: "name",
            header: "Name",
            width: "25%",
            align: "left",
            bodyTemplate: formatNamePageTemplate
        },
        {
            field: "url",
            header: "Permanent URL",
            width: "20%",
            align: "left",
        },
        {
            field: "type",
            header: "Type",
            width: "20%",
            align: "left",
            bodyTemplate: formatTypeBodyTemplate(language, pageTypes),
        },
        {
            field: "status",
            header: "Status",
            width: "calc(15% - 50px)",
            align: "left",
            bodyTemplate: formatStatusBodyTemplate(StatusDisplay),
        },
        {
            field: "created_at",
            header: "Created At",
            width: "10%",
            align: "left",
            bodyTemplate: formatDateBodyTemplate("DD.MM.YYYY"),
        },
        {
            field: "updated_at",
            header: "Updated At",
            width: "10%",
            align: "left",
            bodyTemplate: formatDateBodyTemplate("DD.MM.YYYY"),
        },
    ];

    const dynamicColumns = tableColumns.map((col, i) => {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                style={{ width: col.width }}
                body={col.bodyTemplate}
                align={col.align as ColumnAlignType}
            />
        );
    });

    const handleChangeStatus = (status: any) => {
        setPage(0);
        setFirst(0);
        setSelectedPageType(status.uuid);
    }

    const callUpdatePageAPI = async (uuid: string, data: any) => {
        try {
          const res = await CMsPageManagerAPI.updatePage(uuid, data);
    
          if (res && res.status === 200) {
            showNotification("success", "Published CMS Page successfully", toast);
            return;
          }
        } catch (error) {
          console.log(error);
          showNotification("error", "Failed to publish CMS Page.", toast);
        }
    };

    const callUnpublishedPageAPI = async (pageId: any) => {
        try {
          const res = await CMsPageManagerAPI.unpublishedPage(pageId);
    
          if (res && res.status === 200) {
            showNotification("success", "Unpublished CMS Page successfully", toast);
            return;
          }
        } catch (error) {
          console.log(error);
          showNotification("error", "Failed to unpublish CMS Page.", toast);
        }
    };

    const onPublishPage = async (rowData: any) => {
        try {
            if (!rowData) {
                return;
            }
            setIsLoading(true);
            const pageData: any = { status: 'published' };
            pageData['custom_config'] = _.cloneDeep(rowData.custom_config) || {};
            pageData['custom_config']['published_info'] = pageData['custom_config']['published_info'] || [];
            pageData['custom_config']['published_info'].push({ time: moment().toISOString() });

            await callUpdatePageAPI(rowData.uuid, pageData);
            setIsLoading(false);

            fetchCmsPagesData(selectedPageType);
        } catch (e) {
            console.log(e)
            showNotification("error", "Failed to publish CMS Page.", toast);
            setIsLoading(false);
        }
    }

    const onUnpublishedPage = async (rowData: any) => {
        try {
            if (!rowData) {
                return;
            }
            
            setIsLoading(true);
            await callUnpublishedPageAPI(rowData.uuid);
            setIsLoading(false);
            fetchCmsPagesData(selectedPageType);
        } catch (e) {
            console.log(e)
            showNotification("error", "Failed to unpublish CMS Page.", toast);
            setIsLoading(false);
        }
    }

    const buildMenu = (rowData: any) => {
        const defaultActions = [
            {
                label: "Preview",
                description: "Preview page",
                icon: "fa-solid fa-eye",
                url: `/builder-page/${rowData.uuid}/preview`
            },
            {
                label: "Publish",
                description: "Publish page",
                icon: "fa-solid fa-upload",
                command: (e: any) => onPublishPage(rowData)
            },
            {
                label: "Unpublished",
                description: "Unpublished Page",
                icon: "fa-solid fa-upload",
                className: `${((!rowData?.custom_config?.published_info) || !(rowData?.custom_config?.published_info?.length > 0)) && 'menu-item-hidden'}`,
                command: (e: any) => onUnpublishedPage(rowData)
            },
            {
                label: "Design Content",
                description: "Design page content",
                icon: "fa-solid fa-pen-to-square",
                url: `/builder-page/${rowData.uuid}/content`
            },
            {
                label: "Edit Metadata",
                description: "Edit page metadata",
                icon: "fa-solid fa-pen-to-square",
                url: `/builder-page/${rowData.uuid}`
            },
            {
                label: "Duplicate",
                description: "Duplicate page",
                icon: "fa-solid fa-clone",
                url: `/builder-page?sourceId=${rowData.uuid || ''}`
            },
            {
                label: "Delete",
                description: "Delete page information",
                icon: "fa-solid fa-trash-can delete-icon",
                dangerous: true,
                disabled: !(!rowData.special_type && !rowData.parent_id),
                command: (e: any) => onDelete(e, rowData)
            }
        ];

        return <MenuDot items={[...defaultActions]}></MenuDot>;
    };

    const onSelectedCell = (values: any) => {
        const { field, rowData } = values;
        if (field.includes("field")) {
            return;
        }
        navigate(`/builder-page/${rowData.uuid}/content`);
    };

    return (
        <Layout>
            {isLoading ? (
            <div className="loading-component">
              <ProgressSpinner />
            </div>
            ) : (
                <></>
            )}
            <div className="cms-pages-manager">
                <div className="manager-container">
                    <div className="certificate">
                        <div className="d-flex flex-column flex-md-row justify-content-start justify-content-md-between certificate-action  common-horizontal-layout-header">
                            <WISearchField
                                icon={'pi pi-search'}
                                placeholder="Search for anything"
                                setSearch={(value: any) => setSearch(value.global.value)}
                                enterSearch={onSearch}
                            />
                            <div className="btn-create">
                                <Link className="wi-main-button" to="/builder-page" >
                                    <div className="wi-btn-label">Create <i className="fa-solid fa-circle-plus ml-2"></i></div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="table-pages">
                        <div className="page-type-item">
                            <TabMenu
                                className="tabmenu-donation"
                                model={pageTypes.map((p, i) => ({uuid: p.uuid, key: p.uuid, label: p.name?.de}))}
                                activeIndex={pageTypes.findIndex(p => p.uuid === selectedPageType)}
                                onTabChange={(e: any) => handleChangeStatus(e.value)}
                            />
                        </div>
                        <DataTable
                            loading={isLoading || pageTypes.length === 0}
                            value={cmsPages.data}
                            scrollable
                            // scrollHeight="calc(100vh - 300px)"
                            scrollDirection="both"
                            cellSelection
                            // selectionMode="single"
                            // onSelectionChange={(e) => onSelectedCell(e.value)}
                        >
                            {dynamicColumns}
                            <Column frozen alignFrozen="right" style={{ width: '50px' }} body={buildMenu}></Column>
                        </DataTable>
                        <WIPaginator
                            first={first}
                            rows={rows}
                            totalRecords={cmsPages.totalPage}
                            onPageChange={onBasicPageChange}
                        />
                    </div>

                    <WIConfirmDialog
                        visible={isShowDeleteDialog}
                        onHide={() => setIsShowDeleteDialog(false)}
                        onConfirm={callDeleteAPI}
                        message={
                            <Fragment>
                                <span style={{ fontSize: '18px', fontWeight: '700' }}>Are you sure you want to delete this page?</span>
                                <br />
                                <span style={{ fontSize: '13px' }}>
                                    All of your below data will be deleted <b>permanently</b>:
                                    <ul style={{ marginBottom: '5px' }}>
                                        <li>This page <b>{cmsPage?.name}</b></li>
                                        <li>All <b>child pages</b> related to this page</li>
                                    </ul>
                                    You <b>cannot undo</b> this action.
                                </span>
                            </Fragment>
                        }
                        classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
                    />
                </div>
            </div>
            <Toast ref={toast} />
        </Layout>
    );
}

export default PageManager;