import { useEffect, useRef, useState } from "react";
import React from "react";
import CheckboxSvg from "../../../common/CheckboxSvg";
import "./add-donor-form.scss";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { showNotification } from "../../../../utils/logic";
import { DonorManagerAPI } from "../../../../services";
import { Dropdown } from "primereact/dropdown";
import { ERROR_EXISTING_DONOR } from "../donor-component-util";
import { WIButton } from "../../../common";

const AddDonorFormComponent = (props: any) => {
  const { onHide, countries, fetchCallBack, toast, disableAdd } = props;

  const formikRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errorExistingDonor, setErrorExistingDonor] = useState<string>("");

  const fetchData = async () => {
    setErrorExistingDonor("");
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const onSubmitAddDonor = async () => {
    try {
        // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid, resetForm } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && dirty && isValid) {
        setIsLoading(true);
        let newDonorData = {
          ...values,
          joined_date: moment(values.joined_date).format("DD.MM.YYYY"),
          payment_email: values.payment_email?.trim()?.toLowerCase(),
        };
        
        let getDonorRes = await DonorManagerAPI.getDonorByEmail(
          newDonorData.payment_email
        );
        if (getDonorRes?.status === 200) {
          if (!getDonorRes?.data) {
            let createRes = await DonorManagerAPI.createDonor(newDonorData);
            setIsLoading(false);
            if (createRes?.status === 200 && createRes?.data) {
              showNotification(
                "success",
                "Create new donor successfully.",
                toast
              );
              if (newDonorData.isAddingAnotherDonor) {
                resetForm();
              } else {
                onHide();
              }

              fetchCallBack(createRes.data);
              return;
            }
          } else {
            setErrorExistingDonor(ERROR_EXISTING_DONOR);
            setIsLoading(false);
            return;
          }
        }
      }
    } catch {
        showNotification("error", "Check donor failed.", toast);
        setIsLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    payment_first_name: Yup.string()
    .transform((currentValue, originalValue) => {
      return !originalValue || originalValue.toString().trim() === "" ? null : currentValue;
    })
    .nullable()
    .required("This field is required."),
    payment_last_name: Yup.string()
    .transform((currentValue, originalValue) => {
      return !originalValue || originalValue.toString().trim() === "" ? null : currentValue;
    })
    .nullable().required("This field is required."),
    payment_email: Yup.string()
      .email("Email is invalid format")
      .required("This field is required")
  });

  const initialFormValues = () => {
    let initialDonor = {
      payment_first_name: "",
      payment_last_name: "",
      payment_email: "",
      payment_address: "",
      payment_additional_address: "",
      payment_post_code: "",
      payment_city: "",
      payment_country: "DE",
      joined_date: moment().toDate(),
      isAddingAnotherDonor: false,
    };

    return initialDonor;
  };

  return (
    <>
      <div className="add-donor-form">
        <div className="add-donor-form-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              //console.log("values >>>", values);
            }}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              isValid,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleChange,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">
                          First name
                          <span className="required-label"> *</span>
                        </label>
                        <input
                          className={`${
                            touched.payment_first_name &&
                            errors.payment_first_name
                              ? "has-error"
                              : ""
                          }`}
                          type="text"
                          name="payment_first_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.payment_first_name}
                          maxLength={40}
                        />
                        <div
                          className={`${
                            touched.payment_first_name &&
                            errors.payment_first_name
                              ? "error"
                              : ""
                          }`}
                        >
                          {touched.payment_first_name &&
                            errors.payment_first_name}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">
                          Last name
                          <span className="required-label"> *</span>
                        </label>
                        <input
                          className={`${
                            touched.payment_last_name &&
                            errors.payment_last_name
                              ? "has-error"
                              : ""
                          }`}
                          type="text"
                          name="payment_last_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.payment_last_name}
                          maxLength={40}
                        />
                        <div
                          className={`${
                            touched.payment_last_name &&
                            errors.payment_last_name
                              ? "error"
                              : ""
                          }`}
                        >
                          {touched.payment_last_name &&
                            errors.payment_last_name}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                      <label className="label">
                        Email
                        <span className="required-label"> *</span>
                      </label>
                      <input
                        className={`${
                          touched.payment_email && errors.payment_email
                            ? "has-error"
                            : ""
                        }`}
                        type="text"
                        name="payment_email"
                        onChange={(item) => {
                          if (!!errorExistingDonor) {
                            setErrorExistingDonor("");
                          }

                          setFieldValue(
                            "payment_email",
                            item.target.value,
                            true
                          );
                        }}
                        onBlur={handleBlur}
                        value={values.payment_email}
                        maxLength={255}
                      />
                    <div
                        className={`${
                          touched.payment_email && errors.payment_email
                            ? "error"
                            : ""
                        }`}
                      >
                        {touched.payment_email && errors.payment_email}
                      </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">Street name and house number</label>
                        <input
                          type="text"
                          name="payment_address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.payment_address}
                          maxLength={100}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">Additional address</label>
                        <input
                          type="text"
                          name="payment_additional_address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.payment_additional_address}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">Postal code</label>
                        <input
                          type="text"
                          name="payment_post_code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.payment_post_code}
                          maxLength={10}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">City</label>
                        <input
                          type="text"
                          name="payment_city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.payment_city}
                          maxLength={85}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">Country</label>
                        <div className="p-dropdown-common">
                          <Dropdown
                            className="add-donor-dropdown-component"
                            panelClassName="add-donor-dropdown-panel"
                            name="payment_country"
                            value={values.payment_country}
                            options={countries || []}
                            onChange={(item: any) => {
                              setFieldValue(
                                "payment_country",
                                item.value,
                                false
                              );
                              handleChange(item);
                            }}
                            optionLabel="name"
                            optionValue="ISO2"
                            //appendTo="self"
                            filter
                            filterBy="name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                {
                    !disableAdd &&
                    <div className="row">
                        <div className="check-box-container col-md-12">
                        <CheckboxSvg
                            className={`add-donor-checkbox`}
                            id={`isAddingAnotherDonor`}
                            name={`isAddingAnotherDonor`}
                            label={"Add another donor"}
                        />
                        </div>
                    </div>
                }

                  <div className="row">
                    <div className="col-md-10 error-existing">
                      <div className="element-form">
                        <div
                          className={`${
                            !!errorExistingDonor ? "error-existing-donor" : ""
                          }`}
                        >
                          {errorExistingDonor}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="add-donor-submit-component">
                        <WIButton
                            className="add-donation"
                            primary={true}
                            type="submit"
                            label="Add"
                            icon={"pi-angle-right"}
                            onClick={() => onSubmitAddDonor()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddDonorFormComponent;
