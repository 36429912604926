import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { showNotification, formatDate } from "../../../../../utils/logic";
import { SelectButton } from "primereact/selectbutton";
import _ from "lodash";
import { CampaignCouponStatus, CurrencyCoupons, TypeCoupons } from "../../../../../utils/utils";

const CreateCouponDialog = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { visible, onHide, addCoupon, editCoupon, coupon, coupons, id } = props;

  const toast = useRef(null);
  const formikRef = useRef(null);

  const COUPON_DEFAULT_VALUE = {
    uuid: uuidv4(),
    coupon: "",
    couponType: TypeCoupons[0].code,
    receiptStatus: "",
    couponStatus: CampaignCouponStatus[0].key,
    start_date: moment().isValid() ? moment().toDate() : new Date(),
    end_date: moment().isValid() ? moment().toDate() : new Date(),
    currency: CurrencyCoupons[0].code,
    budget: 0,
    limit: 0,
  };

  const initialCouponFormValues = () => {
    let initialCoupon = COUPON_DEFAULT_VALUE;
    if (coupon) {
      initialCoupon = _.pick(coupon, [
        "uuid",
        "coupon",
        "couponType",
        "receiptStatus",
        "couponStatus",
        "currency",
        "budget",
        "limit",
        "start_date",
        "end_date",
      ]);
      initialCoupon.start_date = moment(coupon.start_date).toDate();
      initialCoupon.end_date = moment(coupon.end_date).toDate();
    }

   
    return initialCoupon;
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object().shape({
    coupon: Yup.string().required("This field is required."),
    currency: Yup.string().required("This field is required."),
    couponStatus: Yup.string().required("This field is required."),
    limit: Yup.string().required("This field is required."),
    budget: Yup.string().required("This field is required."),
    start_date: Yup.string().required("This field is required."),
    end_date: Yup.string().required("This field is required."),
  });

  const generateCoupon = async () => {
    // @ts-ignore: Object is possibly 'null'.
    formikRef.current.validateForm();
    // @ts-ignore: Object is possibly 'null'.
    if (formikRef && formikRef.current.dirty) {
      try {
        setIsLoading(true);
        const dataCoupon = {
          // @ts-ignore: Object is possibly 'null'.
          ...formikRef?.current.values,
        };

        // @ts-ignore: Object is possibly 'null'.
        dataCoupon.start_date = formatDate(formikRef?.current.values.start_date);
        // @ts-ignore: Object is possibly 'null'.
        dataCoupon.end_date = formatDate(formikRef?.current.values.end_date);

        if (checkDuplicateCoupon(dataCoupon.coupon,  dataCoupon.uuid) === true) {
          showNotification("error", "Coupon already exists", toast);
          setIsLoading(false);
          return;
        }

        if (coupon) {
          editCoupon(dataCoupon);
        } else {
          addCoupon(dataCoupon);
        }
        setIsLoading(false);
        onHide();
      } catch (error) {
        // showNotification("error", "Generate failed", toast);
        setIsLoading(false);
      }
    }
  };

  const checkDuplicateCoupon = (coupon: string, uuid: any) => {
    return coupons.some((c: any) => c.coupon.toLowerCase().trim() === coupon.toLowerCase().trim() && c.uuid !== uuid);
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={coupon ? "Edit Coupon" : "Create Coupon"}
        visible={visible}
        style={{ width: "800px" }}
        modal
        className="wi-dialog"
        onHide={onHide}
      >
        <div className="codes-receipt-form">
          <div className="receipt-form-container">
            <Formik
              enableReinitialize={true}
              innerRef={formikRef}
              initialValues={initialCouponFormValues()}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                //console.log("values >>>", values);
              }}
            >
              {({
                values,
                errors,
                touched,
                dirty,
                isValid,
                handleBlur,
                handleSubmit,
                setFieldValue,
                handleChange,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Coupon <span className="asterik">*</span>
                          </label>
                          <InputText
                            value={values.coupon}
                            name="coupon"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <div
                            className={`${
                              touched.coupon && errors.coupon ? "error" : ""
                            }`}
                          >
                            {touched.coupon && errors.coupon}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Currency <span className="asterik">*</span>
                          </label>
                          <SelectButton
                            id="currency"
                            name="currency"
                            value={values.currency}
                            className="wi-selectbutton"
                            options={CurrencyCoupons}
                            onChange={(item: any) => {
                              if (item.value) {
                                setFieldValue("currency", item.value, false);
                              }
                            }}
                            optionLabel="name"
                            optionValue="code"
                          />
                          <div
                            className={`${
                              touched.currency && errors.currency ? "error" : ""
                            }`}
                          >
                            {touched.currency && errors.currency}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Coupon Type <span className="asterik">*</span>
                          </label>
                          <SelectButton
                            id="couponType"
                            name="couponType"
                            value={values.couponType}
                            className="wi-selectbutton"
                            options={TypeCoupons}
                            onChange={(item: any) => {
                              if (item.value) {
                                setFieldValue("couponType", item.value, false);
                              }
                            }}
                            optionLabel="name"
                            optionValue="code"
                          />
                          <div
                            className={`${
                              touched.couponType && errors.couponType
                                ? "error"
                                : ""
                            }`}
                          >
                            {touched.couponType && errors.couponType}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Budget <span className="asterik">*</span>
                          </label>
                          <InputText
                            value={values.budget}
                            name="budget"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div
                            className={`${
                              touched.budget && errors.budget ? "error" : ""
                            }`}
                          >
                            {touched.budget && errors.budget}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Limit <span className="asterik">*</span>
                          </label>
                          <InputText
                            value={values.limit}
                            name="limit"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <div
                            className={`${
                              touched.limit && errors.limit ? "error" : ""
                            }`}
                          >
                            {touched.limit && errors.limit}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            Start date <span className="asterik">*</span>
                          </label>
                          <Calendar
                            id="basic"
                            name="start_date"
                            panelClassName="expire-calendar"
                            value={values.start_date}
                            onBlur={handleBlur}
                            onChange={(item) => {
                              setFieldValue("start_date", item.value, false);
                            }}
                            dateFormat="dd.mm.yy"
                          />
                          <div
                            className={`${
                              touched.start_date && errors.start_date
                                ? "error"
                                : ""
                            }`}
                          >
                            {touched.start_date && errors.start_date
                              ? "This field is invalid"
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="element-form">
                          <label className="label">
                            End date <span className="asterik">*</span>
                          </label>
                          <Calendar
                            id="basic"
                            name="end_date"
                            panelClassName="expire-calendar"
                            value={values.end_date}
                            onBlur={handleBlur}
                            onChange={(item) => {
                              setFieldValue("end_date", item.value, false);
                            }}
                            dateFormat="dd.mm.yy"
                          />
                          <div
                            className={`${
                              touched.end_date && errors.end_date ? "error" : ""
                            }`}
                          >
                            {touched.end_date && errors.end_date
                              ? "This field is invalid"
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="btn-submit-code">
                      <Button
                        type="submit"
                        label={coupon ? "Edit" : "Create"}
                        icon="pi pi-chevron-right"
                        disabled={!(isValid && dirty)}
                        iconPos="right"
                        loading={isLoading}
                        className="p-button-rounded btn-submit wi-button"
                        onClick={generateCoupon}
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CreateCouponDialog;
