import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import Layout from "../../layout/layout";
import { FAQsManagerAPI } from "../../../services";
import { Sidebar } from 'primereact/sidebar';
import { DataTable } from "primereact/datatable";
import { Column, ColumnAlignType } from "primereact/column";
import "./footers-manager.scss";
import FootersDialogComponent from "./components/footers-dialog-component";
import { getInternalURLPages, fetchCMSAllPages } from "../../pages/builder-page.common";
import { buildActionBtnTemplate, formatDateBodyTemplate, formatNameBodyTemplate } from "../../common/column-template-table/column-template";

export const FootersManager = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [footers, setFooters] = useState<any[]>();
  const [footer, setFooter] = useState<any>();
  const [search, setSearch] = useState<any>(null);
  const toast = useRef(null);
  const [globalContent, setGlobalContent] = useState<any>({
    search_content: []
  });

  const fetchData = async () => {
    setIsLoading(true);
    const result = await FAQsManagerAPI.getFooters();
    // @ts-ignore: Object is possibly 'null'.
    if (result.status === 200 && result.data.records) {
      setFooters(result.data.records);
      setIsLoading(false);
    }
  };

  const onCreateFooters = (isCreating: boolean, e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setFooter(null);
    setIsShowSidebar(true);
  };

  const getSearchContent = () => {
    fetchCMSAllPages().then( async (allPages: any) => {
      let internal_urls = await getInternalURLPages(allPages);
      setGlobalContent({ search_content : { internal_urls: internal_urls } })
      }).catch((err: any) => console.log("err", err));
  }

  useEffect(() => {
    getSearchContent();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateFooters = async (e: any, rowData: any) => {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);
    const res = await FAQsManagerAPI.getFooter(rowData.uuid);
    if (res && res.status === 200) {
      setFooter(res.data.record);
      setIsLoading(false);
      setIsShowSidebar(true);
    }
  };

  const onDeleteFooters = async (e: any, rowData: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const listActionBtns = [
    {
      title: "Edit Footers",
      className: "fa-solid fa-pencil edit-icon",
      btnFunc: onUpdateFooters,
    },
  ]

  const tableColumns = [
    { field: 'name', header: 'Name', width: "calc(74% - 50px)", align: 'left', BodyTemplate: formatNameBodyTemplate("footer")},
    { field: 'created', header: 'Created At', width: "13%", align: 'left', BodyTemplate: formatDateBodyTemplate("DD.MM.YYYY")},
    { field: 'changed', header: 'Updated At', width: "13%", align: 'left', BodyTemplate: formatDateBodyTemplate("DD.MM.YYYY")},
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return <Column
      key={col.field}
      field={col.field}
      header={col.header}
      className={col.field}
      style={{width: col.width}}
      body={col.BodyTemplate}
      align={col.align as ColumnAlignType}
    />;
  });

  return <>
    <Layout>
      <Toast ref={toast} />
      <div className="footers-manager">
        <div className="manager-container">
          <div className="certificate">
            <div className="headline">
              <h3>Footers</h3>
            </div>
          </div>
          <div className="table-footers">
            <DataTable
              loading={isLoading}
              value={footers}
              filters={search}
              globalFilterFields={['name.de', 'name.en']}
              emptyMessage="No footers found."
              scrollable
              scrollHeight="600px"
              scrollDirection="both"
            >
              {dynamicColumns}
              <Column
                frozen
                alignFrozen="right"
                style={{ width: '50px' }} 
                body={(rowData) => buildActionBtnTemplate(rowData, listActionBtns)}></Column>
            </DataTable>
          </div>
          <Sidebar 
            visible={isShowSidebar} 
            position="right" 
            className="p-sidebar-md" 
            style={{width:'40em'}}
            onHide={() => setIsShowSidebar(false)}>
              <FootersDialogComponent
                footer={footer}
                fetchFootersData={fetchData}
                globalContent={globalContent}
                onHide={() => setIsShowSidebar(false)}
              />
            </Sidebar>
        </div>
      </div>
    </Layout>
  </>;
};

export default FootersManager;
