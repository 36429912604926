import React from "react";
import { useRef } from "react";
import { Dialog } from "primereact/dialog";
import { AxiosResponse } from "axios";
import { Toast } from "primereact/toast";
import { showNotification } from "../../../../utils/logic";
import { PartnerTypeBlock } from "../utils";
import { PartnerTypeManagerAPI } from "../../../../services";
import DynamicForm from "../../../dynamic-form/dynamic-form";
import "./create-partner-type-dialog.scss";

const CreatePartnerTypeDialogComponent = (props: any) => {
  const { visible, onHide, partnerType, fetchPartnerTypeData } = props;
  const toast = useRef(null);
  const block = { ...PartnerTypeBlock };

  const onSubmitDataForm = async (value: any) => {
    try {
      const resGeneratePartnerType = await requestPartnerTypeData(value);
      handleRequestPartnerType(resGeneratePartnerType);
    } catch (error) {
      showNotification("error", "Failed to generate partner type", toast);
    }
  };

  const handleRequestPartnerType = (resGeneratePartnerType: AxiosResponse<any, any>) => {
    // @ts-ignore: Object is possibly 'null'.
    const response = resGeneratePartnerType;
    let method = response.config.method;

    if (
      response &&
      response.status === 200 &&
      (method === "post" || method === "put")
    ) {
      let mesage =
        method === "post"
          ? "Generate partner type successfully"
          : "Update partner type successfully";
      showNotification("success", mesage, toast);
      onHide();
      fetchPartnerTypeData();

    } else {
      showNotification("error", "Failed to generate partner type", toast);
    }
  };

  const requestPartnerTypeData = async (value: any) => {
    // @ts-ignore: Object is possibly 'null'.
    if (partnerType) {
      // @ts-ignore: Object is possibly 'null'.
      const partnerTypeObj = partnerType;

      let request = { ...partnerTypeObj, ...value };
      return updatePartnerType({
        ...request,
      });
    }

    return generatePartnerType({
      ...value,
    });
  };

  const generatePartnerType = async (parameters: any) => {
    return await PartnerTypeManagerAPI.createPartnerType(parameters);
  };

  const updatePartnerType = async (parameters: any) => {
    partnerType.name = parameters.name;
    partnerType.description = parameters.description;
    partnerType.status = parameters.status;

    return await PartnerTypeManagerAPI.updatePartnerType(partnerType);
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        className="create-partner-type-dialog wi-dialog"
        header={partnerType?.uuid ? "Update Type" : "New Type"}
        visible={visible}
        style={{ width: "600px" }}
        modal
        onHide={onHide}
      >
        <DynamicForm
          formSchema={block?.configuration || {}}
          data={
            partnerType
              ? {
                    name: partnerType.name,
                    description: partnerType.description,
                    status: partnerType.status
                }
              : block?.parameters
          }
          submitButtonConfig={{
            title: partnerType?.uuid ? "Update" : "Create",
            icon: "pi pi-chevron-right",
            iconPos: "right",
          }}
          onSubmitData={onSubmitDataForm}
          mode="dialog"
        ></DynamicForm>
      </Dialog>
    </>
  );
};

export default CreatePartnerTypeDialogComponent;
