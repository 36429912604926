import _ from "lodash"; 
import { generatePageData } from "../../utils/commonGeneratePageData"; 

export const generatePartnerPageData = (formikValues: any, parentPages: any, cmsPage: any, partnerPageType: any, allBlocks: any, footers: any, headers: any, partnerId: any, isPublished = false) => {
    let mappings: any = {
        'image-content-header': {
            'headline.de': formikValues.name,
            'subHeadline.de': formikValues.slogan?.de,
            'picture': formikValues.headline_image,
            'mobile_picture': formikValues.mobile_headline_image
        },
        'partner-box': {
            'partner.partner_logo': formikValues.partner_logo,
            'partner.description.de': formikValues.fact?.de,
            'partner.profile_url': formikValues.profile_url
        },
        'paragraph-with-title': {
            'headline.de': '',
            'paragraph.de': formikValues.description?.de
        }
    };

    return generatePageData(formikValues, parentPages, cmsPage, partnerPageType, mappings, allBlocks, footers, headers, partnerId, formikValues.headline_image, isPublished);
}