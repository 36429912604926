import { Dialog } from "primereact/dialog";
import React, { useRef, } from "react";
import { Toast } from "primereact/toast";
import DynamicForm from "../../../../dynamic-form/dynamic-form";
import { showNotification } from "../../../../../utils/logic";
import { AxiosResponse } from "axios";
import { MediaFolderManagerAPI } from "../../../../../services";
import "./create-directory-dialog.scss";
import _ from "lodash";

export const DirectoryBlock = {
  configuration: {
    name: {
      type: "textField",
      label: "Name",
      isRequired: true,
      maxLength: 50,
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["Name is required"],
        },
        {
          type: "max",
          params: [50, "Max length is 50 letters"],
        },
        {
          type: "matches",
          params: ['^[^\\\\/?*"><:|]*$', 'Name cannot content any of the following character: \\ / : * ? " < > |'],
        },
      ],
    },
    parentId: {
      type: "",
      label: "Parent Folder Id",
      maxLength: 36,
    },
  },
  parameters: {
    parentId: null
  }
};

const CreateDirectoryDialogComponent = (props: any) => {
  const { visible, fetchMediaFolder, onHide, data, isAllNodes} = props;
  const toast = useRef(null);
  const block = { ...DirectoryBlock };

  const onSubmitDataForm = async (value: any) => {
    try {
      const counter = 0;
      let folders = data.selectedNode?.node?.children || data.allNodes[0]?.children
      if (isAllNodes) {
        folders = data.allNodes;
      }
      const folderName = verifyFolderName(folders, value.name, counter);
      value.name = folderName;
      const resGenerateDirectory = await requestDirectoryData(value);
      handleRequestDirectory(resGenerateDirectory);

    } catch (error) {
      console.log("Error:", error);
      showNotification("error", "Failed to generate folder", toast);
    }
  };

  const requestDirectoryData = async (value: any) => {
    // @ts-ignore: Object is possibly 'null'.
    if (data.directory) {
      // @ts-ignore: Object is possibly 'null'.
      const directoryObje = data.directory;

      let request = { ...directoryObje, ...value };
      return updateDirectory({
        ...request,
      });
    }

    return generateDirectoryV2({
      ...value,
    });
  };

  const handleRequestDirectory = (
    resGenerateDirectory: AxiosResponse<any, any>
  ) => {
    // @ts-ignore: Object is possibly 'null'.
    const response = resGenerateDirectory;
    let method = response.config.method;
    if (
      response &&
      response.status === 200 &&
      (method === "post" || method === "put")
    ) {
      let mesage =
        method === "post"
          ? "Generate folder successfully"
          : "Update folder successfully";
      showNotification("success", mesage, toast);
      onHide();
      fetchMediaFolder();
    } else {
      showNotification("error", "Failed to generate folder", toast);
    }
  };

  const generateDirectoryV2 = async (parameters: any) => {
    parameters["parentId"] = null;
    if (data.selectedNode && data.selectedNode.node) {
      parameters["parentId"] = data.selectedNode.node?.uuid;
    }
    parameters["createdUser"] = "admin";
    return await MediaFolderManagerAPI.createMediaFolderV2(parameters);
  };

  const updateDirectory = async (parameters: any) => {
    // Only update Name
    data.directory.name = parameters.name;
    data.directory.parentId = parameters.parentId;

    return await MediaFolderManagerAPI.updateMediaFolderV2(data.directory);
  };

  const verifyFolderName = (nodes: any, folderName: string, counter:any): string => {
    if (counter > nodes?.length) return `${folderName} (${counter})`;
    
    let name = folderName;
    const isDuplicated = checkDuplicateFolder(nodes, folderName, counter);

    if (isDuplicated) {
      counter = counter + 1;
      name = verifyFolderName(nodes, folderName, counter);
    } else if (!isDuplicated && counter > 0) {
      name = `${folderName} (${counter})`;
    }

    return name;    
  };

  const checkDuplicateFolder = (nodes: any, newNode: any, subfix: any) => {
    const checkName = subfix > 0 ? `${newNode} (${subfix})` : newNode;
    return _.some(nodes, (item: any) => item.label === checkName);
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        maskClassName="top-mask-dialog"
        className="create-directory-dialog wi-dialog"
        header={data.directory?.uuid ? "Update Folder" : "New Folder"}
        visible={visible}
        style={{ width: "30vw" }}
        modal
        onHide={onHide}
      >
        <DynamicForm
          formSchema={block.configuration || {}}
          data={
            data.directory
              ? {
                  name: data.directory.name,
                  parentId: data.directory.parent_folder_id
                }
              : block?.parameters
          }
          submitButtonConfig={{
            title: data.directory?.uuid ? "Update" : "Create",
            // icon: "pi pi-chevron-right",
            // iconPros: "right",
          }}
          onSubmitData={onSubmitDataForm}
          mode="dialog"
        />
      </Dialog>
    </>
  );
};

export default CreateDirectoryDialogComponent;
