import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../../layout/layout";
import CreateContactFormComponent from "./create-contact-form/create-contact-form";
import { findCmsPage } from "../../../../utils/commonGeneratePageData";
import {
  fetchPageTypes,
  fetchCMSParentPages,
  getPageTypeByCode,
} from "../../../pages/builder-page.common";
import { PAGE_CODE } from "../../../pages/utils";
import { CMsPageManagerAPI, ContactManagerAPI } from "../../../../services";

export const CreateContactManager = () => {
  let { id } = useParams();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [person, setPerson] = useState<any>(null);
  const [cmsPersonPages, setCmsPersonPages] = useState<any>([]);
  const [cmsMasterPages, setCmsMasterPages] = useState<any>([]);
  const [personPageType, setPersonPageType] = useState<any>();
  const [parentPages, setParentPages] = useState<any>([]);

  useEffect(() => {
    if (id) {
      fetchPersonData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPersonData = async (uuid: string) => {
    setIsLoading(true);
    try {
      const res = await ContactManagerAPI.getContact(uuid);
      if (res && res.status === 200) {
        setPerson(res.data.record);
        setIsLoading(false);
      }
    } catch (error) {
      setPerson(null);
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    let pageTypes = await fetchPageTypes();
    let personPageType = getPageTypeByCode(pageTypes, PAGE_CODE.Person);
    let masterPageType = getPageTypeByCode(pageTypes, PAGE_CODE.Master);

    let [personRes, masterRes] = await Promise.all([
      CMsPageManagerAPI.getPages(personPageType?.uuid, {
        pageNo: 0,
        range: 10000,
      }),
      CMsPageManagerAPI.getPages(masterPageType?.uuid, {
        pageNo: 0,
        range: 10000,
      }),
    ]);

    let personPages = personRes?.data?.records;
    let masterPages = masterRes?.data?.records;

    setPersonPageType(personPageType);
    setCmsPersonPages(personPages);
    setCmsMasterPages(masterPages);

    let parentPages = fetchCMSParentPages(masterPages);
    setParentPages(parentPages);

    setIsLoading(false);
  };

  return (
    <Layout customLayout={true} customCopyRight={true}>
      {isLoading ? (
        <div className="loading-component">
          <ProgressSpinner />
        </div>
      ) : (
        <></>
      )}
      <Toast ref={toast} />
      <div className="d-flex flex-column w-100 text-left">
        <CreateContactFormComponent
          personPageType={personPageType}
          contact={person}
          parentPages={parentPages}
          cmsPersonPage={findCmsPage(cmsPersonPages, person)}
          cmsPersonPages={cmsPersonPages}
          cmsMasterPages={cmsMasterPages}
          toast={toast}
        />
      </div>
    </Layout>
  );
};

export default CreateContactManager;
