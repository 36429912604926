import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import Layout from "../../layout/layout";
import { CMsPageManagerAPI } from "../../../services";
import { Sidebar } from 'primereact/sidebar';
import { DataTable } from "primereact/datatable";
import { Column, ColumnAlignType } from "primereact/column";
import "./global-block.scss";
import GlobalBlockDialogComponent from "./components/global-block-component";
import { GLOBAL_BLOCKS } from "../../../utils/utils";
import { buildActionBtnTemplate, formatDateBodyTemplate, formatNameBodyTemplate } from "../../common/column-template-table/column-template";

export const GlobalBlockManager = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [allGlobalBlock, setAllGlobalBlock] = useState<any[]>();
  const [globalBlock, setGlobalBlock] = useState<any>();
  const [search, setSearch] = useState<any>(null);
  const toast = useRef(null);

  const fetchData = async () => {
    setIsLoading(true);
    const result = await CMsPageManagerAPI.getCTABlocks(false);
    // @ts-ignore: Object is possibly 'null'.
    if (result.status === 200 && result.data.records) {
      const globalBlocks = result.data.records.filter((block: any) => GLOBAL_BLOCKS.includes(block.code));
      
      setAllGlobalBlock(globalBlocks);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateGlobalBlock = async (e: any, rowData: any) => {
    e.preventDefault();
    e.stopPropagation();

    setGlobalBlock({...rowData, parameters: {  ...rowData.parameters, is_global_ref: false }});
    setIsShowSidebar(true);

  };

  const listActionBtns = [
    {
      title: "Edit Global Content",
      className: "fa-solid fa-pencil edit-icon",
      btnFunc: onUpdateGlobalBlock,
    },
  ]

  const tableColumns = [
    { field: 'name', header: 'Name', width: "calc(74% - 50px)", align: 'left', BodyTemplate: formatNameBodyTemplate("global")},
    { field: 'created', header: 'Created At', width: "13%", align: 'left', BodyTemplate: formatDateBodyTemplate("DD.MM.YYYY")},
    { field: 'changed', header: 'Updated At', width: "13%", align: 'left', BodyTemplate: formatDateBodyTemplate("DD.MM.YYYY")},
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return <Column
      key={col.field}
      field={col.field}
      header={col.header}
      className={col.field}
      style={{width: col.width}}
      body={col.BodyTemplate}
      align={col.align as ColumnAlignType}
    />;
  });

  return <>
    <Layout>
      <Toast ref={toast} />
      <div className="footers-manager">
        <div className="manager-container">
          <div className="certificate">
            <div className="headline">
              <h3>Global Blocks</h3>
            </div>
          </div>
          <div className="table-footers">
            <DataTable
              loading={isLoading}
              value={allGlobalBlock}
              filters={search}
              globalFilterFields={['name.de', 'name.en']}
              emptyMessage="No global block found."
              scrollable
              scrollHeight="600px"
              scrollDirection="both"
            >
              {dynamicColumns}
              <Column
                frozen
                alignFrozen="right"
                style={{ width: '50px' }} 
                body={(rowData) => buildActionBtnTemplate(rowData, listActionBtns)}></Column>
            </DataTable>
          </div>
          <Sidebar 
            visible={isShowSidebar} 
            position="right" 
            className="p-sidebar-md global-blocks-dialog" 
            style={{width:'40em'}}
            onHide={() => setIsShowSidebar(false)}>
              <GlobalBlockDialogComponent
                globalBlock={globalBlock}
                fetchGlobalBlockData={fetchData}
                onHide={() => setIsShowSidebar(false)}
              />
            </Sidebar>
        </div>
      </div>
    </Layout>
  </>;
};

export default GlobalBlockManager;
