import React, { useEffect } from "react";
import "./404-component.scss";
import Layout from "../layout/layout";
import { SKIP_GRID } from '../../utils/utils';

const NotFoundPage = () => {

let treeCount = 364;
let easterEggs = Math.round(treeCount * 0.5);
let buttons;
const gridDebug = false;
const waterCount = SKIP_GRID.length - 1;
let isIntital = false;

const setUpCanvas = (treeCount: any, forestElt: any) => {
    if(!isIntital) {
        const randomEasterEggs = getEasterEggs();

        const spawnIcons = new Map();
        spawnIcons.set(getSpawnPoint(), { name: "whale" });
        spawnIcons.set(getSpawnPoint(), { name: "boat" });
        spawnIcons.set(getSpawnPoint(), { name: "shark" });
        spawnIcons.set(getSpawnPoint(), { name: "dolphine" });
        spawnIcons.set(getSpawnPoint(), { name: "marlin" });
      
        for (let i = 0; i < treeCount; i++) {
          const treeHTML = document.createElement("div");
      
          let itemClassName = "tree";
      
          if (SKIP_GRID.includes(i)) {
            itemClassName = "water";
          }
      
          if (spawnIcons.get(i)) {
            itemClassName = spawnIcons.get(i).name;
          }
      
          if (randomEasterEggs.includes(i)) {
            treeHTML.innerHTML = `<div class="${itemClassName}" data-easter-egg="true">${
              gridDebug ? i : ""
            }</div>`;
          } else {
            treeHTML.innerHTML = `<div class="${itemClassName}">${
              gridDebug ? i : ""
            }</div>`;
          }
      
          treeHTML.classList.add(`${itemClassName}_wrapper`);
          treeHTML.dataset.gridItemType = `${itemClassName}`;
      
          forestElt.appendChild(treeHTML);
        }
      
        const progressBarWrapper = document.createElement("div");
        progressBarWrapper.id = "abw_progressbar";
        progressBarWrapper.innerHTML = `<div id="progressBar"></div>`;
        forestElt.after(progressBarWrapper);
    }
    isIntital = true;
  
}

const initEventListener = (forestElt: any) => {
  const bar = document.getElementById("progressBar");

  forestElt.addEventListener(
    "mouseenter",
    (e: any) => {
      if (!e.target.dataset.gridItemType) return;

      e.target.classList.add(`${e.target.dataset.gridItemType}_grow`);

      updateProgress(bar);
    },
    true
  );

  forestElt.addEventListener(
    "touchmove",
    (e: any) => {
      if (!e.target.dataset.gridItemType) return;

      e.target.classList.add(`${e.target.dataset.gridItemType}_grow`);

      updateProgress(bar);

      e.preventDefault();
      e.stopPropagation();
    },
    false
  );

  const modal: any = document.getElementById("abw_modal");

  document.addEventListener(
    "click",
    (e: any) => {
      if (e.target.matches(".aw_modal, .aw_modal *:not(#abw_close_modal)"))
        return;

      if (e.target.dataset.easterEgg === "true") return;

      modal.close();
    },
    false
  );

  forestElt.addEventListener("click", (e: any) => {
    if (e.target.dataset.easterEgg === "true") return;
    modal.show();
  });
}

const updateProgress = (bar: any) => {
  const grownTrees = +document.querySelectorAll("#forest .tree_grow").length;

  bar.style.width = `${(grownTrees / (treeCount - waterCount)) * 100}%`;

  revealBtns(grownTrees / (treeCount - waterCount));

  if (grownTrees === treeCount - waterCount && !bar.dataset.done) {
    bar.dataset.done = "true";
    showAllDoneModal();
  }
}

const revealBtns = (ratio: any) => {
  const buttonCount: any = buttons.length;

  const btnsToReveal = Math.round(buttonCount * ratio);

  const hiddenBtns = document.querySelectorAll("#abw_btn_wrapper a");

  for (let i = 0; i < btnsToReveal; i++) {
    hiddenBtns[i].classList.add("aw_no_blur");
  }
}

const showAllDoneModal = () => {
  const modal: any = document.getElementById("abw_modal");
  modal.show();
}

const getRandNo = () => {
  return Math.floor(Math.random() * treeCount);
}

const getEasterEggs = () => {
  let randomEasterEggs = [];
  for (let i = 0; i < easterEggs; i++) {
    randomEasterEggs.push(getRandNo());
  }

  return randomEasterEggs;
}

const getSpawnPoint = () => {
  let spawnPoint = Math.floor(Math.random() * treeCount);

  while (!SKIP_GRID.includes(spawnPoint)) {
    spawnPoint = Math.floor(Math.random() * treeCount);
  }

  return spawnPoint;
}

    useEffect(() => {
        const forestElt = document.getElementById("forest");
          
        if (!forestElt) return;

            forestElt.classList.toggle("noun-world-map", gridDebug);
        
            buttons = document.querySelectorAll("#abw_btn_wrapper a");
            
            setUpCanvas(treeCount, forestElt);
            initEventListener(forestElt);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

 
  return (
    <Layout>
      <div className="not-found-page">
        <h1>404 - Page not found </h1>
        <dialog className="aw_modal" id="abw_modal">
            <div className="dia-log">
                <div className="btn-container">
                    <button id="abw_close_modal"><i className="fa-solid fa-circle-xmark"></i></button>
                </div>
                <h2>Easter Egg / Finished</h2>
                <p>Change content dynamically based on context. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae dolorum cupiditate impedit perferendis, mollitia iste molestias optio, vero harum nobis quod a sapiente recusandae.</p>
            </div>
        </dialog>

        <div id="forest"></div>
        <div id="abw_btn_wrapper">
        <a href="https://www.wilderness-international.org/lp/secret-forest.html">Link 1</a>
        <a href="#">Link 2</a>
        <a href="#">Link 3</a>
        <a href="#">Link 4</a>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage;