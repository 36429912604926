import * as React from "react";
import { useEffect, useState } from "react";
import "./donation-filter-component.scss";
import { Button } from "primereact/button";
import { DONATION_CURRENCY_LIST, DONATION_PAYMENT_METHOD_LIST, DONATION_STATUS_LIST, DONATION_TYPE_LIST, RHYTHM_LIST } from "../../../../utils/utils";
import FilterField from "./filter-field";
import { TabMenu } from "primereact/tabmenu";
import { WISearchField } from "../../../common";
import CalendarField from "./calendar-field";
import _ from 'lodash';
import MenuButton from "../../../common/menu-button/menu-button";

const DonationFilterComponent = (props: any) => {
  const { fields, items, lang, selectedItem, isStatusAll, onStatusChange, onRemove, onChange, onSearchFunc, filterValues, onAddDonation, onReserveDonation, area, campaigns, regions } = props;
  const [currency, setCurrency] = useState<any[]>(DONATION_CURRENCY_LIST);
  const [status, setStatus] = useState<any[]>(DONATION_STATUS_LIST.filter(c => c.code !== 'reserved'));
  const [payments, setPayments] = useState<any[]>(DONATION_PAYMENT_METHOD_LIST);
  const [donationTypes, setDonationTypes] = useState<any[]>(DONATION_TYPE_LIST);
  const [rhythms, setRhythms] = useState<any[]>(RHYTHM_LIST);
  const [values, setValues] = useState<Map<string, string[]>>();
  const [options, setOptions] = useState<any[]>([]);
  const [isDirty, setDirty] = useState<boolean>(false);
  const [search, setSearch] = useState<any>(filterValues.search);


  const getFields = () => {
    const resetValues = new Map<string, string[]>();
    fields.forEach((item: string) => {
      resetValues.set(item, []);
    });
    setValues(resetValues);
  };

  const ucFirst = (text: string): string => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  useEffect(()=> {
    getFields();
  }, []);

  useEffect(() => {
    const filter = new Map<string, string[]>();
    fields.forEach((k: string) => {
      if(k === 'dates' && filterValues.where.from && filterValues.where.to) {
        filter.set(k, [filterValues.where.from, filterValues.where.to]);
      } else {
          filter.set(k, filterValues.where[k]);
      }
    });
    setValues(filter);
    setSearch(filterValues.search);
  }, [filterValues]);

  useEffect(() => {
    const list = items.map((c: any, i: number) => {
      return {
        key: i,
        label: ucFirst(c.name[lang]),
        code: c.code,
      };
    });
    setOptions(list);
  }, [items]);

  const onRemoveFilters = async () => {
    fields.forEach(async (item: string) => {
      values?.set(item, []);
    });
    setSearch('');
    await onRemove();
  };

  const onFilterChange = async (event: any) => {
    setDirty(true);
    values?.set(event.field, event.value);
    await onChange({field: event.field, value: event.value});  
  };

  const onActiveIndex = (selectedItem: any): number => {
    return options.findIndex((c) => c.code === selectedItem);
  };

  const Action_List = () => {
    return [
      {
        label: "Add",
        description: "Add a donation",
        icon: "fa-solid fa-circle-plus",
        command: (e: any) => {
          onAddDonation();
        }
      },
      {
        label: "Import",
        description: "Import a list of donations",
        icon: "fa-solid fa-file-import",
        url: '/import'
      },
      {
        label: "Reserve",
        description: "Reserve a donation",
        icon: "fa-solid fa-circle-plus",
        command: (e: any) => {
          onReserveDonation();
        }
      },
    ]
  };

  return (
    <div className="filter-donation-component">
      <div className="status-panel">
        <TabMenu
          className="tabmenu-donation"
          model={options}
          activeIndex={onActiveIndex(selectedItem)}
          onTabChange={(e: any) => onStatusChange(e.value)}
        />
      </div>
      <div className="flex align-items-center flex-wrap justify-content-between filter-panel">
        <div className="filter-container">
          <WISearchField
              icon={"pi pi-search"}
              placeholder="Search for anything"
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={() => onSearchFunc(search)}
              globalValue={search}
          />
          <FilterField
            name="region_id"
            label="Region"
            className="wi-filter-option-donation"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get("region_id") || []}
            options={regions}
            optionLabel={"name.de"}
            optionValue={"uuid"}
            visible={values?.has("region_id") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
            filter
            filterBy="name.de,code"
          />
          <FilterField
            name="land_id"
            label="Area"
            className="wi-filter-option-donation"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get("land_id") || []}
            options={area}
            optionLabel={"name.de"}
            optionValue={"uuid"}
            visible={values?.has("land_id") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <FilterField
            name="campaign_id"
            label="Campaign"
            className="wi-filter-option-donation"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get("campaign_id") || []}
            options={campaigns}
            optionLabel={"name.de"}
            optionValue={"uuid"}
            visible={values?.has("campaign_id") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
            filter
            filterBy="name.de,code"
          />
          <FilterField
            name="payment_currency_code"
            label="Currency"
            className="wi-filter-option-donation"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get("payment_currency_code") || []}
            options={currency}
            optionLabel={"name.de"}
            optionValue={"code"}
            visible={values?.has("payment_currency_code") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <FilterField
            name="status"
            label="Status"
            className="wi-filter-option-donation"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get("status")  || []}
            options={status}
            optionLabel={"name.de"}
            optionValue={"code"}
            visible={isStatusAll}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <FilterField
            name="payment_method"
            label="Method"
            className="wi-filter-option-donation"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get("payment_method")  || []}
            options={payments}
            optionLabel={"name.de"}
            optionValue={"code"}
            visible={values?.has("payment_method") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
            filter
            filterBy="name.de,code"
          />
          <FilterField
            name="donation_type"
            label="Type"
            className="wi-filter-option-donation"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get("donation_type")  || []}
            options={donationTypes}
            optionLabel={"name.de"}
            optionValue={"code"}
            visible={values?.has("donation_type") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <FilterField
            name="rhythm"
            label="Rhythm"
            className="wi-filter-option-donation"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get("rhythm")  || []}
            options={rhythms}
            optionLabel={"name"}
            optionValue={"code"}
            visible={values?.has("rhythm") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <FilterField
            name="subsequent"
            label="Subsequent"
            className="wi-filter-option-donation"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get("subsequent")  || []}
            options={[{ 'code': 1, 'name': 'All' }, { 'code': 0, 'name': 'Subsequent Only' }]}
            optionLabel={"name"}
            optionValue={"code"}
            visible={values?.has("subsequent") || true}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <CalendarField
            name="dates"
            label="Dates"
            className="wi-filter-option-donation"
            pannelClassName="overlaypanel-filter-donations"
            selectedItems={values?.get("dates") || []}
            visible={values?.has("dates") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
        </div>
        <div className="remove-filter-button">
          <Button
            type="button"
            icon="pi pi-times"
            label="Clear filter(s)"
            onClick={onRemoveFilters}
          />
        </div>
        <div className="actions-donations">
            <MenuButton label="Actions" items={Action_List()}></MenuButton>
        </div>
      </div>
    </div>
  );
};

export default DonationFilterComponent;
