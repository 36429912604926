import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { WIButton, WIPaginator, WISearchField } from "../common";
import Layout from "../layout/layout";
import "./block-manager.scss";
import { CMsPageManagerAPI } from "../../services";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { dynamicBlockColumns } from "./block-common";
import _ from "lodash";

const BlockManager = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [block, setBlock] = useState<any>(null);
  const [CTABlocks, setCTABlocks] = useState({
    data: [],
    totalPage: 0,
  });
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const [first, setFirst] = useState(0);
  const toast = useRef(null);

  const getCMSPageData = async () => {
    setIsLoading(true);
    const { data: { records } } = await CMsPageManagerAPI.getCTABlocks();
    const blocks = [...records];
    setCTABlocks({
      data: records,
      totalPage: 1
    })
    setIsLoading(false);
  };

  useEffect(() => {
    getCMSPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === "number") {
      getCMSPageData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const onEdit = async (e: any, rowData: any) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/blocks/update/${rowData.uuid}`, { state : { toastRef: toast }});
  };

  const onDelete = async (e: any, rowData: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const actionEditDeleteTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <div className="btn-action">
          <i className="pi pi-user-edit edit-icon" onClick={(e) => onEdit(e, rowData)}></i>
          <i className="pi pi-trash delete-icon" onClick={(e) => onDelete(e, rowData)}></i>
        </div>
      </React.Fragment>
    );
  };

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const onShowCreateDialog = (isVisible: boolean) => {
    setBlock(null);
    navigate('/blocks/create');
  };

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="wi-block-manager block-manager">
        <div className="block-manager-container">
          <div className="certificate">
            <div className="d-flex flex-column flex-md-row justify-content-start justify-content-md-between certificate-action">
              <WISearchField
                icon={"pi pi-search"}
                placeholder="Search for anything"
                setSearch={(value: any) => setSearch(value)}
              />
              <div className="btn-create">
                <WIButton
                  primary={true}
                  type={"button"}
                  size={"small"}
                  label="Add New"
                  icon={"pi-angle-right"}
                  onClick={() => onShowCreateDialog(true)}
                />
              </div>
            </div>
          </div>
          <div className="table-blocks">
            <DataTable
              loading={isLoading}
              value={CTABlocks.data}
              filters={search}
              globalFilterFields={['name', 'type']}
              scrollable
              scrollHeight="600px"
              scrollDirection="both"
            >
              {dynamicBlockColumns}
              <Column
                frozen
                alignFrozen="right"
                style={{ width: "10%" }}
                body={actionEditDeleteTemplate}
              ></Column>
            </DataTable>
            <WIPaginator
              first={first}
              rows={rows}
              totalRecords={CTABlocks.totalPage}
              onPageChange={onBasicPageChange}
              rowsPerPageOptions={[5, 10, 15, 20]}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlockManager;
