import * as React from "react";
import { useEffect, useState } from "react";
import "../../../coupon-manager/components/coupon-filter/coupon-filter-component";
import { Button } from "primereact/button";
import { RegionManagerAPI } from "../../../../services";
import { WISearchField } from "../../../common"; 
import FilterField from "../../../donation-manager/components/donation-filter/filter-field";

const CampaignFilterComponent = (props: any) => {
    const { fields, filterValues, lang, onRemove, onChange, onSearchFunc } = props;
    const [region, setRegion] = useState<any[]>([]);
    const [values, setValues] = useState<Map<string, string[]>>();
    const [isDirty, setDirty] = useState<boolean>(false);
    const [search, setSearch] = useState<any>(filterValues.search);

    const getAllOptionsData = async () => {
        const regionRes = await RegionManagerAPI.getAllRegions();
        if (regionRes.status === 200 && regionRes.data.records.length > 0) {
            setRegion(regionRes.data.records);
        }
    };

    const getFields = () => {
        const resetValues = new Map<string, string[]>();
        fields.forEach((item: string) => {
            resetValues.set(item, []);
        });
        setValues(resetValues);
    };

    const ucFirst = (text: string): string => {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    useEffect(()=> {
        getAllOptionsData();
        getFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const filter = new Map<string, string[]>();
        fields.forEach((k: string) => {
            filter.set(k, filterValues.where[k]);
        });
        setValues(filter);
        setSearch(filterValues.search);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues]);

    const onRemoveFilters = async () => {
        fields.forEach(async (item: string) => {
            values?.set(item, []);
        });
        setSearch('');
        await onRemove();
    };

    const onFilterChange = async (event: any) => {
        setDirty(true);
        values?.set(event.field, event.value);
        await onChange({field: event.field, value: event.value});  
        setSearch(search);
    };

    return (
        <div className="filter-coupon-component">
            <div className="flex align-items-center flex-wrap justify-content-between filter-panel">
                <div className="filter-container">
                    <WISearchField
                        icon={"pi pi-search"}
                        placeholder="Search for anything"
                        setSearch={(value: any) => setSearch(value.global.value)}
                        enterSearch={() => onSearchFunc(search)}
                        globalValue={search}
                    />
                    <FilterField
                        name="region_id"
                        label="Region"
                        className="wi-filter-option-coupon"
                        pannelClassName="overlaypanel-filter-coupons"
                        selectedItems={values?.get("region_id") || []}
                        options={region}
                        optionLabel={"name.de"}
                        optionValue={"uuid"}
                        visible={values?.has("region_id") || false}
                        isDirty={isDirty}
                        setDirty={setDirty}
                        onChange={onFilterChange}
                        filter
                        filterBy="name.de,code"
                    />
                </div>
                <div className="remove-filter-button">
                <Button
                    type="button"
                    icon="pi pi-times"
                    label="Clear filter(s)"
                    onClick={onRemoveFilters}
                />
                </div>
            </div>
        </div>
    );
};

export default CampaignFilterComponent;
