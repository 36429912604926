import * as React from "react";
import { useFormikContext } from "formik";
import { useEffect, useRef } from "react";
import "./create-block-form.scss";
import _ from "lodash";
import html2canvas from "html2canvas";
import { replaceParameter } from "../../../pages/utils";
import { buildTemplateHTML } from "../../../../utils/templateHTML";

const IFrameContent = (props: any) => {
    const { components } = props;
    let { headHtml } = props;
    const { setFieldValue } = useFormikContext();
    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const attributeHTML: any = {
        'style': `
        <style type="text/css">
          .selected-component {
            border: 2px dashed #13735f;
            border-radius: 10px;
          }
  
          .unselected-component {
            opacity: 0.1;
          }
        </style>
      `,
        'script': `
        <script type="text/javascript">
          window.smoothScroll = function(target) {
            target.scrollIntoView({behavior: "smooth"});
          }
  
          document.addEventListener("DOMContentLoaded", function(e) {
            var btnCollection = document.getElementsByClassName('aw_btn');
            for (var i = 0; i < btnCollection.length; i++) {
              btnCollection[i].setAttribute("target", "_blank");
            }
          });
        </script>
      `
    };

    Object.keys(attributeHTML).forEach((f: string) => {
        headHtml = replaceParameter(headHtml, `{{parameters.${f}}}`, attributeHTML[f]);
    })
    const html = buildTemplateHTML(components, headHtml);

    const getImageThumbnail = async (iframe: any) => {
        if (iframe && iframe.contentWindow) {
            // @ts-ignore: Object is possibly 'null'.
            const html = iframe.contentWindow?.document?.body;
            const canvas = await html2canvas(html, {
                scale: 0.5,
                allowTaint: true,
                useCORS: true,
                backgroundColor: null
            });
            const image = canvas.toDataURL();
            // setFieldValue("thumbnail", image, false);
        }
    };

    useEffect(() => {
        const iframe = iframeRef.current;
        if (!iframe) return undefined;
        iframe.addEventListener("load", () => {
            getImageThumbnail(iframe);
            console.warn("loaded");
        });
        return () =>
            iframe.removeEventListener("load", () => {
                console.warn("removed");
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iframeRef]);

    return (
        <iframe
            title={components.name}
            ref={iframeRef}
            style={{ width: "100%", height: `100%`, transformOrigin: "top" }}
            srcDoc={html}
        />
    );
}

export default IFrameContent;