import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "../layout/layout";
import _ from "lodash";
import { Toast } from "primereact/toast";
import { MenuDot, WIPaginator } from "../common";
import MenuButton from "../common/menu-button/menu-button";
import DonorFilterComponent from "./components/donor-filter/donor-filter";
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from "primereact/column";
import "./donor-manager.scss";
import { getObjectSearchValue, getObjectWithValues } from './components/donor-component-util';
import moment from 'moment';
import DonorManagerAPI from '../../services/donorManager';
import { formatDateForBodyTemplate } from '../common/column-template-table/column-template';
import CountryService from "../../services/country-service";
import { showNotification } from '../../utils/logic';
import { Sidebar } from 'primereact/sidebar';
import AddDonorFormComponent from './components/add-donor-form/add-donor-form';

export type DonorFilterParameters = {
    pageNo: number;
    range: number;
    search?: string;
    where: {
      or: any[];
      joined_date: any;
    };
    order: [];
};

const DonorManager = () => {
    const toast = useRef<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const pageNumber = Math.max(+(searchParams?.get("pageNumber") || 1) - 1, 0);
    const filterJoinedDate = searchParams?.get('filterJoinedDate') ? searchParams?.get('filterJoinedDate')?.split(',') || [] : [];
    const sortField = searchParams?.get("sortField") || "joined_date";
    const sortOrder = +(searchParams?.get("sortOrder") || -1);
    const [sortConfig, setSortConfig] = useState<any>({
      sortField: sortField,
      sortOrder: sortOrder,
    });
    const noRows = Math.min(
        Math.max(+(searchParams?.get("noRows") || 50), 50),
        1000
    );
    const searchField = searchParams?.get("search") || "";
    const [filterValues, setFilterValues] = useState<DonorFilterParameters>({
        pageNo: pageNumber,
        range: noRows,
        search: searchField,
        where: {
          or: searchField ? getObjectSearchValue(searchField) : [],
          joined_date: filterJoinedDate
        },
        order: [],
    });

    const [donors, setDonors] = useState({
      data: [] as any,
      totalPage: 0,
    });

    const [selectedDonors, setSelectedDonors] = useState<any>([]);
    const [page, setPage] = useState(pageNumber);
    const [first, setFirst] = useState(pageNumber * noRows);
    const [rows, setRows] = useState(noRows);
    const [isShowSidebar, setIsShowSidebar] = useState(false);
    const [countries, setCountries] = useState([]);

    const getCountries = async () => {
      try {
        const response = await CountryService.getCountries();
        if (response && response.status === 200) {
          if (response.data.data && response.data.data.length > 0) {
            return response.data.data;
          }
        }
      } catch (error) {
        console.log("getCountries: " + error);
      }
  
      return [];
    };

    const buildAddressTemplate = (data: any) => {
      let arrData = [];
      let address = data?.payment_address?.trim() ? `${data.payment_address.trim()}` : '';
      address && arrData.push(address);
      let postCode = data?.payment_post_code?.trim() ? `${data.payment_post_code.trim()}` : '';
      let city = data?.payment_city?.trim() ? `${data.payment_city.trim()}` : '';
      let postCodeCity = `${postCode} ${city}`.trim();
      postCodeCity && arrData.push(postCodeCity);
      let countryCode = data?.payment_country?.trim() ? `${data.payment_country.trim()}` : '';
      let countryObj: any = _.find(countries, {'ISO2': countryCode});
      countryObj && arrData.push(countryObj?.name);
      let joinedAddress = arrData.join(', ');
      
      return joinedAddress;
    }

    const tableColumns: any = [
      {
        field: "full_name",
        header: "Name",
        width: "25%",
        align: "left",
        bodyTemPlate: (rowData: any) => {
          return rowData.payment_first_name + " " + rowData.payment_last_name;
        }
      },
      {
        field: "payment_email",
        header: "Email",
        width: "25%", 
        align: "left",
      },
      {
        field: "address",
        header: "Address",
        width: "30%",
        align: "left",
        disabledSort: true,
        bodyTemPlate: buildAddressTemplate
      },
      {
        field: "joined_date",
        header: "Joined Date",
        width: "10%", 
        align: "left",
        bodyTemPlate: (rowData: any) => {
          return rowData?.joined_date ? formatDateForBodyTemplate(rowData?.joined_date, "DD.MM.YYYY") : '';
        }
      },
    ];

    const dynamicColumns = tableColumns.map((col: any, i: any) => {
      return (
      <Column
      key={col.field}
      field={col.field}
      header={col.header}
      style={{ width: col.width }}
      body={col.bodyTemPlate}
      align={col.align as ColumnAlignType}
      sortable={!col?.disabledSort}/>
      );
    });

    const onBasicPageChange = (event: any) => {
      setPage(event.page);
      setFirst(event.first);
      setRows(event.rows);
      setSelectedDonors([]);
    };

    const onAddDonor = () => {
      setIsShowSidebar(true);
    }

    const onOpenDonorPortal = async (e: any, rowData: any) => {
      setIsLoading(true);
      if (rowData) {
        const paymentEmail = rowData.payment_email?.toLowerCase();
        const donorData = {
          donorEmail: paymentEmail,
          language: "de",
        };

        try {
          let newTab: any = window.open();
          const response = await DonorManagerAPI.generateDonorPortalURL(donorData);
          setIsLoading(false);
          if (response?.status === 200 && response?.data) {
            const data = response.data;
           // @ts-ignore: Object is possibly 'null'.
          newTab.location.href = data.link;
          } else {
            showNotification("error", "Get donor portal URL failed.", toast);
          }
        } catch (error) {
          setIsLoading(false);
          showNotification("error", "Get donor portal URL failed.", toast);
        }  
      }
    };

   const fetchDonorsData = async () => {
    setIsLoading(true);

    const countries = await getCountries();
    setCountries(countries);

    const where_conditions = getObjectWithValues(filterValues.where);
    let filter = {
      pageNo: (page).toString(),
      range: rows.toString(),
      where: {
        ...where_conditions,
        search_name: filterValues.search,
      },
      order: [sortConfig.sortField, sortConfig.sortOrder === -1 ? "DESC": "ASC"],
    }
    try {
      const response = await DonorManagerAPI.getDonorsBySearch(filter);
      if (response && response.status === 200) {
        if (response.data.total > 0) {
          setDonors({
            data: response.data.records,
            totalPage: response.data.total
          });
        } else {
          setDonors({
            data: [],
            totalPage: response.data.length || 0,
          });
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setDonors({
        data: [],
        totalPage: 0
      })
    }
   }

    const onFilterChange = (values: any) => {
      const { field, value } = values;
      const where_conditions = getObjectWithValues(filterValues.where);
      const dates = value.map((c: any) => moment(c).format("DD/MM/YYYY"));
      where_conditions[field] = dates;
      where_conditions["from_"+field] = dates[0];
      where_conditions["to_"+field] = dates[1];

      setFilterValues({
        ...filterValues,
        pageNo: filterValues.pageNo,
        range: filterValues.range,
        where: where_conditions,
        order: filterValues.order,
      });
    };

    const onSearchFunc = (searchText: string) => {
      const where_conditions = getObjectWithValues(filterValues.where);
      const or_conditions = getObjectSearchValue(searchText);
      where_conditions["or"] = searchText ? or_conditions : null;
      setFilterValues({
        pageNo: filterValues.pageNo,
        range: filterValues.range,
        where: where_conditions,
        search: searchText,
        order: filterValues.order,
      });
      if (page !== 0) {
        setPage(0);
        setFirst(0);
      }
    };

    const onFilterRemove = async () => {
      let p = _.cloneDeep({ ...filterValues });
      p.where = Object.create({});
      p.search = "";
      setFilterValues(p);
    };

    const actionItems: any = [
      { 
        label: 'Add',
        icon: 'fa-solid fa-circle-plus ml-2',
        command: onAddDonor
      }
    ];
    
    const buildActionMenu = (data: any) => {
      return actionItems;
    };
    
    const buildMenu = (rowData: any) => {
      const defaultActions = [
        {
          label: "Open",
          description: "Open donor details",
          icon: "fa-solid fa-arrow-up-right-from-square",
          // url: `/donors/${rowData.id}`,
        },
        {
          label: "Open donor portal",
          description: "Generate donor portal token",
          icon: "fa-solid fa-arrow-up-right-from-square",
          command: (e: any) => onOpenDonorPortal(e, rowData),
        }
      ];
      return <MenuDot items={[...defaultActions]}></MenuDot>;
    };

    useEffect(() => {
      if (typeof page === "number") {
        setFilterValues({
          ...filterValues,
          pageNo: page,
          range: rows
        });
  
        setSearchParams({ 
          pageNumber: (page + 1).toString(),
          noRows: rows.toString()
        });

        setSelectedDonors([]);}
      }, [page, rows]);

      useEffect(() => {
        const searchParams = { 
          pageNumber: (page + 1).toString(),
          noRows: rows.toString(),
          search: filterValues.search,
          sortField: sortConfig.sortField,
          sortOrder: sortConfig.sortOrder,
          filterJoinedDate: filterValues.where.joined_date?.join(',') || ''
        }
        setSearchParams(_.omitBy(searchParams, (p) => !p));
        
        fetchDonorsData();
        setSelectedDonors([]);
        
      }, [filterValues, sortConfig]);

    return(
        <Layout>
            <Toast ref={toast}/>
            <div className="donor-manager">
                <div className="donor-header-panel">
                    <DonorFilterComponent filterValues={filterValues}
                    fields={["joined_date"]}
                    onChange={onFilterChange}
                    onRemove={onFilterRemove}
                    onSearchFunc={onSearchFunc}/>
                    <MenuButton label="Actions" items={[...buildActionMenu(null)]}></MenuButton>
                </div>
                <div className="donor-table">
                  <div className="content-table">
                    <DataTable 
                    loading={isLoading}
                    value={donors.data}
                    scrollable
                    selectionPageOnly
                    selectionMode='checkbox'
                    selection={selectedDonors}
                    onSelectionChange={e =>{
                      setSelectedDonors(e.value);
                    }}
                    dataKey='id'
                    scrollDirection='both'
                    rowClassName={(data) => 'selectable-donation-row'}
                    onSort={(e: any) => {
                      setSortConfig({
                        sortField: e.sortField,
                        sortOrder: e.sortOrder,
                      });
                      setSelectedDonors([]);
                    }}
                    sortField={sortConfig.sortField}
                    sortOrder={sortConfig.sortOrder}
                    lazy>
                       <Column selectionMode="multiple" headerStyle={{ width: '5%' }}></Column>
                       {dynamicColumns}
                       <Column
                        frozen
                        alignFrozen="right"
                        className="p-align-right"
                        style={{ width: "5%" }}
                        body={buildMenu}></Column>
                    </DataTable>
                    <WIPaginator
                    first={first}
                    rows={rows}
                    totalRecords={donors.totalPage}
                    onPageChange={onBasicPageChange}/>
                  </div>
                </div>
            </div>
            <Sidebar 
              visible={isShowSidebar} 
              position="right" 
              className="p-sidebar-md add-donor-sibar-right" 
              style={{width:'30em'}}
              onHide={() => setIsShowSidebar(false)}>
                <div className="add-donor-sidebar-content">
                  <div className="headline">
                    <h6>Add a new donor</h6>
                  </div>
                  <AddDonorFormComponent
                    countries={countries}
                    fetchCallBack={((donorItem:any) => fetchDonorsData())}
                    toast={toast}
                    onHide={() => setIsShowSidebar(false)}
                  />
                </div>
              </Sidebar>
        </Layout>
    );
};

export default DonorManager;