import { Dialog } from "primereact/dialog";
import * as React from "react";
import DynamicForm from "../../../dynamic-form/dynamic-form";

const WITreeDialogComponent = (props: any) => {
  const { visible, onHide, selectedNode, globalContent, onSumbit } = props;
  const rowData = { ...rowProperty };

  return (
    <>
      <Dialog
        className="headers-dialog-component"
        header={selectedNode?.key ? "Update the row" : "Create new row"}
        visible={visible}
        style={{ width: "60vw" }}
        modal
        onHide={onHide}
      >
        <DynamicForm
          formSchema={rowData?.configuration || {}}
          data={selectedNode ? {
            key: selectedNode.key,
            name: selectedNode.name.de,
            url: selectedNode.url
          } : rowData.parameters}
          submitButtonConfig={{
            title: selectedNode?.key ? "Update" : "Create",
            icon: "pi pi-chevron-right",
            iconPos: "right",
          }}
          onSubmitData={onSumbit}
          mode="dialog"
          globalContent={globalContent}
        ></DynamicForm>
      </Dialog>
    </>
  );
};

const rowProperty = {
  uuid: "d202f689-23d5-4c2d-841f-bcs20011998",
  name: "Headers",
  code: "headers",
  type: "General Headers Blocks",
  type_title: "General Headers Blocks",
  configuration: {
    name: {
      type: "textFieldCustom",
      label: "Name",
      isRequired: true,
      // supportMultiLanguages: ["de", "en"],
      maxLength: 100,
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["Name is required"],
        },
        {
          type: "nullable",
          params: [],
        },
      ],
    },
    url: {
      type: "searchTextField",
      label: "URL",
      isRequired: true,
      supportMultiLanguages: ["de", "en"],
      sourceConfiguration: {
        type: "internal_urls",
        fieldName: "url",
        searchFields: ["name"],
        labelField: "name",
      },
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["URL is required"],
        },
      ],
    },
    xssConfiguration: {
      name: "textFieldCustom",
      url: "searchTextField"
    }
  },
  parameters: {
    name: "",
    url: {
      page_id: "",
      global_content_id: "",
      de: "#",
      en: "#"
    }
  },
};

export default WITreeDialogComponent;
