import { Toast } from "primereact/toast";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column, ColumnAlignType } from "primereact/column";
import "./donation-manager.scss";
import { CampaignManagerAPI, DonationManagerAPI, LandManagerAPI, RegionManagerAPI } from "../../services";
import Layout from "../layout/layout";
import CertificateDonationDialogComponent from "./components/generate-certificate-donation-dialog/generate-certificate-donation-dialog";
import { onDownloadMultipleReceipt, onDownloadReceipt } from "../../utils/donationManagerLogic";
import UpdateDonationDialogComponent from "./components/update-donation-dialog/update-donation-dialog";
import { WIPaginator, MenuDot } from "../common";
import { DONATION_STATUS_LIST, StatusDisplay, RHYTHM_LIST, DONATION_TYPE_LIST, LandTypes } from "../../utils/utils";
import _ from "lodash";
import { formatDateBodyTemplate, formatDonationStatusBodyTemplate } from "../common/column-template-table/column-template";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import DonationFilterComponent from "./components/donation-filter/donation-filter-component";
import { showNotification } from "../../utils/logic";
import { getObjectSearchValue, getObjectWithValues, getPayloadConditions, NO_ACTION_LIST, disabledEditAction, isValidDonorInfo } from "./donation-manager.util";
import moment from "moment";
import SnackBarComponent from "./components/snack-bar/snack-bar";
import GenerateReceiptDonationDialogComponent from "./components/generate-receipt-donation-dialog/generate-receipt-donation-dialog";
import ExportDonationDialogComponent from "./components/export-donation-dialog/export-donation-dialog";
import { Sidebar } from 'primereact/sidebar';
import AddDonationFormComponent from "./components/add-donation/components/add-donation-form/add-donation-form";
import CountryService from "../../services/country-service";

export type DonationFilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[],
    status: string[],
    // land_id: string[],
    region_id: string[],
    campaign_id: string[],
    payment_currency_code: string[],
    payment_method: string[],
    dates: string[],
    donation_type: string[],
    rhythm: number[],
    subsequent: number[],
  }
  order: []
}
const SuperAdminKey = 'nature';

const DonationManager = (props: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isSuperAdmin = searchParams?.get('superadmin') === SuperAdminKey;
  const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
  const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);
  const sortField = searchParams?.get('sortField') || 'donation_serial';
  const sortOrder = +(searchParams?.get('sortOrder') || -1);
  const filterStatus = (searchParams?.get('filterStatus') || DONATION_STATUS_LIST.map((c) => c.code).join(',')).split(',');
  const filterRegions = searchParams?.get('filterRegions') ? searchParams?.get('filterRegions')?.split(',') || [] : [];
  // const filterLands = searchParams?.get('filterLands') ? searchParams?.get('filterLands')?.split(',') || [] : [];
  const filterCampaigns = searchParams?.get('filterCampaigns') ? searchParams?.get('filterCampaigns')?.split(',') || [] : [];
  const filterCurrencies = searchParams?.get('filterCurrencies') ? searchParams?.get('filterCurrencies')?.split(',') || [] : [];
  const filterMethods = searchParams?.get('filterMethods') ? searchParams?.get('filterMethods')?.split(',') || [] : [];
  const filterRhythms = searchParams?.get('filterRhythms') ? searchParams?.get('filterRhythms')?.split(',').map(r => +r) || [] : [];
  const filterSubsequent = searchParams?.get('filterSubsequent') ? searchParams?.get('filterSubsequent')?.split(',').map(c => +c) || [0, 1] : [0, 1];
  const filterTypes = (searchParams?.get('filterTypes') || DONATION_TYPE_LIST.map((c) => c.code).join(',')).split(',');
  const filterDates = searchParams?.get('filterDates') ? searchParams?.get('filterDates')?.split(',') || [] : [];
  const searchField = searchParams?.get('search') || '';
  
  const statusTypeAll = 'all';
  const toast = useRef<any>(null);
  const [isShowCreateCertificateDialog, setIsShowCreateCertificateDialog] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDonations, setSelectedDonations] = useState([]);
  const [donations, setDonations] = useState({
    data: [],
    totalPage: 0,
  });
  const [donation, setDonation] = useState<any>(null);
  const [page, setPage] = useState(pageNumber);
  const [first, setFirst] = useState(pageNumber * noRows);
  const [rows, setRows] = useState(noRows);
  const [statusDonations, setStatusDonations] = useState<any[]>([]);
  const [selectedStatusDonation, setSelectedStatusDonation] = useState<any>(filterStatus.length !== 1 ? statusTypeAll : filterStatus[0]);
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder
  });
  const [filterValues, setFilterValues] = useState<DonationFilterParameters>({
    pageNo: pageNumber,
    range: noRows,
    search: searchField,
    where: {
      or: searchField ? getObjectSearchValue(searchField) : [],
      status: filterStatus,
      // land_id: filterLands,
      region_id: filterRegions,
      campaign_id: filterCampaigns,
      payment_currency_code: filterCurrencies,
      payment_method: filterMethods,
      rhythm: filterRhythms,
      subsequent: filterSubsequent,
      donation_type: filterTypes,
      dates: filterDates 
    },
    order: []
  });
  const [isMultipleDownload, setIsMultipleDownload] = useState(false);
  const [isShowReceiptDialog, setIsShowReceiptDialog] = useState(false);
  const [isShowExportDonationDialog, setIsShowExportDonationDialog] = useState(false);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [campaigns, setCampaigns] = useState<any>([]);
  const [lands, setLands] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [countries, setCountries] = useState([]);
  const [isReserve, setIsReserve] = useState(false);
  
  const navigate = useNavigate();

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
    setSelectedDonations([]);
  };

  const setupDonationStatus = async () => {
    const statusList = _.cloneDeep(DONATION_STATUS_LIST);
    statusList.unshift({ code: 'all', name: { de: 'All', en: 'All' } });
    // setSelectedStatusDonation(statusList && statusList.length > 0 ? statusList[0].code : '')
    setStatusDonations(statusList);
  };

  const fetchDonationsData = async () => {
    setIsLoading(true);

    const payload = getPayloadConditions(filterValues.where, sortConfig);
    try {
      const res = await DonationManagerAPI.getDonationsBySearch({pageNo: page, range: rows, ...payload});
      if (res && res.status === 200) {
        setIsLoading(false);
        setDonations({
          data: res.data.records ? res.data.records.map((item: any) => ({
            ...item,
            full_name:
              item.payment_company_name ||
              item.payment_first_name + " " + item.payment_last_name,
          })) : [],
          totalPage: res.data.total || 0,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setDonations({
        data: [],
        totalPage: 0
      })
    }
  };

  const fetchAreasData = async () => {
    setIsLoading(true);
    const [regionRes, landsRes, campaignsRes, countriesRes] = await Promise.all([
      RegionManagerAPI.getAllRegions(),
      LandManagerAPI.getAllLands(),
      CampaignManagerAPI.getAllCampaigns(),
      CountryService.getCountries()
    ]);

    // @ts-ignore: Object is possibly 'null'.
    if (regionRes && regionRes.status === 200 && regionRes.data.records?.length > 0) {
      setRegions(_.sortBy(regionRes.data.records, (item) => item.name?.de?.toLowerCase()));
    }
    // @ts-ignore: Object is possibly 'null'.
    if (landsRes && landsRes.status === 200 && landsRes.data.records?.length > 0) {
      const landList = landsRes.data.records.filter((l: any) => l.land_type !== LandTypes.CampaignOnly);
      const regionList = regionRes?.data?.records;
      landList.forEach((item: any) => {
        item.region = regionList?.find((r: any) => r.default_land_id === item.uuid);
        item.master_region = regionList?.find((r: any) => r.uuid === item.region_id);
      });
      setLands(_.sortBy(landList, (item) => item.name?.de?.toLowerCase()));
    }
    // @ts-ignore: Object is possibly 'null'.
    if (campaignsRes && campaignsRes.status === 200 && campaignsRes.data.records?.length > 0) {
      const campaignList = campaignsRes?.data?.records;
      campaignList.forEach((item: any) => {
        item.master_region = regions?.find((r: any) => r.uuid === item.land?.region_id);
      });
      setCampaigns(_.sortBy(campaignList, (item) => item.name?.de?.toLowerCase()));
    }

    if (countriesRes && countriesRes.status === 200) {
      if (countriesRes.data.data && countriesRes.data.data.length > 0) {
        setCountries(countriesRes.data.data);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchAreasData();
    setupDonationStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === "number") {
      setFilterValues({
        ...filterValues,
        pageNo: page,
        range: rows
      });

      setSearchParams({ 
        pageNumber: (page + 1).toString(),
        noRows: rows.toString()
      });

      setSelectedDonations([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  useEffect(() => {
    if (typeof selectedStatusDonation === "string") {
      let p = _.cloneDeep({ ...filterValues });
      if (selectedStatusDonation === statusTypeAll) {
        // p.where["status"] = [...DONATION_STATUS_LIST.map((c) => c.code)];
      } else {
        p.where["status"] = [selectedStatusDonation];
      }
      setFilterValues(p);
      setSelectedDonations([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatusDonation]);

  useEffect(() => {
    const searchParams = { 
      pageNumber: (page + 1).toString(),
      noRows: rows.toString(),
      search: filterValues.search,
      sortField: sortConfig.sortField,
      sortOrder: sortConfig.sortOrder,
      filterStatus: filterValues.where.status.join(',') || '',
      filterRegions: filterValues.where.region_id?.join(',') || '',
      // filterLands: filterValues.where.land_id?.join(',') || '',
      filterCampaigns: filterValues.where.campaign_id?.join(',') || '',
      filterCurrencies: filterValues.where.payment_currency_code?.join(',') || '',
      filterMethods: filterValues.where.payment_method?.join(',') || '',
      filterRhythms: filterValues.where.rhythm?.join(',') || '',
      filterSubsequent: filterValues.where.subsequent?.join(',') || '0,1',
      filterTypes: filterValues.where.donation_type?.join(',') || '',
      filterDates: filterValues.where.dates?.join(',') || '',
      superadmin: isSuperAdmin ? SuperAdminKey : ''
    }
    setSearchParams(_.omitBy(searchParams, (p) => !p));
    
    fetchDonationsData();
    setSelectedDonations([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, sortConfig]);

  const onFilterChange = (values: any) => {
    const { field, value } = values;
    const where_conditions = getObjectWithValues(filterValues.where);
    if (field !== "dates") {
      where_conditions[field] = value;
    } else {
      const dates = value.map((c: any) => moment(c).format("DD/MM/YYYY"));
      where_conditions[field] = dates;
      where_conditions.from = dates[0];
      where_conditions.to = dates[1];
    }
    setFilterValues({
      ...filterValues,
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order
    });
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    if (selectedStatusDonation === statusTypeAll) {
      // p.where["status"] = [...DONATION_STATUS_LIST.filter(c => c.code !== 'reserved').map((c) => c.code)];
      p.where["status"] = [...DONATION_STATUS_LIST.map((c) => c.code)];
    } else {
      p.where["status"] = [selectedStatusDonation];
    }
    p.where["donation_type"] = [...DONATION_TYPE_LIST.map((c) => c.code)];
    p.where["subsequent"] = [1];
    p.search = '';
    setFilterValues(p);
  };

  const onEditDonation = async (e: any, rowData: any) => {
    setIsLoading(true);
    if (rowData) {
      setDonation(rowData);
      setIsLoading(false);
      setIsShowUpdate(true);
    }
  };

  const onGenerateCertificateDialog = (e: any, rowData: any) => {
    setIsMultipleDownload(false);
    setDonation(rowData);
    setIsShowCreateCertificateDialog(true);
  };

  const handleDisableIcon = (rowData: any) => {
    const isChildDonation = rowData.is_first_time === false;
    const isNoAction = NO_ACTION_LIST.includes(rowData?.status?.toLowerCase());
    return isNoAction || isChildDonation;
  }

  const handleDisableOldData = (rowData: any) => {
    if (rowData?.old_donation_id)
    {
      const paymentDate = new Date(rowData.payment_date);
      const year = paymentDate.getFullYear();
      const dateValid = [2021,2022,2023];
      return  !dateValid.includes(year);
    }
    
    return false;
  }

  const handleChangeStatus = (status: any) => {
    setPage(0);
    setFirst(0);
    setSelectedStatusDonation(status.code);

    if (status.code === statusTypeAll) {
      let p = _.cloneDeep({ ...filterValues });
      // p.where["status"] = [...DONATION_STATUS_LIST.filter(c => c.code !== 'reserved').map((c) => c.code)];
      p.where["status"] = [...DONATION_STATUS_LIST.map((c) => c.code)];
      setFilterValues(p);
    }
  };

  const navigateToDetail = (rowData: any) => {
    navigate(`/donations/${rowData.uuid}`)
  };

  const copyLinkDonation = (rowData: any) => {
    navigator.clipboard.writeText(`${window.location.origin}${window.location.pathname}/${rowData.uuid}`).then(() => {
      showNotification("success", "Copied donation link successfully", toast);
    });
  }

  const formatAmountBodyTemplate = (rowData: any) => {
    return <div><b style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>{rowData ? parseFloat(rowData.donation_money?.toString())?.toLocaleString('de') : '-'}</b> <span>{rowData.payment_currency_code?.toUpperCase()}</span></div>;
  };

  const formatSerialBodyTemplate = (rowData: any) => {
    return <Link to={`/donations/${rowData.uuid}`} className="btn-serial" onClick={() => navigateToDetail(rowData)} >{rowData.donation_serial || `00-${rowData.serial}`}</Link>
  };

  const tableColumns = [
    {
      field: "donation_serial",
      header: "ID",
      width: "90px",
      align: "left",
      bodyTemPlate: formatSerialBodyTemplate,
    },
    {
      field: "donation_money",
      header: "Amount",
      width: "120px",
      align: "right",
      bodyTemPlate: formatAmountBodyTemplate,
    },
    {
      field: "status",
      header: "",
      width: "130px",
      align: "center",
      bodyTemPlate: formatDonationStatusBodyTemplate(StatusDisplay),
      disabledSort: true
    },
    {
      field: "rhythm",
      header: "Rhythm",
      width: "110px",
      align: "left",
      bodyTemPlate: (rowData: any) => {
        const rhythm = RHYTHM_LIST.find(c => c.code === rowData.rhythm)?.name;
        if (rhythm && +rowData.rhythm > 0 && rowData.is_first_time) {
          return <span>{rhythm} <i className="fa-solid fa-clock-rotate-left rhythm-icon" title='First time donation'></i></span>
        }

        if (rhythm && +rowData.rhythm > 0 && !rowData.is_first_time) {
          return <span>{rhythm} <i className="fa-solid fa-arrows-rotate rhythm-icon" title='Subsequent donation'></i></span>
        }

        return RHYTHM_LIST.find(c => c.code === rowData.rhythm)?.name || 'Once';
      }
    },
    {
      field: "region.name.de",
      header: "Region",
      width: "170px",
      align: "left",
      disabledSort: true,
      bodyTemPlate: (rowData: any) => {
        return <Link to={`/regions/${rowData.region_id}`} className="table-ref-link-cell">{rowData.region?.name?.de}</Link> 
      }
    },
    {
      field: "campaign.name.de",
      header: "Campaign",
      width: "170px",
      align: "left",
      disabledSort: true,
      bodyTemPlate: (rowData: any) => {
        return <Link to={`/campaigns/${rowData.campaign_id}`} className="table-ref-link-cell">{rowData.campaign?.name?.de}</Link> 
      }
    },
    {
      field: "full_name",
      header: "Name",
      width: "calc(100% - 1430px)",
      align: "left",
      minWidth: "150px",
    },
    {
      field: "payment_email",
      header: "Email",
      width: "230px",
      align: "left",
      wordBreak: "break-word",
      maxWidth: "270px",
    },
    {
      field: "payment_date",
      header: "Date",
      width: "160px",
      align: "left",
      bodyTemPlate: formatDateBodyTemplate("MMM DD, YYYY HH:mm:ss"),
    },
    {
      field: "payment_method",
      header: "Method",
      width: "140px",
      align: "left",
    }
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
          wordBreak: col.wordBreak || "normal",
          maxWidth: col.maxWidth || "none",
          minWidth: col.minWidth || "none",
        }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
        sortable={!col.disabledSort}
      />
    );
  });

  const onSearchFunc = (searchText: string) => {
    const where_conditions = getObjectWithValues(filterValues.where);
    const or_conditions = getObjectSearchValue(searchText);
    where_conditions["or"] = searchText ? or_conditions : null;
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      search: searchText,
      order: filterValues.order,
    });
    if (page !== 0) {
      setPage(0);
      setFirst(0);
    }
  };

  const handleActionAdd = () => {
    setIsShowSidebar(true);
    setIsReserve(false);
  }

  const handleActionReserve = () => {
    setIsShowSidebar(true);
    setIsReserve(true);
  }

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="donation-manager">
        <div className="manager-container">
          <div className="donation-header-panel">
            <DonationFilterComponent
              lang={"de"}
              items={statusDonations}
              selectedItem={selectedStatusDonation}
              onStatusChange={handleChangeStatus}
              isStatusAll={selectedStatusDonation === statusTypeAll}
              filterValues={filterValues}
              fields={["region_id", "campaign_id", "payment_currency_code", "status", "payment_method", "donation_type", "rhythm", "subsequent", "dates"]}
              onChange={onFilterChange}
              onRemove={onFilterRemove}
              onSearchFunc={onSearchFunc}
              onAddDonation={handleActionAdd}
              onReserveDonation={handleActionReserve}
              area={lands} 
              regions={regions} 
              campaigns={campaigns}
            />
          </div>
          <div className={"table-donation"}>
            <DataTable
              loading={isLoading}
              value={donations.data}
              // filters={search}
              scrollable
              selectionPageOnly
              isDataSelectable={(event) => {
                const data = event.data;
                return !handleDisableIcon(data);
              }}
              selectionMode="checkbox"
              selection={selectedDonations} 
              onSelectionChange={e => {
                if(e.value && e.value.length === 0) {
                  setIsMultipleDownload(false);
                }
                setSelectedDonations(e.value)}} 
              dataKey="uuid"
              scrollHeight="calc(100vh - 186px)"
              scrollDirection="both"
              rowClassName={(data) => handleDisableIcon(data) ? 'disabled-donation-row' : 'selectable-donation-row'}
              lazy
              onSort={(e: any) => {
                setSortConfig({
                  sortField: e.sortField,
                  sortOrder: e.sortOrder
                });
                setSelectedDonations([]);
              }}
              sortField={sortConfig.sortField}
              sortOrder={sortConfig.sortOrder}
            >
              <Column selectionMode="multiple" headerStyle={{ width: '50px' }}></Column>
              {dynamicColumns}
              <Column
                frozen
                alignFrozen="right"
                style={{ width: "50px" }}
                body={(rowData) => {
                  const defaultActions = [
                    {
                      label: 'Open',
                      icon: 'fa-solid fa-arrow-up-right-from-square',
                      url: `/donations/${rowData.uuid}`,
                      description: 'Open and edit donation details'
                    },
                    {
                      label: 'Copy Donation Link',
                      icon: 'pi pi-copy',
                      command:(e: any) => copyLinkDonation(rowData),
                      description: 'Copy donation link to clipboard'
                    }
                  ];
                  if (+rowData.rhythm > 0 && !rowData.is_first_time) {
                    return <MenuDot items={[ 
                      ...defaultActions, 
                      {
                        label: 'Open parent donation',
                        icon: 'fa-solid fa-arrow-up-right-from-square',
                        url: `/donations/${rowData.parent_donation_id}`,
                        description: 'Open and edit parent donation details'
                      }
                    ]}></MenuDot>
                  }

                  return <MenuDot items={[
                    ...defaultActions,
                    {
                      label: 'Quick Edit',
                      icon: 'pi pi-file-edit',
                      disabled: disabledEditAction(rowData) || handleDisableIcon(rowData),
                      command:(e: any) => onEditDonation(e, rowData),
                      description: 'Quickly edit email and personalize the certificate'
                    },
                    {
                      label: 'Download Certificate',
                      icon: 'pi pi-download',
                      disabled: handleDisableIcon(rowData),
                      command:(e: any) => onGenerateCertificateDialog(e, rowData),
                      description: 'Download certificate as PDF'
                    },
                    {
                      label: 'Download Receipt',
                      icon: 'pi pi-download',
                      disabled: rowData?.disabled_receipt || handleDisableIcon(rowData) || !isValidDonorInfo(rowData) || handleDisableOldData(rowData),
                      command:(e: any) => onDownloadReceipt(toast, rowData, e),
                      description: 'Download receipt as PDF'
                    },
                  ]}></MenuDot>;
                }}
              ></Column>
            </DataTable>
            <WIPaginator
              first={first}
              rows={rows}
              totalRecords={donations.totalPage}
              onPageChange={onBasicPageChange}
            />
          </div>
          <UpdateDonationDialogComponent
            visible={isShowUpdate}
            fetchDonationsData={fetchDonationsData}
            onHide={() => setIsShowUpdate(false)}
            donation={donation}
          />
          <CertificateDonationDialogComponent
            visible={isShowCreateCertificateDialog}
            onHide={() => setIsShowCreateCertificateDialog(false)}
            donation={donation}
            selectedDonations={selectedDonations}
            isMultipleDownload={isMultipleDownload}
          />

          <GenerateReceiptDonationDialogComponent
            visible={isShowReceiptDialog}
            onHide={() => setIsShowReceiptDialog(false)}
            selectedDonations={selectedDonations}
          />
          
          <ExportDonationDialogComponent
            visible={isShowExportDonationDialog}
            onHide={() => setIsShowExportDonationDialog(false)}
            selectedDonations={selectedDonations}
          />

          <SnackBarComponent
            selectedDonations={selectedDonations}
            visible={selectedDonations && selectedDonations.length > 0}
            clearDonations={() => setSelectedDonations([])}
            downloadCertificate={() => {
              setIsMultipleDownload(true);
              setIsShowCreateCertificateDialog(true)
            }}
            downloadReceipt={() => {
              const ids = selectedDonations.map((donation: any) => donation.uuid);
              onDownloadMultipleReceipt(ids, 'de', toast).then(() => {
                setIsLoading(false);
              })
            }}
            exportDonation={() => setIsShowExportDonationDialog(true)}
          />
        </div>
        <Sidebar 
            visible={isShowSidebar} 
            position="right" 
            className="p-sidebar-md add-donor-sibar-right" 
            style={{width:'35em'}}
            onHide={() => setIsShowSidebar(false)}>
              <div className="add-donor-sidebar-content">
                <div className="headline">
                  <h6>{isReserve ? "Reserve" : "Add"} a new donation</h6>
                </div>
                <AddDonationFormComponent 
                  lands={lands} 
                  campaigns={campaigns}
                  toast={toast}
                  setIsLoading={setIsLoading}
                  setIsShowSidebar={setIsShowSidebar}
                  countries={countries}
                  isSuperAdmin={isSuperAdmin}
                  isReserve={isReserve}
                />
              </div>
        </Sidebar>
      </div>
    </Layout>
  );
};

export default DonationManager;
