import React, { useRef } from "react";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import "./editor-dialog.scss";
import "tui-image-editor/dist/tui-image-editor.css";
import { whiteTheme } from "./white-theme";
// @ts-ignore
import ImageEditor from "@toast-ui/react-image-editor";
import _ from "lodash";

const ImageEditorDialogComponent = (props: any) => {
  const { visible, onHide, data, saveChangesPhoto, fileBase64 } = props;
  const toast = useRef(null);
  const editorRef = useRef(null);

  const convertDataUrlToFile = (dataUrl: any, fileName: any) => {
    let arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]), 
    n = bstr.length, 
    u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type:mime});
  }

  const submitImage = async () => {
    // @ts-ignore
    let instance = editorRef.current.getInstance();
    let dataURL = instance.toDataURL();
    if (dataURL) {
      const mediaInfo = _.cloneDeep(data);
      mediaInfo.file = convertDataUrlToFile(dataURL, mediaInfo.name);
      mediaInfo.size = parseFloat(data.size);
      saveChangesPhoto(mediaInfo);

      // clear object
      instance.clearObjects();
      instance.clearUndoStack();
      instance.clearRedoStack();
    }

    onHide();
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        maskClassName="top-mask-dialog"
        className="editor-photo-dialog wi-dialog"
        visible={visible}
        header={"Photo Editor"}
        style={{ width: "65vw" }}
        modal
        onHide={onHide}
      >
        <ImageEditor
          key={"imageEditor"}
          ref={editorRef}
          includeUI={{
            loadImage: {
              path: fileBase64,
              name: "SampleImage",
            },
            theme: whiteTheme,
            menu: ["resize", "crop", "flip", "rotate"],
            initMenu: "",
            uiSize: {
              width: "100%",
              height: "600px",
            },
            menuBarPosition: "right",
          }}
          cssMaxHeight={400}
          cssMaxWidth={700}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          usageStatistics={false}
        />

        <div className="footer-panel">
          <button className="p-button p-button-rounded btn-submit wi-button" onClick={submitImage}>
            Save Changes
            <i className="pi pi-angle-right"></i>
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default ImageEditorDialogComponent;
