import React, { Fragment, useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import Layout from "../../layout/layout";
import { QuoteManagerAPI } from "../../../services";
import { Column, ColumnAlignType } from "primereact/column";
import { MenuDot, WIButton, WIConfirmDialog, WISearchField } from "../../common";
import { DataTable } from "primereact/datatable";
import { LanguageList } from "../../../utils/utils";
import { showNotification } from "../../../utils/logic";
import CreateQuoteDialogComponent from "./components/create-quote-dialog";
import "./quote-manager.scss"
import { buildActionBtnTemplate, formatDateBodyTemplate, formatInnerHtmlDataByLanguageBodyTemplate } from "../../common/column-template-table/column-template";

const QuoteManager = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [language] = useState(LanguageList[0]);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false)
  const [quotes, setQuotes] = useState<any[]>();
  const [quote, setQuote] = useState<any>();
  const [search, setSearch] = useState<any>(null);
  const toast = useRef(null);

  const fetchData = async () => {
    setIsLoading(true);
    const result = await QuoteManagerAPI.getAll(false);
    // @ts-ignore: Object is possibly 'null'.
    if (result.status === 200 && result.data.records) {
      setQuotes(result.data.records);
    } else {
      setQuotes([]);
    }
    setIsLoading(false);
  };

  const onCreateQuote = (isCreating: boolean, e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setQuote(null);
    setIsShowDialog(isCreating);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateQuote = async (e: any, rowData: any) => {
    setIsLoading(true);
    const res = await QuoteManagerAPI.getQuote(rowData.uuid);
    if (res && res.status === 200) {
      setQuote(res.data.record);
      setIsLoading(false);
      setIsShowDialog(true);
    }
  };

  const onDeleteQuote = async (e: any, rowData: any) => {
    setIsShowDeleteDialog(true);
    setQuote(rowData);
  };

  const callDeleteQuoteAPI = async () => {
    try {
      let res = await QuoteManagerAPI.deleteQuote(quote?.uuid);
      if (res && res.status === 200 && res.data.result.isValid) {
        setQuote(null);
        setIsShowDeleteDialog(false);
        fetchData();
        showNotification("success", "Delete Quote successfully", toast);
        return;
      }
      else{
        console.log(res);
      }
    } catch (error) {
      console.log('catch error', error);
    }

    showNotification("error", "Delete Campaign failed.", toast);
  }

  const tableColumns = [
    // { field: 'serial', header: 'No', width: "10%", align: 'center' },
    { field: 'owner_name', header: 'Owner Name', width: "calc(22% - 50px)", align: 'left' },
    { field: 'quote', header: 'Quote', width: "52%", align: 'left', BodyTemplate: formatInnerHtmlDataByLanguageBodyTemplate(language) },
    { field: 'created', header: 'Created At', width: "13%", align: 'left', BodyTemplate: formatDateBodyTemplate("DD.MM.YYYY") },
    { field: 'changed', header: 'Updated At', width: "13%", align: 'left', BodyTemplate: formatDateBodyTemplate("DD.MM.YYYY") },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return <Column
      key={col.field}
      field={col.field}
      header={col.header}
      className={col.field}
      style={{width: col.width}}
      body={col.BodyTemplate}
      align={col.align as ColumnAlignType}
    />;
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
          label: "Edit",
          description: "Edit information",
          icon: "fa-solid fa-pen-to-square",
          command: (e: any) => {
            onUpdateQuote(e, rowData)
          },
      },
      {
        label: "Delete",
        description: "Delete information",
        icon: "fa-solid fa-trash-can delete-icon",
        dangerous: true,
        command: (e: any) => {
          onDeleteQuote(e, rowData);
        },
      }
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="quotes-manager">
        <div className="manager-container">
          <div className="headline">
            <h3>Quote</h3>
          </div>
          <div className="certificate">
            <div className="d-flex flex-column flex-md-row justify-content-start justify-content-md-between certificate-action common-horizontal-layout-header">
              <WISearchField
                icon={" pi pi-search"}
                placeholder="Search for anything"
                setSearch={(value: any) => setSearch(value)}
              />
              <div className="btn-create">
                <WIButton
                  primary={true}
                  type={"button"}
                  label="Add New"
                  icon={"pi-angle-right"}
                  onClick={(e: any) => onCreateQuote(true, e)}
                />
              </div>
            </div>
          </div>
          <div className="table-quotes">
            <DataTable
              loading={isLoading}
              value={quotes}
              filters={search}
              globalFilterFields={['owner_name', 'quote.de', 'quote.en']}
              emptyMessage="No quotes found."
              scrollable
              scrollHeight="600px"
              scrollDirection="both"
            >
              {dynamicColumns}
              <Column
                frozen
                alignFrozen="right"
                style={{ width: '50px' }} 
                body={buildMenu}></Column>
            </DataTable>
          </div>
          <WIConfirmDialog 
            visible={isShowDeleteDialog}
            onHide={() => setIsShowDeleteDialog(false)}
            onConfirm={callDeleteQuoteAPI}
            message={
              <Fragment>
                <span style={{fontSize: '18px', fontWeight: '700'}}>Are you sure you want to delete this quote?</span>
                <br/>
                <span style={{fontSize: '13px'}}>
                  All of your data will be deleted <b>permanently</b>.
                  <br/>
                  You <b>cannot undo</b> this action.
                  <br/>
                  <br/>
                  <b>Note</b>: <br/> If this <b>quote</b> is using by our website, it can cause <b>unexpected issues</b>.
                  </span>
              </Fragment>
            }
            classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
          />
          <CreateQuoteDialogComponent
            visible={isShowDialog}
            quote={quote}
            fetchQuotesData={fetchData}
            onHide={() => setIsShowDialog(false)}
          />
        </div>
      </div>
    </Layout>
  )
};

export default QuoteManager;