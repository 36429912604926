import * as React from "react";
import { useState, useEffect, useRef, Fragment } from "react";
import { Column, ColumnAlignType } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "./region-manager.scss";
import Layout from "../../layout/layout";
import { LanguageList, removeEmpty, FIELDS_SEARCH, StatusDisplay } from "../../../utils/utils";
import { RegionManagerAPI } from "../../../services";
import CountryService from "../../../services/country-service";
import { env } from "../../../environment";
import { showNotification } from "../../../utils/logic";
import { Toast } from "primereact/toast";
import { MenuDot, WIConfirmDialog, WIPaginator, WISearchField } from "../../common";
import * as _ from "lodash";
import { formatInnerHtmlDataByLanguageBodyTemplate, formatStatusBodyTemplate, buildNumberBodyTemplate, buildProgressTemplate } from "../../common/column-template-table/column-template";
import { Link } from "react-router-dom";

const RegionManager = (props: any) => {
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const [regions, setRegions] = useState({
    data: [],
    totalPage: 0,
  });
  const [page, setPage] = useState(0);
  const [language] = useState(LanguageList[0]);
  const [region, setRegion] = useState<any>(null);
  const [countries, setCountries] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [search, setSearch] = useState<any>(null);

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  }

  const fetchRegionsData = async (valueSearch?: string) => {
    setIsLoading(true);
    const data = removeEmpty({
      pageNo: page,
      range: rows,
      search_text: valueSearch || undefined,
      search_fields: valueSearch ? FIELDS_SEARCH.REGIONS : undefined
    });

    try {
      const res = await RegionManagerAPI.getRegions(data)

      if (res && res.status === 200) {
        setIsLoading(false);
        setRegions({
          data: res.data.records || [],
          totalPage: res.data.total || 0,
        }); 
      }
    } catch (error) {
      setIsLoading(false);
        setRegions({
          data: [],
          totalPage: 0,
        }); 
    }
  }

  const fetchData = async () => {
    setIsLoading(true);

    const [countriesRes, regionsRes] = await Promise.all([
      CountryService.getCountries(),
      RegionManagerAPI.getRegions({pageNo: page, range: rows}),
    ]);
    if (countriesRes.status === 200 && regionsRes.status === 200) {
      setIsLoading(false);
      setCountries(countriesRes.data.data || []);
      setRegions({
        data: regionsRes.data.records || [],
        totalPage: regionsRes.data.total || 0,
      });
    }
  };

  const onSearchHistory = () => {
    if (page !== 0) {
      setPage(0);
      setFirst(0);
      return;
    }
    fetchRegionsData(search);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === "number") {
      fetchRegionsData(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const getCountryByAlphaCodeBodyTemplate = (rowData: any) => {
    const countryName = rowData.country;
    // @ts-ignore: Object is possibly 'null'.
    const country = countries.find((c) => c.ISO2 === countryName);
    if (rowData.country && country) {
      // @ts-ignore: Object is possibly 'null'.
      return country.name;
    }
    return "";
  };

  const onShowDeleteDialog = (isVisible: boolean) => {
    setRegion(null);
    setIsShowDeleteDialog(isVisible);
  };

  const onDeleteRegions = async (e: any, rowData: any) => {
    setIsShowDeleteDialog(true);
    setRegion(rowData);
  };

  const onDeleteConfirm = async () => {
    const response = await RegionManagerAPI.deleteRegion(region.uuid);
    if (response && response.status === 200) {
      onShowDeleteDialog(false);
      fetchData();
      showNotification("success", "Delete regions successfully.", toast);
    } else {
      showNotification("info", "Delete regions failed.", toast);
    }
  };

  const formatIdentifierBodyTemplate = (rowData: any) => {
    return <a target="_blank" className="table-ref-link-cell" href={`${env.DONATION_URL}?region=${rowData.identifier}`} rel="noreferrer">
      {rowData.identifier}
    </a>;
  };

  const tableColumns = [
    {
      field: "name",
      header: "Name",
      width: "15%",
      align: "left",
      bodyTemPlate: formatInnerHtmlDataByLanguageBodyTemplate(language),
    },
    {
      field: "status",
      header: "",
      width: "10%",
      align: "left",
      bodyTemPlate: formatStatusBodyTemplate(StatusDisplay),
    },
    {
      field: "identifier",
      header: "Identifier",
      width: "10%",
      align: "left",
      bodyTemPlate: formatIdentifierBodyTemplate,
    },
    {
      field: "default_land_id",
      header: "Default Area",
      width: "15%",
      align: "left",
      bodyTemPlate: (rowData: any) => {
        return rowData.land && rowData.land?.uuid ? <Link className="table-ref-link-cell" to={`/areas/${rowData.land?.uuid}`}>{rowData.land?.name?.de ||rowData.land?.name }</Link> : rowData.land?.name?.de || rowData.land?.name;
      }
    },
    { field: 'protected_size', header: 'Progress', width: "calc(30% - 50px)", align: 'left', bodyTemPlate: buildProgressTemplate("", "size", "protected_size") },
    { field: 'no_donations', header: 'Donation', width: "10%", align: 'left', bodyTemPlate: buildNumberBodyTemplate },
    {
      field: "country",
      header: "Country",
      width: "10%",
      align: "left",
      bodyTemPlate: getCountryByAlphaCodeBodyTemplate,
    },
  ];

  const buildMenu = (rowData: any) => {
    const defaultActions = [
        {
            label: "Edit",
            description: "Edit page information",
            icon: "fa-solid fa-pen-to-square",
            url: `/regions/${rowData?.uuid}`,
            // command: (e: any) => onUpdateRegions(e, rowData)
        },
        {
            label: "Delete",
            description: "Delete page information",
            icon: "fa-solid fa-trash-can delete-icon",
            dangerous: true,
            disabled: rowData.hasLands,
            command: (e: any) => onDeleteRegions(e, rowData)
        }
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
};

  const dynamicColumns = tableColumns.map((col, i) => {
    return <Column
      key={col.field}
      field={col.field}
      header={col.header}
      style={{ width: col.width }}
      body={col.bodyTemPlate}
      align={col.align as ColumnAlignType}
    />;
  });

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="region-manager">
        <div className="manager-container">
          <div className="certificate">
            <div className="d-flex flex-column flex-md-row justify-content-start justify-content-md-between certificate-action common-horizontal-layout-header">
              <WISearchField
                // className="search-box"
                icon={" pi pi-search"}
                placeholder="Search for anything"
                setSearch={(value: any) => setSearch(value.global.value)}
                enterSearch={onSearchHistory}
              />
              <div className="btn-create">
                <Link className="wi-main-button" to="/regions/create" >
                  <div className="wi-btn-label">Create <i className="fa-solid fa-circle-plus ml-2"></i></div>
                </Link>
              </div>
            </div>
          </div>
          <div className="table-region">
            <DataTable
              loading={isLoading}
              value={regions.data}
              filters={search}
              scrollable
              scrollHeight="calc(100vh - 166px)"
              scrollDirection="both"
            >
              {dynamicColumns}
              <Column
                style={{ width: "50px" }}
                body={buildMenu}
              ></Column>
            </DataTable>
            <WIPaginator
              first={first}
              rows={rows}
              totalRecords={regions.totalPage}
              onPageChange={onBasicPageChange}
            />
          </div>
          <WIConfirmDialog
            visible={isShowDeleteDialog}
            onHide={() => onShowDeleteDialog(false)}
            onConfirm={onDeleteConfirm}
            message={
              <Fragment>
                <span style={{fontSize: '18px', fontWeight: '700'}}>Are you sure you want to delete this region?</span>
                <br/>
                <span style={{fontSize: '13px'}}>
                  This will delete this region <b>{region?.name.de}</b> and its <b> areas</b> permanently. 
                  <br/>
                  You cannot undo this action.
                  </span>
              </Fragment>
            }
            classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
          />
        </div>
      </div>
    </Layout>
  );
};

export default RegionManager;
