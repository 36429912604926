import * as React from "react"
import { useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';  
// import { GoogleLogout } from 'react-google-login';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { Divider } from 'primereact/divider';
import icon from '../../images/logo.svg';
import './login.scss';
import { DrupalAPI } from '../../services';
import {
  useLocation
} from "react-router-dom";
import moment from 'moment';

const LoginPage = () => {
  const toast: any = useRef(null);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state }: { state: any } = useLocation();
  const navigate = useNavigate();

  const responseGoogle = (response: any) => {
    console.log(response);
  }

  const logout = () => {
    console.log('logout');
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      password: '',
    },
    onSubmit: async (data) => {
      setDisable(true);
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        setDisable(false);
      }, 1000);

      try {
        // const user = await DrupalAPI.login(data.name, data.password);
        const user = true;
        if (user) {
          const token = await DrupalAPI.getJWT(data.name, data.password);
          localStorage.setItem('access_token', token?.data?.token);
          localStorage.setItem('expire_time', moment().add('hours', 1).toISOString());
          if (state?.from) {
            navigate(state?.from, { replace: true });
          } else navigate("/", { replace: true });
        } else {
          toast.current.show({severity:'error', summary: 'Error Message', detail:'Username or password incorrect', life: 3000});
        }
        formik.resetForm();
      } catch (e) {
        toast.current.show({severity:'error', summary: 'Error Message', detail:'Username or password incorrect', life: 3000});
      }
    }
  });

  return (
    <div className="login-container">
      <div className="login-container__left">
        <div className="login-form">
          <Toast ref={toast} />
          <img src={icon} className="icon-header" />
          <form onSubmit={formik.handleSubmit} className="p-fluid form-control">
            {/* <div className="google-login">
              <GoogleLogin
                clientId="540124695545-b80b0n73un7f0cqlevjiutpt49n9ul6p.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}

              />
              <GoogleLogout
                clientId="540124695545-b80b0n73un7f0cqlevjiutpt49n9ul6p.apps.googleusercontent.com"
                buttonText="Logout"
                onLogoutSuccess={logout}
                onFailure={logout}
              ></GoogleLogout>
            </div>
            <Divider align="center">
              <b>or</b>
            </Divider> */}
            <h2 className="title-header">Log In</h2>
            <div className="my-2 mt-4 input-controls">
              <label htmlFor="name">
                Username <span className="color-red">*</span>
              </label>
              <InputText
                id="name"
                name="name"
                className="input-control"
                value={formik.values.name}
                onChange={formik.handleChange}
                autoFocus
                disabled= {disable}
              />
              <small id="username-help" className="warning-messenger">
                Enter your username to reset your password.
              </small>
            </div>
            <div className="field my-2 input-controls">
              <label htmlFor="password">
                Password <span className="color-red">*</span>
              </label>
              <Password
                id="password"
                name="password"
                className="input-control"
                toggleMask
                feedback={false}
                value={formik.values.password}
                onChange={formik.handleChange}
                disabled= {disable}
              />
              <small id="username-help" className="warning-messenger">
                Enter the password that accompanies your username.
              </small>
            </div>
            <Button
              type="submit"
              label="Sign In"
              className="my-2 btn btn-w-sign-in active"
              loading= {loading}
            />
            <Button
              type="button"
              label="Create new account"
              className="my-2 btn btn-w-create"
              style={{ visibility: "hidden" }}
            />
          </form>
        </div>
      </div>
      <div className="login-container__right">
        <img
          src="//source.unsplash.com/collection/9407737"
          alt="Random image from unsplash"
        />
      </div>
    </div>
  );
}

export default LoginPage
