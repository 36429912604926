import { AxiosClient, cacheAge } from './httpClient';

export default class CMsPageManagerAPI {
 
  static get() {
    return AxiosClient.get(`/pages/test`);
  }

  static update(id: string, content: any) {
    return AxiosClient.put(`/pages/${id}/content`, {content});
  }

  static getCTABlocks(enableCache: boolean = true) {
    return enableCache
    ? AxiosClient.get(`/cta-blocks`, cacheAge)
    : AxiosClient.get(`/cta-blocks`);
  }

  static getCTABlocksById(id: string) {
    return AxiosClient.get(`/cta-blocks/${id}`);
  }

  static createCTABlocks(data: any) {
    return AxiosClient.post(`/cta-blocks`, data);
  }

  static updateCTABlocks(data: any) {
    return AxiosClient.put(`/cta-blocks/${data.uuid}`, data);
  }

  static getPages(page_type_id: string, data: any) {
    return AxiosClient.get(`/pages/${page_type_id}/pagination`, {...data, maxAge: 10 * 1000});
  }

  static getAllPagesWithPagination(data: any) {
    return AxiosClient.get(`/pages/pagination`, {...data, maxAge: 10 * 1000});
  }

  static searchAllPagesWithPagination(query: any, params: any) {
    return AxiosClient.post(`/pages/search/pagination`, query, {params: params});
  }

  static getAllPages() {
    return AxiosClient.get(`/pages`, cacheAge);
  }

  static getCMSPageById(id: string) {
    return AxiosClient.get(`/pages/${id}/content`);
  }

  static deletePage(id: string) {
    return AxiosClient.delete(`/pages/${id}`);
  }

  static createPage(data: any) {
    return AxiosClient.post(`/pages`, data);
  }

  static createAndPublishPage(data: any) {
    return AxiosClient.post(`/pages/publish`, data);
  }

  static getAllPageType() {
    return AxiosClient.get(`/page-types`, cacheAge);
  }

  static getAllCMSFooters() {
    return AxiosClient.get(`/footers`, cacheAge);
  }

  static getAllCMSHeaders() {
    return AxiosClient.get(`/headers`, cacheAge);
  }

  static getAllCMSMenuNodes() {
    return AxiosClient.get(`/menu-nodes`);
  }

  static getCMSPageDetail(uuid: string) {
    return AxiosClient.get(`/pages/${uuid}`);
  }

  static updatePage(id: string,data: any) {
    return AxiosClient.put(`/pages/${id}`,  data);
  }

  static getCmsPagePreview(id: string, lang = 'de') {
    return AxiosClient.get(`/pages/${id}/preview?lang=${lang}`);
  }

  static getHeadHtml() {
    return AxiosClient.get(`/pages/html-head`, cacheAge);
  }

  static getAllCMSDonationExamples() {
    return AxiosClient.get(`/donation-examples`);
  }

  static importCMSPages(csvData: any) {
    return AxiosClient.post(`/pages/import-csv`, csvData);
  }

  static unpublishedPage(pageId: any) {
    return AxiosClient.put(`/pages/${pageId}/unpublished`);
  }
}
