import * as React from "react";
import Layout from "../../../layout/layout";
import "./create-block-manager.scss";
import CreateBlockFormComponent from "../create-block-form/create-block-form";

const CreateBlockManager = (props: any) => {
  return (
    <Layout>
      <div className="d-flex flex-column">
        <CreateBlockFormComponent />
      </div>
    </Layout>
  );
};

export default CreateBlockManager;
