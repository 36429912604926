import * as React from "react";
import moment from "moment";
import { Column, ColumnAlignType } from "primereact/column";
import { env } from "../../environment";
import { Tag } from "primereact/tag";
import { formatDateBodyTemplate, formatLogoBodyTemplate, formatStatusBodyTemplate } from "../common/column-template-table/column-template";
import { StatusDisplay } from "../../utils/utils";

export const BlockType = [
  { name: 'Media', key: 'Media' },
  { name: 'Text', key: 'text' },
  { name: 'General blocks', key: 'General block' },
  { name: 'CTA', key: 'CTA' },
  { name: 'Content header', key: 'Content Header' }
];

export const blocksTable = [
  { field: "serial", header: "No.", width: "5%", align: "center" },
  { field: "thumbnail", header: "Thumbnail", width: "15%", align: "center", bodyTemplate: formatLogoBodyTemplate(env.PUBLIC_IMAGE_URL) },
  { field: "name", header: "Name", width: "15%", align: "center"},
  { field: "type", header: "Type", width: "10%", align: "center" },
  { field: "is_deleted", header: "Status", width: "10%", align: "center", bodyTemplate: formatStatusBodyTemplate(StatusDisplay) },
  { field: "created_at", header: "Created", width: "12.5%", align: "center", bodyTemplate: formatDateBodyTemplate("DD.MM.YYYY") },
  { field: "updated_at", header: "Updated", width: "12.5%", align: "center", bodyTemplate: formatDateBodyTemplate("DD.MM.YYYY") },
  { field: "updated_user", header: "Updated By", width: "10%", align: "center" }
];

export const dynamicBlockColumns = blocksTable.map((col, i) => {
  return (
      <Column
          key={col.field}
          field={col.field}
          header={col.header}
          style={{ width: col.width }}
          body={col.bodyTemplate}
          align={col.align as ColumnAlignType}
      />
  );
});

export const parametersObject = (keys: string[]) => {
  const entries = new Map();
  if (keys.length > 0) {
    for (const item of keys) {
      entries.set(item, "");
    }
    return Object.fromEntries(entries);
  }
  return undefined;
};

export const templateHTML = (data?: any, style?: string, script?: string, selectedComponent?: any) => `
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Info Box</title>

    <link rel="stylesheet" href="https://cta-library.wilderness-international.huunguyen.de/src/css/above_the_fold.min.css">
    <link rel="stylesheet" href="https://cta-library.wilderness-international.huunguyen.de/src/css/main.min.css">
    <style type="text/css">
      @font-face {
        font-family: 'wildicon';
        src: url(fonts/icon/wildicon.woff2) format('woff2'),
          url(fonts/icon/wildicon.woff) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: block;
      }
      ::-webkit-scrollbar {
        width: 10px;
      }
    
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 8px;
      }
    
      ::-webkit-scrollbar-thumb {
        background-color: #13735f;
        border-radius: 10px;
      }
    
      ::-webkit-scrollbar-thumb:hover {
        background-color: #13735f;
      }
    </style>
    <script src="https://cta-library.wilderness-international.huunguyen.de/src/js/min/theme.min.js"></script>
    <script src="https://cta-library.wilderness-international.huunguyen.de/src/js/min/sliders.min.js"></script>
    <script src="https://cta-library.wilderness-international.huunguyen.de/src/js/min/accordion.min.js"></script>
    <script src="https://cta-library.wilderness-international.huunguyen.de/src/js/min/custom_select.min.js"></script>
    <script src="https://cta-library.wilderness-international.huunguyen.de/src/js/min/custom_number_input.min.js"></script>
    <script src="https://cta-library.wilderness-international.huunguyen.de/src/js/min/double_optin.min.js"></script>
    <script src="https://cta-library.wilderness-international.huunguyen.de/src/js/min/lazy_loading.min.js"></script>
    <script src="https://cta-library.wilderness-international.huunguyen.de/src/js/min/menu.min.js"></script>
    <script src="https://cta-library.wilderness-international.huunguyen.de/src/js/min/newsletter_signup.min.js"></script>
    <script src="https://cta-library.wilderness-international.huunguyen.de/src/js/min/opt_out.min.js"></script>
    <script src="https://cta-library.wilderness-international.huunguyen.de/src/js/min/resubscribe.min.js"></script>
    <script src="https://cta-library.wilderness-international.huunguyen.de/src/js/min/sticky_cta_btn.min.js"></script>
  </head>
  <body style="background-color: transparent">
    <section id='123' class="unselected-component">
      ${data}
    </section>
  </body>
`;