import { AxiosClient } from "./httpClient";

export default class DonorManagerAPI {

  static getDonorsBySearch(data: any) {
    return AxiosClient.post(`/donors/search`, data);
  }

  static getDonors(uuid: string) {
    return AxiosClient.get(`/donors/${uuid}`);
  }

  static createDonor(data: any) {
    return AxiosClient.post(`/donors`,  data);
  }

  static getDonorByEmail(email: any) {
      return AxiosClient.get(`/donors/${email}`);
  }

  static generateDonorPortalURL(data: any) {
    return AxiosClient.post(`/donors/portal-link`, data);
  }

  static searchDonors(data: any) {
    return AxiosClient.post(`/donors/simple-search`, { text: data });
  }
}