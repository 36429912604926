import * as React from "react"
import { useState, useEffect, useRef, Fragment } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import { LandManagerAPI } from '../../services';
import { showNotification } from "../../utils/logic";
import { LanguageList, FIELDS_SEARCH, removeEmpty, StatusDisplay } from "../../utils/utils";
import { Toast } from "primereact/toast";
import RegionManagerAPI from "../../services/regionManager";
import Layout from '../layout/layout';
import './land-manager.scss';
import { MenuDot, WIConfirmDialog, WIPaginator, WISearchField } from "../common";
import { buildNumberBodyTemplate, buildProgressTemplate, formatDateBodyTemplate, formatInnerHtmlDataByLanguageBodyTemplate, formatStatusBodyTemplate } from "../common/column-template-table/column-template";

const LandManagerComponent = () => {
  const toast = useRef(null);
  const [selectedLand, setSelectedLand] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [lands, setLands] = useState({
    data: [],
    totalPage: 0,
  });
  const [regions, setRegions] = useState([]);
  const [page, setPage] = useState(0);
  const [language] = useState(LanguageList[0]);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [search, setSearch] = useState<any>(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  }

  const fetchLandsData = async (valueSearch?: string) => {
    setIsLoading(true);

    const data = removeEmpty({
      pageNo: page,
      range: rows,
      search_text: valueSearch || undefined,
      search_fields: valueSearch ? FIELDS_SEARCH.LANDS : undefined
    });

    try {
      const [res, regionRes] = await Promise.all([LandManagerAPI.getLands(data), RegionManagerAPI.getAllRegions()]);
      if (res && res.status === 200) {
        const dataLands = res.data.records || [];
        const dataRegions = (regionRes.data.records || []).map((f: any) => ({ id: f.uuid, name: f.name.de  }));
        setIsLoading(false);
        handleRegionName(dataLands, dataRegions);
        setRegions(dataRegions);
        setLands({
          data: dataLands,
          totalPage: res.data.total || 0,
        });

        return dataLands;
      }
      return [];
    } catch (error) {
      setIsLoading(false);
      setRegions([]);
      setLands({
        data: [],
        totalPage: 0,
      });
    }
  }

  const onSearch = () => {
    if (page !== 0) {
      setPage(0);
      setFirst(0);
      return;
    }
    fetchLandsData(search);
  }

  const loadData = async () => fetchLandsData(search);

  useEffect(() => {
    if (typeof page === "number") {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const handleRegionName = (land: any, regionList = []) => {
    if (land && regionList) {
      land.forEach((l: any) => {
        const selectedRegion: any = (regionList || regions).find((r: any) => r.id === l.region_id);
        l.region_name = selectedRegion ? selectedRegion.name : null;
      })
    }
  }

  const onShowDeleteDialog = (isVisible: boolean) => {
    setSelectedLand(null);
    setIsShowDeleteDialog(isVisible);
  };

  const onDeleteConfirm = async () => {
    const response = await LandManagerAPI.deleteLand(selectedLand.uuid);
    if (response && response.status === 200 && response.data.result.isValid) {
      onShowDeleteDialog(false);
      fetchLandsData(search);
      showNotification("success", "Delete areas successfully.", toast);
    } else {
      showNotification("info", "Delete areas failed.", toast);
    }
  };

  const tableColumns = [
    { field: 'serial', header: 'Serial', width: "5%", align: 'left' },
    { field: 'name', header: 'Name', width: "15%", align: 'left', bodyTemPlate: formatInnerHtmlDataByLanguageBodyTemplate(language) },
    {
      field: 'code', header: 'Identifier', width: "9%", align: 'left',
      // bodyTemPlate: (rowData: any) => {
      //   return <a className="table-ref-link-cell" href={`${env.DONATION_URL}?area=${rowData.code}`} target="_blank">{rowData.code}</a>;
      // }
    },
    { field: 'status', header: '', width: "10%", align: 'center', bodyTemPlate: formatStatusBodyTemplate(StatusDisplay) },
    { field: 'land_type', header: 'Type', width: "10%", align: 'left', bodyTemPlate: (rowData: any) => rowData.land_type === 'public' ? 'Public' : 'Campaign Only' },
    {
      field: 'region_id', header: 'Region', width: "15%", align: 'left',
      bodyTemPlate: (rowData: any) => {
        return <a className="table-ref-link-cell" href={`/regions/${rowData.region_id}`} target="_blank">{rowData.region_name}</a>;
      }
    },
    { field: 'protected_size', header: 'Progress', width: "21%", align: 'left', bodyTemPlate: buildProgressTemplate("calculator", "size", "available_size") },
    { field: 'no_donations', header: 'Donation', width: "9%", align: 'left', bodyTemPlate: buildNumberBodyTemplate },
    { field: 'updated_at', header: 'Updated At', width: "calc(15% - 50px)", align: 'left', bodyTemPlate: formatDateBodyTemplate("DD.MM.YYYY") },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return <Column
      key={col.field}
      field={col.field}
      header={col.header}
      style={{ width: col.width }}
      body={col.bodyTemPlate}
      align={col.align as ColumnAlignType}
    />;
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      // {
      //   label: "Open Donation Link",
      //   description: "Open area's donation link",
      //   icon: "fa-solid fa-arrow-up-right-from-square",
      //   externalUrl: `${env.DONATION_URL}?area=${rowData.code}`,
      // },
      {
        label: "Edit",
        description: "Edit area information",
        icon: "fa-solid fa-pen-to-square",
        url: `/areas/${rowData?.uuid}`,
      },
      {
        label: "Delete",
        description: "Delete area information that isn't used anywhere yet",
        icon: "fa-solid fa-trash-can delete-icon",
        dangerous: true,
        disabled: +rowData.protected_size > 0,
        command: (e: any) => {
          setSelectedLand(rowData);
          setIsShowDeleteDialog(true);
        },
      }
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const buildDeleteMessage = () => {
    return (<Fragment>
      <span style={{ fontSize: '18px', fontWeight: '700' }}>Are you sure you want to delete this area?</span>
      <br />
      <span style={{ fontSize: '13px' }}>
        All of your below data will be deleted <b>permanently</b>:
        <ul style={{ marginBottom: '5px' }}>
          <li>This area with identified code <b>{selectedLand?.code}</b></li>
          <li>All <b>campaigns</b> belongs to this area</li>
          <li>All <b>donations</b> belongs to this area</li>
          <li>All <b>codes</b> belongs to this area</li>
        </ul>
        You <b>cannot undo</b> this action.
      </span>
    </Fragment>)
  }

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="land-manager">
        <div className="manager-container">
          <div className="header-content common-horizontal-layout-header">
            <WISearchField
              icon={'pi pi-search'}
              placeholder="Search for anything"
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={onSearch}
            />
            <div className="certificate">
              <div className="btn-create">
                <Link className="wi-main-button" to="/areas/create" >
                  <div className="wi-btn-label">Create <i className="fa-solid fa-circle-plus ml-2"></i></div>
                </Link>
              </div>
            </div>
          </div>
          <div className="table-land">
            <DataTable
              loading={isLoading}
              value={lands.data}
              filters={search}
              globalFilterFields={['name', 'code']}
              emptyMessage="No areas found."
              scrollable
              scrollHeight="calc(100vh - 166px)"
              scrollDirection="both"
            >
              {dynamicColumns}
              <Column
                frozen
                alignFrozen="right"
                style={{ width: "50px" }}
                body={buildMenu}
              ></Column>
            </DataTable>
            <WIPaginator
              first={first}
              rows={rows}
              totalRecords={lands.totalPage}
              onPageChange={onBasicPageChange}
            />
          </div>
          <WIConfirmDialog
            visible={isShowDeleteDialog}
            onHide={() => onShowDeleteDialog(false)}
            onConfirm={onDeleteConfirm}
            message={buildDeleteMessage()}
            classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
          />
        </div>
      </div>
    </Layout>
  );
}

export default LandManagerComponent;