import { useRef, useState } from "react";
import React from "react";
import "./add-certificate-form.scss";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { parseStringToFloat, showNotification } from "../../../../utils/logic";
import { Dropdown } from "primereact/dropdown";
import { WIButton } from "../../../common";
import { TypeLandCode } from "../../../../utils/utils";
import { SelectButton } from "primereact/selectbutton";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { CertificateManagerAPI } from "../../../../services";

const AddCertificateFormComponent = (props: any) => {
  const { onHide, fetchCallBack, toast, certificateData, regions, campaigns } = props;
  
  const formikRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const ImportTypeList = [
    {
      name: 'Region',
      code: TypeLandCode.region
    },
    {
      name: 'Campaign',
      code: TypeLandCode.campaign
    }
  ]

  const onAddCertificate = async () => {
    try {
        // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && dirty && isValid) {
        setIsLoading(true);
        let valuesData = {
          ...values,
          certificate_date: moment.utc(moment(new Date(values.certificate_date)).format("MM/DD/YYYY HH:mm:ss")).toISOString(),
          protected_amount: +values.protected_amount
        };

        if (values.type === TypeLandCode.region) {
          valuesData.region_id = values.region_code;
          valuesData.campaign_id = null;
        } else {
          valuesData.campaign_id = values.region_code;
          valuesData.region_id = null;
        }

        delete valuesData.region_code;

        if(certificateData) {
          let updateRes = await CertificateManagerAPI.updateCertificate(certificateData.id, valuesData);
          if (updateRes?.status === 200 && updateRes?.data) {
            showNotification(
              "success",
              "Update certificate successfully.",
              toast
            );
            onHide();
  
            fetchCallBack(updateRes.data);
            return;
          }
        } else {
          let createRes = await CertificateManagerAPI.generateCertificate(valuesData);
          if (createRes?.status === 200 && createRes?.data) {
            showNotification( 
              "success",
              "Update certificate successfully.",
              toast
            );
            onHide();
  
            fetchCallBack(createRes.data);
            return;
          }
        }
      }
    } catch {
        showNotification("error", "Check certificate failed.", toast);
        setIsLoading(false);
    }
  };

  const initialFormValues = () => {
    let initialCertificate = {
      type: TypeLandCode.region,
      region_id: "",
      campaign_id: "",
      certificate_date: moment().toDate(),
      certificate_name: "",
      occasion: "",
      protected_amount: 0,
      region_code: '',
    };

    if(certificateData) {
      initialCertificate.type = certificateData.type || TypeLandCode.region;
      initialCertificate.region_id = certificateData.region_id || '';
      initialCertificate.campaign_id = certificateData.campaign_id || '';
      initialCertificate.certificate_date = new Date(certificateData.certificate_date) || moment().toDate();
      initialCertificate.certificate_name = certificateData.certificate_name || '';
      initialCertificate.occasion = certificateData.occasion || '';
      initialCertificate.protected_amount = certificateData.protected_amount || '';
      initialCertificate.region_code = certificateData.type === TypeLandCode.region ? certificateData.region_id : certificateData.campaign_id;
    }

    return initialCertificate;
  };

  const validationSchema = Yup.object().shape({
    certificate_date: Yup.string().nullable().required("This field is required."),
    region_code: Yup.string().nullable().required("This field is required."),
    certificate_name: Yup.string().nullable().required("This field is required."),
    protected_amount: Yup.number().nullable().moreThan(0, "This field must be greater than 0.").required("This field is required."),
  });

  const formatOptionTemplate = (option: any, available_size: any) => {
    return (
      <div>
        {option.name.de} - Available size [
        {parseInt(available_size)?.toLocaleString("de-DE")} m<sup>2</sup>]{" "}
      </div>
    );
  };

  const selectedOptionTemplate = (
    option: any,
    values: any,
    available_size: any,
    props: any
  ) => {
    if (available_size < values.custom_area) {
      values.donation_type === "region"
        ? (values.region_id = "")
        : (values.campaign_id = "");
      option = null;
    }

    return option ? (
      formatOptionTemplate(option, available_size)
    ) : (
      <span>{props.placeholder}</span>
    );
  };


  return (
    <>
      <div className="add-certificate-form">
        <div className="add-certificate-form-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
            }}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              isValid,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleChange,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">
                          Type
                          <span className="required-label"> *</span>
                        </label>
                        <SelectButton
                          id="type"
                          name="type"
                          value={values.type}
                          className="wi-selectbutton"
                          options={ImportTypeList}
                          onChange={(item: any) => {
                            if (item.value) {
                              setFieldValue("type", item.value, true);
                            }
                          }}
                          optionLabel="name"
                          optionValue="code"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">
                          Certificate date
                          <span className="required-label"> *</span>
                        </label>
                        <div className="calendar-item">
                          <Calendar
                            id="basic"
                            name="certificate_date"
                            panelClassName="expire-calendar"
                            value={values.certificate_date}
                            onBlur={handleBlur}
                            onChange={(item) => {
                              setFieldValue("certificate_date", item.value, false);
                            }}
                            dateFormat="dd.mm.yy"
                            iconPos="right"
                            icon="fa-solid fa-calendar-days"
                            showIcon={true}
                          />
                        </div>
                        <div
                          className={`${
                            touched.certificate_date && errors.certificate_date
                              ? "error"
                              : ""
                          }`}
                        >
                          {`${
                            touched.certificate_date && errors.certificate_date
                              ? errors.certificate_date
                              : ""
                          }`}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">
                          {values.type === TypeLandCode.region
                            ? "Region"
                            : "Campaign"}
                          <span className="required-label"> *</span>
                        </label>
                        <Dropdown
                          name="region_code"
                          id="region_code"
                          className="p-dropdown-custom-search"
                          value={values.region_code}
                          onBlur={handleBlur}
                          options={values.type === TypeLandCode.region ? regions : campaigns || []}
                          onChange={(item: any) => {
                            setFieldValue("region_code", item.value, false);
                          }}
                          optionLabel={`name.de`}
                          optionDisabled={(option) => values.type === TypeLandCode.region ? (option?.size - option?.protected_size) < 0 : option?.land?.available_size < 0
                          }
                          itemTemplate={(option) =>
                            formatOptionTemplate(
                              option,
                              values.type === TypeLandCode.region
                                ? (option?.size - option?.protected_size)
                                : option?.land?.available_size
                            )
                          }
                          valueTemplate={(option, props) =>
                            selectedOptionTemplate(
                              option,
                              values,
                              values.type === TypeLandCode.region
                                ? (option?.size - option?.protected_size)
                                : option?.land?.available_size,
                              props
                            )
                          }
                          optionValue={"uuid"}
                          appendTo="self"
                          placeholder={`Choose ${
                            values.type === TypeLandCode.region
                              ? "a region"
                              : "a campaign"
                          }`}
                          filter
                          filterBy="region.name.de,name,name.de,code"
                        />
                        <div
                          className={`${
                            touched.region_code && errors.region_code ? "error" : ""
                          }`}
                        >
                          {`${
                            touched.region_code && errors.region_code
                              ? errors.region_code
                              : ""
                          }`}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">Certificate name <span className="required-label"> *</span></label>
                        <InputText
                          value={values.certificate_name}
                          name="certificate_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={80}
                        />
                        <div className={`${touched.certificate_name && errors.certificate_name ? 'error' : ''}`}>
                            {touched.certificate_name && errors.certificate_name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">Certificate occasion</label>
                        <InputText
                          value={values.occasion}
                          name="occasion"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={80}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">Protected amount (m<sup>2</sup>) <span className="required-label"> *</span></label>
                        <InputNumber
                          value={values.protected_amount}
                          name="protected_amount"
                          onBlur={(e) => {
                              const value = e.target.value ? parseStringToFloat(e.target.value) : 0;
                              setFieldValue('protected_amount', value, false);
                              setTimeout(() => {
                                  handleBlur(e);
                              }, 200);
                          }}
                          mode="decimal" 
                          locale="de-DE" 
                          placeholder="0.00"
                          minFractionDigits={0}
                          maxFractionDigits={2}
                      />
                        <div className={`${touched.protected_amount && errors.protected_amount ? 'error' : ''}`}>
                            {touched.protected_amount && errors.protected_amount}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-submit">
                    <WIButton
                      className="add-certificate"
                      primary={true}
                      type="submit"
                      label={certificateData ? 'Update' : 'Add'}
                      icon={"pi-angle-right"}
                      onClick={() => onAddCertificate()}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddCertificateFormComponent;
