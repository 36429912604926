import * as React from "react";
import { useState, useEffect, useRef, Fragment } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Column, ColumnAlignType } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from 'primereact/toast';
import { PartnerManagerAPI, CampaignManagerAPI, PartnerTypeManagerAPI, CMsPageManagerAPI } from "../../services";
import { env } from "../../environment";
import { removeEmpty, FIELDS_SEARCH, StatusDisplay, LanguageList } from "../../utils/utils";
import CountryService from "../../services/country-service";
import Layout from "../layout/layout";
import "./partner-manager.scss";
import { showNotification } from "../../utils/logic";
import { WIButton, WIConfirmDialog, WISearchField, WIPaginator, MenuDot} from "../common";
import { getInternalURLPages, fetchPageTypes, getPageTypeByCode } from "../pages/builder-page.common";
import { PAGE_CODE } from "../pages/utils";
import { buildActionBtnTemplate, formatDateBodyTemplate, formatInnerHtmlDataByLanguageBodyTemplate, formatLogoBodyTemplate, formatStatusBodyTemplate } from "../common/column-template-table/column-template";
import { findCmsPage } from "../../utils/commonGeneratePageData";

const PartnerManagerComponent = () => {
  const navigate = useNavigate();
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [partners, setPartners] = useState({
    data: [],
    totalPage: 0,
  });
  const [page, setPage] = useState(0);
  const [partner, setPartner] = useState<any>(null);
  const [countries, setCountries] = useState([]);
  const [search, setSearch] = useState<any>(null);
  const toast = useRef(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [globalContent, setGlobalContent] = useState<any>([]);
  const [partnerTypes, setPartnerTypes] = useState<any>([]);
  const [cmsPartnerPages, setCmsPartnerPages] = useState<any>([]);
  const [cmsMasterPages, setCmsMasterPages] = useState<any>([]);
  const [language] = useState(LanguageList[0]);

  const getSearchContent = async () => {
    let pageTypes = await fetchPageTypes();
    let partnerPageType = getPageTypeByCode(pageTypes, PAGE_CODE.Partner);
    let masterPageType = getPageTypeByCode(pageTypes, PAGE_CODE.Master);

    let [
      partnerRes,
      masterRes
    ] = await Promise.all([
        CMsPageManagerAPI.getPages(partnerPageType?.uuid, { pageNo: 0, range: 10000 }),
        CMsPageManagerAPI.getPages(masterPageType?.uuid, { pageNo: 0, range: 10000 })
    ]);

    let partnerPages = partnerRes?.data?.records;
    let masterPages = masterRes?.data?.records;
    
    setCmsPartnerPages(partnerPages);
    setCmsMasterPages(masterPages);

    let internal_urls = await getInternalURLPages(partnerPages.concat(masterPages));
    let partnerIds = partnerPages.map((m: any) => m.uuid);
    let partner_urls = internal_urls.filter(f => partnerIds.includes(f.id));
    setGlobalContent(partner_urls || [])
  }

  const fetchPartnerType = async () => {
    const result = await PartnerTypeManagerAPI.getAll();
    if (result.status === 200 && result.data.records) {
      setPartnerTypes(result.data.records);
    }
  };

  const getAllData = async () => {
     await Promise.all([
      getSearchContent(),
      fetchPartnerType(),
      fetchData()
    ]);
  }

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === "number") {
      fetchPartnersData(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  }

  const onSearchHistory = () => {
    if (page !== 0) {
      setPage(0);
      setFirst(0);
      return;
    }
    fetchPartnersData(search);
  }

  const fetchPartnersData = async (valueSearch?: string) => {
    setIsLoading(true);

    const data = removeEmpty({
      pageNo: page,
      range: rows,
      search_text: valueSearch || undefined,
      search_fields: valueSearch ? FIELDS_SEARCH.PARTNERS : undefined
    });

    try {
      const res = await PartnerManagerAPI.getPartners(data, false);

      if (res && res.status === 200) {
        setIsLoading(false);
        setPartners({
          data: res.data.records || [],
          totalPage: res.data.total || 0
        }); 
      }
    } catch (error) {
      setIsLoading(false);
        setPartners({
          data: [],
          totalPage: 0
        }); 
    }
  }


  const fetchData = async () => {
    setIsLoading(true);
    const [countriesRes, partnersRes] = await Promise.all([
      CountryService.getCountries(), 
      PartnerManagerAPI.getPartners({pageNo: page, range: rows}, false),
      getSearchContent()
    ]);

    // @ts-ignore: Object is possibly 'null'.
    if (countriesRes.status === 200 && partnersRes.status === 200) {
      setCountries(countriesRes.data.data);
      setPartners({
        data: partnersRes.data.records || [],
        totalPage: partnersRes.data.total || 0,
      });
      setIsLoading(false);
    }
  };

  const onShowCreateDialog = (event: any) => {
    event.preventDefault();
    navigate('/partners/create');
  };

  const onShowDeleteDialog = (isVisible: boolean) => {
    setPartner(null);
    setIsShowDeleteDialog(isVisible);
  };

  // const formatPartnerTypeBodytemplate = (field: string) => (rowData: any) => {
  //   const filterPartner = partnerTypes?.find((partner) => partner.uuid === rowData[field]);
  //   return <div dangerouslySetInnerHTML={{__html: filterPartner ? filterPartner.name.de : ''}}></div>
  // };

  const getCountryByAlphaCodeBodyTemplate = (rowData: any) => {
    const countryName = rowData.country;
    // @ts-ignore: Object is possibly 'null'.
    const country = countries.find((c) => c.ISO2 === countryName);
    if (rowData.country && country) {
      // @ts-ignore: Object is possibly 'null'.
      return country.name;
    }
    return "";
  };

  const getConditiontoDeletePartner = async (id: string) => {
    const data = {
      pageNo: page,
      range: rows,
      search_text: id || undefined,
      search_fields: id ? 'partner_id' : undefined
    }

    const campaignData = await CampaignManagerAPI.getCampaigns(data);

    return campaignData.data.total ? false : true;
    
  }

  const onDeletePartners = async (e: any, rowData: any) => {
    const isDelete = await getConditiontoDeletePartner(rowData.uuid);
    if(isDelete) {
      setIsShowDeleteDialog(true);
      setPartner(rowData);
    } else {
      showNotification("error", "This partner has ongoing campaigns and cannot be deleted.", toast);
    }
  };
  
  const tableColumns = [
    // { field: 'serial', header: 'ID', width: "5%", align: 'center' },
    { field: 'partner_logo', header: 'Logo', width: "15%", align: 'left', bodyTemPlate: formatLogoBodyTemplate(env.PUBLIC_UPLOAD_URL) },
    { field: 'name', header: 'Name', width: "calc(25% - 50px)", align: 'left'},
    { field: 'code', header: 'Code', width: "5%", align: 'left' },
    { field: 'status', header: '', width: "12%", align: 'center', bodyTemPlate: formatStatusBodyTemplate(StatusDisplay) },
    { field: 'type_id', header: 'Partner Type', width: "17%", align: 'left', bodyTemPlate: formatInnerHtmlDataByLanguageBodyTemplate(language, partnerTypes) },
    // { field: 'country', header: 'Country', width: "10%", align: 'center', bodyTemPlate: getCountryByAlphaCodeBodyTemplate},
    { field: 'created_at', header: 'Created At', width: "13%", align: 'left', bodyTemPlate: formatDateBodyTemplate("DD.MM.YYYY") },
    { field: 'updated_at', header: 'Updated At', width: "13%", align: 'left', bodyTemPlate: formatDateBodyTemplate("DD.MM.YYYY") },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return <Column
      key={col.field}
      field={col.field}
      header={col.header}
      className={col.field}
      style={{width: col.width}}
      body={col.bodyTemPlate}
      align={col.align as ColumnAlignType}
    />;
  });

  
  const onDeleteConfirm = async () => {
    if(!partner?.uuid) {
      return;
    }

    let cmsPartnerPage = findCmsPage(cmsPartnerPages, partner);
    if(cmsPartnerPage) {
      let deletePartnerPageRes = await CMsPageManagerAPI.deletePage(cmsPartnerPage.uuid);
      if (deletePartnerPageRes && deletePartnerPageRes.status === 200 && deletePartnerPageRes.data.result.isValid) {
        showNotification("success", "Delete partner page successfully.", toast);
      } else {
        showNotification("info", "Delete partner page failed.", toast);
        return;
      }
    }

    const response = await PartnerManagerAPI.deletePartner(partner.uuid);
    if (response && response.status === 200 && response.data.result.isValid) {
      onShowDeleteDialog(false);
      fetchData();
      showNotification("success", "Delete partners successfully.", toast);
    } else {
      showNotification("info", "Delete partners failed.", toast);
    }
  };

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
          label: "Edit",
          description: "Edit partner information",
          icon: "fa-solid fa-pen-to-square",
          url: `/partners/${rowData?.uuid}`,
      },
      {
        label: "Delete",
        description: "Delete partner information",
        icon: "fa-solid fa-trash-can delete-icon",
        dangerous: true,
        command: (e: any) => {
          onDeletePartners(e, rowData);
        },
      }
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="partner-manager">
        <div className="manager-container">
          <div className="headline">
            <h3>Partner</h3>
          </div>
          <div className="certificate">
            <div className="d-flex flex-column flex-md-row justify-content-start justify-content-md-between certificate-action common-horizontal-layout-header">
              <WISearchField
                // className="search-box"
                icon={'pi pi-search'}
                placeholder="Search for anything"
                setSearch={(value: any) => setSearch(value.global.value)}
                enterSearch={onSearchHistory}
              />
              <div className="btn-create">
                <Link className="wi-main-button" to="/partners/create" >
                  <div className="wi-btn-label">Create <i className="fa-solid fa-circle-plus ml-2"></i></div>
                </Link>
              </div>
            </div>
          </div>
          <div className="table-land table-partners">
            <DataTable
              loading={isLoading}
              value={partners.data}
              filters={search}
              globalFilterFields={['name', 'code']}
              emptyMessage="No partners found."
              responsiveLayout="scroll"
              scrollable 
              scrollHeight="calc(100vh - 215px)"
              scrollDirection="both"
            >
              {dynamicColumns}
              <Column 
                frozen
                alignFrozen="right"
                style={{ width: '50px' }} 
                body={buildMenu}></Column>
            </DataTable>
            <WIPaginator
              first={first}
              rows={rows}
              totalRecords={partners.totalPage}
              onPageChange={onBasicPageChange}
            />
          </div>
          <WIConfirmDialog 
            visible={isShowDeleteDialog}
            onHide={() => onShowDeleteDialog(false)}
            onConfirm={onDeleteConfirm}
            message={
              <Fragment>
                <span style={{fontSize: '18px', fontWeight: '700'}}>Are you sure you want to delete this partner?</span>
                <br/>
                <span style={{fontSize: '13px'}}>
                  All of your below data will be deleted <b>permanently</b>:
                  <ul style={{marginBottom: '5px'}}>
                    <li>This partner with identified code <b>{partner?.code}</b></li>
                    <li>The <b>page</b> related to this partner</li>
                    <li>All <b>codes</b> belongs to this partner</li>
                  </ul>
                  You <b>cannot undo</b> this action.
                  </span>
              </Fragment>
            }
            classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
          />
        </div>
      </div>
    </Layout>
  );
};

export default PartnerManagerComponent;
