import * as React from "react";
import { useEffect, useState } from "react";
import "./subscription-filter-component.scss";

import { Button } from "primereact/button";
import { WISearchField } from "../../common";
import { SUBSCRIPTION_RHYTHM_LIST, SUBSCRIPTION_STATUS } from "../../../utils/utils";
import CalendarField from "../../donation-manager/components/donation-filter/calendar-field";
import FilterField from "../../donation-manager/components/donation-filter/filter-field";

const SubscriptionFilterComponent = (props: any) => {
  const { fields, filterValues, lang, onRemove, onChange, onSearchFunc } =
    props;
  const [status, setStatus] = useState<any[]>(SUBSCRIPTION_STATUS);
  const [rhythm, setRhythm] = useState<any[]>(SUBSCRIPTION_RHYTHM_LIST);
  const [values, setValues] = useState<Map<string, string[]>>();
  const [isDirty, setDirty] = useState<boolean>(false);
  const [search, setSearch] = useState<any>(filterValues.search);

  const getFields = () => {
    const resetValues = new Map<string, string[]>();
    fields.forEach((item: string) => {
      resetValues.set(item, []);
    });
    setValues(resetValues);
  };

  useEffect(() => {
    getFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filter = new Map<string, string[]>();
    fields.forEach((k: string) => {
      if (k === "collection_date" && filterValues.where.from_collection_date && filterValues.where.to_collection_date) {
        filter.set(k, [filterValues.where.from_collection_date, filterValues.where.to_collection_date]);
      }
      else if (k === "start_date" && filterValues.where.from_start_date && filterValues.where.to_start_date) {
        filter.set(k, [filterValues.where.from_start_date, filterValues.where.to_start_date]);
      }
      else if (k === "finished_date" && filterValues.where.from_finished_date && filterValues.where.to_finished_date) {
        filter.set(k, [filterValues.where.from_finished_date, filterValues.where.to_finished_date]);
      }
      else {
        filter.set(k, filterValues.where[k]);
      }
    });
    setValues(filter);
    setSearch(filterValues.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  const onRemoveFilters = async () => {
    fields.forEach(async (item: string) => {
      values?.set(item, []);
    });
    setSearch("");
    await onRemove();
  };

  const onFilterChange = async (event: any) => {
    setDirty(true);
    values?.set(event.field, event.value);
    await onChange({ field: event.field, value: event.value });
  };

  return (
    <div className="filter-subscription-component">
      <div className="flex flex-wrap justify-content-between filter-panel">
        <div className="filter-container">
          <WISearchField
            icon={"pi pi-search"}
            placeholder="Search for anything"
            setSearch={(value: any) => setSearch(value.global.value)}
            enterSearch={() => onSearchFunc(search)}
            globalValue={search}
          />
          <FilterField
            name="rhythm"
            label="Rhythm"
            className="wi-filter-option-subscription"
            pannelClassName="overlaypanel-filter-subscriptions"
            selectedItems={values?.get("rhythm") || []}
            options={rhythm}
            optionLabel={"name"}
            optionValue={"code"}
            visible={values?.has("rhythm") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <FilterField
            name="status"
            label="Status"
            className="wi-filter-option-subscription"
            pannelClassName="overlaypanel-filter-subscriptions"
            selectedItems={values?.get("status") || []}
            options={status}
            optionLabel={"name.de"}
            optionValue={"code"}
            visible={values?.has("status") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <CalendarField
            name="collection_date"
            label="Collection Date"
            className="wi-filter-option-subscription"
            pannelClassName="overlaypanel-filter-subscriptions"
            selectedItems={values?.get("collection_date") || []}
            visible={values?.has("collection_date") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <CalendarField
            name="start_date"
            label="Start Date"
            className="wi-filter-option-subscription"
            pannelClassName="overlaypanel-filter-subscriptions"
            selectedItems={values?.get("start_date") || []}
            visible={values?.has("start_date") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
          <CalendarField
            name="finished_date"
            label="Finished Date"
            className="wi-filter-option-subscription"
            pannelClassName="overlaypanel-filter-subscriptions"
            selectedItems={values?.get("finished_date") || []}
            visible={values?.has("finished_date") || false}
            isDirty={isDirty}
            setDirty={setDirty}
            onChange={onFilterChange}
          />
        </div>
        <div className="remove-filter-button">
          <Button
            type="button"
            icon="pi pi-times"
            label="Clear filter(s)"
            onClick={onRemoveFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionFilterComponent;
