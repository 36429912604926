import moment from "moment";
import React, { useState } from "react";
import {
  formatFileSize,
  formatImageFileURL,
  getOriginalFileName,
  getThumbnailFullpath,
  IconConfig,
} from "../../../../../utils/mediaUtils";
import { LanguageList } from "../../../../../utils/utils";
import "./list-item.scss";
import { env } from "../../../../../environment";

const ListItem = (props: any) => {
  const { mediaFile, onEditMedia, isInsertMediaPopup, onSetSelectedItem, handleDoubleClick, selectedMedia } = props;
  const [language] = useState(LanguageList[0]);
  const fileUrl =
    mediaFile && mediaFile.type === "Image"
      ? encodeURI(`${env.PUBLIC_UPLOAD_URL}/${getThumbnailFullpath(formatImageFileURL(mediaFile.fullPath))}`)
      : encodeURI(IconConfig[mediaFile?.file_extension]);

  return mediaFile.type ? (
    <div
      className={`media-list-item row ${mediaFile?.uuid === selectedMedia?.uuid ? 'card-item-highlight' : ''}`}
      onClick={(e) => {
        isInsertMediaPopup ? onSetSelectedItem(mediaFile) : onEditMedia(true, e, mediaFile);
      }}
      onDoubleClick={(e) => {
        if (isInsertMediaPopup) {
         handleDoubleClick(e);
        }
      }}
    >
      <div className="col-1">
        <img
          src={`${
            mediaFile.type === "Image"
              ? `${fileUrl}?v=${mediaFile?.updatedAt ? moment(mediaFile.updatedAt).unix() : moment(mediaFile.createdAt).unix()}`
              : `${fileUrl}`
          }`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src= encodeURI(`${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(mediaFile.fullPath)}`);
          }}
          alt={mediaFile?.alternativeText ? mediaFile.alternativeText[language.code.toLowerCase()] : ""}
          title={mediaFile?.title ? mediaFile.title[language.code.toLowerCase()] : ""}
          className={`w-3rem w-3 my-0 mr-5 mr-0 card-image card-img-bg-${mediaFile?.file_extension}`}
          onClick={(e) => {
            isInsertMediaPopup ? onSetSelectedItem(mediaFile) : onEditMedia(true, e, mediaFile);
          }}
          loading="lazy"
        />
      </div>
      <div className="col-4 text-start">
        {getOriginalFileName(mediaFile.name)}
      </div>
      <div className="col-2 text-start">{mediaFile.type}</div>
      <div className="col-2 text-start">
        {formatFileSize(mediaFile.size)}
      </div>
      <div className="col-3 text-start">
        {moment(mediaFile.createdAt).format("DD MMM y hh:mm:ss")}
      </div>
    </div>
  ) : null;
};

export default ListItem;
