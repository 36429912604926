import * as React from "react";
import { useState, useEffect, useRef, Fragment } from "react";
import { Column, ColumnAlignType } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "./donation-examples-manager.scss";
import Layout from "../../layout/layout";
import { LanguageList, removeEmpty, FIELDS_SEARCH } from "../../../utils/utils";
import DonationExamplesManagerAPI from "../../../services/global-contents/donationExamplesManager";
import { showNotification } from "../../../utils/logic";
import { Toast } from "primereact/toast";
import {
  MenuDot,
  WIButton,
  WIConfirmDialog,
  WIPaginator,
  WISearchField,
} from "../../common";
import CreateDonationExampleDialogComponent from "./components/donation-example-dialog/donation-example-dialog";
import { getInternalURLPages, fetchCMSAllPages } from "../../pages/builder-page.common";
import { buildActionBtnTemplate, formatDateBodyTemplate, formatInnerHtmlDataByLanguageBodyTemplate } from "../../common/column-template-table/column-template";

const DonationExamplesManager = (props: any) => {
  const [isShowCreate, setIsShowCreate] = useState(false);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const [donationExamples, setDonationExamples] = useState({
    data: [],
    totalPage: 0,
  });
  const [page, setPage] = useState(0);
  const [language] = useState(LanguageList[0]);
  const [donationExample, setDonationExample] = useState<any>(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [search, setSearch] = useState<any>(null);
  const [globalContent, setGlobalContent] = useState<any>({
    search_content: []
  });

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const fetchDonationExamplesData = async (valueSearch?: string) => {
    setIsLoading(true);
    const data = removeEmpty({
      pageNo: page,
      range: rows,
      search_text: valueSearch || undefined,
      search_fields: valueSearch ? FIELDS_SEARCH.DONATIONEXAMPLES : undefined,
    });

    try {
      const res = await DonationExamplesManagerAPI.getDonationExamples(data);
      if (res && res.status === 200) {
        setIsLoading(false);
        setDonationExamples({
          data: res.data.records || [],
          totalPage: res.data.total || 0,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setDonationExamples({
        data: [],
        totalPage: 0,
      });
    }
  };

  const fetchGlobalContent = async () => {
    let allPages = await fetchCMSAllPages();
    let internal_urls = getInternalURLPages(allPages);
    let search_content = { internal_urls: internal_urls }
    let newGlobalContent = {...globalContent, search_content};
    setGlobalContent(newGlobalContent);
  }

  const fetchData = async() => {
    setIsLoading(true);
    await Promise.all([
      fetchDonationExamplesData(),
      fetchGlobalContent()
    ])
    setIsLoading(false);
  }

  const onSearchHistory = () => {
    if (page !== 0) {
      setPage(0);
      setFirst(0);
      return; 
    }
    fetchDonationExamplesData(search);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === "number") {
      fetchDonationExamplesData(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const onCreateDonationExample = (isCreating: boolean, e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setDonationExample(null);
    setIsShowCreate(true);
  };

  const onShowDeleteDialog = (isVisible: boolean) => {
    setDonationExample(null);
    setIsShowDeleteDialog(isVisible);
  };

  const onDeleteDonationExamples = async (e: any, rowData: any) => {
    setIsShowDeleteDialog(true);
    setDonationExample(rowData);
  };

  const onDeleteConfirm = async () => {
    const response = await DonationExamplesManagerAPI.deleteDonationExample(donationExample.uuid);
    if (response && response.status === 200 && response.data.result.isValid) {
      onShowDeleteDialog(false);
      fetchDonationExamplesData(search);
      showNotification("success", "Delete Donation Example successfully.", toast);
    } else {
      showNotification("info", "Delete Donation Example failed.", toast);
    }
  };

  const onUpdateDonationExamples = async (e: any, rowData: any) => {
    setIsLoading(true);
    const res = await DonationExamplesManagerAPI.getDonationExample(rowData.uuid);
    if (res && res.status === 200) {
      setDonationExample(res.data.record);
      setIsLoading(false);
      setIsShowCreate(true);
    }
  };

  const tableColumns = [
    // { field: "serial", header: "ID", width: "5%", align: "center" },
    {
      field: "example",
      header: "Donation Example",
      width: "calc(47% - 50px)",
      align: "left",
      bodyTemPlate: formatInnerHtmlDataByLanguageBodyTemplate(language),
    },
    {
      field: "url",
      header: "URL",
      width: "27%",
      align: "left",
      bodyTemPlate: formatInnerHtmlDataByLanguageBodyTemplate(language),
    },
    {
      field: "created_at",
      header: "Created At",
      width: "13%",
      align: "left",
      bodyTemPlate: formatDateBodyTemplate("DD.MM.YYYY"),
    },
    {
      field: "updated_at",
      header: "Updated At",
      width: "13%",
      align: "left",
      bodyTemPlate: formatDateBodyTemplate("DD.MM.YYYY"),
    }
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
          label: "Edit",
          description: "Edit information",
          icon: "fa-solid fa-pen-to-square",
          command: (e: any) => {
            onUpdateDonationExamples(e, rowData)
          },
      },
      {
        label: "Delete",
        description: "Delete information",
        icon: "fa-solid fa-trash-can delete-icon",
        dangerous: true,
        command: (e: any) => {
          onDeleteDonationExamples(e, rowData);
        },
      }
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="donation-example-manager">
        <div className="manager-container">
          <div className="headline">
            <h3>Donation Example</h3>
          </div>
          <div className="certificate">
            <div className="d-flex flex-column flex-md-row justify-content-start justify-content-md-between certificate-action common-horizontal-layout-header">
              <WISearchField
                // className="search-box"
                icon={" pi pi-search"}
                placeholder="Search for anything"
                setSearch={(value: any) => setSearch(value.global.value)}
                enterSearch={onSearchHistory}
              />
              <div className="btn-create">
                <WIButton
                  primary={true}
                  type={"button"}
                  label="Create"
                  icon={"pi-angle-right"}
                  onClick={(e: any) => onCreateDonationExample(true, e)}
                />
              </div>
            </div>
          </div>
          <div className="table-donation-example">
            <DataTable
              loading={isLoading}
              value={donationExamples.data}
              filters={search}
              scrollable
              scrollHeight="calc(100vh - 225px)"
              scrollDirection="both"
            >
              {dynamicColumns}
              <Column
                frozen
                alignFrozen="right"
                style={{ width: "50px" }}
                body={buildMenu}
              ></Column>
            </DataTable>
            <WIPaginator
              first={first}
              rows={rows}
              totalRecords={donationExamples.totalPage}
              onPageChange={onBasicPageChange}
            />
          </div>
          <WIConfirmDialog
            visible={isShowDeleteDialog}
            onHide={() => onShowDeleteDialog(false)}
            onConfirm={onDeleteConfirm}
            message={
              <Fragment>
                <span style={{fontSize: '18px', fontWeight: '700'}}>Are you sure you want to delete this example?</span>
                <br/>
                <span style={{fontSize: '13px'}}>
                  All of your data will be deleted <b>permanently</b>.
                  <br/>
                  You <b>cannot undo</b> this action.
                  <br/>
                  <br/>
                  <b>Note</b>: <br/> If this <b>example</b> is using by our website, it can cause <b>unexpected issues</b>.
                  </span>
              </Fragment>
            }
            classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
          />
          <CreateDonationExampleDialogComponent
            visible={isShowCreate}
            fetchDonationExamplesData={fetchDonationExamplesData}
            onHide={() => setIsShowCreate(false)}
            donationExample={donationExample}
            globalContent={globalContent}
          />
        </div>
      </div>
    </Layout>
  );
};

export default DonationExamplesManager;