import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from 'primereact/selectbutton';
import './searchURLGlobal.scss';
import * as _ from 'lodash';

const SearchURLGlobal = (props: any) => {

  const OPTION_BUTTON = {
    Internal: 0,
    External: 1
  }

  const urlOptions = [
    { name: 'Internal', value: OPTION_BUTTON.Internal },
    { name: 'External', value: OPTION_BUTTON.External }
  ];

  const defaultURL = '#';

  const valueDefault = {
    de: defaultURL,
    en: defaultURL,
    global_content_id: '',
    page_id: ''
  }

  const {
    label,
    isRequired,
    placeholder,
    disabled,
    maxLength,
    search_content,
    searchFields,
    onChangeValue
  } = props;

  const [sources, setSources] = useState(search_content ? search_content : []);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [urlOptionValue, setUrlOptionValue] = useState<any>();
  const [valuesURL, setValuesURL] = useState<any>({});

  useEffect(() => {
    if (props.values.page_id) {
      setSelectedValue(props.values.page_id);
      setUrlOptionValue(OPTION_BUTTON.Internal);
    } else {
      setValuesURL(props.values);
      setUrlOptionValue(urlOptionValue === OPTION_BUTTON.Internal ? OPTION_BUTTON.Internal : OPTION_BUTTON.External);
    }

  }, [props.values]);

  useEffect(() => {
    if (search_content) {
      setSources(search_content);
    }
  }, [search_content]);

  useEffect(() => {
    if (selectedValue) {
      updateFieldValue(selectedValue);
    }
  }, [selectedValue]);

  const updateFieldValue = (page_id: any) => {
    let source = sources.find((f: any) => f.id === page_id)
    setValueURL(source ? {
      ...source.url,
      page_id: source.id,
      global_content_id: source.id
    } : valueDefault)
  }

  const setValueURL = (value: any) => {
    setValuesURL(value);
    if (typeof onChangeValue === 'function') {
      onChangeValue(value || '');
    }
  }

  const customItemTemplate = (option: any) => {
    return <div>
      <div>{_.get(option, 'name')}</div>
      <div className="page-item">/{option.nodes?.map((n: any) => n.url).filter((u: string) => !!u).join('/')}</div>
    </div>
  }

  const customValueTemplate = (option: any) => {
    return urlOptionValue === OPTION_BUTTON.Internal ? <div className="page-item">/{option?.nodes?.map((n: any) => n.url).filter((u: string) => !!u).join('/')}</div> : <></>
  }

  const onChangeInput = (event: any, type: string) => {
    const value = {
      ...valuesURL,
      [type]: event?.target?.value || '',
      page_id: '',
      global_content_id: ''
    }

    setValueURL(value);
  }

  const setValueDefaultURL = (valueOption: any) => {
    setUrlOptionValue(valueOption)
    if (valueOption === OPTION_BUTTON.Internal) {
      updateFieldValue(selectedValue);
    } else {
      setValueURL(valueDefault);
    }
  }

  return (
    <div className="search-global">
      <div className="label">
        {label} {isRequired && <span style={{ color: "red" }}> *</span>}
      </div>
      <div className="search-form-element">
        <div style={{ margin: '-10px' }}>
          <div className="apply-btn" style={{ display: 'flex' }}>
            <div className="select-group">
              {
                sources ?
                  (
                    <>
                      <div style={{ marginRight: '10px' }}>
                        <SelectButton
                          hidden={disabled}
                          className="wi-form-select-button wi-selectbutton"
                          optionLabel="name"
                          value={urlOptionValue}
                          options={urlOptions}
                          onChange={(e: any) => {
                            setValueDefaultURL(e.value);
                          }}
                          unselectable={false}
                        />
                      </div>
                      {
                        urlOptionValue !== OPTION_BUTTON.External ? <Dropdown
                          panelClassName="dropdown-cms-hyperlink"
                          disabled={urlOptionValue === OPTION_BUTTON.External}
                          hidden={disabled}
                          value={selectedValue}
                          itemTemplate={customItemTemplate}
                          valueTemplate={customValueTemplate}
                          options={sources}
                          showClear={!!selectedValue && selectedValue !== defaultURL}
                          dropdownIcon={urlOptionValue === OPTION_BUTTON.Internal ? 'pi pi-chevron-down' : ''}
                          onChange={(item: any) => {
                            setSelectedValue(item?.value);
                          }}
                          optionLabel={'name'}
                          optionValue="id"
                          filter
                          filterBy={searchFields?.join(",")}
                        /> : <div className="search-form-element-wrapper" style={{ flex: 1 }}>
                          <div className="form-element">
                            <div className="p-inputgroup p-inputgroup-container">
                              <input
                                className={`${isRequired && valuesURL?.de === '' ? "has-error" : ""}`}
                                value={valuesURL?.de || ''}
                                placeholder={placeholder || ""}
                                disabled={urlOptionValue === OPTION_BUTTON.Internal}
                                maxLength={maxLength}
                                onChange={(event) => onChangeInput(event, 'de')}
                              />
                            </div>
                            {
                              isRequired && valuesURL?.de === '' ?
                                <div className="error">
                                  {label ? label : 'This field'} is required.
                                </div> : <></>
                            }

                          </div>
                        </div>
                      }
                    </>
                  ) : (
                    <></>
                  )}
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default SearchURLGlobal;