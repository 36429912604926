const SEARCH_FIELDS = ["name", 'occasion'];

const getObjectWithValues = (where_obj: any) => {
    const tempt_obj: any = {};
    for (const [key, value] of Object.entries(where_obj)) {
        if (key && key !== "dates") {
            if ((Array.isArray(value) || typeof value === "string") && value.length > 0) {
                tempt_obj[key] = value;
            }
        } else {
            if (Array.isArray(value) && value.length === 2) {
                tempt_obj["from"] = value[0];
                tempt_obj["to"] = value[1];
            }
        }
    }
    return tempt_obj;
};

// ============================= GLOBAL FUNCTIONALITY =============================
export const getCertificateObjectWithValues = (where_obj: any) => {
    const tempt_obj: any = {};
    for (const [key, value] of Object.entries(where_obj)) {
        if (key && key !== "dates") {
            if ((Array.isArray(value) || typeof value === "string") && value.length > 0) {
                tempt_obj[key] = value;
            }
        } else {
            if (Array.isArray(value) && value.length === 2) {
                tempt_obj["from"] = value[0];
                tempt_obj["to"] = value[1];
            }
        }
    }
    return tempt_obj;
};

export const getCertificateObjectSearchValue = (value: string) => {
    const search_result: any[] = [];
    SEARCH_FIELDS.forEach((key: string) => {
        const obj = {
            [key]: value,
        };
        search_result.push(obj);
    });

    return search_result;
};

export const getCertificatePayloadConditions = (filterValues: any, sort_config: any) => {
    const { sortField, sortOrder } = sort_config;
    const where_conditions = getObjectWithValues(filterValues.where);
    const mapper: any = {
        serial: [['serial']],
        certificate_name: [["certificate_name"]],
        occasion: [["occasion"]],
        created_at: [["created_at"]]
    };
    const orders = mapper[sortField];
    orders.forEach((r: any) => r.push(sortOrder === -1 ? "DESC" : "ASC"));
    const order = [...orders];
    return {
        where: {
            ...where_conditions,
            search_name: filterValues.search
        },
        order
    };
};