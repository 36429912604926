import { AxiosClient } from '../httpClient'

export default class MediaFolderManagerAPI {
    static getMediaFolders(data: any) {
        return AxiosClient.get(`/media-folders/pagination`, data);
    }

    static deleteMediaFolder(id: string) {
        return AxiosClient.delete(`/media-folders/${id}`);
    }

    static createMediaFolder(data: any) {
        return AxiosClient.post(`/media-folders`, data);
    }

    static updateMediaFolder(data: any) {
        return AxiosClient.put(`/media-folders/${data.uuid}`, data);
    }

    static getAll() {
        return AxiosClient.get(`/media-folders`);
    }

    static getMediaFolder(uuid: string) {
        return AxiosClient.get(`/media-folders/${uuid}`);
    }

    static getAllV2() {
        return AxiosClient.get(`/media-service/folders`);
    }

    static createMediaFolderV2(data: any) {
        return AxiosClient.post(`/media-service/folders`, data);
    }

    static deleteMediaFolderV2(id: string) {
        return AxiosClient.delete(`/media-service/folders/${id}`);
    }

    static updateMediaFolderV2(data: any) {
        return AxiosClient.put(`/media-service/folders/${data.uuid}`, data);
    }
}