import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CMsPageManagerAPI, PartnerManagerAPI, PartnerTypeManagerAPI } from "../../../services";
import CountryService from "../../../services/country-service";
import Layout from "../../layout/layout";
import { fetchPageTypes, getInternalURLPages, getPageTypeByCode, fetchCMSParentPages } from "../../pages/builder-page.common";
import { PAGE_CODE } from "../../pages/utils"; 
import CreatePartnerFormComponent from "./create-partner-form/create-partner-form";
import { findCmsPage } from "../../../utils/commonGeneratePageData";

export const CreatePartnerManager = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [partners, setPartners] = useState({
    data: [],
    totalPage: 0,
  });
  const [countries, setCountries] = useState([]);
  const [partner, setPartner] = useState<any>(null);
  const [globalContent, setGlobalContent] = useState<any>([]);
  const [partnerTypes, setPartnerTypes] = useState<any>([]);
  const [cmsPartnerPages, setCmsPartnerPages] = useState<any>([]);
  const [cmsMasterPages, setCmsMasterPages] = useState<any>([]);
  const [parentPages, setParentPages] = useState<any>([]);

  const getSearchContent = async () => {
    let pageTypes = await fetchPageTypes();
    let partnerPageType = getPageTypeByCode(pageTypes, PAGE_CODE.Partner);
    let masterPageType = getPageTypeByCode(pageTypes, PAGE_CODE.Master);

    let [
      partnerRes,
      masterRes
    ] = await Promise.all([
        CMsPageManagerAPI.getPages(partnerPageType?.uuid, { pageNo: 0, range: 10000 }),
        CMsPageManagerAPI.getPages(masterPageType?.uuid, { pageNo: 0, range: 10000 })
    ]);

    let partnerPages = partnerRes?.data?.records;
    let masterPages = masterRes?.data?.records;
    
    setCmsPartnerPages(partnerPages);
    setCmsMasterPages(masterPages);

    let parentPages = fetchCMSParentPages(masterPages);
    setParentPages(parentPages);

    let internal_urls = getInternalURLPages(partnerPages.concat(masterPages));
    let partnerIds = partnerPages.map((m: any) => m.uuid);
    let partner_urls = internal_urls.filter(f => partnerIds.includes(f.id));
    setGlobalContent(partner_urls || []);
  };

  const fetchPartnerType = async () => {
    const result = await PartnerTypeManagerAPI.getAll();
    if (result.status === 200 && result.data.records) {
      setPartnerTypes(result.data.records);
    }
  };

  const fetchPartnerData = async (uuid: string) => {
    setIsLoading(true);
    try {
      const res = await PartnerManagerAPI.getPartner(uuid);
      if (res && res.status === 200) {
        setPartner(res.data.record);
        setIsLoading(false);
      }
    } catch (error) {
        setPartner(null);
        setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    const [countriesRes, partnersRes] = await Promise.all([
      CountryService.getCountries(), 
      PartnerManagerAPI.getAllPartners(false)
    ]);

    // @ts-ignore: Object is possibly 'null'.
    if (countriesRes.status === 200 && partnersRes.status === 200) {
      setCountries(countriesRes.data.data);
      setPartners({
        data: partnersRes.data.records || [],
        totalPage: partnersRes.data.total || 0,
      });
      setIsLoading(false);
    }
  };

  const getAllData = async () => {
    await Promise.all([
     getSearchContent(),
     fetchPartnerType(),
     fetchData()
   ]);
  };

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      fetchPartnerData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Layout customLayout={true} customCopyRight={true}>
      {isLoading ? (
        <div className="loading-component">
          <ProgressSpinner />
        </div>
      ) : (
        <></>
      )}
      <Toast ref={toast} />
      <div className="d-flex flex-column w-100 text-left">
        <CreatePartnerFormComponent
          countries={countries}
          partnerTypesParam={partnerTypes}
          partner={partner}
          partners={partners}
          parentPages={parentPages}
          cmsPartnerPage={findCmsPage(cmsPartnerPages, partner)}
          cmsMasterPages={cmsMasterPages}
          globalContent={globalContent}
          toast={toast}
        />
      </div>
    </Layout>
  );
};

export default CreatePartnerManager;
