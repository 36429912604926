import { faClock, faCircleStop, faCirclePlay, faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { faTree, faBookBookmark } from '@fortawesome/free-solid-svg-icons';

export const TITLE_APP = "Wilderness International | ";
export const PASSWORD_SENDMAIL = 'm9h@4?xhqPijht';

enum ResponseStatusType {
  OK = 'OK',
  BAD_REQUEST = 'BAD_REQUEST',
  UNAUTHORIZED = 'UNAUTHORIZED',
  VALIDATION_EXCEPTION = 'VALIDATION_EXCEPTION',
  EXCEPTION = 'EXCEPTION',
  WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
  ACCESS_DENIED = 'ACCESS_DENIED',
  NOT_FOUND = 'NOT_FOUND',
  DUPLICATE_ENTITY = 'DUPLICATE_ENTITY',
  INFO_USER_NOT_MATCH = 'INFO_USER_NOT_MATCH',
  EXPRIED_HAS_OTP = 'EXPRIED_HAS_OTP',
}

export default ResponseStatusType;

export enum LandCode {
  SecretForest = 'secret-forest',
  ValentinTorren = 'valentin-torren'
}

export const NameCurrency = {
  EUR: 'eur',
  CAD: 'cad',
  CHF: 'chf'
}

export enum MailStatus {
  NotYet = 'not_yet',
  Completed = 'completed',
  Pending = 'pending'
}

export enum COMMON_STATUS {
  INITIALIZED = 'initialized',
  ACTIVE = 'active',
  PAUSED = 'paused',
  ARCHIVED = 'archived',
  CLOSED = 'closed',
  COMPLETED = 'completed',
  NEW = 'new',
  DELETED = 'deleted',
  DEACTIVATE = 'deactivate',
  INACTIVE = 'inactive',
  EXPIRED = 'expired',
  BLOCKED = 'blocked',
  DRAFT = 'draft',
  REFUND = 'refund',
  CANCELLED = 'cancelled',
  PUBLISHED = 'published',
  NOT_COMPLETED = 'not_completed',
  NOT_YET = 'not_yet',
  NOT_APPLICABLE = 'not_applicable',
}

export enum LanguageCode {
  EN = 'en',
  DE = 'de'
}

export enum DonationKeyEnum {
  EUR = 'eur',
  CAD = 'cad',
  CHF = 'chf'
}

export const ColorStatus = {
  notYet: {
    icon: 'pi pi-times',
    color: 'danger'
  },
  completed: {
    icon: 'pi pi-check',
    color: 'success'
  },
  pending: {
    icon: 'pi pi-exclamation-triangle',
    color: 'warning'
  }
}

export const MenuItems = [
  {
    label: 'Dashboard',
    icon: 'fa-solid fa-gauge-high',
    url: '/',
    className: 'wi-menu-item'
  },
  {
    label: 'Import',
    icon: 'fa-solid fa-file-import',
    url: '/import',
    className: 'wi-menu-item'
  },
  {
    label: 'Donation',
    icon: 'fa-solid fa-hand-holding-dollar',
    url: '/donations',
    className: 'wi-menu-item'
  },
  {
    label: 'Subscription',
    icon: 'fa-solid fa-repeat',
    className: 'wi-menu-item',
    url: '/subscriptions',
  },
  {
    label: 'Donor',
    icon: 'fa-solid fa-users-line',
    className: 'wi-menu-item',
    url: '/donors',
  },
  {
    label: 'Wilderness Runs',
    icon: 'fa-solid fa-person-running',
    className: 'wi-menu-item',
    url: '/wilderness-runs',
  },
  {
    label: 'Region', 
    icon: 'fa-solid fa-earth-americas',
    url: '/regions',
    className: 'wi-menu-item'
  },
  {
    label: 'Area',
    icon: 'fa-solid fa-map',
    url: '/areas',
    className: 'wi-menu-item'
  },
  {
    label: 'Campaign',
    icon: 'fa-solid fa-bullhorn', 
    url: '/campaigns',
    className: 'wi-menu-item'
  },
  {
    label: 'Code', 
    icon: 'fa-solid fa-tags',
    url: '/codes',
    className: 'wi-menu-item'
  },
  // {
  //   label: 'Blocks', 
  //   icon: 'fa-solid fa-list-alt',
  //   url: '/blocks',
  //   className: 'wi-menu-item'
  // },
  {
    label: 'Page', 
    icon: 'fa-solid fa-newspaper',
    url: '/pages',
    className: 'wi-menu-item'
  },
  {
    label: 'Global Content', 
    icon: 'fa-solid fa-globe',
    className: 'wi-menu-item',
    url: '/global-contents',
    items: [
      {
        label: 'Media',
        icon: 'fa-solid fa-photo-film',
        url: '/global-contents/media',
        className: 'wi-menu-item'
      },
      {
        label: 'Headers', 
        icon: 'fa-solid fa-header',
        url: '/global-contents/headers-manager',
        className: 'wi-menu-item'
      },
      {
        label: 'Footers', 
        icon: 'fa-solid fa-arrow-down',
        url: '/global-contents/footers-manager',
        className: 'wi-menu-item'
      },
      {
        label: 'Global Blocks', 
        icon: 'pi pi-instagram',
        url: '/global-contents/global-blocks',
        className: 'wi-menu-item'
      },
      {
        label: 'Partner',
        icon: 'fa-solid fa-handshake',
        url: '/partners',
        className: 'wi-menu-item'
      },
      {
        label: 'Partner Type',
        icon: 'fa-solid fa-user-tag',
        url: '/global-contents/partner-types',
        className: 'wi-menu-item'
      },
      {
        label: 'FAQs', 
        icon: 'fa-solid fa-question-circle',
        url: '/global-contents/faqs',
        className: 'wi-menu-item'
      },
      {
        label: 'People', 
        icon: 'fa-solid fa-address-card',
        url: '/global-contents/people',
        className: 'wi-menu-item'
      },
      // {
      //   label: 'Downloaded File', 
      //   icon: 'fa-solid fa-download',
      //   url: '/global-contents/download-files',
      //   className: 'wi-menu-item'
      // },
      {
        label: 'Donation Example', 
        icon: 'fa-solid fa-hand-holding-dollar',
        url: '/global-contents/donation-examples',
        className: 'wi-menu-item'
      },
      {
        label: 'Quote',
        icon: 'fa-solid fa-quote-left',
        url: '/global-contents/quotes',
        className: 'wi-menu-item'
      }
    ]
  },
  {
    label: 'More',
    icon: 'fa-solid fa-toolbox',
    url: '/utilities',
    className: 'wi-menu-item',
    items: [
      {
        label: 'Receipt',
        icon: 'fa-solid fa-receipt',
        url: '/utilities/receipt',
        className: 'wi-menu-item'
      },
      {
        label: 'Certificate',
        icon: 'fa-solid fa-file-invoice',
        url: '/utilities/certificate',
        className: 'wi-menu-item'
      },
      {
        label: 'Goal Manager',
        icon: 'fa-solid fa-bullseye',
        url: '/utilities/goals',
        className: 'wi-menu-item'
      }
    ]
  },
  // {
  //   label: 'Help',
  //   icon: 'fa-solid fa-clipboard-question',
  //   outsideURL: "https://help.wilderness-international.org/",
  //   className: 'wi-menu-item'
  // }
]

export const CountriesUtils = [
  {
    name: 'Peru',
    code: 'Peru'
  },
  {
    name: 'Canada',
    code: 'Canada'
  }
]

export const LandStatusUtils = [
  {
    name: 'New',
    code: 'new'
  },
  {
    name: 'Paused',
    code: 'paused'
  },
  {
    name: 'Active',
    code: 'active'
  },
  // {
  //   name: 'Closed',
  //   code: 'closed'
  // },
  {
    name: 'Completed',
    code: 'completed'
  },
  {
    name: 'Archived',
    code: 'archived'
  },
  // {
  //   name: 'Deleted',
  //   code: 'deleted'
  // }
]

export const RegionStatusUtils = [
  {
    name: 'New',
    code: 'new'
  },
  {
    name: 'Active',
    code: 'active'
  },
  {
    name: 'Completed',
    code: 'completed'
  },
  {
    name: 'Paused',
    code: 'paused'
  }
]

export const CurrencyCoupons = [
  {
    name: 'EUR',
    code: 'eur'
  },
  {
    name: 'CHF',
    code: 'chf',
  },
  {
    name: 'CAD',
    code: 'cad',
    constant: true
  }
]

export const TypeCoupons = [
  {
    name: "Off Once",
    code: "off-once",
  },
];

export const CampaignCouponStatus = [
  { name: 'Active', key: 'active', value: 0 },
  { name: 'Inactive', key: 'inactive', value: 1 },
];


export const StatusCoupon = [
  { name: 'New', icon: faBookBookmark, key: 'new', value: 0 },
  { name: 'Active', icon: faCirclePlay, key: 'active', value: 1 },
  { name: 'Used', icon: faTree, key: 'used', value: 2 },
  { name: 'Expired', icon: faClock, key: 'expired', value: 3 },
  { name: 'Blocked', icon: faCircleStop, key: 'blocked', value: 4 },
  { name: 'Deleted', icon: faXmarkCircle, key: 'deleted', value: 5 },
];

export const CODE_STATUS = [
  { name: 'Inactive', icon: faBookBookmark, key: 'inactive', value: 0 },
  { name: 'Active', icon: faCirclePlay, key: 'active', value: 1 },
  { name: 'Redeemed', icon: faTree, key: 'redeemed', value: 2 },
  { name: 'Expired', icon: faClock, key: 'expired', value: 3 },
  { name: 'Invalid', icon: faCircleStop, key: 'invalid', value: 4 },
];

export const StatusPartner = [
  { name: 'Initialized', key: 'initialized' },
  { name: 'Active', key: 'active' },
  { name: 'Paused', key: 'paused' },
  { name: 'Archived', key: 'archived' },
  { name: 'Deleted', key: 'deleted' }
];

export const StatusDisplay = {
  [COMMON_STATUS.ACTIVE]: {
    icon: "pi pi-check",
    severity: "success",
  },
  [COMMON_STATUS.PAUSED]: {
    icon: "pi pi-exclamation-triangle",
    severity: "warning",
  },
  [COMMON_STATUS.NEW]: {
    icon: "pi pi-exclamation-triangle",
    severity: "info",
  },
  [COMMON_STATUS.COMPLETED]: {
    icon: "pi pi-check",
    severity: "success",
  },
  [COMMON_STATUS.ARCHIVED]: {
    icon: "pi pi-exclamation-triangle",
    severity: "warning",
  },
  [COMMON_STATUS.DELETED]: {
    icon: "pi pi-exclamation-triangle",
    severity: "danger",
  },
  [COMMON_STATUS.CLOSED]: {
    icon: "pi pi-exclamation-triangle",
    severity: "danger",
  },
  [COMMON_STATUS.DEACTIVATE]: {
    icon: "pi pi-check",
    severity: "warning",
  },
  [COMMON_STATUS.INACTIVE] : {
    icon: "pi pi-exclamation-triangle",
    severity: "info",
  },
  [COMMON_STATUS.EXPIRED]: {
    icon: "pi pi-exclamation-triangle",
    severity: "warning",
  },
  [COMMON_STATUS.BLOCKED]: {
    icon: "pi pi-exclamation-triangle",
    severity: "warning",
  },
  [COMMON_STATUS.DRAFT]: {
    icon: "pi pi-exclamation-triangle",
    severity: "warning",
  },
  [COMMON_STATUS.REFUND]: {
    icon: "pi pi-exclamation-triangle",
    severity: "warning",
  },
  [COMMON_STATUS.CANCELLED]: {
    icon: "pi pi-exclamation-triangle",
    severity: "danger",
  },
  [COMMON_STATUS.PUBLISHED]: {
    icon: "pi pi-check",
    severity: "success",
  },
  [COMMON_STATUS.INITIALIZED]: {
    icon: "pi pi-exclamation-triangle",
    severity: "info",
  },
  [COMMON_STATUS.NOT_COMPLETED]: {
    icon: "pi pi-exclamation-triangle",
    severity: "warning",
  },
  [COMMON_STATUS.NOT_YET]: {
    icon: "pi pi-exclamation-triangle",
    severity: "warning",
  },
  [COMMON_STATUS.NOT_APPLICABLE]: {
    icon: "pi pi-exclamation-triangle",
    severity: "warning",
  },
};

export const CampaignStatus = [
  { name: 'New', code: 'new' },
  { name: 'Paused', code: 'paused' },
  { name: 'Active', code: 'active' },
  { name: 'Completed', code: 'completed' },
  { name: 'Archived', code: 'archived' },
  // { name: 'Deleted', code: 'deleted' },
  // { name: 'Closed', code: 'closed' },
]

export const PartnerCoupons = [
  {
    name: 'Wachen',
    code: 'Wachen'
  },
  {
    name: 'Whale Watching',
    code: 'Whale'
  },
  {
    name: 'Active',
    code: 'Active'
  },
  {
    name: 'C3',
    code: 'C3'
  }
]

export const LandCoupons = [
  {
    name: 'Secret Forest',
    code: 'SF'
  },
  {
    name: 'Land of the Sloth',
    code: 'LS'
  },
  {
    name: 'Land of the Grizzly',
    code: 'LG'
  },
  {
    name: 'Land of the Eagle',
    code: 'LE'
  }
]

export const CurrencyList = [
  {
    name: 'EUR',
    code: 'eur'
  },
  {
    name: 'CHF',
    code: 'chf'
  },
  {
    name: 'CAD',
    code: 'cad'
  }
]

export const LanguageList = [
  {
    name: 'DE',
    code: LanguageCode.DE
  },
  {
    name: 'EN',
    code: LanguageCode.EN
  }
]

export const LanguageDisplayList = [
  {
    name: 'German',
    code: LanguageCode.DE
  },
  {
    name: 'English',
    code: LanguageCode.EN
  }
]

export const PagesLanguageDisplayList = [
  ...LanguageDisplayList,
  {
    name: 'Both',
    code: 'both'
  }
]

export const TypeLandCode = {
  land: 'land',
  region: 'region',
  campaign: 'campaign'
}

export const TypeOldDataCode = {
  donation: 'donation',
  donor: 'donor'
}

export const ImportTypeList = [
  {
    name: 'Region',
    code: TypeLandCode.region
  },
  {
    name: 'Campaign',
    code: TypeLandCode.campaign
  }
]

export const ImportNewTypeList = [
  {
    name: 'Region',
    code: TypeLandCode.region
  },
  {
    name: 'Area',
    code: TypeLandCode.land
  },
  {
    name: 'Campaign',
    code: TypeLandCode.campaign
  }
]

export const ImportOldDonationsTypeList = [
  {
    name: 'Donation',
    code: TypeOldDataCode.donation
  },
  {
    name: 'Donor',
    code: TypeOldDataCode.donor
  }
]

export const LandTypes = {
  Public: 'public',
  CampaignOnly: 'campaign_only',
}


export const LandUseFor = [
  {
    name: 'Public',
    code: LandTypes.Public
  },
  {
    name: 'Campaign Only',
    code: LandTypes.CampaignOnly
  }
]

export const Yes_NO = [
  {
    name: 'Yes',
    code: 1
  },
  {
    name: 'No',
    code: 0
  }
]

export const FIELDS_SEARCH = {
  DASHBOARD: 'serial,land_code,campaign_code,import,created_at,created_user,status,mail_status',
  // DONATION: 'donation_serial,land_code,campaign_code,land_code_v2,campaign_code_v2,payment_email,payment_method,payment_first_name,payment_last_name',
  DONATION: 'payment_method,donor.payment_email,donor.payment_first_name,donor.payment_last_name',
  PARTNERS: 'name,code,status',
  REGIONS: 'name',
  LANDS: 'name,code,status',
  CAMPAIGNS: 'name,code,status',
  CODES: 'campaign_id',
  PAGES: 'name,url,type_id',
  CONTACT: 'name,email,phone',
  FAQS: 'serial,question,answer',
  DOWNLOADFILES: 'file,name,folder_id,file_extension',
  PROJECT: 'name,description',
  DONATIONEXAMPLES: 'example',
  MEDIA: 'name',
}

export const RHYTHM_LIST = [
  {
    'code': 0,
    'name': 'Once'
  },
  {
    'code': 1,
    'name': 'Monthly'
  },
  {
    'code': 3,
    'name': 'Quarterly'
  },
  {
    'code': 6,
    'name': 'Biannually'
  },
  {
    'code': 12,
    'name': 'Annually'
  }
];

export const SUBSCRIPTION_RHYTHM_LIST = [
  {
    'code': 1,
    'name': 'Monthly'
  },
  {
    'code': 12,
    'name': 'Yearly'
  }
];


export const DONATION_STATUS_LIST = [
  {
    code: 'completed', name: { de: 'Succeeded', en: 'Succeeded' }
  },
  {
    code: 'draft', name: { de: 'Pending', en: 'Pending' }
  },
  {
    code: 'reserved', name: { de: 'Reserved', en: 'Reserved' }
  },
  {
    code: 'failed', name: { de: 'Failed', en: 'Failed' }
  },
  {
    code: 'cancelled', name: { de: 'Cancelled', en: 'Cancelled' }
  },
  {
    code: 'refunded', name: { de: 'Refunded', en: 'Refunded' }
  }
]

export const COUPON_STATUS_LIST = [
  {
    code: 'active', name: { de: 'Active', en: 'Active' }
  },
  {
    code: 'finished', name: { de: 'Finished', en: 'Finished' }
  }
]

export const WIR_STATUS_LIST = [
  {
    code: 'new', name: { de: 'New', en: 'New' }
  },
  {
    code: 'active', name: { de: 'Active', en: 'Active' }
  },
  {
    code: 'completed', name: { de: 'Succeeded', en: 'Succeeded' }
  },
]

export const CODE_STATUS_LIST = [
  {
    code: 'active', name: { de: 'Active', en: 'Active' }
  },
  {
    code: 'redeemed', name: { de: 'Redeemed', en: 'Redeemed' }
  },
  {
    code: 'inactive', name: { de: 'Inactive', en: 'Inactive' }
  },
  {
    code: 'invalid', name: { de: 'Invalid', en: 'Invalid' }
  }
] 

export const GOAL_STATUS_LIST = [
  {
    code: 'active', name: { de: 'Active', en: 'Active' }
  },
  {
    code: 'completed', name: { de: 'Succeeded', en: 'Succeeded' }
  },
]

export const GOAL_TYPES = {
  REGION: "region",
  CAMPAIGN: "campaign"
}

export const SEND_MAIL_STATUS = {
  COMPLETED: 'completed',
  NOT_YET: 'not_yet',
  FAILED: 'failed',
  NOT_APPLICABLE: 'not_applicable',
  NOT_COMPLETED: 'not_completed'
}

export const SUBSCRIPTION_STATUS = [
  {code: 'active', name: {de: 'Active', en: 'Active' }},
  {code: 'finished', name: { de: 'Finished', en: 'Finished' }}
];

export const MAILINGS_STATUS = {
  NEW: 'new',
  INPROGRESS: 'inprogress',
  COMPLETED: 'completed',
  INCOMPLETE: 'incomplete',
  OBSOLETED: 'obsoleted'
};

export const MAILING_STATUS = [
  {
    code: SEND_MAIL_STATUS.COMPLETED, name: { de: 'Succeeded', en: 'Succeeded' }
  },
  {
    code: SEND_MAIL_STATUS.NOT_YET, name: { de: 'Not Yet', en: 'Not Yet' }
  },
  {
    code: SEND_MAIL_STATUS.NOT_COMPLETED, name: { de: 'Not Completed', en: 'Not Completed' }
  },
  {
    code: SEND_MAIL_STATUS.FAILED, name: { de: 'Failed', en: 'Failed' }
  },
  {
    code: SEND_MAIL_STATUS.NOT_APPLICABLE, name: { de: 'Not Applicable', en: 'Not Applicable' }
  }
]

export const PAYMENT_METHODS = {
  CREDIT_CARD: 'card',
  SEPA: 'sepa_debit',
  SOFORT: 'sofort',
  APPLE_PAY: 'apple_pay',
  GOOGLE_PAY: 'google_pay',
  PAYPAL: 'paypal_pay',
  WIKANDO_LASTSCHRIFT: 'wikando_lastschrift',
  LASTSCHRIFT: 'lastschrift',
  KLARNA: 'klarna',
  UBERWEISUNG: 'uberweisung',
  IMPORTED: 'imported',
  UNKNOWN: 'unknown',
  CODE: 'code',
  LINK: 'link',
  BARGELD: 'bargeld'
}

export const DONATION_PAYMENT_METHOD_LIST = [
  { name: { de: "Überweisung", en: "Überweisung" }, code: PAYMENT_METHODS.UBERWEISUNG },
  { name: { de: "Bargeld", en: "Bargeld" }, code: PAYMENT_METHODS.BARGELD },
  { name: { de: "Unknown", en: "Unknown" }, code: PAYMENT_METHODS.UNKNOWN },
  { name: { de: "Apple Pay", en: "Apple Pay" }, code: PAYMENT_METHODS.APPLE_PAY },
  { name: { de: "Code", en: "Code" }, code: PAYMENT_METHODS.CODE },
  { name: { de: "Google Pay", en: "Google Pay" }, code: PAYMENT_METHODS.GOOGLE_PAY },
  { name: { de: "Imported", en: "Imported" }, code: PAYMENT_METHODS.IMPORTED },
  { name: { de: "Klarna", en: "Klarna" }, code: PAYMENT_METHODS.KLARNA },
  { name: { de: "Kreditkarte", en: "Kreditkarte" }, code: PAYMENT_METHODS.CREDIT_CARD },
  { name: { de: "Link", en: "Link" }, code: PAYMENT_METHODS.LINK },
  { name: { de: "PayPal", en: "PayPal" }, code: PAYMENT_METHODS.PAYPAL },
  { name: { de: "SEPA Lastschrift", en: "SEPA Lastschrift" }, code: PAYMENT_METHODS.SEPA },
  { name: { de: "Sofort", en: "Sofort" }, code: PAYMENT_METHODS.SOFORT },
  // { name: { de: "Wikando Lastschrift", en: "Wikando Lastschrift" }, code: PAYMENT_METHODS.WIKANDO_LASTSCHRIFT },
  // { name: { de: "Lastschrift", en: "Lastschrift" }, code: PAYMENT_METHODS.LASTSCHRIFT },
]

export const DONATION_TYPE_LIST = [
  { name: { de: "Region", en: "Region" }, code: 'land' },
  { name: { de: "Campaign", en: "Campaign" }, code: 'campaign' },
  { name: { de: "Code", en: "Code" }, code: 'code' },
  { name: { de: "Wilderness Run", en: "Wilderness Run" }, code: 'wilderness_run' },
]

export const DONATION_CURRENCY_LIST = [
  {
    name: { de: 'EUR', en: 'EUR' },
    code: 'eur'
  },
  {
    name: { de: 'CHF', en: 'CHF' },
    code: 'chf'
  },
  {
    name: { de: 'CAD', en: 'CAD' },
    code: 'cad'
  }
];

export const LIST_FIELD_EXPORT = [
  {
    name: "Serial",
    code: "donation_serial"
  },
  {
    name: "Salutation",
    code: "payment_salutation"
  },
  {
    name: "First Name",
    code: "payment_first_name"
  },
  {
    name: "Last Name",
    code: "payment_last_name"
  },
  {
    name: "Email",
    code: "payment_email"
  },
  {
    name: "Address",
    code: "payment_address"
  },
  {
    name: "Postal Code",
    code: "payment_post_code"
  },
  {
    name: "City",
    code: "payment_city"
  },
  {
    name: "Country Code",
    code: "payment_country"
  },
  {
    name: "Additional Address",
    code: "payment_additional_address"
  },
  {
    name: "Sent Receipt?",
    code: "is_sent_receipt"
  },
  {
    name: "Payment Method",
    code: "payment_method"
  },
  {
    name: "Transaction ID",
    code: "payment_transaction_id"
  },
  {
    name: "Status",
    code: "status"
  },
  {
    name: "Payment Date",
    code: "payment_date"
  },
  {
    name: "Payment Received Date",
    code: "payment_received_date"
  },
  {
    name: "Rhythm",
    code: "rhythm"
  },
  {
    name: "Language",
    code: "payment_language_code"
  },
  {
    name: "Currency Unit",
    code: "payment_currency_code"
  },
  {
    name: "Amount",
    code: "donation_money"
  },
  {
    name: "Forest Size (m²)",
    code: "area"
  },
  {
    name: "CO₂ (kg)",
    code: "amount_kg_co2"
  },
  {
    name: "Donate Type",
    code: "donation_type"
  },
  {
    name: "Geo-coordinate (DMS)",
    code: "nw_geo_coordinate"
  },
  {
    name: "Is Imported",
    code: "is_imported"
  }, 
  {
    name: "As Company",
    code: "as_company"
  }, 
  {
    name: "Company",
    code: "payment_company_name"
  }, 
  {
    name: "Personalize Certificate",
    code: "personalize_certificate"
  },
  {
    name: "Certificate Name",
    code: "personalize_certificate_display_name"
  }, 
  {
    name: "Occasion",
    code: "personalize_certificate_reason"
  },
  {
    name: "Subscribe To Newsletter",
    code: "subscribe_newsletter"
  },
  {
    name: "Area Code",
    code: "land_code"
  },
  {
    name: "Campaign Code",
    code: "campaign_code"
  }
]

export const SKIP_GRID = [
  0,
  1,
  2,
  3,
  4,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  33,
  37,
  38,
  40,
  41,
  42,
  51,
  56,
  58,
  59,
  61,
  62,
  63,
  79,
  84,
  86,
  87,
  88,
  100,
  102,
  103,
  104,
  105,
  106,
  112,
  113,
  114,
  127,
  128,
  129,
  130,
  131,
  132,
  137,
  138,
  139,
  140,
  142,
  144,
  153,
  154,
  155,
  156,
  157,
  158,
  159,
  161,
  162,
  163,
  164,
  165,
  172,
  174,
  177,
  178,
  179,
  180,
  181,
  182,
  183,
  184,
  185,
  186,
  189,
  190,
  191,
  198,
  200,
  203,
  204,
  205,
  206,
  207,
  208,
  209,
  210,
  211,
  212,
  213,
  216,
  217,
  218,
  219,
  223,
  224,
  225,
  226,
  227,
  228,
  229,
  232,
  233,
  234,
  235,
  236,
  237,
  238,
  243,
  244,
  245,
  250,
  251,
  252,
  253,
  255,
  256,
  257,
  259,
  260,
  261,
  262,
  263,
  264,
  265,
  269,
  270,
  271,
  272,
  274,
  275,
  276,
  277,
  278,
  279,
  280,
  284,
  285,
  286,
  287,
  288,
  289,
  290,
  291,
  294,
  295,
  296,
  297,
  298,
  299,
  300,
  301,
  302,
  303,
  304,
  305,
  306,
  310,
  312,
  313,
  314,
  315,
  316,
  317,
  320,
  321,
  322,
  323,
  324,
  325,
  326,
  327,
  328,
  329,
  330,
  331,
  332,
  333,
  334,
  335,
  336,
  338,
  339,
  340,
  341,
  342,
  343,
  345,
  346,
  347,
  348,
  349,
  350,
  351,
  352,
  353,
  354,
  355,
  356,
  357,
  358,
  359,
  360,
  361,
  362,
  363,
  364
];

export const REMOVE_ALL = [
  {
    name: {
      en: 'clear all',
      de: 'clear all'
    },
    uuid: -1
  }
]

export const MediaType = [
  { name: 'General', key: 'General' },
  { name: 'Image', key: 'Image' },
  { name: 'Video', key: 'Video' },
  { name: 'Document', key: 'Document' },
  { name: 'Compressed', key: 'Compressed' }
];

export const HEADERS : any = {
  PRIMARY: {
    name: {
      de: "Primary Header",
      en: "Primary Header"
    },
    value: 'Primary'
  },
  SECONDARY: {
    name: {
      de: "Custom Header",
      en: "Custom Header"
    },
    value: 'Secondary'
  }
};

export const FOOTERS : any = {
  PRIMARY: {
    name: 'Full Footer',
    value: 'Primary'
  },
  SECONDARY: {
    name: 'Light Footer',
    value: 'Secondary'
  }
}

export const CODE_BLOCKS = {
  INSTAGRAM: "instagram-block",
  OVERVIEW: "overview-block",
  EIGHT_STEP: "block_8_steps",
  ONE_EURO: "block_1_euro"
}

export const GLOBAL_BLOCKS = [
  CODE_BLOCKS.INSTAGRAM,
  CODE_BLOCKS.EIGHT_STEP,
  CODE_BLOCKS.ONE_EURO]

export const removeEmpty = (obj: any) => {
  return Object.keys(obj).reduce((acc: any, key) => {
    const _acc = acc;
    if (obj[key] !== undefined) _acc[key] = obj[key];
    return _acc;
  }, {})
}

export const SALUTATION_LIST = {
  DE: [
    {
        "key": "Mr.",
        "value": "Herr"
    },
    {
        "key": "Ms.",
        "value": "Frau"
    },
    {
        "key": "Couple",
        "value": "Ehepaar"
    },
    {
        "key": "Diverse",
        "value": "Divers"
    },
    {
        "key": "Family",
        "value": "Familie"
    }
  ],
  EN: [
    {
        "key": "Mr.",
        "value": "Mr."
    },
    {
        "key": "Ms.",
        "value": "Ms."
    },
    {
        "key": "Couple",
        "value": "Couple"
    },
    {
        "key": "Diverse",
        "value": "Diverse"
    },
    {
        "key": "Family",
        "value": "Family"
    }
  ]
}

export const DONATION_STATUS = {
  SUCCEEDED: 'completed',
  PENDING: 'draft',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  REFUNDED: 'refunded',
  RESERVED: 'reserved'
}

export const WIR_STATUS = {
  NEW: 'new',
  ACTIVE: 'active',
  COMPLETED: 'completed'
}

export const SPONSOR_STATUS_LIST = {
  PAID: 'Paid',
  UNPAID: 'Unpaid'
}

export const LIST_TEMPLATE_PREVIEW = [
  {
    name: "Default",
    uuid: "40e8db43-0997-4ed4-947a-7930fbf47539"
  },
  {
    name: "Inclusive 1",
    uuid: "c081b3a0-a771-4252-83ba-51f3bc43c61c"
  },
]

export const showEvents = [
  'donation.started', 
  'payment_intent.created', 
  'payment_intent.succeeded',
  'charge.succeeded', 
  'checkout.session.completed', 
  'certificate.sent.succeeded', 
  'certificate.sent.failed', 
  'payment_intent.payment_failed', 
  'payment_intent.canceled', 
  'checkout.session.async_payment_failed', 
  'receipt.sent.succeeded', 
  'receipt.sent.failed', 
  'charge.refund.updated', 
  'payment.refunded', 
  'charge.dispute.created', 
  'payment.refund.failed', 
  'charge.refunded', 
  'donation.generated', 
  'donation.redeemed',
  'invoice.created',
  'invoice.payment_succeeded',
  'invoice.payment_failed',
  'customer.subscription.created',
  'customer.subscription.deleted',
  'customer.subscription.updated'
];

export const showMessageEvent = [
  'payment_intent.payment_failed', 
  'checkout.session.async_payment_failed', 
  'receipt.sent.failed', 
  'certificate.sent.failed'
];

export const showIconEvents = [
  'payment_intent.payment_failed', 
  'charge.refund.updated', 
  'charge.dispute.created', 
  'checkout.session.async_payment_failed', 
  'payment.refunded', 
  'payment_intent.canceled', 
  'receipt.sent.failed', 
  'certificate.sent.failed', 
  'payment.refund.failed', 
  'charge.refunded',
  'invoice.payment_failed'
];

export const getCO2Factor = (landInfo: any, currencyCode: string) => {
  // TODO: need load from config
  // return 60;
  return landInfo?.amount_co2_conversion?.[currencyCode] || 60;
} 

export const fieldsRegionForm = {
  general: ['name', 'headline', 'identifier', 'forest_type', 'region_logo'],
  certificate: ['certificate_description'],
  email: ['email_template_id', 'email_header_picture', 'email_additional_picture'],
  donationExample: ['donation_example_list'],
  thankyou: ['video_url', 'video_headline']
}