import * as React from "react";
import { useEffect, useState } from "react";
import "./coupon-filter-component.scss";
import { Button } from "primereact/button";
import {
    LandManagerAPI,
    PartnerManagerAPI,
    RegionManagerAPI
} from "../../../../services";
import { WISearchField } from "../../../common";
import { COUPON_STATUS_LIST } from "../../../../utils/utils"; 
import _ from "lodash";
import FilterField from "../../../donation-manager/components/donation-filter/filter-field";

const CouponFilterComponent = (props: any) => {
    const { fields, filterValues, lang, onRemove, onChange, onSearchFunc } = props;
    const [area, setArea] = useState<any[]>([]);
    const [region, setRegion] = useState<any[]>([]);
    const [partners, setPartners] = useState<any[]>([]);
    const [status, setStatus] = useState<any[]>(COUPON_STATUS_LIST);
    const [values, setValues] = useState<Map<string, string[]>>();
    const [isDirty, setDirty] = useState<boolean>(false);
    const [search, setSearch] = useState<any>(filterValues.search);

    const getAllOptionsData = async () => {
        const [areaRes, partners, regions] = await Promise.all([
            LandManagerAPI.getAllLands(),
            PartnerManagerAPI.getAllPartners(),
            RegionManagerAPI.getAllRegions(),
        ]);
        if (areaRes.status === 200 && areaRes.data.records.length > 0) {
            areaRes.data.records.forEach((element: any) => {
                element.name = _.isString(element.name) ? element.name : element.name?.de
            });
            setArea(areaRes.data.records);
        }
        if (partners.status === 200 && partners.data.records.length > 0) {
            setPartners(partners.data.records);
        }
        if (regions.status === 200 && regions.data.records.length > 0) {
            setRegion(regions.data.records);
        }
    };

    const getFields = () => {
        const resetValues = new Map<string, string[]>();
        fields.forEach((item: string) => {
            resetValues.set(item, []);
        });
        setValues(resetValues);
    };

    const ucFirst = (text: string): string => {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    useEffect(()=> {
        getAllOptionsData();
        getFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const filter = new Map<string, string[]>();
        fields.forEach((k: string) => {
            filter.set(k, filterValues.where[k]);
        });
        setValues(filter);
        setSearch(filterValues.search);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues]);

    const onRemoveFilters = async () => {
        fields.forEach(async (item: string) => {
            values?.set(item, []);
        });
        setSearch('');
        await onRemove();
    };

    const onFilterChange = async (event: any) => {
        setDirty(true);
        values?.set(event.field, event.value);
        await onChange({field: event.field, value: event.value});  
        setSearch(search);
    };
    
    return (
        <div className="filter-coupon-component">
            <div className="flex align-items-center flex-wrap justify-content-between filter-panel">
                <div className="filter-container">
                    <WISearchField
                        icon={"pi pi-search"}
                        placeholder="Search for anything"
                        setSearch={(value: any) => setSearch(value.global.value)}
                        enterSearch={() => onSearchFunc(search)}
                        globalValue={search}
                    />
                    <FilterField
                        name="land_id"
                        label="Area"
                        className="wi-filter-option-coupon"
                        pannelClassName="overlaypanel-filter-coupons"
                        selectedItems={values?.get("land_id") || []}
                        options={area}
                        optionLabel={"name"} 
                        optionValue={"uuid"}
                        visible={values?.has("land_id") || false}
                        isDirty={isDirty}
                        setDirty={setDirty}
                        onChange={onFilterChange}
                        filter
                        filterBy="name"
                    />
                     <FilterField
                        name="region_id"
                        label="Region"
                        className="wi-filter-option-coupon"
                        pannelClassName="overlaypanel-filter-coupons"
                        selectedItems={values?.get("region_id") || []}
                        options={region}
                        optionLabel={"name.de"}
                        optionValue={"uuid"}
                        visible={values?.has("region_id") || false}
                        isDirty={isDirty}
                        setDirty={setDirty}
                        onChange={onFilterChange}
                        filter
                        filterBy="name.de,code"
                    />
                    <FilterField
                        name="partner_id"
                        label="Partner"
                        className="wi-filter-option-coupon"
                        pannelClassName="overlaypanel-filter-coupons"
                        selectedItems={values?.get("partner_id") || []}
                        options={partners}
                        optionLabel={"name"}
                        optionValue={"uuid"}
                        visible={values?.has("partner_id") || false}
                        isDirty={isDirty}
                        setDirty={setDirty}
                        onChange={onFilterChange}
                        filter
                        filterBy="name"
                    />
                    <FilterField
                        name="status"
                        label="Status"
                        className="wi-filter-option-coupon"
                        pannelClassName="overlaypanel-filter-coupons"
                        selectedItems={values?.get("status") || []}
                        options={status}
                        optionLabel={"name.de"}
                        optionValue={"code"}
                        visible={values?.has("status") || false}
                        isDirty={isDirty}
                        setDirty={setDirty}
                        onChange={onFilterChange}
                    />
                    {/* <CalendarField
                        name="dates"
                        label="Dates"
                        className="wi-filter-option-coupon"
                        pannelClassName="overlaypanel-filter-coupons"
                        selectedItems={values?.get("dates") || []}
                        visible={values?.has("dates") || false}
                        isDirty={isDirty}
                        setDirty={setDirty}
                        onChange={onFilterChange}
                    /> */}
                </div>
                <div className="remove-filter-button">
                <Button
                    type="button"
                    icon="pi pi-times"
                    label="Clear filter(s)"
                    onClick={onRemoveFilters}
                />
                </div>
            </div>
        </div>
    );
};

export default CouponFilterComponent;
