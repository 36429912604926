import { useRef, useState } from "react";
import React from "react";
import "./add-wilderness-run-form.scss";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { parseStringToInt, showNotification } from "../../../../utils/logic";
import { Dropdown } from "primereact/dropdown";
import { WIButton } from "../../../common";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import WIUploadMediaField from "../../../common/upload/wi-upload-media-field";
import _ from "lodash";
import { env } from "../../../../environment";
import { WildernessRunsManagerAPI } from "../../../../services";
import { formatImageFileURL, getThumbnailFullpath } from "../../../../utils/mediaUtils";

const partner_none: any = [
  {
      name: 'NONE',
      uuid: ''
  }
]

const PartnerItem = (props: any) => {
  const { option } = props;
  return (
      <div className="global-page-item">
          <div className="global-page-picture">
              {option.partner_logo ?
              <img 
              src={`${env.PUBLIC_UPLOAD_URL}/${getThumbnailFullpath(formatImageFileURL(option.partner_logo))}?v=${moment(option.changed).unix()}`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src= `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(option.partner_logo)}`;
              }}
              alt={option.name} /> 
              : ''}
          </div>
          <div className="page-details">
              <div className="global-page-name">{option.name}</div>
              <div className="global-page-position">{option.code}</div>
          </div>
      </div>
  );
}

const AddWildernessFormComponent = (props: any) => {
  const { onHide, fetchCallBack, toast, wildernessRunData, lands, partners } = props;
  const formikRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitWIR = async () => {
    try {
        // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && dirty && isValid) {
        setIsLoading(true);
        let valuesData = {
          ...values,
          run_date: moment.utc(moment(new Date(values.run_date)).format("MM/DD/YYYY HH:mm:ss")).toISOString(),
          unit_length: +values.unit_length,
          logo: values.logo || ""
        };

        if (!valuesData.partner_id) {
          delete valuesData['partner_id']
        }

        let res = wildernessRunData 
          ? await WildernessRunsManagerAPI.updateWildernessRun(wildernessRunData.id, valuesData) 
          : await WildernessRunsManagerAPI.generateWildernessRun(valuesData);
        if (res?.status === 200 && res?.data) {
          showNotification(
            "success",
            `${wildernessRunData ? 'Update' : 'Generate'} wilderness run successfully.`,
            toast
          );
          
          onHide();
          fetchCallBack(res.data);
        }
        setIsLoading(false);
      }
    } catch {
        showNotification("error", "Check wilderness run failed.", toast);
        setIsLoading(false);
    }
  };

  const initialFormValues = () => {
    let initialWildernessRun = {
      partner_id: "",
      name: "",
      run_date: moment().toDate(),
      location: "",
      unit_length: 0,
      land_id: '',
      contact_person_name: "",
      contact_person_email: "",
      logo: "",
      certificate_occasion: ''
    };

    if(wildernessRunData) {
      initialWildernessRun.partner_id = wildernessRunData.partner_id || '';
      initialWildernessRun.name = wildernessRunData.name || '';
      initialWildernessRun.run_date = new Date(wildernessRunData.run_date) || moment().toDate();
      initialWildernessRun.location = wildernessRunData.location || '';
      initialWildernessRun.unit_length = wildernessRunData.unit_length || 0;
      initialWildernessRun.land_id = wildernessRunData.land_id || '';
      initialWildernessRun.contact_person_name = wildernessRunData.contact_person_name || '';
      initialWildernessRun.contact_person_email = wildernessRunData.contact_person_email || '';
      initialWildernessRun.logo = wildernessRunData.logo || '';
      initialWildernessRun.certificate_occasion = wildernessRunData.certificate_occasion || '';
    }

    return initialWildernessRun;
  };

  const validationSchema = Yup.object().shape({
    // partner_id: Yup.string().nullable().required("This field is required."),
    name: Yup.string().nullable().required("This field is required."),
    run_date: Yup.string().nullable().required("This field is required."),
    location: Yup.string().nullable().required("This field is required."),
    land_id: Yup.string().nullable().required("This field is required."),
    contact_person_name: Yup.string().nullable().required("This field is required."),
    contact_person_email: Yup.string().email("Email is invalid format").required("This field is required"),
    unit_length: Yup.number().nullable().min(1, "This field must be greater than 1.").required("This field is required."),
  });

  const formatOptionTemplate = (option: any) => {
    return (
      <div>
        {option.name.de} - Available size [
        {option ? parseInt(option.available_size)?.toLocaleString("de-DE") : 0} m<sup>2</sup>]{" "}
      </div>
    );
  };

  const selectedOptionTemplate = (
    option: any,
    values: any,
    props: any
  ) => {
    return option ? (
      formatOptionTemplate(option)
    ) : (
      <span>{props.placeholder}</span>
    );
  };

  const getPartnerList = (partners: any[]) => {
    return partners ? partner_none.concat(_.sortBy(partners, (item) => item.name?.toLowerCase())) : [];
  }

  return (
    <>
      <div className="add-wir-form">
        <div className="add-wir-form-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
            }}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              isValid,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleChange,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">Partner <span className="required-label">*</span></label>
                        <Dropdown
                            id="partner_id"
                            name="partner_id"
                            value={values.partner_id}
                            options={getPartnerList(partners)}
                            scrollHeight={'500px'}
                            filter
                            filterBy={['name', 'code'].join(",")}
                            onChange={(item: any) => {
                              setFieldValue('partner_id', item.value, false);
                              const itemselect = _.find(partners, {uuid: item.value});
                              setFieldValue('logo', itemselect?.partner_logo, false);
                            }}
                            itemTemplate={(option) => (<PartnerItem option={option}></PartnerItem>)}
                            optionLabel="name"
                            optionValue="uuid"
                            appendTo="self"
                            placeholder="Choose a partner"
                        />
                        <div className="helper-text">
                            <i className="fa-solid fa-circle-info"></i> Shows only <b className="helper-bold">active</b> partners
                        </div>
                        <div className={`${touched.partner_id && errors.partner_id ? 'error' : ''}`}>
                            {touched.partner_id && errors.partner_id}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">
                          Name
                          <span className="required-label"> *</span>
                        </label>
                        <InputText
                          className={`${
                            touched.name && errors.name
                              ? "has-error"
                              : ""
                          }`}
                          value={values.name}
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={50}
                        />
                        <div
                          className={`${
                            touched.name && errors.name
                              ? "error"
                              : ""
                          }`}
                        >
                          {`${
                            touched.name && errors.name
                              ? errors.name
                              : ""
                          }`}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">
                          Date
                          <span className="required-label"> *</span>
                        </label>
                        <div className="calendar-item">
                          <Calendar
                            id="basic"
                            name="run_date"
                            panelClassName="expire-calendar"
                            value={values.run_date}
                            onBlur={handleBlur}
                            onChange={(item) => {
                              setFieldValue("run_date", item.value, false);
                            }}
                            dateFormat="dd.mm.yy"
                            iconPos="right"
                            icon="fa-solid fa-calendar-days"
                            showIcon={true}
                          />
                        </div>
                        <div
                          className={`${
                            touched.run_date && errors.run_date
                              ? "error"
                              : ""
                          }`}
                        >
                          {`${
                            touched.run_date && errors.run_date
                              ? errors.run_date
                              : ""
                          }`}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">
                          Location
                          <span className="required-label"> *</span>
                        </label>
                        <InputText
                          className={`${
                            touched.location && errors.location
                              ? "has-error"
                              : ""
                          }`}
                          value={values.location}
                          name="location"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={50}
                        />
                         <div
                          className={`${
                            touched.location && errors.location
                              ? "error"
                              : ""
                          }`}
                        >
                          {`${
                            touched.location && errors.location
                              ? errors.location
                              : ""
                          }`}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">
                          Unit length (m)
                          <span className="required-label"> *</span>
                        </label>
                        <div>
                        <InputNumber
                          className={`${
                            touched.unit_length && errors.unit_length
                              ? "has-error"
                              : ""
                          }`}
                          value={values.unit_length}
                          name="unit_length"
                          onBlur={(e) => {
                              const updateValue = e.target.value.split(",")[0];
                              const value = updateValue ? parseStringToInt(updateValue) : 0;
                              setFieldValue('unit_length', value, false);
                              setTimeout(() => {
                                  handleBlur(e);
                              }, 200);
                          }}
                          min={1}
                          placeholder="0"
                          locale="de-DE"
                      />
                        <div
                          className={`${
                            touched.unit_length && errors.unit_length
                              ? "error"
                              : ""
                          }`}
                        >
                          {`${
                            touched.unit_length && errors.unit_length
                              ? errors.unit_length
                              : ""
                          }`}
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">
                          Area
                          <span className="required-label"> *</span>
                        </label>
                        <Dropdown
                          name="land_id"
                          id="land_id"
                          value={values.land_id}
                          onBlur={handleBlur}
                          options={lands || []}
                          onChange={(item: any) => {
                            setFieldValue("land_id", item.value, false);
                          }}
                          optionLabel={`name.de`}
                          optionDisabled={(option) => option.available_size < 0
                          }
                          itemTemplate={(option) =>
                            formatOptionTemplate(
                              option,
                            )
                          }
                          valueTemplate={(option, props) =>
                            selectedOptionTemplate(
                              option,
                              values,
                              props
                            )
                          }
                          optionValue={"uuid"}
                          appendTo="self"
                          placeholder={`Choose an area`}
                          disabled={wildernessRunData?.actual_paid_money > 0}
                        />
                        <div
                          className={`${
                            touched.land_id && errors.land_id ? "error" : ""
                          }`}
                        >
                          {`${
                            touched.land_id && errors.land_id
                              ? errors.land_id
                              : ""
                          }`}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">Contact person name <span className="required-label"> *</span></label>
                        <InputText
                          className={`${
                            touched.contact_person_name && errors.contact_person_name
                              ? "has-error"
                              : ""
                          }`}
                          value={values.contact_person_name}
                          name="contact_person_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={80}
                        />
                        <div className={`${touched.contact_person_name && errors.contact_person_name ? 'error' : ''}`}>
                            {touched.contact_person_name && errors.contact_person_name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">Contact person email <span className="required-label"> *</span></label>
                        <InputText
                          className={`${
                            touched.contact_person_email && errors.contact_person_email
                              ? "has-error"
                              : ""
                          }`}
                          value={values.contact_person_email}
                          name="contact_person_email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={255}
                        />
                        <div className={`${touched.contact_person_email && errors.contact_person_email ? 'error' : ''}`}>
                            {touched.contact_person_email && errors.contact_person_email}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">Certificate occasion for donor</label>
                        <InputText
                          value={values.certificate_occasion}
                          name="certificate_occasion"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={80}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form">
                        <WIUploadMediaField
                          type="file"
                          hideLabelFileName={true}
                          classImage="image-cert"
                          className="wir_logo"
                          name="logo"
                          label="Logo (recommended: PNG 1:1)"
                          classField="logo-form"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="btn-submit">
                    <WIButton
                      className="add-wir"
                      primary={true}
                      type="submit"
                      label={wildernessRunData ? 'Update' : 'Add'}
                      icon={"pi-angle-right"}
                      onClick={() => onSubmitWIR()}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddWildernessFormComponent;
