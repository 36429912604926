import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import "./import-reference-codes-dialog.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import CouponDetailAPI from "../../../../services/couponDetail";
import { WICSVUploadField } from "../../../common";
import { SelectButton } from "primereact/selectbutton";
import { showNotification } from "../../../../utils/logic";

const ImportReferenceCodesDialogComponent = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { visible, onHide, fetchCouponDataHeader, getCouponData, id } = props;

  const toast = useRef(null);
  const formikRef = useRef(null);

  const importFormSchema = Yup.object().shape({
    dataImport: Yup.string()
      .required('This field is required.'),
  });

  const initialValues = () => {
    return {
      dataImport: "",
    }
  };

  const handleImportUngivenCodesCSV = async (import_data: any) => {
    try {
      let data = {
        _batch: {
          data: import_data.dataImport
        },
        batchId: id
      };
  
      const res = await CouponDetailAPI.importReferenceCodes(data);
      console.log(res);
      if (res && res.status === 200) {
        showNotification("success", "Import successfully", toast);
        fetchCouponDataHeader();
        getCouponData();
        onHide();
      } else {
        showNotification("error", "Import failed", toast);
      }
    } catch (error) {
      console.log(error);
      showNotification("error", "Import failed", toast);
    }
    
    setIsLoading(false);
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        className="wi-dialog"
        header={`Import References & 2FA`}
        visible={visible}
        style={{ width: "350px" }}
        modal
        onHide={onHide}
      >
        <div className="coupon-detail-import-container">
          <div className="import-btn">
            <Formik
              innerRef={formikRef}
              initialValues={initialValues()}
              validationSchema={importFormSchema}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                handleImportUngivenCodesCSV(values);
              }}
            >
              {({
                values,
                dirty,
                isValid,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-start upload-file">
                      <WICSVUploadField
                        name="dataImport"
                        className="input-upload"
                        labelClassName="label-upload-csv"
                        nameFileClassName="name-file" />
                    </div>
                    <div className="col-12 d-flex flex-column align-items-start justify-content-start column-message">
                      <p>Column Order: <b>Code</b>, <b>Reference Code</b>, <b>Text 2FA</b></p>
                    </div>
                    <div className="col-12 d-flex flex-column align-items-start justify-content-start note-message">
                      <p>Please make sure that all data are correct. <br/> This cannot be undone.</p>
                    </div>
                    <div className="btn-import">
                      <Button
                        loading={isLoading}
                        disabled={!(isValid && dirty)}
                        type="submit"
                        label="Submit"
                        iconPos="right"
                        className="btn-submit p-button-rounded wi-button">
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ImportReferenceCodesDialogComponent;